import { EVENTS, SEVERITY_TEXT, STAGES } from '../../constants';
import { log } from '../../logging';
import { getDynamicHttpLogAttributes } from '../../utils/getDynamicHttpLogAttributes';
// import { incrementMetricsCounter } from '../../metrics';
import { withSafelyExecute } from '../../utils/withSafelyExecute';
import { getIdsFromSpan, setOtToHeaders } from '../../utils';

function otlFetchStart(span, url, config) {
    const { method, headers, body } = config;
    setOtToHeaders(config, span);
    span.addEvent(EVENTS.REQUEST_STARTED);
    log(`Starting request to ${url}`, SEVERITY_TEXT.INFO, {
        url,
        method,
        ...getIdsFromSpan(span),
        ...getDynamicHttpLogAttributes({ url, stage: STAGES.REQUEST, headers, params: body }),
    });
    // incrementMetricsCounter(method, url);
}

export default withSafelyExecute(otlFetchStart);

import isFunction from 'lodash/isFunction';
import initialWsOtl from './initialWsOtl';
import { getWsOt } from '../../utils';

/**
 * Wraps a callback function with OpenTelemetry tracing context for WebSocket operations.
 *
 * @param {Object} payload - The payload data for the WebSocket operation.
 * @param {string|number} payload.serial - The serial number of the hub.
 * @param {string} payload.method - json-rpc method name.
 * @param {Object} payload.params - json-rpc params.
 * @param {Function} fn - The function to call.
 * @returns {Promise} The result of the called function, potentially wrapped with tracing logic.
 */
export function withOtl(payload, fn) {
    const { span, onSuccess, onError } = initialWsOtl(payload) || {};

    if (!span || !isFunction(onSuccess) || !isFunction(onError)) {
        return fn(payload.method, payload.params);
    }

    return fn(payload.method, payload.params, getWsOt(span)).then(onSuccess).catch(onError);
}

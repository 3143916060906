import { convertToJson, getFilesMetadata, removeUndefinedFields } from './shared';
import { withSafelyExecute } from './withSafelyExecute';
import { getType } from './getType';
import { store } from 'store/configureStore';
import { decode } from 'js-base64';
import { decodeCloudToken } from 'containers/Ezlo/EzloCustomization/utils';
import * as localStorageKeys from 'constants/localStorageKeys';
import { isObject } from 'lodash';
import { BEARER } from 'containers/Ezlo/EzloRule/EditForm/RuleSettings/components/PAAS/paas-constants';

/**
 * Converts a token using a decode function and returns its JSON string representation.
 *
 * @param {string} token - The token to be decoded.
 * @param {Function} decode - The decode function to apply to the token.
 * @returns {string|undefined} The JSON string representation of the decoded token, or undefined if input is invalid.
 */
export const convertTokenToJson = withSafelyExecute((token, decode) => {
    if (!token || !decode) {
        return undefined;
    }

    return JSON.stringify(decode(token));
});

/**
 * Extracts a token from an authorization header string.
 *
 * @param {string} authorization - The authorization header string.
 * @returns {string|undefined} The extracted token, or undefined if not found.
 */
export const extractToken = withSafelyExecute((authorization) => {
    return authorization?.match(/^Bearer\s+(\S+)$/)?.[1] || authorization?.match(/^Basic\s+(\S+)$/)?.[1];
});

/**
 * Retrieves the cloud token, either from local storage or extracted from the authorization header.
 *
 * @param {string} authorization - The authorization header string.
 * @returns {string|undefined} The cloud token, or undefined if not found.
 */
export const getCloudToken = (authorization) => {
    const cloudToken = localStorage.getItem(localStorageKeys.CLOUD_TOKEN);

    if (authorization && cloudToken) {
        return cloudToken;
    }

    const token = extractToken(authorization);

    if (token) {
        return token;
    }
};

/**
 * Retrieves user-related data, including user UUID, MMS token, and cloud token.
 *
 * @param {Object} headers - The headers object containing authorization information.
 * @param {string} [headers.MMSAuth] - The MMSAuth header.
 * @param {string} [headers.authorization] - The authorization header.
 * @param {string} [headers.Authorization] - The Authorization header.
 * @returns {Object} An object containing user_uuid, mms_token, and cloud_token.
 */
export const getUserData = withSafelyExecute(
    (headers = {}) => {
        const state = store.getState();
        const { MMSAuth, authorization, Authorization } = headers;

        const { user_uuid } = state?.account?.data || {};

        return {
            user_uuid,
            mms_token: convertTokenToJson(MMSAuth, decode),
            cloud_token: convertTokenToJson(getCloudToken(authorization || Authorization), decodeCloudToken),
        };
    },
    () => ({}),
);

/**
 * Removes the signature (third part) from a JWT token in the Authorization header.
 * If the header does not contain a valid Bearer token, the function returns the headers unchanged.
 *
 * @param {Object} headers - The HTTP headers object.
 * @returns {Object} - A new headers object with the sanitized Authorization token.
 *
 * @example
 * // Input:
 * const headers = {
 *   Authorization: "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOjEyMywiZXhwIjoxNjg1NzM5MDAwfQ.UB4ST2oAOhJ5_9rdUp6-jQA12B6AsoQiraV-Vrg6mDU"
 * };
 *
 * // Output:
 * {
 *   Authorization: "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOjEyMywiZXhwIjoxNjg1NzM5MDAwfQ."
 * }
 */
function sanitizeAuthorization(headers) {
    try {
        if (!headers || !isObject(headers)) {
            return headers;
        }

        const authHeader = headers.Authorization || headers.authorization;
        if (authHeader && authHeader.startsWith(`${BEARER} `)) {
            const tokenParts = authHeader.split(' ');
            if (tokenParts.length === 2) {
                const token = tokenParts[1].split('.');
                token.pop();
                const sanitizedToken = token.join('.');

                return { ...headers, authorization: `${BEARER} ${sanitizedToken}.` };
            }
        }

        return headers;
    } catch (e) {
        return headers;
    }
}

/**
 * Generates dynamic HTTP log attributes by collecting relevant information.
 *
 * @param {Object} params - The parameters object.
 * @param {string} params.url - The URL of the request.
 * @param {'request'|'response'} params.stage - The stage of the request.
 * @param {Object} params.headers - The headers of the request.
 * @param {Object} params.data - The data payload of the request.
 * @param {*} params.params - Additional parameters of the request.
 * @returns {Object} An object containing dynamic log attributes.
 */
export const getDynamicHttpLogAttributes = withSafelyExecute(
    ({ url, stage, headers, data, params }) => {
        return removeUndefinedFields({
            type: getType(url, stage),
            ...getUserData(headers),
            data: convertToJson(data),
            params: convertToJson(params),
            headers: convertToJson(sanitizeAuthorization(headers)),
            files: convertToJson(getFilesMetadata(params)),
        });
    },
    () => ({}),
);

import getOt from './getOt';

/**
 * Retrieves OpenTelemetry trace IDs specifically for WebSocket operations.
 *
 * Calls `getOt` with 'trace' and 'span' as keys to obtain an object containing
 * the trace ID and span ID for WebSocket tracing.
 *
 * @param {Object} span - OT span.
 * @returns {Object} An object containing ot with the trace ID and span ID with keys 'trace' and 'span',
 *                             or empty object if the trace IDs are unavailable.
 */
export function getWsOt(span) {
    const ot = getOt(span, 'trace', 'span');

    return { ot };
}

import {
    ACTION_EXECUTION_POLICY_LABEL_KEYS,
    SCENE_EXECUTION_POLICY_LABEL_KEYS,
} from '../../../constants/language_tokens';

export const MESHBOT_SECTION_TYPE = {
    TRIGGER: 'trigger',
    ACTION: 'action',
    GLOBAL_RESTRICTION: 'globalRestriction',
};

// should be replaced by constants from localization file
export const MESHBOT_SECTION_NAME = {
    TRIGGER: 'Trigger',
    ACTION: 'Action',
};

export const CODE_KEY = 'code';

export const NOT_NAME = 'not';

export const CLOUD = 'cloud';

export const IS_DATE_ARGS = {
    TIME: 'time',
};

export const CURRENT_VALUE = 'Current value';
export const EZLOGIC_TITLE_CONVERSION_MESSAGE_FLOAT = 'ezlogic.title.conversion_message_float';
export const EZLOGIC_TITLE_CONVERSION_MESSAGE_LOCAL = 'ezlogic.title.conversion_message_local';
export const REQUEST_TO_GET_CURRENT_VALUE_FIELD = 'Request to get current value failed.';
export const COMPARED_CLOUD_VARIABLE = 'COMPARED_CLOUD_VARIABLE';
export const TEXT = 'text';
export const NUMBER = 'number';
export const NUMERIC = 'numeric';
export const INTEGER = 'integer';
export const STRING = 'string';
export const VARIABLE_VALUE_INDEX = 3;
export const VARIABLE_NAME_INDEX = 1;
export const VARIABLE_ABSTRACT_INDEX = 0;

export const LOCAL_FORM = 'local';

export const CLOUD_VARIABLE_FILTERS = {
    VARIABLE_TYPE: {
        KEY: 'variable_type',
        VALUES: {
            INTEGER: 'integer',
            STRING: 'string',
        },
    },
};

export const DEVICE_STATE = 'deviceState';
export const CLOUD_VARIABLES = 'cloudVariables';
export const NUCAL_FIELDS = {
    INTEGRATION: 'integration',
    METHOD: 'method',
    FIELDS: 'fields',
    ACCOUNT: 'account',
};
export const CREATING_SUBSCRIPTION_ERROR = 'creating Subscriptions in trigger local meshbot';
export const EXECUTION_POLICIES = {
    INHERIT: 'inherit',
    CHECK: 'check_result',
    IGNORE: 'ignore_result',
    WAIT: 'wait_result',
};
export const EXECUTION_POLICIES_OF_ALL_ACTIONS = [
    { value: EXECUTION_POLICIES.IGNORE, labelKey: SCENE_EXECUTION_POLICY_LABEL_KEYS.IGNORE },
    { value: EXECUTION_POLICIES.WAIT, labelKey: SCENE_EXECUTION_POLICY_LABEL_KEYS.WAIT },
    { value: EXECUTION_POLICIES.CHECK, labelKey: SCENE_EXECUTION_POLICY_LABEL_KEYS.CHECK },
];
export const EXECUTION_POLICIES_OF_EACH_ACTION = [
    { value: EXECUTION_POLICIES.IGNORE, labelKey: ACTION_EXECUTION_POLICY_LABEL_KEYS.IGNORE },
    { value: EXECUTION_POLICIES.WAIT, labelKey: ACTION_EXECUTION_POLICY_LABEL_KEYS.WAIT },
    { value: EXECUTION_POLICIES.CHECK, labelKey: ACTION_EXECUTION_POLICY_LABEL_KEYS.CHECK },
    { value: EXECUTION_POLICIES.INHERIT, labelKey: ACTION_EXECUTION_POLICY_LABEL_KEYS.INHERIT },
];
export const ACTIONS_EXECUTION_POLICY_BY_DEFAULT = EXECUTION_POLICIES.IGNORE;
export const ACTION_EXECUTION_POLICY_BY_DEFAULT = EXECUTION_POLICIES.INHERIT;
export const ACTION_EXECUTION_POLICY_LEVELS = {
    SCENE: 'SCENE',
    EACH_ACTION: 'EACH_ACTION',
};
export const PDM_REQUESTED_HIDDEN_CLOUD_CAPABILITIES = [
    'fw_category',
    'fw_subcategory',
    'name',
    'user',
    'geofences',
    'geofences_all',
    'username',
    'scene_run_input',
];

import produce from 'immer';

import at from '../constants/ActionTypes/Main';
import { LOGOUT } from '../constants/ActionTypes';

const initState = {
    isShowModalCreateRule: false,
    isShowModalClearMeshBot: false,
    isShowModalDevice: false,
    isShowNavigation: false,
    abstractCapabilities: {},
    abstractsList: {},
    paas: {
        paasAuthServiceUuid: '',
    },
    editableComponent: {},
    savedAction: null,
};

const mainReducer = produce((draft = initState, action) => {
    const { type, payload = {} } = action;

    switch (type) {
        case at.IS_SHOW_MODAL_CREATE_RULE:
            draft.isShowModalCreateRule = true;
            break;

        case at.IS_SHOW_MODAL_CLEAR_MESH_BOT:
            draft.isShowModalClearMeshBot = true;
            break;

        case at.IS_SHOW_MODAL_DEVICE:
            draft.isShowModalDevice = true;
            break;

        case at.HIDE_MODAL:
            draft.isShowModalCreateRule = false;
            draft.isShowModalClearMeshBot = false;
            draft.isShowModalDevice = false;
            break;

        case at.GET_ABSTRACT_CAPABILITIES_LIST: {
            draft.abstractCapabilities = {
                isAbstractsCapabilitiesLoading: true,
                capabilities: {},
                fetchAbstractsCapabilitiesError: null,
            };
            break;
        }
        case at.GET_ABSTRACT_CAPABILITIES_LIST_SUCCESS: {
            draft.abstractCapabilities = {
                isAbstractsCapabilitiesLoading: false,
                capabilities: action.abstractCapabilities,
            };
            break;
        }
        case at.GET_ABSTRACT_CAPABILITIES_LIST_ERROR: {
            draft.abstractsList = {
                isAbstractsListLoading: false,
                capabilities: {},
                fetchAbstractsError: action.error,
            };
            break;
        }

        case at.GET_ABSTRACTS_LIST: {
            draft.abstractsList = {
                isAbstractsListLoading: true,
                abstracts: payload ?? [],
            };
            break;
        }
        case at.GET_ABSTRACTS_LIST_SUCCESS: {
            draft.abstractsList = {
                isAbstractsListLoading: false,
                abstracts: payload || [],
            };
            break;
        }
        case at.GET_ABSTRACTS_LIST_ERROR: {
            const { error } = action;

            draft.abstractsList = {
                isAbstractsListLoading: false,
                abstracts: [],
                fetchAbstractsError: error,
            };
            break;
        }

        case at.ENROLL_TO_PAAS_AUTH: {
            draft.paas = {
                isPaasAuthLoading: true,
                paasAuthServiceUuid: '',
                fetchPaasAuthError: null,
            };
            break;
        }
        case at.ENROLL_TO_PAAS_AUTH_ERROR: {
            const { error } = payload;

            draft.paas = {
                isPaasAuthLoading: false,
                paasAuthServiceUuid: null,
                fetchPaasAuthError: error,
            };
            break;
        }
        case at.ENROLL_TO_PAAS_AUTH_SUCCESS: {
            const { paasAuthUuid } = payload;

            draft.paas = {
                isPaasAuthLoading: false,
                paasAuthServiceUuid: paasAuthUuid,
                fetchPaasAuthError: null,
            };
            break;
        }
        case at.SHOW_NAVIGATION: {
            draft.isShowNavigation = true;
            break;
        }
        case at.HIDE_NAVIGATION: {
            draft.isShowNavigation = false;
            break;
        }
        case at.SET_STATE_PAGE_BEING_EDITED: {
            draft.editableComponent = payload;
            break;
        }
        case at.SAVE_USER_ACTION: {
            draft.savedAction = payload;
            break;
        }
        case at.CLEAR_SAVED_ACTION: {
            draft.savedAction = null;
            break;
        }
        case at.SET_CONFIRMATION_USER: {
            draft.editableComponent.isDirty = payload;
            break;
        }
        case LOGOUT: {
            return initState;
            break;
        }
        default:
            return draft;
    }
});

export default mainReducer;

import { logAndTraceErrorResponse } from './logAndTraceErrorResponse';
import { withSafelyExecute } from '../../utils/withSafelyExecute';

/**
 * Handles a failed HTTP response by logging and tracing the error.
 *
 * @param {Object} error - The error object from Axios.
 * @param {Object} error.config - The Axios request configuration.
 * @param {Object} error.config.span - The OpenTelemetry span associated with the request.
 * @param {Object} error.config.context - The OpenTelemetry context associated with the request.
 * @param {Object} [error.config.logData] - Additional data to include in the log.
 * @param {string} error.config.url - The URL of the request.
 * @param {Object} [error.response] - The Axios response object, if available.
 * @param {number} [error.response.status] - The HTTP status code.
 * @param {string} [error.response.statusText] - The HTTP status text.
 */
function otlHandleFailedResponse(error) {
    const { span, logData, url } = error.config || {};
    const { status, statusText } = error.response || {};

    if (!span) {
        return;
    }

    logAndTraceErrorResponse({
        span,
        url,
        logData,
        statusText,
        status,
        error: error.message,
    });
}

export default withSafelyExecute(otlHandleFailedResponse);

import axios from 'axios';
import * as types from '../constants/ActionTypes/Integrations';
import { axiosCloudConfig } from '../api/config';
import { API_CLOUD_EZLO_REQUEST_URL } from '../constants/URLs';
import {
    buildAbstractListDetailedRequestBody,
    buildVirtualDeviceSetRequestBody,
} from '../containers/Ezlo/EzloVirtualDeviceManager/utils';
import {
    buildAbstractStateGetRequestBody,
    buildIntegrationSetupRequestBody,
    buildIntegrationsListRequestBody,
    extractUserIntegrationsUuidFromAbstracts,
    sortUserIntegrationsByAbstractsLength,
} from '../containers/Ezlo/EzloParjectsSettings/utils';
import { ENROLLING_DELAY } from '../containers/Ezlo/EzloParjectsSettings/constants';
import { bugsnagNotify } from '../containers/ErrorBoundary/utils';
import { buildAccountsInfoData } from '../helpers/integrations';
import {
    apiGetAbstractStateGet,
    getVariableValue,
} from '../containers/Ezlo/EzloMeshbot/components/PaasNodeForTrigger/utils';
import { UPDATE_CLOUD_VARIABLE_CURRENT_VALUE } from '../constants/ActionTypes/Integrations';
import { getPayloadForSetCloudVariableCurrentValue } from '../containers/Ezlo/EzloRule/EditForm/RuleSettings/components/PAAS/utils';
import {
    GET_PAYLOAD_STATUSES,
    NONE,
} from '../containers/Ezlo/EzloRule/EditForm/RuleSettings/components/PAAS/paas-constants';
import { FAILED, TRUE, VALUE } from '../constants/MeshbotConstant';
import { isArray, isEmpty, isObject, isString } from 'lodash';
import { toast, TOAST_TYPE } from '../components/Toast';
import {
    EZLOGIC_TITLE_SOMETHING_WENT_WRONG,
    EZLOGIC_TOAST_DEVICE_ADDED,
    EZLOGIC_TOAST_DEVICE_DATA_UPDATED,
    EZLOGIC_TOAST_DEVICE_ERROR_WHILE_SAVING,
    EZLOGIC_TOAST_PLUGIN_HAS_BEEN_SUCCESSFULLY,
    EZLOGIC_TOAST_REQUEST_PROCEED,
    EZLOGIC_TOAST_UPDATE_CONFIGURE_ERROR,
    EZLOGIC_TOAST_UPDATE_CONFIGURE_SUCCESSFULLY,
} from '../constants/language_tokens';
import { DeviceActions, EzloActions, IntegrationsActions, PluginActions } from './index';
import { STATUS, SYNCED } from '../constants/NotificationTemplates';
import { REMOVE, STATUSES } from '../constants/Plugins';
import { ERROR_UNTIL_ACKNOWLEDGED_OPTIONS, INFO_OPTIONS, SUCCESS_OPTIONS } from '../constants/ReportToast';
import toastsActions from './toastsActions';
import { REMOVED } from '../constants/DeviceAssociations';
import { INSTALLED } from '../constants/Integrations';
import { setIsFirstLoadIntegrations } from '../containers/Ezlo/EzloIntegrations/utils';
import { apiDeleteAbstractByUuid } from '../containers/Ezlo/EzloRule/EditForm/RuleSettings/components/PAAS/PaasAPI/paas-api-requests';
import actionsMain from './MainAction';
import at from '../constants/ActionTypes/Plugins';
import { getIntegrationsData, setAdditionalDataFromIntegrationsPreview } from '../helpers/mainActionUtils';
import GenericActions from './GenericActions';
import { sleep } from 'helpers/sleep';
import MainAction from './MainAction';
import { t } from 'helpers/language';
import { fetchIntegrationPreview } from 'helpers/api';
import { compressData } from 'lib/pako';

const actions = {
    fetchIntegrationsPageData: () => async (dispatch) => {
        await dispatch(actions.fetchIntegrationsList);
        await dispatch(actions.fetchAbstractListDetailed());
        await dispatch(actions.setUserIntegrations());
    },

    fetchIntegrationsInitialInfo: () => async (dispatch) => {
        await dispatch(actions.fetchAbstractListDetailed());
        await dispatch(actions.setUserIntegrations());
        await dispatch(actionsMain.getAbstractsList());
    },

    fetchIntegrationsList: async (dispatch) => {
        dispatch({ type: types.FETCH_INTEGRATIONS_LIST });

        try {
            const integrations = await getIntegrationsData(buildIntegrationsListRequestBody());

            if (!isObject(integrations)) {
                return;
            }

            // TODO Remove setAdditionalDataFromIntegrationsPreview and all code that is associated with the function after implementing https://jira.mios.com/browse/ENMC-10299 task
            await setAdditionalDataFromIntegrationsPreview(integrations);

            const integrationsList = Object.entries(integrations).reduce((acc, [uuid, integration]) => {
                const authentication = integration?.authentication;
                acc[uuid] = {
                    uuid,
                    authType: authentication === null ? NONE : authentication?.type,
                    authExplanation: authentication?.explanation,
                    icon: integration?.imageSource,
                    title: integration.friendlyName,
                    name: integration.name,
                    category: integration.category,
                    description: integration.description,
                    hasCallBack: integration.hasCallBack,
                };

                return acc;
            }, {});
            dispatch({ type: types.FETCH_INTEGRATIONS_LIST_ON_SUCCESS, integrations: integrationsList });

            return integrationsList;
        } catch (error) {
            bugsnagNotify(error, { type: types.FETCH_INTEGRATIONS_LIST_ON_ERROR });
            dispatch({ type: types.FETCH_INTEGRATIONS_LIST_ON_ERROR, error });

            return error;
        }
    },

    fetchAbstractListDetailed: () => async (dispatch) => {
        dispatch({ type: types.FETCH_ABSTRACT_LIST_DETAILED });

        return axios
            .post(API_CLOUD_EZLO_REQUEST_URL(), buildAbstractListDetailedRequestBody(), axiosCloudConfig())
            .then((response) => {
                const abstracts = response.data?.data?.abstracts;
                if (!isArray(abstracts)) {
                    return;
                }
                dispatch({ type: types.FETCH_ABSTRACT_LIST_DETAILED_ON_SUCCESS, abstracts });
            })
            .catch((error) => {
                bugsnagNotify(error, { type: types.FETCH_ABSTRACT_LIST_DETAILED_ON_ERROR });
                dispatch({ type: types.FETCH_ABSTRACT_LIST_DETAILED_ON_ERROR, error });
            });
    },

    setUserIntegrations: () => async (dispatch, getState) => {
        const { integrations, abstracts } = getState().integrations;
        const userIntegrationsUuidArr = extractUserIntegrationsUuidFromAbstracts(abstracts);
        const unorderedUserIntegrations = Object.entries(integrations)
            .filter(([key]) => userIntegrationsUuidArr.includes(key))
            .reduce((acc, [key, integration]) => {
                const integrationAbstracts = abstracts.filter((abstract) => abstract.details.integration_uuid === key);
                acc[key] = {
                    ...integration,
                    abstracts: integrationAbstracts,
                };

                return acc;
            }, {});

        const userIntegrations = sortUserIntegrationsByAbstractsLength(unorderedUserIntegrations);
        await dispatch({ type: types.SET_USER_INTEGRATIONS, userIntegrations });
    },

    setAccountsInfo: (groupedAbstractsByIntegrations) => (dispatch) => {
        dispatch({ type: types.FETCH_ACCOUNTS_INFO });
        const uuidsList = groupedAbstractsByIntegrations.reduce((acc, { abstracts }) => {
            abstracts.forEach((abstract) => acc.push(abstract.uuid));

            return acc;
        }, []);

        return axios
            .post(API_CLOUD_EZLO_REQUEST_URL(), buildAbstractStateGetRequestBody(uuidsList), axiosCloudConfig())
            .then((response) => {
                const accountsInfo = buildAccountsInfoData(response.data.data.abstract_capability_values);
                dispatch({ type: types.FETCH_ACCOUNTS_INFO_SUCCESS, accountsInfo });
            })
            .catch((err) => {
                bugsnagNotify(err);
            });
    },

    enrollToIntegration: (params) => async (dispatch) => {
        dispatch({ type: types.ENROLL_TO_INTEGRATION });

        return axios
            .post(API_CLOUD_EZLO_REQUEST_URL(), buildIntegrationSetupRequestBody(params), axiosCloudConfig())
            .then((response) => {
                setTimeout(async () => {
                    await dispatch(actions.fetchAbstractListDetailed());
                    await dispatch(actions.setUserIntegrations());
                    dispatch({ type: types.ENROLL_TO_INTEGRATION_ON_SUCCESS });
                }, ENROLLING_DELAY);

                return response.data;
            })
            .catch((err) => {
                bugsnagNotify(err, { type: types.ENROLL_TO_INTEGRATION_ON_ERROR, params });
                dispatch({ type: types.ENROLL_TO_INTEGRATION_ON_ERROR, error: err });
            });
    },

    editIntegration: (params) => async (dispatch) => {
        dispatch({ type: types.UPDATE_INTEGRATION });

        return axios
            .post(API_CLOUD_EZLO_REQUEST_URL(), buildVirtualDeviceSetRequestBody(params), axiosCloudConfig())
            .then((response) => {
                dispatch({ type: types.UPDATE_INTEGRATION_ON_SUCCESS, payload: response.data.data.uuid });
            })
            .catch((error) => {
                bugsnagNotify(error, { type: types.UPDATE_INTEGRATION_ON_ERROR, params });
                dispatch({ type: types.UPDATE_INTEGRATION_ON_ERROR, error });
            });
    },
    /**
     * function that receives data about the selected cloud variable and stores it in the store and returns it,
     * also handles an error if the request is not successful.
     * @param {String} abstractUuid - Abstract uuid of the selected cloud variable
     * @param {String} variableName - Variable name of the selected cloud variable
     * @returns {Object|undefined} - The data of the selected cloud variable received from the query.
     */
    setCloudVariableCurrentValue: (abstractUuid, variableName) => async (dispatch) => {
        const { START, ERROR, SUCCESS } = GET_PAYLOAD_STATUSES;
        try {
            // Start getting data for the selected variable
            const payload = getPayloadForSetCloudVariableCurrentValue({ abstractUuid, variableName }, START);
            dispatch({ type: UPDATE_CLOUD_VARIABLE_CURRENT_VALUE, payload });
            // request to get variable data and save the received data
            const abstract_capability_values = await apiGetAbstractStateGet([abstractUuid]);
            if (abstract_capability_values) {
                const variableValue = getVariableValue(abstract_capability_values, variableName);
                const payload = getPayloadForSetCloudVariableCurrentValue(
                    { abstractUuid, variableName, variableValue },
                    SUCCESS,
                );
                dispatch({ type: UPDATE_CLOUD_VARIABLE_CURRENT_VALUE, payload });

                return payload.variableData;
            }
        } catch (error) {
            // error handling if the request is not successful
            const payload = getPayloadForSetCloudVariableCurrentValue({ abstractUuid, variableName, error }, ERROR);
            dispatch({ type: UPDATE_CLOUD_VARIABLE_CURRENT_VALUE, payload });
        }
    },

    getAbstractState: (abstractUuid) => async (dispatch, getState) => {
        const state = getState();
        const { integrations } = state;

        if (isEmpty(integrations.abstractState)) {
            const abstractStatesResult = await apiGetAbstractStateGet(abstractUuid);
            const abstractsWithVariables = abstractStatesResult
                ?.map((variable) => {
                    return variable?.includes(VALUE) && variable;
                })
                .filter((item) => !!item);

            dispatch({ type: types.UPDATE_ABSTRACT_STATE, abstractsWithVariables });
        }
    },

    setActiveIntegrationsTab: (data) => (dispatch) => {
        dispatch({ type: types.SET_ACTIVE_INTEGRATIONS_TAB, data });
    },

    onSubscribeDeviceSettingUpdate: (serial, name, id, t) => (dispatch) => {
        return ({ result }) => {
            const showToast = (message, type) => {
                toast(t(message), { type });
            };
            const handleSuccess = () => {
                dispatch(DeviceActions.unSubscribeDeviceSettingUpdate(serial, name));
                showToast(EZLOGIC_TOAST_DEVICE_DATA_UPDATED, TOAST_TYPE.SUCCESS);
            };
            const handleError = () => {
                dispatch(DeviceActions.unSubscribeDeviceSettingUpdate(serial, name));
                showToast(EZLOGIC_TOAST_DEVICE_ERROR_WHILE_SAVING, TOAST_TYPE.ERROR);
            };

            if (result.status === FAILED) {
                handleError();
            } else if (result.hasOwnProperty(STATUS) && result.status === SYNCED) {
                handleSuccess();
            } else {
                dispatch(DeviceActions.getSettingDevice(serial, { ids: [id] }));
                showToast(EZLOGIC_TOAST_REQUEST_PROCEED, TOAST_TYPE.INFO);
            }
        };
    },

    onSuccessfullyFinishedAddingDeviceIntegration: (serial) => (dispatch) => {
        dispatch(PluginActions.unSubscribeBroadcastConfigure(serial));
        dispatch(EzloActions.loadEzloDataByPluginUpdate(serial));
        dispatch(PluginActions.getListInstalledPlugins(serial));
        dispatch(PluginActions.handleCloseSuccessPluginModal());
    },

    notificationUpdateConfigure: (serial, t) => (dispatch) => {
        return ({ result }) => {
            dispatch(PluginActions.setPluginIntegrationSuccess());

            if (result.status === STATUSES.ERROR || result.status === STATUSES.ADD_DEVICE_FAIL) {
                dispatch(
                    toastsActions.showToast({
                        message: result.message ?? result.error,
                        options: ERROR_UNTIL_ACKNOWLEDGED_OPTIONS,
                        isSave: true,
                    }),
                );
                dispatch(PluginActions.unSubscribeBroadcastConfigure(serial));
                dispatch(PluginActions.unSubscribeUpdateConfigure(serial));
            } else {
                if (result.status === STATUSES.SUCCESS || result.status === STATUSES.OK) {
                    dispatch(
                        toastsActions.showToast({
                            message: result.message ?? t(EZLOGIC_TOAST_DEVICE_ADDED),
                            options: SUCCESS_OPTIONS,
                        }),
                    );
                    dispatch(IntegrationsActions.onSuccessfullyFinishedAddingDeviceIntegration(serial));
                }

                if (result.status === STATUSES.FAILED) {
                    dispatch(
                        toastsActions.showToast({
                            message: t(EZLOGIC_TOAST_UPDATE_CONFIGURE_ERROR),
                            options: ERROR_UNTIL_ACKNOWLEDGED_OPTIONS,
                            isSave: true,
                        }),
                    );
                    dispatch(PluginActions.unSubscribeUpdateConfigure(serial));
                }

                if (result.status === STATUSES.FINISHED) {
                    dispatch(
                        toastsActions.showToast({
                            message: t(EZLOGIC_TOAST_UPDATE_CONFIGURE_SUCCESSFULLY),
                            options: INFO_OPTIONS,
                        }),
                    );
                    dispatch(IntegrationsActions.onSuccessfullyFinishedAddingDeviceIntegration(serial));
                    dispatch(PluginActions.getListInstalledPlugins(serial));
                }
            }
        };
    },

    notificationUpdateControllerForIntegrations: (serial, type, t) => (dispatch) => {
        return ({ result }) => {
            if (result.status === STATUSES.ABORTED) {
                toast(result.error.message, { type: TOAST_TYPE.ERROR });
                dispatch(PluginActions.unSubscribeUpdateController(serial));
                dispatch(PluginActions.removeActiveInstalling(serial));
            }

            if (result.status === STATUSES.FINISHED) {
                dispatch(
                    toastsActions.showToast({
                        message: `${t(EZLOGIC_TOAST_PLUGIN_HAS_BEEN_SUCCESSFULLY)} ${
                            type === REMOVE ? REMOVED : INSTALLED
                        }`,
                        options: SUCCESS_OPTIONS,
                    }),
                );
                dispatch(PluginActions.unSubscribeUpdateController(serial));
                dispatch(PluginActions.getListInstalledPlugins(serial));
                dispatch(PluginActions.removeActiveInstalling(serial));
                setIsFirstLoadIntegrations(TRUE);
            }
        };
    },

    setOpenIntegrationWaitingModal: (data) => (dispatch) => {
        dispatch({ type: types.SET_OPEN_INTEGRATION_WAITING_MODAL, data });
    },
    setOpenIntegrationSuccessfulModal: (data) => (dispatch) => {
        dispatch({ type: types.SET_OPEN_INTEGRATION_SUCCESSFUL_MODAL, data });
    },
    setOpenIntegrationAbortedModal: (data) => (dispatch) => {
        dispatch({ type: types.SET_OPEN_INTEGRATION_ABORTED_MODAL, data });
    },
    setOpenIntegrationRemoveModal: (data) => (dispatch) => {
        dispatch({ type: types.SET_OPEN_INTEGRATION_REMOVE_MODAL, data });
    },
    onRemoveIntegration: (parjectUuid, integrationId) => async (dispatch) => {
        await apiDeleteAbstractByUuid(parjectUuid, integrationId);

        await new Promise((resolve) => setTimeout(resolve, 2000));

        await dispatch(IntegrationsActions.fetchIntegrationsPageData());
    },

    onBroadcastConfigureResponse: (controller) => (dispatch) => {
        return ({ result }) => {
            dispatch(PluginActions.unSubscribeBroadcastConfigure(controller));
            if (result.status === STATUSES.FAILED) {
                const { message } = result?.error;
                dispatch({ type: at.SET_INTEGRATION_CONFIGURE_PLUGIN.rejected, data: message });
            }

            if (
                result.status === STATUSES.SUCCESS ||
                result.status === STATUSES.OK ||
                result.status === STATUSES.FINISHED
            ) {
                dispatch(IntegrationsActions.actionToHandleSuccessCreatingDeviceByPluginLogic(controller));
            }
        };
    },

    onBroadcastCreatingDeviceByPluginResponse: (controller) => (dispatch) => {
        return ({ result }) => {
            dispatch(PluginActions.unSubscribeUpdateConfigure(controller));
            if (
                result.status === STATUSES.ERROR ||
                result.status === STATUSES.ADD_DEVICE_FAIL ||
                result.status === STATUSES.FAILED
            ) {
                dispatch(IntegrationsActions.actionToHandleErrorCreatingDeviceByPluginLogic(result, controller));
            }

            if (
                result.status === STATUSES.SUCCESS ||
                result.status === STATUSES.OK ||
                result.status === STATUSES.FINISHED
            ) {
                dispatch(IntegrationsActions.actionToHandleSuccessCreatingDeviceByPluginLogic(controller));
            }
        };
    },

    actionToHandleSuccessCreatingDeviceByPluginLogic: (controller) => (dispatch) => {
        dispatch(PluginActions.setPluginIntegrationSuccess());
        dispatch(PluginActions.onNotificationUpdateConfigure(controller));
        dispatch(PluginActions.removeActiveInstalling(controller));
        setIsFirstLoadIntegrations(TRUE);
    },

    actionToHandleErrorCreatingDeviceByPluginLogic: (result, controller) => (dispatch) => {
        dispatch(PluginActions.setPluginIntegrationReject(result?.message || result?.error?.message));
        dispatch(PluginActions.removeActiveInstalling(controller));
    },
    /**
     * Thunk action that deletes a user enrollment for a given integration.
     *
     * Dispatches a loading state, calls the API to delete the enrollment, and then refreshes
     * the abstract list if successful. If an error occurs, shows an error toast and ends loading state.
     *
     * @function
     * @param {Object} payload - The payload object.
     * @param {string} payload.uuid - The UUID of the enrollment to delete.
     * @param {Object} payload.details - Additional details about the integration.
     * @param {string} payload.details.integration_uuid - The UUID of the integration.
     * @returns {Function} A Redux thunk function that accepts `dispatch` and returns a Promise.
     */
    deleteEnrollment:
        ({ uuid, details }) =>
        async (dispatch) => {
            try {
                dispatch(GenericActions.setLineLoading(true));
                const response = await apiDeleteAbstractByUuid(uuid, details.integration_uuid);

                if (response?.data?.status === 0) {
                    throw response.data.data;
                }
                await sleep(5000);
                await dispatch(MainAction.getAbstractListDetailed);
            } catch (e) {
                const message = e?.message || e?.error_message || t(EZLOGIC_TITLE_SOMETHING_WENT_WRONG);
                toast(message, { type: TOAST_TYPE.ERROR });
                dispatch(GenericActions.setLineLoading(false));
            } finally {
                dispatch(GenericActions.setLineLoading(false));
            }
        },
    /**
     * Action creator that signals the start of fetching an integration preview.
     *
     * @function
     * @param {string} uuid - The UUID of the integration to fetch.
     * @returns {Object} A plain Redux action.
     */
    fetchIntegrationPreview: (uuid) => ({ type: types.FETCH_INTEGRATION_PREVIEW, uuid }),
    /**
     * Action creator that signals a successful fetch of an integration preview.
     *
     * @function
     * @param {string} uuid - The UUID of the integration that was fetched.
     * @param {string} data - The data of the fetched integration preview (a compressed string).
     * @returns {Object} A plain Redux action.
     */
    fetchIntegrationPreviewOnSuccess: (uuid, data) => ({
        type: types.FETCH_INTEGRATION_PREVIEW_ON_SUCCESS,
        uuid,
        data,
    }),
    /**
     * Action creator that signals an error occurred while fetching an integration preview.
     *
     * @function
     * @param {string} uuid - The UUID of the integration that was being fetched.
     * @param {string} error - The error message.
     * @returns {Object} A plain Redux action.
     */
    fetchIntegrationPreviewOnError: (uuid, error) => ({ type: types.FETCH_INTEGRATION_PREVIEW_ON_ERROR, uuid, error }),
    /**
     * Thunk action that fetches an integration preview from the server unless it's already cached.
     *
     * - Checks if the preview is already a string in the Redux state with no error.
     * - If not, dispatches `fetchIntegrationPreview`, calls the API to fetch the data,
     *   and then dispatches `fetchIntegrationPreviewOnSuccess` with compressed data.
     * - If an error occurs, dispatches `fetchIntegrationPreviewOnError`.
     *
     * @function
     * @param {string} uuid - The UUID of the integration to fetch.
     * @returns {Function} A Redux thunk function that accepts `(dispatch, getState)` and returns a Promise.
     */
    runFetchingIntegrationPreview: (uuid) => async (dispatch, getState) => {
        try {
            if (!uuid) {
                return;
            }
            const state = getState()?.integrations?.integrations_preview?.[uuid];
            if (isString(state?.data) && !state?.error) {
                return;
            }
            dispatch(actions.fetchIntegrationPreview(uuid));

            const integrationPreview = await fetchIntegrationPreview(uuid);

            dispatch(actions.fetchIntegrationPreviewOnSuccess(uuid, compressData(integrationPreview)));
        } catch (e) {
            const error =
                e.data?.data?.error_message ||
                e.data?.data?.error_text ||
                e.message ||
                EZLOGIC_TITLE_SOMETHING_WENT_WRONG;
            dispatch(actions.fetchIntegrationPreviewOnError(uuid, error));
        }
    },
};

export default actions;

import { cloneDeep, isArray, isEmpty, isString } from 'lodash';
import axios from 'axios';
import { axiosCloudConfig } from 'api/config';
import {
    CUSTOM,
    INDEX_OF_ONE,
    INDEX_OF_ZERO,
    OPERATOR_NOT,
    X_CALLBACKS,
} from '../../../../../../../constants/MeshbotConstant';
import hash from '../../../../../../../constants/uniqueHash';
import { API_CLOUD_EZLO_REQUEST_URL } from '../../../../../../../constants/URLs';
import { buildAccountsInfoData } from '../../../../../../../helpers/integrations';
import { bugsnagNotify } from '../../../../../../ErrorBoundary/utils';
import { LOCATION } from '../../../../../EzloCustomization/constants';
import { STARTED_TEMPLATE_FOR_CODE_SIMPLE } from '../../../../../EzloMeshbot/components/SaveOutputBlock/constants';
import { mapTypes } from '../../../../../EzloMeshbot/components/SaveOutputBlock/template';
import { buildAbstractStateGetRequestBody } from '../../../../../EzloParjectsSettings/utils';
import {
    APIKEY,
    BASIC,
    BEARER,
    EZLO,
    GET_PAYLOAD_STATUSES,
    INDEX_OF_CAPABILITY,
    INDEX_OF_VALUE,
    INJECT_VALUE,
    LAST_CHARACTER_NUMBER,
    NOT_REPRESENTED_INDEX,
    PAASAuth,
    STRINGIFY_STR,
    VOI_CONTROL,
} from './paas-constants';
import { STRING } from '../../../../../../../constants/DevicePluginGenerator';
import isObject from 'lodash/isObject';
import { getGroupIntegrations, sortByName } from '../../../../../EzloMeshbot/utils';
import { ABSTRACT_SOURCES } from '../../../../../EzloVirtualDeviceManager/constants';
import { createSelector } from 'reselect';
import { abstractsSelector } from '../../../../../../../helpers/selectors';

/**
 * Returns an array of project (parject) names that should be filtered out.
 *
 * @returns {Array<string>} An array of names, e.g. [PAASAuth, EZLO, VOI_CONTROL].
 */
export const getParjectNamesToFilter = () => {
    return [PAASAuth, EZLO, VOI_CONTROL];
};
/**
 * Filters an array of abstracts, returning only those which:
 * - Have details.source === ABSTRACT_SOURCES.INTEGRATION
 * - Whose `name` is not in `getParjectNamesToFilter()`
 * - Whose `name` does not include the substring `PAASAuth`
 *
 * @param {Array<Object>} abstracts - The array of abstracts to filter.
 * @returns {Array<Object>} If `abstracts` is an array, returns the filtered list. Otherwise returns an empty array.
 *
 * Example abstract object:
 * {
 *   uuid: string,
 *   name: string,
 *   details: {
 *     source: 'integration',
 *     integration_uuid: string,
 *     ...
 *   }
 * }
 */
export const filterAbstractsAsIntegrationsAccounts = (abstracts) =>
    isArray(abstracts)
        ? abstracts.filter(
              ({ name, details }) =>
                  details &&
                  details.source === ABSTRACT_SOURCES.INTEGRATION &&
                  !getParjectNamesToFilter().includes(name) &&
                  !name.includes(PAASAuth),
          )
        : [];
/**
 * A reselect selector that combines `abstractsSelector` with `filterAbstractsAsIntegrationsAccounts`.
 *
 * @function
 * @returns {Function} A memoized selector which, given Redux state, returns the filtered array of abstracts.
 *
 * Usage:
 * const filtered = abstractsAsIntegrationsAccountsSelector(state);
 */
export const abstractsAsIntegrationsAccountsSelector = createSelector(
    abstractsSelector,
    filterAbstractsAsIntegrationsAccounts,
);

export const generateMeshbotCloudApiField = (value, actionBlockName = 'then') => {
    return {
        blockType: actionBlockName,
        blockOptions: {
            method: {
                name: 'cloudAPI',
                args: {
                    method: 'cloudApiName',
                    version: 'version',
                    params: 'params',
                },
            },
        },
        fields: [
            {
                name: 'cloudApiName',
                type: 'string',
                value: 'abstract_command',
            },
            {
                name: 'version',
                type: 'int',
                value: 1,
            },
            {
                name: 'params',
                type: 'string',
                value: JSON.stringify(value),
            },
        ],
    };
};

export const generatePaasAbstractCommand = (data) => {
    const parametersWithCode = data.code
        ? {
              code: data.code,
              language: 'javascript',
              inject: INJECT_VALUE,
          }
        : {};

    return {
        capability: 'api',
        uuid: data.accountUuid,
        command: 'call',
        parameters: {
            name: data.method,
            params: {
                ...data.fields,
            },
            ...parametersWithCode,
        },
    };
};

export const getAccountInfo = async (accountsList) => {
    try {
        const uuidsList = accountsList.map((abstract) => abstract.uuid);
        const response = await axios.post(
            API_CLOUD_EZLO_REQUEST_URL(),
            buildAbstractStateGetRequestBody(uuidsList),
            axiosCloudConfig(),
        );

        return buildAccountsInfoData(response.data.data.abstract_capability_values);
    } catch (error) {
        bugsnagNotify(error);
    }
};

export const getAllAbstractsByUuid = (abstracts, abstractUuid) => {
    if (!Array.isArray(abstracts) || !abstracts) {
        return [];
    }

    const ABSTRACTS = abstracts.filter(({ details }) => {
        const { integration_uuid: integrationUuid } = details;

        if (!integrationUuid) {
            return false;
        }

        return integrationUuid === abstractUuid;
    });

    return ABSTRACTS || [];
};

/**
 * Retrieves the UUID of the PAAS Authentication integration from the given array of integrations.
 *
 * @param {Array<Object>} integrations - The array of integration objects.
 * @param {string} integrations[].name - The name of the integration.
 * @param {string} integrations[].uuid - The UUID of the integration.
 * @throws {Error} If the PAASAuth integration is missing or does not have a UUID.
 * @returns {string} The UUID of the PAASAuth integration.
 */
export const getPaasAuthIntegrationUuid = (integrations) => {
    const integration = integrations?.find(({ name }) => name === PAASAuth);
    if (!integration || !integration.uuid) {
        throw new Error('Missing PAASAuth Integration');
    }

    return integration.uuid;
};

export const getEnrolmentNumber = (name) => {
    const splittedNameBySpace = name.split(' ');
    const { length } = splittedNameBySpace;

    return parseInt(splittedNameBySpace?.[length - 1]) || 0;
};

export const isEnrollmentByToken = (enrolment) => {
    return [APIKEY, BASIC, BEARER].includes(enrolment);
};

export const validate = (initialValue, fields) => {
    const errors = {};
    for (const field of initialValue) {
        if (!fields?.[field] || fields[field] == '') {
            errors[field] = 'This field is required!';
        }
    }

    return errors;
};

export const coverRawJsonVariableIntoQuotes = (string) => {
    const regex = /(stringify\(raw_json.*?)([,\s].?)(?="string)/gm; // extract substring started with raw_json

    return string.replaceAll(regex, '"$1",'); // wrap extracted substring into double quotes
};

export const replaceEndOfExpression = (string) => {
    const expression = coverRawJsonVariableIntoQuotes(string);

    return expression.replace(/output;/, 'return output;'); // in order to return state object from code string
};

export const executeFunctionFromString = (string) => {
    try {
        const resultOfExpression = new Function(string)();

        return resultOfExpression.state ? resultOfExpression.state : [];
    } catch {
        return null;
    }
};

export const parseJSFromCodeField = (codeString) => {
    const replacedExpression = replaceEndOfExpression(codeString);

    return executeFunctionFromString(replacedExpression);
};

export const buildArrayOfParsedCodeFromString = (codeString) => {
    const parsedResult = parseJSFromCodeField(codeString);
    const getVariableValue = (variable) => {
        const variableValue = variable[INDEX_OF_VALUE];
        const isValueInFunction = typeof variableValue === STRING && variableValue.startsWith(STRINGIFY_STR);

        return isValueInFunction ? variableValue.slice(STRINGIFY_STR.length, LAST_CHARACTER_NUMBER) : variableValue;
    };

    return (
        parsedResult &&
        parsedResult.map((item) => ({
            uuid: hash(),
            capability: item[INDEX_OF_CAPABILITY],
            value: getVariableValue(item),
        }))
    );
};

export const buildCloudVariableInitialState = (code) => {
    const parsedSimpleCode = buildArrayOfParsedCodeFromString(code);
    const isSimpleMapMode = code.includes(STARTED_TEMPLATE_FOR_CODE_SIMPLE);

    return {
        code,
        codeArray: isSimpleMapMode && parsedSimpleCode ? parsedSimpleCode : [],
        mapType: isSimpleMapMode && !!parsedSimpleCode ? mapTypes.SIMPLE : mapTypes.ADVANCED,
        selectedSaveOutput: Boolean(code),
        isSimpleDisabled: !parsedSimpleCode || !isSimpleMapMode,
    };
};

export const extractCallbackEventName = (paths, method) => {
    return paths[method]?.post?.[X_CALLBACKS]?.[0]?.substring(1);
};

export const getCurrentLocalAction = (id, actionBlockName, rules) => {
    const blocks = rules[actionBlockName];

    const currentBlock = blocks.find((block) => block.id === id);

    const params = currentBlock?.blocks[0]?.fields?.find(({ name }) => name === 'params');

    if (params) {
        const value = JSON.parse(params.value);

        return {
            abstractUuid: value.uuid,
            methodValue: value.parameters.name,
            fieldsList: value.parameters.params,
            code: value?.parameters?.code,
        };
    }

    return {
        abstractUuid: undefined,
        methodValue: undefined,
        fieldsList: undefined,
        code: '',
    };
};
export const getCurrentCloudAction = (id, ruleCloudAction) => {
    const currentAction = ruleCloudAction?.find(({ id: actionId }) => actionId === id);
    const parameters = currentAction?.PAAS?.parameters;

    return {
        abstractUuid: parameters?.find(({ name }) => name === 'abstract').uuid,
        methodValue: parameters?.find(({ name }) => name === 'apiname').value,
        fieldsList: parameters?.find(({ name }) => name === 'params').params,
        code: parameters?.find(({ name }) => name === 'code')?.code,
        blackListVariable: parameters?.find(({ name }) => name === 'code_output_blacklist')?.list || [],
    };
};

export const getCurrentCloudTrigger = (id, ruleCloudTriggers) => {
    const currentTrigger = ruleCloudTriggers.find(({ id: triggerId }) => triggerId === id);
    if (isEmpty(currentTrigger?.subscription) && currentTrigger?.subscriptionDataFromKvs) {
        return mapDataFromKvsForEditModeTrigger(currentTrigger);
    }

    if (
        isEmpty(currentTrigger?.subscription) &&
        !currentTrigger?.subscriptionDataFromKvs &&
        !isEmpty(currentTrigger?.blocks?.[INDEX_OF_ZERO]?.parameters)
    ) {
        return extractSubscriptionIdForEditMode(currentTrigger);
    } else if (!isEmpty(currentTrigger?.subscription)) {
        const parametersOfSubscriptionTrigger = currentTrigger?.subscription?.params?.parameters;
        const abstractUuid = currentTrigger?.subscription?.params?.uuid;
        const { name: methodValue, params: requiredField } = parametersOfSubscriptionTrigger;

        return {
            abstractUuid: abstractUuid,
            methodValue: methodValue,
            fieldsList: requiredField,
        };
    }

    return {
        abstractUuid: '',
        methodValue: '',
        fieldsList: '',
    };
};

export const extractSubscriptionIdForEditMode = (currentTrigger) => {
    const parametersOfTrigger = currentTrigger?.blocks?.[INDEX_OF_ZERO]?.parameters;
    const triggerPayloadParentName = currentTrigger?.blocks?.[INDEX_OF_ZERO]?.name;
    const { abstract: abstractUuid, capability } =
        triggerPayloadParentName === OPERATOR_NOT
            ? parametersOfTrigger?.[INDEX_OF_ZERO]?.parameters?.[INDEX_OF_ZERO] || []
            : parametersOfTrigger?.[INDEX_OF_ZERO] || [];

    const subscriptionId = extractSubscriptionId(capability);

    return {
        abstractUuid: abstractUuid,
        subscriptionId: subscriptionId,
    };
};

export const mapDataFromKvsForEditModeTrigger = (currentTrigger) => {
    const parametersOfSubscriptionTrigger = currentTrigger.subscriptionDataFromKvs?.parameters;
    const abstractUuid = currentTrigger.subscriptionDataFromKvs?.uuid;
    const { name: methodValue, params: requiredField } = parametersOfSubscriptionTrigger;

    return {
        abstractUuid: abstractUuid,
        methodValue: methodValue,
        fieldsList: requiredField,
    };
};

const extractSubscriptionId = (capability) => {
    const subscriptionId = capability?.split(`${CUSTOM}_`);

    return subscriptionId && subscriptionId[INDEX_OF_ONE];
};

const isValueInString = (value, str) => str.toLowerCase().indexOf(value.toLowerCase()) !== -1;

const setSearchedIntegration = (integration, searchValue, searchedIntegrations) => {
    const filteredAbstracts = integration?.abstracts?.filter(({ name }) => isValueInString(searchValue, name));
    const isSearchValueInIntegrationName = isValueInString(searchValue, integration.name);
    const isSearchValueInAbstracts = filteredAbstracts?.length > 0;

    if (isSearchValueInIntegrationName) {
        searchedIntegrations.push(integration);
    } else if (isSearchValueInAbstracts) {
        searchedIntegrations.push({ ...integration, abstracts: filteredAbstracts });
    }
};

const getSearchedIntegrations = (searchValue, integrations) => {
    if (!searchValue) {
        return integrations;
    }
    const searchedIntegrations = [];
    integrations?.forEach((integration) => {
        setSearchedIntegration(integration, searchValue, searchedIntegrations);
    });

    return searchedIntegrations;
};

const filterIntegrations = (integrations) => {
    const parjectsToFilter = getParjectNamesToFilter();

    return integrations.filter(({ name }) => !parjectsToFilter.includes(name));
};

const sortIntegrations = (integrations) => {
    return integrations.sort((a, b) => {
        return getEnrolmentNumber(b.name) - getEnrolmentNumber(a.name);
    });
};

export const getActualIntegrations = (userIntegrationsList = [], searchInput = '') => {
    const filteredIntegrations = filterIntegrations(userIntegrationsList);
    const searchedIntegrations = getSearchedIntegrations(searchInput, filteredIntegrations);

    return sortIntegrations(searchedIntegrations);
};

export const uploadSubscriptionData = (updatedSubscriptionData, KVS_KEY) => {
    const data = {
        type: LOCATION,
        public: 1,
        key: KVS_KEY,
        value: updatedSubscriptionData,
    };

    return data;
};

export const parseData = (str) => {
    try {
        return JSON.parse(str);
    } catch (e) {
        return str;
    }
};

/**
 * function to get payload to save cloud variable data from storage
 * @param {Object} variableData - Cloud variable data(abstractUuid, variableName, variableValue or error)
 * @param {String} type - The type of the desired payload
 * @returns {Object} - Assembled payload depending on the type
 */
export const getPayloadForSetCloudVariableCurrentValue = (variableData, type) => {
    const { abstractUuid, variableName, variableValue, error } = variableData;
    const { START, SUCCESS, ERROR } = GET_PAYLOAD_STATUSES;

    if (type === START) {
        return {
            abstractUuid,
            variableName,
            variableData: {
                isLoading: true,
            },
        };
    }

    if (type === ERROR) {
        return {
            abstractUuid,
            variableName,
            variableData: {
                isLoading: false,
                value: '',
                error,
            },
        };
    }

    if (type === SUCCESS) {
        return {
            abstractUuid,
            variableName,
            variableData: {
                isLoading: false,
                value: variableValue,
                error: null,
            },
        };
    }
};
/**
 * Retrieves a "nucalComponent" object from a given integration and method name.
 *
 * 1. Checks if `integration?.interactions` is an array and if `method` is provided.
 *    - If not, returns null.
 * 2. Finds an interaction whose `name` equals the given `method`.
 *    - If none found, returns null.
 * 3. Clones the interaction's `parameters`, then filters out any fields from `required`
 *    whose corresponding property in `properties` has `isCallbackUrl === true`.
 * 4. Returns the resulting "nucalComponent" object, or null if parameters is invalid.
 *
 * @param {Object} integration - The integration object, which may contain an `interactions` array.
 * @param {string} method - The name of the method (interaction) to look up.
 * @returns {Object|null} A cloned "nucalComponent" object with updated `required` fields, or null if missing/invalid.
 */
export const getNucalComponent = (integration, method) => {
    if (!isArray(integration?.interactions) || !method) {
        return null;
    }

    const interaction = integration.interactions.find((interaction) => interaction?.name === method);
    const parameters = interaction?.parameters;
    if (!isObject(parameters)) {
        return null;
    }

    const nucalComponent = cloneDeep(parameters);
    if (isArray(nucalComponent.required) && isObject(nucalComponent.properties)) {
        nucalComponent.required = nucalComponent.required.filter((fieldName) => {
            return !nucalComponent.properties[fieldName]?.isCallbackUrl;
        });
    }

    return nucalComponent;
};

/**
 * Finds the first abstract object whose 'name' property includes 'PAASAuthentication'.
 *
 * @param {Array<Object>} abstracts - The array of abstract objects to search.
 * @returns {Object|undefined} The first abstract object with a name that includes 'PAASAuthentication', or undefined if none found.
 */
export function getPAASAuthenticationAbstract(abstracts) {
    if (!isArray(abstracts) || abstracts.length === 0) {
        return;
    }

    return abstracts.find((abstract) => isString(abstract?.name) && abstract.name.includes(PAASAuth));
}
/**
 * Extracts integrations that have a callback (integration.hasCallBack === true).
 *
 * @param {Array<Object>} integrations - The array of integration objects.
 * @returns {Array<Object>} A filtered array of integrations with `hasCallBack = true`, or an empty array if `integrations` is not an array.
 */
export const extractTriggeredIntegrations = (integrations) => {
    if (!isArray(integrations)) {
        return [];
    }

    return integrations.filter((integration) => integration.hasCallBack);
};
/**
 * Filters an integrations list by a search filter, excluding those whose name is in `getParjectNamesToFilter`.
 *
 * @param {Array<Object>} integrationsList - An array of integration objects.
 * @param {string} filter - A search term to match against the integration's `name`.
 * @returns {Array<Object>} A filtered array of integrations, or an empty array if input is not an array.
 */
export const getFilteredIntegrations = (integrationsList, filter) => {
    if (!isArray(integrationsList)) {
        return [];
    }

    const parjectNamesToFilter = getParjectNamesToFilter();

    return integrationsList?.filter(({ name }) => {
        const isIntegrationHaveAllowedName = !parjectNamesToFilter.includes(name);
        const isIntegrationShouldBeFiltered = name.toLowerCase().indexOf(filter.toLowerCase()) > NOT_REPRESENTED_INDEX;

        return isIntegrationShouldBeFiltered && isIntegrationHaveAllowedName;
    });
};
/**
 * Groups and sorts integrations by category. Uses `getGroupIntegrations` to group by category,
 * then `sortByName` to sort the resulting groups.
 *
 * @param {Array<Object>} integrations - The array of integration objects.
 * @returns {Array<Object>} An array of grouped/sorted integrations, or an empty array if input is not an array.
 */
export const getGroupedSortedIntegrations = (integrations) => {
    if (!isArray(integrations)) {
        return [];
    }
    const categoriesList = Array.from(new Set(integrations.map((integration) => integration.category)));

    return sortByName(getGroupIntegrations(categoriesList, integrations));
};

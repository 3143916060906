import hash from './uniqueHash';
import { CONTROLLER_FEATURES_NAMES } from './ControllerFeatures';
import styles from '../containers/Ezlo/EzloMeshbot/components/TriggerBlock.module.scss';
import {
    EZLOGIC_TITLE_ANY,
    EZLOGIC_TITLE_ARMED,
    EZLOGIC_TITLE_ARM_STAY,
    EZLOGIC_TITLE_AT_LEAST,
    EZLOGIC_TITLE_AWAY,
    EZLOGIC_TITLE_BETWEEN,
    EZLOGIC_TITLE_CLOUD_VARIABLE,
    EZLOGIC_TITLE_CONTROLLER,
    // Hidden until the PRD for Lua Scripting (Actions) is updated.
    // EZLOGIC_TITLE_CREATE_NEW_SCRIPT,
    EZLOGIC_TITLE_DASHBOARD,
    EZLOGIC_TITLE_DAYS,
    EZLOGIC_TITLE_DELETE,
    EZLOGIC_TITLE_DEVICE,
    EZLOGIC_TITLE_DISABLE,
    EZLOGIC_TITLE_DISARMED,
    EZLOGIC_TITLE_ENABLE,
    EZLOGIC_TITLE_END_TIME,
    EZLOGIC_TITLE_EQUAL,
    EZLOGIC_TITLE_FACTORY_RESET,
    EZLOGIC_TITLE_FALSE,
    EZLOGIC_TITLE_FOLLOW,
    EZLOGIC_TITLE_FOR,
    EZLOGIC_TITLE_GET,
    EZLOGIC_TITLE_GREAER_THAN,
    EZLOGIC_TITLE_GREATER,
    EZLOGIC_TITLE_HOME,
    EZLOGIC_TITLE_HOURS,
    EZLOGIC_TITLE_HOUSE_MODE,
    EZLOGIC_TITLE_HOUSE_MODE_CHANGED_FROM,
    EZLOGIC_TITLE_HOUSE_MODE_CHANGED_TO,
    EZLOGIC_TITLE_HTTP_REQUEST,
    EZLOGIC_TITLE_INFINITE,
    EZLOGIC_TITLE_LAST,
    EZLOGIC_TITLE_LATCH,
    EZLOGIC_TITLE_LESS,
    EZLOGIC_TITLE_LESS_THAN,
    EZLOGIC_TITLE_LESS_THEN,
    EZLOGIC_TITLE_LOCAL_VARIABLE,
    EZLOGIC_TITLE_LUA_SCRIPT,
    EZLOGIC_TITLE_MESHBOT,
    EZLOGIC_TITLE_MINUTES,
    EZLOGIC_TITLE_NIGHT,
    EZLOGIC_TITLE_NOTIFICATION,
    EZLOGIC_TITLE_NOT_BETWEEN,
    EZLOGIC_TITLE_NOT_EQUAL,
    EZLOGIC_TITLE_NO_VALID_DATE_RANGE,
    EZLOGIC_TITLE_NUCAL,
    EZLOGIC_TITLE_ONCE,
    EZLOGIC_TITLE_POST,
    EZLOGIC_TITLE_PULSE,
    EZLOGIC_TITLE_PUT,
    EZLOGIC_TITLE_REBOOT_CONTROLLER,
    EZLOGIC_TITLE_REPEAT,
    EZLOGIC_TITLE_RESET_CONTROLLER,
    EZLOGIC_TITLE_RUN,
    EZLOGIC_TITLE_SECONDS,
    EZLOGIC_TITLE_SECURITY_MODE,
    EZLOGIC_TITLE_SECURITY_MODE_CHANGE_FROM,
    EZLOGIC_TITLE_SECURITY_MODE_CHANGE_TO,
    EZLOGIC_TITLE_SELECT_EXISTING_SCRIPT,
    EZLOGIC_TITLE_SELECT_FUNCTION,
    EZLOGIC_TITLE_SOFT_RESET,
    EZLOGIC_TITLE_START_TIME,
    EZLOGIC_TITLE_TRUE,
    EZLOGIC_TITLE_UNLATCH,
    EZLOGIC_TITLE_VACATION,
    EZLOGIC_TITLE_VALUE,
    EZLOGIC_TITLE_REACHABLE,
    EZLOGIC_TITLE_UNREACHABLE,
} from './language_tokens';

const { trigger_block, editing, is_valid, connection } = styles;
export const SET_MESHBOT = 'set';
export const INDEX_OF_ZERO = 0;
export const GEOFENCE_BLOCK_INDEX = 0;
export const INDEX_OF_ONE = 1;
export const INDEX_OF_TWO = 2;
export const OPERATOR_TYPE = 'optionType';
export const ON_CHANGE = 'onchange';
export const OPERATOR_AND = 'and';
export const OPERATOR_OR = 'or';
export const OPERATOR_XOR = 'xor';
export const OPERATOR_NOT = 'not';
export const OPERATORS_CLOUD = ['and', 'or'];
export const OPERATORS_LOCAL = ['and', 'or', 'xor'];
export const TRIGGER_EDIT_MODE = `${trigger_block} ${editing} ${connection}`;
export const TRIGGER_VALID_MODE = `${trigger_block} ${is_valid} ${connection}`;
export const TRIGGER_INVALID_MODE = `${trigger_block} ${connection}`;
export const ACTION_EDIT_MODE = `${trigger_block} ${editing}`;
export const ACTION_VALID_MODE = `${trigger_block} ${is_valid}`;
export const ACTION_INVALID_MODE = `${trigger_block}`;
export const DELETE_MESHBOT = 'delete';
export const IS_ITEM_STATE = 'isItemState';
export const YEAR = new Date().getFullYear();
export const MAX_YEAR = 2999;
export const SINGLE_TRIGGER = 1;
export const LAST_WEEK = 6;
export const WEEKS_PER_YEAR = 53;
export const DEFAULT_CUSTOM_DAY = 'everyday';
export const DEFAULT_CUSTOM_MONTH = 'everymonth';
export const DEFAULT_CUSTOM_YEAR = 'everyyear';
export const BETWEEN = 'between';
export const START_TIME = 'startTime';
export const END_TIME = 'endTime';
export const END_YEAR = 'endYear';
export const WEEKDAYS = 'weekdays';
export const IS_DATE = 'isDate';
export const IS_ONCE = 'isOnce';
export const INTERVAL = 'interval';
export const RESPONSE = 0;
export const FAIL = 0;
export const SUCCESS = 1;
export const STATIC = 'static';
export const AV = 'av';
export const CUSTOM_ENROLL_INFORMATION = 'custom_enroll_information';
export const CUSTOM_SUBSCRIPTION = 'custom_subscription';
export const IS_SHOW_FOR_SECOND = '&show_for_seconds=0';
export const SMALL = 'small';
export const CONTAINED = 'contained';
export const NOT_BETWEEN = 'not_between';
export const START_MONTH = 'startMonth';
export const END_MONTH = 'endMonth';
export const GEOFENCES = 'geofences';
export const LOCATION_BLOCK_INDEX = 0;
export const USER_BLOCK_INDEX = 1;
/**
 * Minimum allowed value for number input fields.
 * @constant
 * @type {number}
 */
export const MIN_NUMBER_INPUT_VALUE = -999999999999;
export const SELECTED_OPERATOR = 'selectedOperator';

export const CUSTOM_DAY_ARRAY = {
    name: DEFAULT_CUSTOM_DAY,
    value: DEFAULT_CUSTOM_DAY,
};
export const CUSTOM_MONTH_ARRAY = {
    name: DEFAULT_CUSTOM_MONTH,
    value: DEFAULT_CUSTOM_MONTH,
};
export const CUSTOM_YEAR_ARRAY = {
    name: DEFAULT_CUSTOM_YEAR,
    value: DEFAULT_CUSTOM_YEAR,
};
export const DAY_LABEL = 'Day';
export const MONTH_LABEL = 'Month';
export const YEAR_LABEL = 'Year';
export const HOUR12 = 12;
export const ZERO_INT = 0;
export const MINUS_ONE_INT = -1;
export const HOUR11 = 11;
export const ZERO = '00';
export const EMPTY = 0;
export const HOUR23 = 23;
export const MINUTES60 = 60;
export const pmDefault = '12:00';
export const amDefault = '00:00';
export const ARMED = 'armed';
export const REACHABLE = 'reachable';
export const DISARMED = 'disarmed';
export const DEVICE_ON = 'true';
export const DEVICE_OFF = 'false';
export const MANUAL = 'manual';
export const SECONDS = 'seconds';
export const MINUTES = 'minutes';
export const HOURS = 'hours';
export const DAYS = 'days';
export const SELECTED_HEADERS = 'selectedHeaders';
export const HEADERS = 'headers';
export const FOR = 'for';
export const EQUAL = '==';
export const NOT_EQUAL = '!=';
export const LESSER = '<';
export const GREATER = '>';
export const REPEAT = 'repeat';
export const FOLLOW = 'follow';
export const DELETE_DEVICE = 'delete_device';
export const PULSE = 'pulse';
export const LATCH = 'latch';
export const SELECT_FUNCTION = 'selectFunction';
export const APICALL_NAME = 'apicall';
export const TIME_OF_DAY = 'timeOfDay';
export const CUSTOM = 'custom';
export const HOURS_TIME_ARRAY = '24_hours_time_array';
export const DATE_AND_TIME = 'dataAndTime';
export const SCHEDULE = 'schedule';
export const GROUP = 'group';
export const ACTIONS = 'actions';
export const CREATE = 'create';
export const EDIT = 'edit';
export const DEVICE = 'Device';
export const DEVICE_STATE = 'deviceState';
export const UNLATCH_SCENE = 'unlatchScene';
export const TRIGGER_FORM = 'triggerForm';
export const NO_CLOUD_VARIABLES = 'No Cloud Variables';
export const SELECT_SERVICE = 'Select Service';

export const UNLATCH_ALL_LATCHES = 'unlatchAllLatches';
export const RESET_SCENE_LATCHES = 'resetSceneLatches';
export const ALL = 'all';
export const CLOUD = 'cloud';
export const SELECTED_POINT = 'selectedPoint';
export const SELECTED_REQUEST = 'selectedRequest';
export const TRUE = 'true';
export const FALSE = 'false';
export const EMAIL = 'email';
export const BLOCK_WITH_NAME_SUB_BLOCK_VALUE = 0;
export const IS_ENABLED = 1;
export const SINGLE_ZERO = '0';
export const ARRAY_LENGTH = 1;
export const SINGLE_ELEMENT_ARRAY_LENGTH = 1;
export const DELAY_TIME = 10000;
export const INDEX_SELECTED_BLOCKS_ELEMENT = 0;
export const BLOCK_WITH_FIELDS_ARRAY = 0;
export const INDEX_SELECTED_FIELDS_ELEMENT = 1;
export const CURRENT_TIME_FORMAT = 'HH:mm';
export const RADIX = 10;
export const NUMBER_OF_ITEMS_TO_BE_REMOVED = 1;

export const MIN_FUNCTION_REPEAT_TIMES = 2;
export const RESET_LATCH = 'resetLatch';
export const CLOUD_API = 'cloudAPI';
export const SET_ITEM_VALUE = 'setItemValue';
export const SET_SCENE_STATE = 'setSceneState';
export const RUN_SCENE = 'runScene';
export const SET_DEVICE_ARMED = 'setDeviceArmed';
export const SET_HTTP_REQUEST = 'sendHttpRequest';
export const RUN_CUSTOM_SCRIPT = 'runCustomScript';
export const AM = 'am';
export const PM = 'pm';
export const TIME_NODE = 'time';
export const TIMES_NODE = 'times';
export const HOUR_NODE = 'hour';
export const IS_PM = 'isPm';
export const OFFSET = 'offset';
export const SUNSET = 'sunset';
export const SUNRISE = 'sunrise';
export const SPECIAL_TIME_OF_THE_DAY = 'specialTimeOfTheDay';
export const ACTION_THEN = 'then';
export const ACTION_ELSE = 'else';
export const BLOCK_WHEN = 'when';
export const TRIGGER = 'trigger';
export const SELECTED_SPECIFIC_LABEL = 'selectedSpecificLabel';
export const SELECTED_TIME_TYPE = 'selectedTimeType';
export const SELECTED_FIELD_DATE = 'selectedFieldDate';
export const SELECTED_SPECIFIC_DATE = 'selectedSpecificDate';
export const SELECT_DAY = 'selectDay';
export const SELECT_MONTH = 'selectMonth';
export const SELECT_YEAR = 'selectYear';
export const SUBSCRIPTION = 'subscription';
export const MULTIPLE = 'multiple';
export const SINGLE = 'single';
export const CLEAR = 'clear';
export const ADD = 'add';
export const RISE = 'rise';
export const SET = 'set';
export const AFTER = 'after';
export const BEFORE = 'before';
export const EXCEPTION = 'exception';
export const AND = 'and';
export const OR = 'or';
export const NOT = 'not';
export const ITEM = 'item';
export const IN_GROUP = 'inGroup';
export const BLOCK_NAME = 'blockName';
export const BLOCK_ID = 'blockId';
export const OPTION_TYPE = 'optionType';
export const PLUS = 'plus';
export const MINUS = 'minus';
export const CONTROL = 'control';
export const CONTROLLER = 'controller';
export const _COMMAND = '_command';
export const NUMBER = 'number';
export const X_CALLBACKS = 'x-callbacks';
export const OBJECT = 'object';
export const SCENES_NAME_MAX_LENGTH = 128;
export const INTEGRATION = 'integration';
export const STRING = 'string';
export const NULL = ' null';
export const COLON = ': ';
export const MAXIMUM_VALUE_FOR_INTERVAL_TYPE = 59;
export const MINIMUM_VALUE_FOR_INTERVAL_TYPE = 1;
export const INT_ARRAY_TYPE = 'int_array';
export const SELECTED_VALUE_FIELD_INDEX = 2;
export const SELECTED_TYPE_FIELD_INDEX = 0;
export const BLOCK_WITH_VALUE_STRING = 0;
export const COMPARATOR = 'comparator';
export const VALUE = 'value';
export const STATE = 'state';
export const SELECTED_METADATA_FIELD_INDEX = 4;
export const DASHBOARD = 'dashboard';
export const DASHBOARD_NAME = 'Dashboard';
export const NOTIFICATION = 'notification';
export const REQUIRED_FIELDS = 'requiredFields';
export const CLOUD_NOTIFICATION = 'cloudNotification';
export const COMMAND = 'command';
export const CAPABILITY = 'capability';
export const TARGET = 'target';
export const CAPABILITY_COMMANDS = 'capabilityCommands';
export const DEVICE_PARAMETERS = 'deviceParameters';
export const SETPOINT_HEAT_COMMAND = 'setpoint_heat_command';
export const SETPOINT_HEAT = 'setpoint_heat';
export const SETPOINT_COOL_COMMAND = 'setpoint_cool_command';
export const SETPOINT_COOL = 'setpoint_cool';
export const INPUT_TYPE = 'inputType';
export const NAME = 'name';
export const ABSTRACT = 'abstract';
export const NUCAL = 'NuCAL';
export const PARAMS = 'params';
export const SELECTED_CODE = 'selectedCode';
export const VALUE_TYPE = 'Value';
export const VALUE_FOR_CLOUD = 'Value';
export const CLOUD_VARIABLE = 'Cloud variable';
export const UNSUPPORTED_COMPARATOR = 'Not supported comparator type';
export const CURRENT_VALUE = 'Current value:';
export const VALUE_TYPES_FOR_CLOUD_VARIABLE = [
    { value: 'Value', name: EZLOGIC_TITLE_VALUE },
    { value: 'Cloud variable', name: EZLOGIC_TITLE_CLOUD_VARIABLE },
];
export const CAPABILITY_SETTINGS = {
    VARIABLE: 'value',
    VALUE_TYPE: 'string',
};
export const ABSTRACT_INDEXES = {
    ABSTRACT_UUID: 0,
    CAPABILITY_NAME: 1,
    VALUE: 3,
};
export const WEEKS_OF_THE_MONTH = [
    { name: 1, checked: false },
    { name: 2, checked: false },
    { name: 3, checked: false },
    { name: 4, checked: false },
    { name: 5, checked: false },
    { name: 6, checked: false },
    { name: -1, checked: false },
];

export const CLOUD_MESHBOT_CONTROLLABLES_NAME = {
    DEVICE: EZLOGIC_TITLE_DEVICE,
    NOTIFICATION_NAME: EZLOGIC_TITLE_NOTIFICATION,
    DASHBOARD_NAME: EZLOGIC_TITLE_DASHBOARD,
    NUCAL: EZLOGIC_TITLE_NUCAL,
};

export const LOCAL_MESHBOT_CONTROLLABLES_NAME = {
    DEVICE_STATE: EZLOGIC_TITLE_DEVICE,
    MESHBOT_STATE: EZLOGIC_TITLE_MESHBOT,
    NOTIFICATION_STATE: EZLOGIC_TITLE_NOTIFICATION,
    HTTP_REQUEST: EZLOGIC_TITLE_HTTP_REQUEST,
    LUA_SCRIPT: EZLOGIC_TITLE_LUA_SCRIPT,
    PAAS: EZLOGIC_TITLE_NUCAL,
    ACTION_HOUSE_MODE: EZLOGIC_TITLE_HOUSE_MODE,
    LOCAL_VARIABLE: EZLOGIC_TITLE_LOCAL_VARIABLE,
    CONTROLLER: EZLOGIC_TITLE_CONTROLLER,
    SECURITY_MODE: EZLOGIC_TITLE_SECURITY_MODE,
};

export const generateWeeksArray = (n) =>
    Array.from(Array(n).keys())
        .map((i) => ({ name: ++i, checked: false }))
        .concat({ name: -1, checked: false });

export const WEEKS_OF_THE_YEAR = generateWeeksArray(WEEKS_PER_YEAR);

export const generatecustomDayArray = (n, initialValue = 0) => {
    return Array.from(Array(n).keys()).map((i) => {
        const value = ++i + initialValue;

        return { name: value, value };
    });
};

export const DAYS_CUSTOM_ARRAY = generatecustomDayArray(31);
export const MONTH_CUSTOM_ARRAY = generatecustomDayArray(12);
export const YEARS_CUSTOM_ARRAY = generatecustomDayArray(20, YEAR - 1);
DAYS_CUSTOM_ARRAY.unshift(CUSTOM_DAY_ARRAY);
MONTH_CUSTOM_ARRAY.unshift(CUSTOM_MONTH_ARRAY);
YEARS_CUSTOM_ARRAY.unshift(CUSTOM_YEAR_ARRAY);

export const AVAILABLE_YEARS_ARRAY = YEARS_CUSTOM_ARRAY.slice(1);

export const BLOCK_FIELD_TYPES = {
    INT: 'int',
    INTEGER: 'integer',
    FLOAT: 'float',
    BOOL: 'bool',
    STRING: 'string',
    TOKEN: 'token',
    DICTIONARY: 'dictionary',
    EMPTY: 'empty',
    BOOL_AND_TOKEN: 'boolAndToken',
    EXPRESSION: 'expression',
    VARIABLE: 'variable',
    VALUE: 'value',
    VALUE_BLOCK: 'valueBlock',
    RGB: 'rgb',
    TEMPERATURE: 'temperature',
    BUTTON_STATE: 'button_state',
    HUMIDITY: 'humidity',
    ILLUMINANCE: 'illuminance',
    LOUDNESS: 'loudness',
    ITEM: 'item',
};

export const DEVICE_NODE_SUPPORTED_TYPES = [
    BLOCK_FIELD_TYPES.INT,
    BLOCK_FIELD_TYPES.FLOAT,
    BLOCK_FIELD_TYPES.STRING,
    BLOCK_FIELD_TYPES.BOOL,
    BLOCK_FIELD_TYPES.TOKEN,
    BLOCK_FIELD_TYPES.TEMPERATURE,
    BLOCK_FIELD_TYPES.BUTTON_STATE,
    BLOCK_FIELD_TYPES.ILLUMINANCE,
    BLOCK_FIELD_TYPES.HUMIDITY,
    BLOCK_FIELD_TYPES.LOUDNESS,
];

/**
 * List between blocks
 * @returns { array } list blocks for custom date and time
 * @example
 * betweenList
 */
export const betweenList = [
    { id: hash(), label: EZLOGIC_TITLE_START_TIME, value: 'startTime' },
    { id: hash(), label: EZLOGIC_TITLE_END_TIME, value: 'endTime' },
];

/**
 * Formatting time for two-digit numbers
 * @returns { string } string time
 * @example
 * formattingTime(data)
 */
export const formattingTime = (data) => {
    data = JSON.stringify(Number(data));

    return data.length === 1 || data === '00' ? `0${data}` : data;
};

/**
 * List nodes for Date and time
 * @returns { array } List nodes
 * @example
 * listDateTime
 */
export const listDateTime = [
    { id: hash(), value: 'daily' },
    { id: hash(), value: 'custom' },
    { id: hash(), value: 'weekdays' },
    { id: hash(), value: 'weeks' },
    { id: hash(), value: 'yearWeeks' },
    { id: hash(), value: 'days' },
    { id: hash(), value: 'endYear' },
    { id: hash(), value: 'isOnce' },
];

/**
 * Elements for 'date time' interval
 * @returns { array } List element
 * @example
 * intervalSelect
 */
export const intervalSelect = [
    { value: 's', name: EZLOGIC_TITLE_SECONDS },
    { value: 'm', name: EZLOGIC_TITLE_MINUTES },
    { value: 'h', name: EZLOGIC_TITLE_HOURS },
    { value: 'd', name: EZLOGIC_TITLE_DAYS },
];

/**
 * Default value when we choose subtype  "custom date and time"
 * @returns { array } List element
 * @example
 * unBetweenType or betweenType
 */
export const unBetweenType = [
    { label: 'selectedTimeType', value: 'am' },
    { label: 'selectDay', value: 'everyday' },
    { label: 'selectMonth', value: '' },
    { label: 'selectYear', value: '' },
];

export const betweenType = [
    { label: 'selectedTimeType', value: { startTime: 'am', endTime: 'am' } },
    { label: 'selectDay', value: { startDay: 'everyday', endDay: 'everyday' } },
    { label: 'selectMonth', value: {} },
    { label: 'selectYear', value: {} },
];

/**
 * // todo: rename, what is exception, check validation for what?
 * Element to check validation
 * @returns { array } List element
 * @example
 * exception
 */
export const exception = ['endYear', 'custom', 'daily', 'interval'];

/**
 * Option for material select
 * @returns { object } List options
 * @example
 * optionsSelect
 */
export const optionsSelect = {
    anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
    transformOrigin: { vertical: 'top', horizontal: 'left' },
    disableScrollLock: true,
};

//Do not change the values that are needed for queries.
export const ARMED_SELECT_VALUES = {
    ANY: 'any',
    ARMED: 'armed',
    DISARMED: 'disarmed',
};

export const ARMED_SELECT_OPTIONS = [
    { label: EZLOGIC_TITLE_ANY, value: ARMED_SELECT_VALUES.ANY },
    { label: EZLOGIC_TITLE_ARMED, value: ARMED_SELECT_VALUES.ARMED },
    { label: EZLOGIC_TITLE_DISARMED, value: ARMED_SELECT_VALUES.DISARMED },
];

export const MESHBOT_NODE_TYPES = {
    SELECT_BLOCK: 'selectBlock',
    DEVICE_STATE: 'deviceState',
    DEVICE_STATE_ADVANCED: 'deviceStateAdvanced',
    NOTIFICATION_STATE: 'notification',
    DASHBOARD: 'dashboard',
    DATE_AND_TIME: 'dataAndTime',
    MESHBOT_STATE: 'meshBotState',
    EXPRESSION: 'expression',
    VIDOO: 'vidoo',
    HTTP_REQUEST: 'sendHttpRequest',
    LUA_SCRIPT: 'luaScript',
    PAAS: 'PAAS',
    ACTION_HOUSE_MODE: 'actionHouseMode',
    TRIGGER_HOUSE_MODE: 'triggerHouseMode',
    CLOUD_VARIABLES: 'cloudVariables',
    GEOFENCE: 'geofence',
    LOCAL_VARIABLE: 'setVariable',
    CONTROLLER_TRIGGER: 'controller',
    CONTROLLER: 'controller',
    EXPRESSION_COMPARISON: 'expressionComparison',
    VARIABLE_COMPARISON: 'variableComparison',
    DEVICE_GROUP: 'deviceGroup',
};

export const COMPARISON_DATA = {
    COMPARATOR: 'comparator',
    VALUE: 'value',
    START_VALUE: 'startValue',
    END_VALUE: 'endValue',
    METHOD: {
        COMPARE_NUMBER: 'compareNumbers',
        COMPARE_NUMBER_RANGE: 'compareNumberRange',
        IS_ITEM_STATE: 'isItemState',
        IS_BUTTON_STATE: 'isButtonState',
        COMPARE_VALUES: 'compareValues',
        COMPARE_STRINGS: 'compareStrings',
        STRING_OPERATION: 'stringOperation',
        BATTERY_STATE: 'battery state',
        BATTERY_LEVEL: 'battery level',
        CLOUD_CONNECTION: 'cloud connection',
        BUTTERY_STATE: 'battery state',
        BUTTERY_LEVEL: 'battery level',
        IS_USER_LOCK_OPERATION: 'isUserLockOperation',
        NUMBERS_ARRAY: 'numbersArray',
        IS_ITEM_STATE_CHANGED: 'isItemStateChanged',
        IS_DEVICE_STATE: 'isDeviceState',
    },
    OPERATION: {
        LENGTH: 'length',
        NOT_LENGTH: 'not_length',
    },
};

export const WHEN_BLOCK = {
    BLOCK_TYPE_WHEN: 'when',
    VALUE: 'value',
    EXPRESSION: 'expression',
    COMPARATOR: 'comparator',
    OPERATION: 'operation',
    ITEM: 'item',
    START_VALUE: 'startValue',
    END_VALUE: 'endValue',
    ARMED: 'armed',
    STATE: 'state',
    USER: 'user',
    TYPE: {
        STRING: 'string',
        INT: 'int',
        ITEM: 'item',
        BOOLEAN: 'bool',
        FLOAT: 'float',
        TOKEN: 'token',
        DICTIONARY_ID: 'dictionaryId',
    },
    META_TYPE: {
        DEVICE_ADVANCED: 'deviceAdvanced',
        DEVICE: 'device',
        CLOUD_VARIABLES: 'cloudVariables',
        NUCAL_SUBSCRIPTION: 'nucalSubscription',
        DEVICE_GROUP: 'deviceGroup',
    },
    METHOD_NAME: {
        IS_CLOUD_STATE: 'isCloudState',
        IS_BATTERY_LEVEL: 'isBatteryLevel',
    },
    METHOD_ARGS: {
        STATE: 'state',
        COMPARATOR: 'comparator',
        DEVICE: 'device',
        REACHABLE: 'reachable',
        ARMED: 'armed',
    },
    FIELDS: {
        COMPARATOR: 'comparator',
        VALUE: 'value',
        NAME: {
            STATE: 'state',
            DEVICE: 'device',
            REACHABLE: 'reachable',
            ARMED: 'armed',
        },
        TYPE: { TOKEN: 'token', STRING: 'string', INT: 'int', DEVICE: 'device', BOOL: 'bool' },
    },
};

const getSelectBlock = (initialParams) => {
    return {
        id: '',
        not: initialParams?.notOperatorValue,
        blocks: [],
    };
};

const getControllerNodeBlock = () => ({
    id: '',
    blocks: [],
    selectedFieldTrigger: '',
});

const getDeviceAdvancedStateBlock = (initialParams) => {
    return {
        id: '',
        not: initialParams?.notOperatorValue,
        deviceId: '',
        name: '',
        selectedFieldTrigger: '',
        selectedCapability: {},
        selectedComparator: {},
        compareTo: 'value',
        comparingValue: undefined,
    };
};

const getNotificationStateBlock = (initialParams) => {
    return {
        id: '',
        not: initialParams?.notOperatorValue,
        name: '',
        firstBlock: '',
        blocks: [],
        selectedFieldTrigger: '',
    };
};

const getDateAndTimeBlock = (initialParams) => {
    return {
        id: '',
        not: initialParams?.notOperatorValue,
        selectedFieldTrigger: '',
        selectedFieldDate: '',
        selectedSpecificDate: '',
        selectedSpecificLabel: '',
        selectedTimeType: '',
        selectDay: '',
        selectMonth: '',
        selectYear: '',
        blocks: [],
    };
};

const getControllerBlock = (initialParams) => {
    return {
        not: initialParams?.notOperatorValue,
        id: '',
        blocks: [],
        selectedControllerCapability: '',
        selectedControllerCapabilityValue: '',
        selectedControllerCapabilityComparator: '',
        selectedControllerCapabilityComparatorValue: '',
    };
};

const getMeshBotStateBlock = (initialParams) => {
    return {
        id: '',
        not: initialParams?.notOperatorValue,
        name: '',
        idDev: '',
        firstBlock: '',
        blocks: [],
        selectedFieldTrigger: '',
    };
};

const getExpressionBlock = () => {
    return {
        id: '',
        selectedFieldTrigger: '',
        nameExpression: '',
        code: '',
        nameVar: '',
        name: '',
        nameItem: '',
    };
};

const getVidooBlock = (initialParams) => {
    return {
        id: '',
        not: initialParams?.notOperatorValue,
        name: '',
        idDev: '',
        firstBlock: '',
        blocks: [],
        selectedFieldTrigger: '',
    };
};

const getHttpRequestBlock = () => {
    return {
        id: '',
        blocks: [],
        selectedFieldTrigger: '',
        selectedRequest: '',
        selectedUrl: '',
        selectedSecurity: false,
        selectedCredentialUser: '',
        selectedCredentialPassword: '',
        selectedContentType: '',
        selectedHeaders: {},
        selectedContent: '',
    };
};

const getLuaScriptBlock = () => {
    return {
        id: '',
        blocks: [],
        selectedFieldTrigger: '',
        selectedName: '',
        selectedCode: '',
        selectedPoint: '',
    };
};

// TODO refactore
const getPAASBlock = () => {
    return {
        id: '',
        blocks: [],
        selectedFieldTrigger: '',
    };
};

const getActionHouseModeBlock = () => ({
    id: '',
    blocks: [],
    selectedFieldTrigger: '',
});

const getTriggerHouseModeBlock = (initialParams) => ({
    id: '',
    not: initialParams?.notOperatorValue,
    blocks: [],
    selectedFieldTrigger: '',
});

const getTriggerCloudVariablesBlock = (initialParams) => ({
    id: '',
    not: initialParams?.notOperatorValue,
    selectedFieldTrigger: '',
    selectedCapability: {},
    selectedComparator: {},
    compareTo: 'value',
    comparingValue: undefined,
});

const getLocalVariableBlock = () => ({
    id: '',
    blocks: [],
    selectedFieldTrigger: '',
});

const getExpressionComparisonBlock = (initialParams) => ({
    id: '',
    not: initialParams?.notOperatorValue,
    blocks: [],
    selectedFieldTrigger: '',
});

const getTriggerDeviceGroupBlock = (initialParams) => ({
    id: '',
    not: initialParams?.notOperatorValue,
    deviceGroupId: '',
    itemGroupId: '',
    selectedFieldTrigger: '',
    selectedCapability: {},
    selectedComparator: {},
    compareTo: 'value',
    comparingValue: undefined,
});

/**
 * List templates for nodes
 * @returns { object } List templates
 * @example
 * templatesNodes[typeNode]
 */
export const getNodeBlockTemplateByKey = (templateKey, initialParams) => {
    switch (templateKey) {
        case MESHBOT_NODE_TYPES.SELECT_BLOCK:
            return getSelectBlock(initialParams);

        case MESHBOT_NODE_TYPES.CONTROLLER_TRIGGER:
            return getControllerBlock(initialParams);

        case MESHBOT_NODE_TYPES.DEVICE_STATE:
        case MESHBOT_NODE_TYPES.DEVICE_STATE_ADVANCED:
            return getDeviceAdvancedStateBlock(initialParams);

        case MESHBOT_NODE_TYPES.NOTIFICATION_STATE:
            return getNotificationStateBlock(initialParams);

        case MESHBOT_NODE_TYPES.DATE_AND_TIME:
            return getDateAndTimeBlock(initialParams);

        case MESHBOT_NODE_TYPES.MESHBOT_STATE:
            return getMeshBotStateBlock(initialParams);

        case MESHBOT_NODE_TYPES.EXPRESSION:
            return getExpressionBlock();

        case MESHBOT_NODE_TYPES.VIDOO:
            return getVidooBlock(initialParams);

        case MESHBOT_NODE_TYPES.HTTP_REQUEST:
            return getHttpRequestBlock();

        case MESHBOT_NODE_TYPES.LUA_SCRIPT:
            return getLuaScriptBlock();

        case MESHBOT_NODE_TYPES.PAAS:
            return getPAASBlock();

        case MESHBOT_NODE_TYPES.ACTION_HOUSE_MODE:
            return getActionHouseModeBlock();

        case MESHBOT_NODE_TYPES.TRIGGER_HOUSE_MODE:
            return getTriggerHouseModeBlock(initialParams);
        case MESHBOT_NODE_TYPES.CLOUD_VARIABLES:
            return getTriggerCloudVariablesBlock(initialParams);

        case MESHBOT_NODE_TYPES.LOCAL_VARIABLE:
            return getLocalVariableBlock();

        case MESHBOT_NODE_TYPES.CONTROLLER:
            return getControllerNodeBlock();

        case MESHBOT_NODE_TYPES.EXPRESSION_COMPARISON:
        case MESHBOT_NODE_TYPES.VARIABLE_COMPARISON:
            return getExpressionComparisonBlock(initialParams);

        case MESHBOT_NODE_TYPES.DEVICE_GROUP:
            return getTriggerDeviceGroupBlock(initialParams);
    }
};

/**
 * Type 'data and time'
 * @returns { string } Return type for custom 'data and time'
 * @example
 * getValueField(data, 'day', 'startTime')
 */
export const getValueField = (data, typeField, type) => {
    if (typeof data !== 'string' && typeof data !== 'number') {
        if (typeField === 'day' && type === 'startTime') {
            return data.startDay;
        } else if (typeField === 'day' && type === 'endTime') {
            return data.endDay;
        } else if (typeField === 'month' && type === 'startTime') {
            return data.startMonth;
        } else if (typeField === 'month' && type === 'endTime') {
            return data.endMonth;
        } else if (typeField === 'year' && type === 'startTime') {
            return data.startYear;
        } else if (typeField === 'year' && type === 'endTime') {
            return data.endYear;
        } else {
            return data[type];
        }
    }

    return data;
};

/**
 * Defining 'custom' subtype
 * @returns { string } Return string
 * @example
 * detectBetween('hour', 'startTime)
 */
export const detectBetween = (data, type) => {
    if (type === 'startTime' && data === 'hour') {
        return 'startHours';
    }

    if (type === 'startTime' && data === 'minute') {
        return 'startMinutes';
    }

    if (type === 'endTime' && data === 'hour') {
        return 'endHours';
    }

    if (type === 'endTime' && data === 'minute') {
        return 'endMinutes';
    }
};
export const VALIDATION_STATE = {
    isValid: false,
    isEdit: false,
    name: '',
    when: [],
    then: [],
    else: [],
    exceptionWhen: [],
};

export const TRIGGER_TYPES = {
    IS_ITEM_STATE: 'isItemState',
    IS_BUTTON_STATE: 'isButtonState',
    COMPARE_NUMBERS: 'compareNumbers',
    COMPARE_NUMBER_RANGE: 'compareNumberRange',
    COMPARE_VALUES: 'compareValues',
    COMPARE_STRINGS: 'compareStrings',
    STRING_OPERATION: 'stringOperation',
    IS_DATE: 'isDate',
    IS_ONCE: 'isOnce',
    IS_DATE_RANGE: 'isDateRange',
    IS_INTERVAL: 'isInterval',
    IS_SUN_STATE: 'isSunState',
    IS_SCENE_STATE: 'isSceneState',
    FUNCTION: 'function',
    IS_DETECTED_IN_HOTZONE: 'isDetectedInHotzone',
    IS_HOUSE_MODE_CHANGED_TO: 'isHouseModeChangedTo',
    IS_HOUSE_MODE_CHANGED_FROM: 'isHouseModeChangedFrom',
    IS_BATTERY_STATE: 'isBatteryState',
    IS_BATTERY_LEVEL: 'isBatteryLevel',
    IS_CLOUD_STATE: 'isCloudState',
    IS_BUTTERY_STATE: 'isBatteryState',
    IS_BUTTERY_LEVEL: 'isBatteryLevel',
    CONTROLLER: 'controller',
    IS_ITEM_STATE_CHANGED: 'isItemStateChanged',
    IS_USER_LOCK_OPERATION: 'isUserLockOperation',
    IS_DEVICE_STATE: 'isDeviceState',
};

export const ACTION_TYPES = {
    SET_ITEM_VALUE: 'setItemValue',
    SET_DEVICE_ARMED: 'setDeviceArmed',
    RUN_CUSTOM_SCRIPT: 'runCustomScript',
    SEND_HTTP_REQUEST: 'sendHttpRequest',
    RUN_SCENE: 'runScene',
    SET_SCENE_STATE: 'setSceneState',
    CLOUD_API: 'cloudAPI',
    RESET_LATCH: 'resetLatch',
    RESET_SCENE_LATCHES: 'resetSceneLatches',
    SWITCH_HOUSE_MODE: 'switchHouseMode',
    TOGGLE_VALUE: 'toggleValue',
    LOCAL_VARIABLE: 'setVariable',
    REBOOT_HUB: 'rebootHub',
    RESET_HUB: 'resetHub',
};

export const stepNumberInput = {
    [BLOCK_FIELD_TYPES.INT]: 1,
    [BLOCK_FIELD_TYPES.FLOAT]: 0.1,
};

/**
 * Array with methods for work to HttpRequest
 * More info: {@link https://log.ezlo.com/new/hub/scenes/action_blocks/#sendhttprequest sendHttpRequest}
 * @returns { array } Return array
 */
export const requestMethods = [
    { id: hash(), name: EZLOGIC_TITLE_GET, value: 'GET' },
    { id: hash(), name: EZLOGIC_TITLE_POST, value: 'POST' },
    { id: hash(), name: EZLOGIC_TITLE_PUT, value: 'PUT' },
    { id: hash(), name: EZLOGIC_TITLE_DELETE, value: 'DELETE' },
];

/**
 * Array with points for work to lua script
 * @returns { array } Return array
 */
export const luaPoints = [
    { id: hash(), name: 'existingScript', label: EZLOGIC_TITLE_SELECT_EXISTING_SCRIPT },
    // Hidden until the PRD for Lua Scripting (Actions) is updated.
    // { id: hash(), name: 'newScript', label: EZLOGIC_TITLE_CREATE_NEW_SCRIPT },
];

export const EZLOPI_FIRMWARE = {
    VERSION_3_1_4: '3.1.4',
    VERSION_3_3_6: '3.3.6',
    VERSION_3_4_7: '3.4.7',
    VERSION_3_5_8: '3.5.8',
    VERSION_4_1_3: '4.1.3',
    VERSION_4_1_4: '4.1.4',
    VERSION_4_1_5: '4.1.5',
    VERSION_4_1_6: '4.1.6',
};

export const MESHBOT_ACTION_SELECT_VALUES = [
    {
        value: 'runScene',
        label: EZLOGIC_TITLE_RUN,
        firmwareVersion: EZLOPI_FIRMWARE.VERSION_3_3_6,
    },
    {
        value: 'enableScene',
        label: EZLOGIC_TITLE_ENABLE,
        firmwareVersion: EZLOPI_FIRMWARE.VERSION_3_3_6,
    },
    {
        value: 'disableScene',
        label: EZLOGIC_TITLE_DISABLE,
        firmwareVersion: EZLOPI_FIRMWARE.VERSION_3_3_6,
    },
    {
        value: 'unlatchScene',
        label: EZLOGIC_TITLE_UNLATCH,
        firmwareVersion: EZLOPI_FIRMWARE.VERSION_4_1_3,
    },
];

export const LEAST = 'least';
export const FUNCTION_INITIAL_SECOND = 0;
export const FALSE_PERIOD = 'falsePeriod';
export const TIMES = 'times';

export const MESHBOT_FUNCTION_FOR_SELECT_VALUES = [
    {
        key: hash(),
        value: LEAST,
        label: EZLOGIC_TITLE_AT_LEAST,
    },
    {
        key: hash(),
        value: 'less',
        label: EZLOGIC_TITLE_LESS_THAN,
    },
];
export const PULSE_FUNCTION_ITEM = {
    once: 'once',
    repeat: 'repeat',
    infinite: 'infinite',
};

export const MESHBOT_FUNCTION_PULSE_SELECT_VALUES = [
    {
        key: hash(),
        value: PULSE_FUNCTION_ITEM.once,
        label: EZLOGIC_TITLE_ONCE,
        lowestSupportedVersion: '1.0',
    },
    {
        key: hash(),
        value: PULSE_FUNCTION_ITEM.repeat,
        label: EZLOGIC_TITLE_REPEAT,
        lowestSupportedVersion: '1.29',
    },
    {
        key: hash(),
        value: PULSE_FUNCTION_ITEM.infinite,
        label: EZLOGIC_TITLE_INFINITE,
        lowestSupportedVersion: '1.37',
    },
];

export const MESHBOT_FUNCTION_TYPES = [
    {
        key: hash(),
        value: SELECT_FUNCTION,
        label: EZLOGIC_TITLE_SELECT_FUNCTION,
        dependencies: {
            featureName: CONTROLLER_FEATURES_NAMES.ADVANCED_SCENES,
            lowestSupportedVersion: '0',
        },
    },
    {
        key: hash(),
        value: FOR,
        label: EZLOGIC_TITLE_FOR,
        dependencies: {
            featureName: CONTROLLER_FEATURES_NAMES.ADVANCED_SCENES,
            lowestSupportedVersion: '1.18',
        },
    },
    {
        key: hash(),
        value: REPEAT,
        label: EZLOGIC_TITLE_REPEAT,
        dependencies: {
            featureName: CONTROLLER_FEATURES_NAMES.ADVANCED_SCENES,
            lowestSupportedVersion: '1.20',
        },
    },
    {
        key: hash(),
        value: FOLLOW,
        label: EZLOGIC_TITLE_FOLLOW,
        dependencies: {
            featureName: CONTROLLER_FEATURES_NAMES.ADVANCED_SCENES,
            lowestSupportedVersion: '1.27',
        },
    },
    {
        key: hash(),
        value: PULSE,
        label: EZLOGIC_TITLE_PULSE,
        dependencies: {
            featureName: CONTROLLER_FEATURES_NAMES.ADVANCED_SCENES,
            lowestSupportedVersion: '1.29',
        },
    },
    {
        key: hash(),
        value: LATCH,
        label: EZLOGIC_TITLE_LATCH,
        dependencies: {
            featureName: CONTROLLER_FEATURES_NAMES.ADVANCED_SCENES,
            lowestSupportedVersion: '1.31',
        },
    },
];

export const TRIGGER_TYPE_LIST = {
    SINGLE_TRIGGER: 'single_trigger',
};

export const MESHBOT_PROTOCOLS_PLUGIN = {
    HTTP: 'http_client_send',
    UDP: 'udp_client_send',
    TCP: 'tcp_client_send',
};

export const MESHBOT_PROTOCOLS_PLUGIN_LIST = [
    MESHBOT_PROTOCOLS_PLUGIN.HTTP,
    MESHBOT_PROTOCOLS_PLUGIN.UDP,
    MESHBOT_PROTOCOLS_PLUGIN.TCP,
];

export const VIDOO_TYPES = {
    ANY_MOTION: 'any_motion',
    FACE_DETECTION: 'face_detection',
    OBJECT_DETECTION: 'object_detection',
    QR_CODE_DETECTION: 'qr_code_detection',
};

export const SUMMARY_VIDOO_OPTIONS = {
    ANY_PERSON: 'any_person',
    ANY_OBJECT: 'any_object',
    ANY_QRCODE: 'any_qrcode',
    FULL_SCREEN: 'full_screen',
    ANY_ZONE: 'any_zone',
};
export const MESHBOT_NOTIFICATION = 'notification';
export const MESHBOT_BLOCKS = 'blocks';
export const LUA_SCRIPT = 'luaScript';
export const HTTP_REQUEST = 'sendHttpRequest';
export const SCRIPT = 'script';
export const GET = 'get';
export const LIST = 'list';
export const SELECTED = 'selected';
export const ADD_SUCCESS = 'addSuccess';
export const SET_SUCCESS = 'setSuccess';
export const START_UPDATE = 'startUpdate';
export const REMOVE_SUCCESS = 'removeSuccess';
export const REJECTED = 'rejected';
export const LIMITED_MINUTES = 61;
export const LIMITED_HOURS = 13;
export const DELAY = 'delay';
export const IMMEDIATELY = 'immediately';
export const BODY = 'body';
export const BODY_HTML = 'body_html';
export const SUBJECT = 'subject';
export const SUBJECT_PLACEHOLDER = 'Subject';
export const SET_VARIABLE = 'setVariable';
export const CREATE_NEW_VARIABLE_LABEL = '+ Create New Variable';
export const CREATE_NEW_LOCATION_LABEL = '+ Create New Location';
export const CHOOSE_VARIABLE_PLACEHOLDER = 'Choose a variable';
export const PRIMARY = 'primary';
export const NO_OPTIONS = 'No options';
export const INLINE = 'inline';
export const DATE_FORMAT = 'MM/dd/yyyy';
export const NORMAL = 'normal';
export const INPUT_PROPS = {
    min: 15,
    max: 60,
};
export const INITIAL_VIDOO_CAPABILITY_VALUE = 1;

export const VIDOO_CAMERA_CAPABILITIES = {
    MAKE_RECORDING: 'make_recording',
    TAKE_SNAPSHOT: 'take_snapshot',
    STOP_RECORDING: 'stop_recording',
};

// export const DEVICE_CAMERA_CAPABILITIES = {
//     TYPE_IS_TOKEN: {
//         AUDIO_STREAM_CONTROL: 'audio_stream_control',
//     },
//     TYPE_IS_INT: {
//         MUTABLE: {
//             MAKE_RECORDING: 'make_recording',
//         },
//         IMMUTABLE: {
//             TAKE_SNAPSHOT: 'take_snapshot',
//             STOP_RECORDING: 'stop_recording',
//             FORMAT_SD_CARD: 'format_sd_card',
//             RESET: 'reset',
//         },
//     },
// };

export const CAMERA = 'camera';
export const CAMERA_HOTZONE = 'camera_hotzone';
export const SELECTED_VALUE = 1;
export const INDEX_SELECTED_VALUE = 0;

export const VIDOO_ITEM_NAME = {
    DETECTABLE_PERSONS: 'detectable_persons',
    DETECTABLE_OBJECTS: 'detectable_objects',
    DETECTABLE_QRCODES: 'detectable_qrcodes',
    HOTZONES: 'hotzones',
};

export const NAME_ARGUMENT_FIELDS = {
    FILTERS: 'filters',
    PERSONS: 'persons',
    OBJECTS: 'objects',
    QRCODES: 'qrcodes',
    ITEM: 'item',
    HOTZONES: 'hotzones',
    LIST: 'list',
};

export const HTTP_REQUEST_FIELD_KIND = {
    REQUEST: 'request',
    URL: 'url',
    SKIP_SECURITY: 'skipSecurity',
    CREDENTIAL: 'credential',
    HEADERS: 'headers',
    CONTENT_TYPE: 'contentType',
    CONTENT: 'content',
};

export const PAAS_ACTION = 'PAAS';

export const TYPE_ERROR = 'Error! The type of the transmitted data does not match the required one';

/**
 * More info about this params: [Link] {@link https://log.ezlo.com/new/hub/scenes/local_scenes_api/#hubscenesblockdatalist}
 * @constructor
 */

export const SCENES_BLOCK_DATA_LIST_PARAMS = {
    valueTypes: true,
    scalableValueTypes: true,
    valueScales: true,
    scenesValueTypes: true,
    valueTypeFamilies: true,
    comparisonOperators: true,
    comparisonMethods: true,
};

export const VALUE_TYPES_FAMILIES = {
    NUMERIC: 'numeric',
    STRINGS: 'strings',
    VALUES_WITH_LESS: 'valuesWithLess',
    VALUES_WITHOUT_LESS: 'valuesWithoutLess',
};

export const MAP_TO_JS_VALUE_TYPES = {
    [VALUE_TYPES_FAMILIES.NUMERIC]: 'number',
    [VALUE_TYPES_FAMILIES.STRINGS]: 'string',
};

export const VALUE_TYPES = {
    TOKEN: 'token',
    BOOLEAN: 'bool',
    FLOAT: 'float',
    BUTTON_STATE: 'button_state',
    TEMPERATURE: 'temperature',
    SCALABLE_VALUE_TYPES: 'scalableValueTypes',
    INT: 'int',
};

export const BOOLEAN_OPTIONS = [
    { label: EZLOGIC_TITLE_TRUE, value: TRUE },
    { label: EZLOGIC_TITLE_FALSE, value: FALSE },
];
export const BOOLEAN_VALUES_AS_STRING = [TRUE, FALSE];
/**
 * List of values that were saved as values in scenes and broke those scenes.
 * */
export const LIST_OF_INCORRECT_VALUES_FOR_SCENE = [
    EZLOGIC_TITLE_TRUE,
    EZLOGIC_TITLE_FALSE,
    'ezlo.boolean.true',
    'ezlo.boolean.false',
];

export const HOUSE_MODES = [
    { id: hash(), name: EZLOGIC_TITLE_HOME, value: '1' },
    { id: hash(), name: EZLOGIC_TITLE_AWAY, value: '2' },
    { id: hash(), name: EZLOGIC_TITLE_NIGHT, value: '3' },
    { id: hash(), name: EZLOGIC_TITLE_VACATION, value: '4' },
];

export const SECURITY_MODES = [
    { id: hash(), name: EZLOGIC_TITLE_DISARMED, value: '1' },
    { id: hash(), name: EZLOGIC_TITLE_ARMED, value: '2' },
    { id: hash(), name: EZLOGIC_TITLE_ARM_STAY, value: '3' },
];

export const HOUSE_MODE_NODES = [
    {
        id: hash(),
        value: 'isHouseModeChangedTo',
        label: EZLOGIC_TITLE_HOUSE_MODE_CHANGED_TO,
        firmwareVersion: EZLOPI_FIRMWARE.VERSION_3_4_7,
    },
    {
        id: hash(),
        value: 'isHouseModeChangedFrom',
        label: EZLOGIC_TITLE_HOUSE_MODE_CHANGED_FROM,
        firmwareVersion: EZLOPI_FIRMWARE.VERSION_3_5_8,
    },
];

export const SECURITY_MODE_NODES = [
    {
        id: hash(),
        value: 'isHouseModeChangedTo',
        label: EZLOGIC_TITLE_SECURITY_MODE_CHANGE_TO,
        firmwareVersion: EZLOPI_FIRMWARE.VERSION_3_4_7,
    },
    {
        id: hash(),
        value: 'isHouseModeChangedFrom',
        label: EZLOGIC_TITLE_SECURITY_MODE_CHANGE_FROM,
        firmwareVersion: EZLOPI_FIRMWARE.VERSION_3_5_8,
    },
];

export const HOUSE_MODE_DEFAULT_VALUE = '1';

export const FUNCTION_BLOCK_PORTAL_WRAPPER = 'trigger-block__container';
export const VALUES_FOR_EMPTY_LISTS = {
    NO_EXPRESSIONS: 'No Expressions',
    NO_VARIABLES: 'No Variables',
};

export const INDEX_OF_VARIABLE_NAME = 0;
export const INPUT_NUMBER_STEP_INTERVAL = 50;
export const INPUT_NUMBER_STEP_DELAY = 300;

export const USER_EVENT_VALUE = {
    ARRIVE: 'arrive',
    LEAVE: 'leave',
};

export const USER_EVENT_KINDS = [
    {
        value: USER_EVENT_VALUE.ARRIVE,
        name: 'User arrives at the location',
    },
    {
        value: USER_EVENT_VALUE.LEAVE,
        name: 'User leaves the location',
    },
];

export const BLOCK_NAMES = {
    NOT: 'not',
    IN: 'in',
    STATIC: 'static',
    AVJ: 'avj',
};

export const GENERATOR_FIELDS = {
    LOCATION: 'location',
    EVENT_KIND: 'eventKind',
    USER: 'user',
};

export const TRIGGER_BLOCK_PROPERTIES = {
    NAME: 'name',
    PARAMETERS: 'parameters',
};

export const GEOFENCE_USER_DATA_FOR_API = {
    CAPABILITY: 'geofences',
    VARIABLE: 'geofences',
    TRANSFORM: 'array',
    JSON_PATH: '$[*][0]',
};

export const GEOFENCE_LOCATION_DATA_FOR_API = {
    TRANSFORM: 'string',
};

export const STRING_OF_EMPTY_OBJECT = '{}';
export const CAPABILITIES = {
    USER: 'user',
};

export const PATHNAME = '/ezlo/automation/local/new';
export const FIELD_NAME = 'time';

export const VALUES_FOR_BOOLEAN_TYPE = [
    { label: { text: 'true' }, value: true },
    { label: { text: 'false' }, value: false },
];

export const METHOD_NAME = 'toggleValue';
export const TOGGLE_VALUE_METHOD_NAME = 'toggleValue';

export const TYPES = [
    BLOCK_FIELD_TYPES.TOKEN,
    BLOCK_FIELD_TYPES.BOOL,
    BLOCK_FIELD_TYPES.INT,
    BLOCK_FIELD_TYPES.RGB,
    BLOCK_FIELD_TYPES.TEMPERATURE,
    BLOCK_FIELD_TYPES.DICTIONARY,
];

export const PARJECT_NAMES = ['Stripe', 'GitLab'];

export const STRING_UNIT = '1';
export const INITIAL_VALUE = 1;
export const EMPTY_STRING = '';
export const INDEX_SELECTED_ELEMENT = 0;
export const INITIAL_VALUE_FOR_COUNTER = 0;
export const INTERVAL_TYPE = 'intervalType';
export const INTERVAL_NUMBER = 'intervalNumber';

export const LAST_WEEK_NAME = -1;
export const LAST_WEEK_NAME_ON_UI = EZLOGIC_TITLE_LAST;
export const NOTICE_FOR_AVAILABLE_WEEK = EZLOGIC_TITLE_NO_VALID_DATE_RANGE;
export const DAYS_IN_WEEK = 7;
export const MS_IN_DAY = 24 * 60 * 60 * 1000;
export const CORRECTION_FACTOR = 1;
export const MONTH_NUMBER = 11;
export const DATE_OF_THE_MONTH = 31;
export const OPERAND = 10;
export const FROM = 'From';
export const TO = 'to';
export const TOOLTIPS_DELAY = 1000;
export const MAXIMUM_NUMBER_WEEKS_YEAR = 54;

export const HTTP_REQUEST_SELECTED_FIELD = {
    SELECTED_REQUEST: 'selectedRequest',
    SELECTED_URL: 'selectedUrl',
    SELECTED_SECURITY: 'selectedSecurity',
    SELECTED_CREDENTIAL_USER: 'selectedCredentialUser',
    SELECTED_CREDENTIAL_PASSWORD: 'selectedCredentialPassword',
    SELECTED_CONTENT_TYPE: 'selectedContentType',
    SELECTED_CONTENT: 'selectedContent',
};

export const WARNING_FOR_THE_ONCE_NODE_AND_OPERATOR_AND =
    'The Once node cannot be used with other Date and Time nodes with the AND operator.';
export const WARNING_FOR_THE_INTERVAL_NODE_AND_OPERATOR_AND =
    "Your controller's firmware should include Advanced Scenes version 1.55 or later in order to be able to use the Interval node with other Date and Time nodes with the AND operator. Controller firmwares are updated automatically.";
export const DELAY_FOR_WARNING_FOR_THE_INTERVAL = 20000;

export const SUN_STATE = {
    AFTER_SUNRISE: 'afterSunrise',
    BEFORE_SUNRISE: 'beforeSunrise',
    AFTER_SUNSET: 'afterSunset',
    BEFORE_SUNSET: 'beforeSunset',
    SUNRISE: 'sunrise',
    SUNSET: 'sunset',
};

export const TIME_TYPE_HMS_INTERVAL = 'hms_interval';

export const CONTROLLER_NODE_CAPABILITIES = [
    { name: EZLOGIC_TITLE_REBOOT_CONTROLLER, value: 'rebootHub', id: hash() },
    { name: EZLOGIC_TITLE_RESET_CONTROLLER, value: 'resetHub', id: hash() },
];

export const CONTROLLER_RESET_CAPABILITY_VALUES = [
    { name: EZLOGIC_TITLE_SOFT_RESET, value: 'soft', id: hash() },
    { name: EZLOGIC_TITLE_FACTORY_RESET, value: 'factory', id: hash() },
];

export const CONTROLLER_CAPABILITY_RESET = 'resetHub';
export const CONTROLLER_RESET_SOFT_VALUE = 'soft';

/**
 * @typedef {Object} ExpressioComparator
 * @property {string} id - The unique identifier of the comparator.
 * @property {string} name - The constant for the translation of comparator's title.
 * @property {string} value - The comparator's value.
 */

/**
 * Collection of different expression comparators used in the application.
 * @type {{[key: string]: ExpressioComparator[]}}
 */
export const EXPRESSION_COMPARATORS = {
    GENERAL_COMPARATORS: [
        { id: hash(), name: EZLOGIC_TITLE_EQUAL, value: '==' },
        { id: hash(), name: EZLOGIC_TITLE_NOT_EQUAL, value: '!=' },
    ],
    INTEGER_COMPARATORS: [
        { id: hash(), name: EZLOGIC_TITLE_BETWEEN, value: 'between' },
        { id: hash(), name: EZLOGIC_TITLE_NOT_BETWEEN, value: 'not_between' },
        { id: hash(), name: EZLOGIC_TITLE_LESS, value: '<' },
        { id: hash(), name: EZLOGIC_TITLE_GREATER, value: '>' },
        { id: hash(), name: EZLOGIC_TITLE_LESS_THEN, value: '<=' },
        { id: hash(), name: EZLOGIC_TITLE_GREAER_THAN, value: '>=' },
        { id: hash(), name: EZLOGIC_TITLE_EQUAL, value: '==' },
        { id: hash(), name: EZLOGIC_TITLE_NOT_EQUAL, value: '!=' },
    ],
    RANGE_INTEGER_COMPARATORS: [
        { id: hash(), name: EZLOGIC_TITLE_BETWEEN, value: 'between' },
        { id: hash(), name: EZLOGIC_TITLE_NOT_BETWEEN, value: 'not_between' },
        { id: hash(), name: EZLOGIC_TITLE_EQUAL, value: '==' },
        { id: hash(), name: EZLOGIC_TITLE_NOT_EQUAL, value: '!=' },
    ],
};
export const RANGE_FIELD_NAME = 'range';
export const AT = 'at';
export const TYPE = 'type';
export const ARRAY_LENGTH_LIMIT = 2;
export const IS_DATE_TIME = 'isDateTime';
export const STEP_1 = 1;
export const MAX_VALUE_FOR_TIME = 59;
export const TIME_FORMAT = 'HH:mm:ss';
export const IN_TIME = 'intime';
export const WEEKS = 'weeks';
export const DAILY = 'daily';

export const actionsInitialOptionsSelect = [
    {
        id: MESHBOT_NODE_TYPES.DEVICE_STATE,
        value: MESHBOT_NODE_TYPES.DEVICE_STATE,
        name: LOCAL_MESHBOT_CONTROLLABLES_NAME.DEVICE_STATE,
    },
    {
        id: MESHBOT_NODE_TYPES.MESHBOT_STATE,
        value: MESHBOT_NODE_TYPES.MESHBOT_STATE,
        name: LOCAL_MESHBOT_CONTROLLABLES_NAME.MESHBOT_STATE,
    },
    {
        id: MESHBOT_NODE_TYPES.NOTIFICATION_STATE,
        value: MESHBOT_NODE_TYPES.NOTIFICATION_STATE,
        name: LOCAL_MESHBOT_CONTROLLABLES_NAME.NOTIFICATION_STATE,
    },
    {
        id: MESHBOT_NODE_TYPES.HTTP_REQUEST,
        value: MESHBOT_NODE_TYPES.HTTP_REQUEST,
        name: LOCAL_MESHBOT_CONTROLLABLES_NAME.HTTP_REQUEST,
    },
    {
        id: MESHBOT_NODE_TYPES.LUA_SCRIPT,
        value: MESHBOT_NODE_TYPES.LUA_SCRIPT,
        name: LOCAL_MESHBOT_CONTROLLABLES_NAME.LUA_SCRIPT,
    },
    { id: MESHBOT_NODE_TYPES.PAAS, value: MESHBOT_NODE_TYPES.PAAS, name: LOCAL_MESHBOT_CONTROLLABLES_NAME.PAAS },
    {
        id: MESHBOT_NODE_TYPES.ACTION_HOUSE_MODE,
        value: MESHBOT_NODE_TYPES.ACTION_HOUSE_MODE,
        name: LOCAL_MESHBOT_CONTROLLABLES_NAME.ACTION_HOUSE_MODE,
    },
    {
        id: MESHBOT_NODE_TYPES.LOCAL_VARIABLE,
        value: MESHBOT_NODE_TYPES.LOCAL_VARIABLE,
        name: LOCAL_MESHBOT_CONTROLLABLES_NAME.LOCAL_VARIABLE,
    },
    {
        id: MESHBOT_NODE_TYPES.CONTROLLER,
        value: MESHBOT_NODE_TYPES.CONTROLLER,
        name: LOCAL_MESHBOT_CONTROLLABLES_NAME.CONTROLLER,
    },
];
/**
 * An object containing all possible statuses a run scene can have.
 * @type {Object}
 * @see {https://log.ezlo.com/new/hub/broadcasts/#hubscenerunprogress}
 */
export const SCENE_RUN_STATUSES = {
    STARTED: 'started',
    FINISHED: 'finished',
    PARTIALLY_FINISHED: 'partially_finished',
    FAILED: 'failed',
    STOPPED: 'stopped',
};
/**
 * A list of temporary statuses a scene can have.
 * @type {Array<string>}
 */
export const TEMPORARY_STATUS_LIST = [
    SCENE_RUN_STATUSES.FINISHED,
    SCENE_RUN_STATUSES.STOPPED,
    SCENE_RUN_STATUSES.FAILED,
    SCENE_RUN_STATUSES.PARTIALLY_FINISHED,
];
export const { SECURITY_MODE } = LOCAL_MESHBOT_CONTROLLABLES_NAME;

export const BLOCK = 'block';
export const FAILED = 'FAILED';
export const FINISHED = 'FINISHED';
export const PENDING = 'PENDING';
export const STARTED = 'started';
export const SPINNER = 'spinner';
export const THREE_SECONDS = 3000;
export const BATTERY = 'battery';
export const IDLE = 'idle';
export const ERROR_CODE_METHOD_NOT_FOUND = -32601;
export const MESHBOT_TIMEOUT = 10000;
export const PREDEFINED_CAMERA_CAPABILITY_VALUE = 1;
export const CAMERA_TYPE = 'vidoo_generic_cam';
export const KEY_NAME = 'Delete';

export const AUDIO_STREAM_CONTROL = 'audio_stream_control';
export const TAKE_SNAPSHOT = 'take_snapshot';
export const STOP_RECORDING = 'stop_recording';
export const FORMAT_SD_CARD = 'format_sd_card';
export const RESET = 'reset';
export const MAKE_RECORDING = 'make_recording';

export const DEVICE_CAMERA_CAPABILITIES = {
    [AUDIO_STREAM_CONTROL]: {
        name: AUDIO_STREAM_CONTROL,
        type: VALUE_TYPES.TOKEN,
        isEditable: true,
    },
    [MAKE_RECORDING]: {
        name: MAKE_RECORDING,
        type: VALUE_TYPES.INT,
        isEditable: true,
    },
    [TAKE_SNAPSHOT]: {
        name: TAKE_SNAPSHOT,
        type: VALUE_TYPES.INT,
        isEditable: false,
        value: PREDEFINED_CAMERA_CAPABILITY_VALUE,
    },
    [RESET]: {
        name: RESET,
        type: VALUE_TYPES.INT,
        isEditable: false,
        value: PREDEFINED_CAMERA_CAPABILITY_VALUE,
    },
    [STOP_RECORDING]: {
        name: STOP_RECORDING,
        type: VALUE_TYPES.INT,
        isEditable: false,
        value: PREDEFINED_CAMERA_CAPABILITY_VALUE,
    },
    [FORMAT_SD_CARD]: {
        name: FORMAT_SD_CARD,
        type: VALUE_TYPES.INT,
        isEditable: false,
        value: PREDEFINED_CAMERA_CAPABILITY_VALUE,
    },
};
export const DATA_MESHBOT_ATTRIBUTE = 'data-meshbot';

export const PIN_CODE_USED = 'pin_code_used';
export const PIN_CODE_MENU_ITEM_CONTENT = 'PIN code used';
export const LOCK = 'lock';
export const LOCKED = 'Locked';
export const USER_LOCK_OPERATION = 'user_lock_operation';
export const USER_CODES = 'user_codes';
export const UNLOCK = 'unlock';
export const UNLOCKED = 'Unlocked';
export const RPC_METHOD_NOTFOUND = 'rpc.method.notfound';
export const ID_PROPERTY = '_id';
export const ACTION_GROUP = 'actionGroup';
export const CENTER = 'center';
export const LEFT = 'left';
export const RUN = 'run';
export const DELETE = 'delete';

export const actionsNodesListForEzlopi = [
    {
        id: MESHBOT_NODE_TYPES.DEVICE_STATE,
        value: MESHBOT_NODE_TYPES.DEVICE_STATE,
        name: LOCAL_MESHBOT_CONTROLLABLES_NAME.DEVICE_STATE,
        firmwareVersion: EZLOPI_FIRMWARE.VERSION_3_1_4,
    },
    {
        id: MESHBOT_NODE_TYPES.MESHBOT_STATE,
        value: MESHBOT_NODE_TYPES.MESHBOT_STATE,
        name: LOCAL_MESHBOT_CONTROLLABLES_NAME.MESHBOT_STATE,
        firmwareVersion: EZLOPI_FIRMWARE.VERSION_3_3_6,
    },
    {
        id: MESHBOT_NODE_TYPES.HTTP_REQUEST,
        value: MESHBOT_NODE_TYPES.HTTP_REQUEST,
        name: LOCAL_MESHBOT_CONTROLLABLES_NAME.HTTP_REQUEST,
        firmwareVersion: EZLOPI_FIRMWARE.VERSION_3_3_6,
    },
    {
        id: MESHBOT_NODE_TYPES.LUA_SCRIPT,
        value: MESHBOT_NODE_TYPES.LUA_SCRIPT,
        name: LOCAL_MESHBOT_CONTROLLABLES_NAME.LUA_SCRIPT,
        firmwareVersion: EZLOPI_FIRMWARE.VERSION_3_3_6,
    },
    {
        id: MESHBOT_NODE_TYPES.ACTION_HOUSE_MODE,
        value: MESHBOT_NODE_TYPES.ACTION_HOUSE_MODE,
        name: LOCAL_MESHBOT_CONTROLLABLES_NAME.ACTION_HOUSE_MODE,
        firmwareVersion: EZLOPI_FIRMWARE.VERSION_3_5_8,
    },
    {
        id: MESHBOT_NODE_TYPES.LOCAL_VARIABLE,
        value: MESHBOT_NODE_TYPES.LOCAL_VARIABLE,
        name: LOCAL_MESHBOT_CONTROLLABLES_NAME.LOCAL_VARIABLE,
        firmwareVersion: EZLOPI_FIRMWARE.VERSION_4_1_4,
    },
    {
        id: MESHBOT_NODE_TYPES.CONTROLLER,
        value: MESHBOT_NODE_TYPES.CONTROLLER,
        name: LOCAL_MESHBOT_CONTROLLABLES_NAME.CONTROLLER,
        firmwareVersion: EZLOPI_FIRMWARE.VERSION_3_3_6,
    },
];
/**
 * Represents the 'start' constant.
 * @constant
 * @type {string}
 */
export const START = 'start';
/**
 * Represents the 'finish' constant.
 * @constant
 * @type {string}
 */
export const FINISH = 'finish';
/**
 * List of item valueTypes which are grouped into number type group.
 * @constant
 * @type {string[]}
 */
export const NUMBER_ITEM_VALUE_TYPES = [
    BLOCK_FIELD_TYPES.INT,
    BLOCK_FIELD_TYPES.FLOAT,
    BLOCK_FIELD_TYPES.TEMPERATURE,
    BLOCK_FIELD_TYPES.HUMIDITY,
    BLOCK_FIELD_TYPES.LOUDNESS,
    BLOCK_FIELD_TYPES.ILLUMINANCE,
    VALUE_TYPES.SCALABLE_VALUE_TYPES,
];
/**
 * List of item valueTypes which are grouped into string type group.
 * @constant
 * @type {string[]}
 */
export const STRING_ITEM_VALUE_TYPES = [BLOCK_FIELD_TYPES.STRING];
/**
 * Represents the 'Changes' constant.
 * @constant
 * @type {string}
 */
export const CHANGES = 'Changes';
export const UNIVERSAL_METHODS = {
    [TRIGGER_TYPES.IS_ITEM_STATE_CHANGED]: { method: TRIGGER_TYPES.IS_ITEM_STATE_CHANGED, name: CHANGES },
};
export const LIST_OF_UNIVERSAL_METHODS = Object.values(UNIVERSAL_METHODS);

export const BROADCAST_WAITING_TIME = 30000;
export const REQUEST = 'request';
export const URL = 'url';

export const THEN_SWITCH = 'then_switch';

export const YEAR_WEEKS = 'yearWeeks';

export const LABEL = 'label';

export const OPERATORS_EZLOPI = [
    { name: OPERATOR_AND, firmwareVersion: EZLOPI_FIRMWARE.VERSION_3_1_4 },
    { name: OPERATOR_OR, firmwareVersion: EZLOPI_FIRMWARE.VERSION_3_1_4 },
    { name: OPERATOR_XOR, firmwareVersion: EZLOPI_FIRMWARE.VERSION_3_3_6 },
];
/**
 * A list of node types to which functions are available in trigger.
 * @type {['deviceState', 'deviceStateAdvanced', 'controller', 'triggerHouseMode', 'meshBotState', 'variableComparison', 'expressionComparison', 'deviceGroup', 'cloudVariables']}
 */
export const NODES_WITH_FUNCTIONS = [
    MESHBOT_NODE_TYPES.DEVICE_STATE,
    MESHBOT_NODE_TYPES.DEVICE_STATE_ADVANCED,
    CONTROLLER,
    MESHBOT_NODE_TYPES.TRIGGER_HOUSE_MODE,
    MESHBOT_NODE_TYPES.MESHBOT_STATE,
    MESHBOT_NODE_TYPES.VARIABLE_COMPARISON,
    MESHBOT_NODE_TYPES.EXPRESSION_COMPARISON,
    MESHBOT_NODE_TYPES.DEVICE_GROUP,
    MESHBOT_NODE_TYPES.CLOUD_VARIABLES,
];
/**
 * A list of exceptional node types to which functions are available in exception trigger.
 * @type {['deviceState', 'deviceStateAdvanced', 'controller', 'triggerHouseMode', 'meshBotState', 'variableComparison', 'expressionComparison']}
 */
export const EXCEPTION_NODES_WITH_FUNCTIONS = [
    MESHBOT_NODE_TYPES.DEVICE_STATE,
    MESHBOT_NODE_TYPES.DEVICE_STATE_ADVANCED,
    CONTROLLER,
    MESHBOT_NODE_TYPES.TRIGGER_HOUSE_MODE,
    MESHBOT_NODE_TYPES.MESHBOT_STATE,
    MESHBOT_NODE_TYPES.VARIABLE_COMPARISON,
    MESHBOT_NODE_TYPES.EXPRESSION_COMPARISON,
];
export const TYPE_IS_UNDEFINED = 'undefined';
export const GROUP_LENGTH = 2;
export const GLOBAL_RESTRICTION_COMPARISON_OPERATORS = [BETWEEN, NOT_BETWEEN, EQUAL, NOT_EQUAL];
export const UNAVAILABLE_DATE_AND_TIME_TYPES_FOR_GLOBAL_RESTRICTION = [INTERVAL, IS_ONCE];
export const UNSUPPORTED = 'unsupported';
export const DEVICE_STATE_MENU_ITEM_CONTENT = 'Device State';
export const DEVICE_STATE_METHOD = 'device_state_method';

export const REACHABLE_SELECT_VALUES = {
    ANY: 'any',
    REACHABLE: 'reachable',
    UNREACHABLE: 'unreachable',
};
export const REACHABLE_SELECT_OPTIONS = [
    { label: EZLOGIC_TITLE_ANY, value: REACHABLE_SELECT_VALUES.ANY },
    { label: EZLOGIC_TITLE_REACHABLE, value: REACHABLE_SELECT_VALUES.REACHABLE },
    { label: EZLOGIC_TITLE_UNREACHABLE, value: REACHABLE_SELECT_VALUES.UNREACHABLE },
];
export const CAPABILITY_PROPERTIES = {
    NAME: 'name',
};

export const BLOCK_ENABLE = 'block_enable';
export const HTML_VALIDATION_DEBOUNCE_DELAY_300_MS = 300;
export const TIME_OUT_ZERO = 0;
export const KEY = 'key';
export const TAB = 'Tab';
export const KEY_HEADER_PLACEHOLDER = 'key header';
export const VALUE_HEADER_PLACEHOLDER = 'value header';

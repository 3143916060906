import { tracer } from '../../tracers';
import otlFetchStart from './otlFetchStart';
import otlFetchSuccess from './otlFetchSuccess';
import otlFetchReject from './otlFetchReject';
import { buildSpanOptionsOfHttpRequest } from '../../utils';
import { HTTP_CLIENTS } from '../../constants';

function fetchWithOtl(...args) {
    const [url, { method }] = args;
    const span = tracer.startSpan(
        `HTTP ${method.toUpperCase()} request`,
        buildSpanOptionsOfHttpRequest(url, method, HTTP_CLIENTS.FETCH),
    );
    otlFetchStart(span, ...args);

    return fetch(...args)
        .then(async (response) => {
            const responseData = await response.json();
            response.responseData = responseData;

            // based error case || error case with 200 code
            if (!response.ok || responseData.status === 0) {
                return Promise.reject(response);
            }

            return response;
        })
        .then((response) => {
            otlFetchSuccess(span, url, response);

            return Promise.resolve(response);
        })
        .catch((error) => {
            otlFetchReject(span, url, error);

            return Promise.reject(error);
        })
        .finally(() => {
            span.end();
        });
}

export default fetchWithOtl;

import React from 'react';
import {
    EZLOGIC_HEADING_ACCOUNT_SETTING,
    EZLOGIC_TITLE_ACCOUNT,
    EZLOGIC_TITLE_ADVANCED_SCRIPTING,
    EZLOGIC_TITLE_AUTOMATION,
    EZLOGIC_TITLE_BACKUPS,
    EZLOGIC_TITLE_CONTROLLERS,
    EZLOGIC_TITLE_CUSTOMIZATION,
    EZLOGIC_TITLE_DASHBOARD,
    EZLOGIC_TITLE_DEVICES,
    EZLOGIC_TITLE_DEVICE_GROUPS,
    EZLOGIC_TITLE_EDGE_PLUGINS,
    EZLOGIC_TITLE_EXPRESSIONS,
    EZLOGIC_TITLE_GEOFENCING,
    EZLOGIC_TITLE_INTEGRATION,
    EZLOGIC_TITLE_INTEGRATIONS,
    EZLOGIC_TITLE_IP_DEVICES,
    EZLOGIC_TITLE_LOGOUT,
    EZLOGIC_TITLE_LUA_SCRIPTS,
    EZLOGIC_TITLE_MESHBOTS,
    EZLOGIC_TITLE_MY_ACCOUNT,
    EZLOGIC_TITLE_NOTIFICATIONS_SETTINGS,
    EZLOGIC_TITLE_NOTIFICATION_TEMPLATES,
    EZLOGIC_TITLE_NUCAL,
    EZLOGIC_TITLE_OEM_SETTINGS,
    EZLOGIC_TITLE_PLUGINS,
    EZLOGIC_TITLE_PLUGIN_SETTINGS,
    EZLOGIC_TITLE_SETTINGS,
    EZLOGIC_TITLE_SUPPORT,
    EZLOGIC_TITLE_USER_MANAGEMENT,
    EZLOGIC_TITLE_VARIABLES,
    EZLOGIC_TITLE_VIRTUAL_CONTAINER,
    EZLOGIC_TITLE_VIRTUAL_DEVICES,
    EZLOGIC_TITLE_Z_WAVE_ASSOCIATIONS,
    // EZLOGIC_TITLE_INTEGRATIONS,
    // EZLOGIC_TITLE_INTEGRATIONS,
    EZLOGIC_TITLE_MEDIA_STORAGE,
    EZLOGIC_TITLE_PLUGIN_DEVELOPMENT,
    EZLOGIC_TITLE_MIGRATE_TO_EZLO,
    EZLOGIC_TITLE_PLATFORMS,
    EZLOGIC_TITLE_ADDRESSABLE_LEDS,
    EZLOGIC_TITLE_PIN_CODES,
    EZLOGIC_TITLE_ALERT_HISTORY,
    EZLOGIC_TITLE_INSIGHTS,
} from '../../constants/language_tokens';

import {
    Account,
    Dashboard,
    Ezlogic,
    LeftMenuIntegrationIcon,
    LeftMenuPlugins,
    LeftMenuSettingsIcon,
    LeftMenuSystemHistoryIcon,
    LeftMenuVirtualDeviceManagerIcon,
    IcPlugAndPlayIcon,
    IcActionsTarget,
} from 'assets/icons';

export const EDGE_PLUGINS_LINK = 'ezlo/plugins/edge';
export const WIFI_GENERATOR_LINK = 'ezlo/plugins/wifi-generator';
export const PLUGINS_SETTINGS_LINK = 'ezlo/plugins/settings';
export const PRIVATE_PLUGINS_PAGE = 'ezlo/plugins/edge/private';
export const MEDIA_STORAGE_PATH = '/plugin_development/media-storage';
export const MEDIA_STORAGE_LINK = `ezlo${MEDIA_STORAGE_PATH}`;
export const ACCOUNT = 'account';
export const AUTOMATION = 'automation';

// TODO: move all the menu items ids to the same place
// TODO: search through the project for these values
export const MENU_ITEMS_IDS = {
    DASHBOARD: 'dashboard',
    PLATFORMS: 'platforms',
    ADDRESSABLE_LEDS: 'addressable_leds',
    AUTOMATION: 'automation',
    MESHBOTS: 'meshbot',
    ADVANCED_SCRIPTING: 'advancedScripting',
    PLUGIN_DEVELOPMENT: 'plugin_development',
    EXPRESSIONS: 'expressions',
    VARIABLES: 'variables',
    LUA_SCRIPTS: 'luaScripts',
    MEDIA_STORAGE: 'media-storage',
    INTEGRATION: 'integration',
    INTEGRATION_NUCAL: 'settings_integrations',
    GENERATOR_PLUGIN: 'generator_plugin',
    VIRTUAL_DEVICES: 'virtualDevices',
    SETTINGS_PLUGINS: 'settings_plugins',
    SETTINGS_PLUGINS_EDGE: 'settings_plugins_edge',
    SETTINGS_PLUGIN: 'settings_plugin',
    INTEGRATION_VIRTUAL_CONTAINER: 'virtual',
    SETTINGS: 'settings',
    ACCOUNT: 'account',
    OEM_SETTINGS: 'oem_settings',
    CUSTOMIZATION: 'customization',
    GEOFENCING: 'geofence',
    NOTIFICATIONS: 'notifications',
    SETTINGS_CONTROLLERS: 'settings_controllers',
    SETTINGS_DEVICES: 'settings_devices',
    SETTINGS_DEVICES_GROUPS: 'settings_device_groups',
    DEVICE_ASSOCIATIONS: 'device-associations',
    SETTINGS_BACKUPS: 'settings_backups',
    MIGRATION: 'migration',
    MY_ACCOUNT: 'my_account',
    PIN_CODES: 'pin_codes',
    ACCOUNT_USERS: 'account_users',
    SYSTEM_HISTORY: 'system_history',
    NOTIFICATION_SETTINGS: 'notification_settings',
    HELP: 'help',
    INTERGATIONS: 'integrations',
    ACCOUNT_SETTINGS: 'account_settings',
    INSIGHTS: 'insights',
};

export const MENU_ITEMS_TITLE = {
    DASHBOARD: EZLOGIC_TITLE_DASHBOARD,
    PLATFORMS: EZLOGIC_TITLE_PLATFORMS,
    ADDRESSABLE_LEDS: EZLOGIC_TITLE_ADDRESSABLE_LEDS,
    AUTOMATION: EZLOGIC_TITLE_AUTOMATION,
    MESHBOTS: EZLOGIC_TITLE_MESHBOTS,
    ADVANCED_SCRIPTING: EZLOGIC_TITLE_ADVANCED_SCRIPTING,
    PLUGIN_DEVELOPMENT: EZLOGIC_TITLE_PLUGIN_DEVELOPMENT,
    EXPRESSIONS: EZLOGIC_TITLE_EXPRESSIONS,
    VARIABLES: EZLOGIC_TITLE_VARIABLES,
    LUA_SCRIPTS: EZLOGIC_TITLE_LUA_SCRIPTS,
    INTEGRATION: EZLOGIC_TITLE_INTEGRATION,
    INTEGRATION_NUCAL: EZLOGIC_TITLE_NUCAL,
    GENERATOR_PLUGIN: EZLOGIC_TITLE_IP_DEVICES,
    MEDIA_STORAGE: EZLOGIC_TITLE_MEDIA_STORAGE,
    INTEGRATION_VIRTUAL_CONTAINER: EZLOGIC_TITLE_VIRTUAL_CONTAINER,
    VIRTUAL_DEVICES: EZLOGIC_TITLE_VIRTUAL_DEVICES,
    SETTINGS_PLUGINS: EZLOGIC_TITLE_PLUGINS,
    SETTINGS_PLUGINS_EDGE: EZLOGIC_TITLE_EDGE_PLUGINS,
    SETTINGS_PLUGIN: EZLOGIC_TITLE_PLUGIN_SETTINGS,
    SETTINGS: EZLOGIC_TITLE_SETTINGS,
    SETTINGS_CONTROLLERS: EZLOGIC_TITLE_CONTROLLERS,
    SETTINGS_DEVICES: EZLOGIC_TITLE_DEVICES,
    CUSTOMIZATION: EZLOGIC_TITLE_CUSTOMIZATION,
    NOTIFICATIONS: EZLOGIC_TITLE_NOTIFICATIONS_SETTINGS,
    GEOFENCING: EZLOGIC_TITLE_GEOFENCING,
    DEVICE_ASSOCIATIONS: EZLOGIC_TITLE_Z_WAVE_ASSOCIATIONS,
    DEVICE_GROUPS: EZLOGIC_TITLE_DEVICE_GROUPS,
    SETTINGS_BACKUPS: EZLOGIC_TITLE_BACKUPS,
    MIGRATE_TO_EZLO: EZLOGIC_TITLE_MIGRATE_TO_EZLO,
    ACCOUNT: EZLOGIC_TITLE_ACCOUNT,
    MY_ACCOUNT: EZLOGIC_TITLE_MY_ACCOUNT,
    PIN_CODES: EZLOGIC_TITLE_PIN_CODES,
    NOTIFICATIONS_TEMPLATE: EZLOGIC_TITLE_NOTIFICATION_TEMPLATES,
    OEM_SETTINGS: EZLOGIC_TITLE_OEM_SETTINGS,
    ACCOUNT_USERS: EZLOGIC_TITLE_USER_MANAGEMENT,
    ALERT_EVENT_HISTORY: EZLOGIC_TITLE_ALERT_HISTORY,
    HELP: EZLOGIC_TITLE_SUPPORT,
    LOGOUT: EZLOGIC_TITLE_LOGOUT,
    ACCOUNT_SETTINGS: EZLOGIC_HEADING_ACCOUNT_SETTING,
    INTEGRATIONS: EZLOGIC_TITLE_INTEGRATIONS,
    INSIGHTS: EZLOGIC_TITLE_INSIGHTS,
};

export const MENU_ITEMS = [
    {
        id: MENU_ITEMS_IDS.DASHBOARD,
        title: MENU_ITEMS_TITLE.DASHBOARD,
        icon: <Dashboard />,
        isAbsolutePath: true,
        isControllerDependent: false,
        link: 'ezlo/dashboard',
        route: /ezlo\/dashboard/,
        willChange: [MENU_ITEMS_IDS.DASHBOARD],
    },
    {
        id: MENU_ITEMS_IDS.PLATFORMS,
        title: MENU_ITEMS_TITLE.PLATFORMS,
        link: 'platforms',
        isControllerDependent: true,
        icon: <IcPlugAndPlayIcon />,
        subItems: [
            {
                id: MENU_ITEMS_IDS.ADDRESSABLE_LEDS,
                title: MENU_ITEMS_TITLE.ADDRESSABLE_LEDS,
                link: 'ezlo/led',
                isControllerDependent: true,
                isAbsolutePath: true,
                route: /ezlo\/led/,
                willChange: [MENU_ITEMS_IDS.ADDRESSABLE_LEDS, MENU_ITEMS_IDS.PLATFORMS],
            },
        ],
    },
    {
        id: MENU_ITEMS_IDS.AUTOMATION,
        title: MENU_ITEMS_TITLE.AUTOMATION,
        isControllerDependent: false,
        icon: <Ezlogic />,
        link: 'automation',
        subItems: [
            {
                id: MENU_ITEMS_IDS.MESHBOTS,
                title: MENU_ITEMS_TITLE.MESHBOTS,
                isAbsolutePath: true,
                isControllerDependent: false,
                isSubmenu: true,
                link: 'ezlo/meshbots',
                route: /meshbots/,
                willChange: [MENU_ITEMS_IDS.MESHBOTS, MENU_ITEMS_IDS.AUTOMATION],
            },
            {
                id: MENU_ITEMS_IDS.ADVANCED_SCRIPTING,
                title: MENU_ITEMS_TITLE.ADVANCED_SCRIPTING,
                link: 'advancedScripting',
                isControllerDependent: true,
                subItems: [
                    {
                        id: MENU_ITEMS_IDS.VARIABLES,
                        title: MENU_ITEMS_TITLE.VARIABLES,
                        isAbsolutePath: true,
                        isSubmenu: true,
                        isControllerDependent: true,
                        link: 'ezlo/variables',
                        route: /ezlo\/variables/,
                        parents: [MENU_ITEMS_IDS.ADVANCED_SCRIPTING, MENU_ITEMS_IDS.AUTOMATION],
                        isActive: true,
                        // TODO: very unsafe, in case if we change any id, we will break the logic
                        willChange: [
                            MENU_ITEMS_IDS.VARIABLES,
                            MENU_ITEMS_IDS.ADVANCED_SCRIPTING,
                            MENU_ITEMS_IDS.AUTOMATION,
                        ],
                    },
                    {
                        id: MENU_ITEMS_IDS.EXPRESSIONS,
                        title: MENU_ITEMS_TITLE.EXPRESSIONS,
                        isAbsolutePath: true,
                        isSubmenu: true,
                        isControllerDependent: true,
                        link: 'ezlo/expressions',
                        route: /ezlo\/expressions/,
                        // TODO: very unsafe, in case if we change any id, we will break the logic
                        willChange: [
                            MENU_ITEMS_IDS.EXPRESSIONS,
                            MENU_ITEMS_IDS.ADVANCED_SCRIPTING,
                            MENU_ITEMS_IDS.AUTOMATION,
                        ],
                    },
                    {
                        id: MENU_ITEMS_IDS.LUA_SCRIPTS,
                        title: MENU_ITEMS_TITLE.LUA_SCRIPTS,
                        isAbsolutePath: true,
                        isSubmenu: true,
                        isControllerDependent: true,
                        link: 'ezlo/scripts/private',
                        route: /ezlo\/scripts/,
                        // TODO: very unsafe, in case if we change any id, we will break the logic
                        willChange: [
                            MENU_ITEMS_IDS.LUA_SCRIPTS,
                            MENU_ITEMS_IDS.ADVANCED_SCRIPTING,
                            MENU_ITEMS_IDS.AUTOMATION,
                        ],
                        isActive: false,
                    },
                ],
            },
        ],
    },
    {
        id: MENU_ITEMS_IDS.INTEGRATION,
        title: MENU_ITEMS_TITLE.INTEGRATION,
        link: 'integration',
        isControllerDependent: false,
        icon: <LeftMenuIntegrationIcon />,
        subItems: [
            {
                id: MENU_ITEMS_IDS.INTEGRATION_NUCAL,
                title: MENU_ITEMS_TITLE.INTEGRATION_NUCAL,
                isAbsolutePath: true,
                isSubmenu: true,
                isControllerDependent: false,
                link: 'ezlo/parjects',
                route: /ezlo\/parjects/,
                willChange: [MENU_ITEMS_IDS.INTEGRATION_NUCAL, MENU_ITEMS_IDS.INTEGRATION],
            },
            {
                id: MENU_ITEMS_IDS.GENERATOR_PLUGIN,
                title: MENU_ITEMS_TITLE.GENERATOR_PLUGIN,
                isAbsolutePath: true,
                isSubmenu: true,
                isControllerDependent: false,
                link: WIFI_GENERATOR_LINK,
                route: /ezlo\/plugins\/wifi-generator/,
                // TODO: very unsafe, in case if we change any id, we will break the logic
                willChange: [MENU_ITEMS_IDS.GENERATOR_PLUGIN, MENU_ITEMS_IDS.INTEGRATION],
            },
            {
                id: MENU_ITEMS_IDS.INTEGRATION_VIRTUAL_CONTAINER,
                title: MENU_ITEMS_TITLE.INTEGRATION_VIRTUAL_CONTAINER,
                icon: <LeftMenuVirtualDeviceManagerIcon />,
                isAbsolutePath: true,
                isSubmenu: true,
                isControllerDependent: false,
                link: 'ezlo/virtual-container',
                route: /virtual-container/,
                willChange: [MENU_ITEMS_IDS.INTEGRATION_VIRTUAL_CONTAINER, MENU_ITEMS_IDS.INTEGRATION],
            },
            {
                id: MENU_ITEMS_IDS.VIRTUAL_DEVICES,
                title: MENU_ITEMS_TITLE.VIRTUAL_DEVICES,
                isAbsolutePath: true,
                isSubmenu: true,
                isControllerDependent: true,
                link: 'ezlo/virtualDevices/create',
                route: /ezlo\/virtualDevices\/create/,
                // TODO: very unsafe, in case if we change any id, we will break the logic
                willChange: [MENU_ITEMS_IDS.VIRTUAL_DEVICES, MENU_ITEMS_IDS.INTEGRATION],
            },
            // {
            //     id: MENU_ITEMS_IDS.INTERGATIONS,
            //     title: MENU_ITEMS_TITLE.INTEGRATIONS,
            //     isAbsolutePath: true,
            //     isSubmenu: true,
            //     isControllerDependent: false,
            //     link: 'ezlo/integrations',
            //     route: /ezlo\/integrations/,
            //     // TODO: very unsafe, in case if we change any id, we will break the logic
            //     willChange: [MENU_ITEMS_IDS.INTERGATIONS, MENU_ITEMS_IDS.INTEGRATION],
            // },
        ],
    },
    {
        id: MENU_ITEMS_IDS.SETTINGS_PLUGINS,
        title: MENU_ITEMS_TITLE.SETTINGS_PLUGINS,
        link: 'plugins',
        route: /plugins/,
        isControllerDependent: false,
        icon: <LeftMenuPlugins />,
        subItems: [
            {
                id: MENU_ITEMS_IDS.SETTINGS_PLUGINS_EDGE,
                title: MENU_ITEMS_TITLE.SETTINGS_PLUGINS_EDGE,
                link: PRIVATE_PLUGINS_PAGE,
                isControllerDependent: false,
                isAbsolutePath: true,
                route: /ezlo\/plugins\/edge\/private/,
                // TODO: very unsafe, in case if we change any id, we will break the logic
                willChange: [MENU_ITEMS_IDS.SETTINGS_PLUGINS, MENU_ITEMS_IDS.SETTINGS_PLUGINS_EDGE],
            },
            // {
            //     id: 'settings_plugins_cloud',
            //     title: 'Cloud Plugins',
            //     link: 'ezlo/plugins/cloud',
            //     isAbsolutePath: true,
            //     route: /ezlo\/plugins\/cloud/,
            // },
        ],
    },
    {
        id: MENU_ITEMS_IDS.SETTINGS,
        title: MENU_ITEMS_TITLE.SETTINGS,
        icon: <LeftMenuSettingsIcon />,
        isControllerDependent: false,
        link: 'settings',
        route: /settings/,
        subItems: [
            {
                id: MENU_ITEMS_IDS.SETTINGS_CONTROLLERS,
                title: MENU_ITEMS_TITLE.SETTINGS_CONTROLLERS,
                isAbsolutePath: true,
                isControllerDependent: true,
                link: 'ezlo/groups',
                route: /ezlo\/groups/,
                // TODO: very unsafe, in case if we change any id, we will break the logic
                willChange: [MENU_ITEMS_IDS.SETTINGS, MENU_ITEMS_IDS.SETTINGS_CONTROLLERS],
            },
            {
                id: MENU_ITEMS_IDS.SETTINGS_DEVICES,
                title: MENU_ITEMS_TITLE.SETTINGS_DEVICES,
                isAbsolutePath: true,
                isControllerDependent: true,
                link: 'ezlo/devices',
                route: /ezlo\/devices/,
                // TODO: very unsafe, in case if we change any id, we will break the logic
                willChange: [MENU_ITEMS_IDS.SETTINGS, MENU_ITEMS_IDS.SETTINGS_DEVICES],
            },
            // This is hidden - Reference: Ticket ENWT-5116
            // {
            //     id: MENU_ITEMS_IDS.SETTINGS_DEVICES_GROUPS,
            //     title: MENU_ITEMS_TITLE.DEVICE_GROUPS,
            //     isAbsolutePath: true,
            //     isControllerDependent: true,
            //     link: 'ezlo/device-groups',
            //     route: /ezlo\/device-groups/,
            //     willChange: [MENU_ITEMS_IDS.SETTINGS, MENU_ITEMS_IDS.SETTINGS_DEVICES_GROUPS],
            // },
            {
                id: MENU_ITEMS_IDS.DEVICE_ASSOCIATIONS,
                title: MENU_ITEMS_TITLE.DEVICE_ASSOCIATIONS,
                isAbsolutePath: true,
                isSubmenu: true,
                isControllerDependent: true,
                link: 'ezlo/device-associations',
                route: /ezlo\/device-associations/,
                // TODO: very unsafe, in case if we change any id, we will break the logic
                willChange: [MENU_ITEMS_IDS.SETTINGS, MENU_ITEMS_IDS.DEVICE_ASSOCIATIONS],
            },
            {
                id: MENU_ITEMS_IDS.SETTINGS_BACKUPS,
                title: MENU_ITEMS_TITLE.SETTINGS_BACKUPS,
                isControllerDependent: true,
                link: 'ezlo/backups',
                isAbsolutePath: true,
                route: /ezlo\/backups/,
                // TODO: very unsafe, in case if we change any id, we will break the logic
                willChange: [MENU_ITEMS_IDS.SETTINGS, MENU_ITEMS_IDS.SETTINGS_BACKUPS],
            },
            {
                id: MENU_ITEMS_IDS.MIGRATION,
                title: MENU_ITEMS_TITLE.MIGRATE_TO_EZLO,
                isControllerDependent: true,
                link: 'ezlo/migration',
                isAbsolutePath: true,
                route: /ezlo\/migration/,
                // TODO: very unsafe, in case if we change any id, we will break the logic
                willChange: [MENU_ITEMS_IDS.SETTINGS, MENU_ITEMS_IDS.MIGRATION],
            },
            {
                id: MENU_ITEMS_IDS.CUSTOMIZATION,
                title: MENU_ITEMS_TITLE.CUSTOMIZATION,
                isAbsolutePath: true,
                isControllerDependent: false,
                isPK_PermissionRole: true,
                link: 'ezlo/customization',
                route: /ezlo\/customization/,
                // TODO: very unsafe, in case if we change any id, we will break the logic
                willChange: [MENU_ITEMS_IDS.SETTINGS, MENU_ITEMS_IDS.CUSTOMIZATION],
            },
            {
                id: MENU_ITEMS_IDS.GEOFENCING,
                title: MENU_ITEMS_TITLE.GEOFENCING,
                isAbsolutePath: true,
                isControllerDependent: false,
                isPK_PermissionRole: true,
                link: 'ezlo/geofence-settings',
                route: /ezlo\/geofence-settings/,
                // TODO: very unsafe, in case if we change any id, we will break the logic
                willChange: [MENU_ITEMS_IDS.SETTINGS, MENU_ITEMS_IDS.GEOFENCING],
            },
            // {
            //     id: MENU_ITEMS_IDS.NOTIFICATION_SETTINGS,
            //     title: MENU_ITEMS_TITLE.NOTIFICATIONS,
            //     isAbsolutePath: true,
            //     isSubmenu: true,
            //     isControllerDependent: true,
            //     link: 'ezlo/notification-settings',
            //     route: /ezlo\/notification-settings/,
            //     // TODO: very unsafe, in case if we change any id, we will break the logic
            //     willChange: [MENU_ITEMS_IDS.SETTINGS, MENU_ITEMS_IDS.NOTIFICATION_SETTINGS],
            // },
            {
                id: MENU_ITEMS_IDS.PLUGIN_DEVELOPMENT,
                title: MENU_ITEMS_TITLE.PLUGIN_DEVELOPMENT,
                link: 'plugin_development',
                isControllerDependent: false,
                subItems: [
                    {
                        id: MENU_ITEMS_IDS.SETTINGS_PLUGIN,
                        title: MENU_ITEMS_TITLE.SETTINGS_PLUGIN,
                        isAbsolutePath: true,
                        isSubmenu: true,
                        isControllerDependent: false,
                        link: PLUGINS_SETTINGS_LINK,
                        route: /ezlo\/plugins\/settings/,
                        // TODO: very unsafe, in case if we change any id, we will break the logic
                        willChange: [
                            MENU_ITEMS_IDS.SETTINGS,
                            MENU_ITEMS_IDS.PLUGIN_DEVELOPMENT,
                            MENU_ITEMS_IDS.SETTINGS_PLUGIN,
                        ],
                    },
                    {
                        id: MENU_ITEMS_IDS.MEDIA_STORAGE,
                        title: MENU_ITEMS_TITLE.MEDIA_STORAGE,
                        isAbsolutePath: true,
                        isSubmenu: true,
                        isControllerDependent: false,
                        link: MEDIA_STORAGE_LINK,
                        route: /ezlo\/plugin_development\/media-storage/,
                        willChange: [
                            MENU_ITEMS_IDS.SETTINGS,
                            MENU_ITEMS_IDS.PLUGIN_DEVELOPMENT,
                            MENU_ITEMS_IDS.MEDIA_STORAGE,
                        ],
                    },
                ],
            },
        ],
    },
    {
        id: MENU_ITEMS_IDS.ACCOUNT,
        title: MENU_ITEMS_TITLE.ACCOUNT,
        icon: <Account />,
        isControllerDependent: false,
        link: 'account',
        route: /account/,
        subItems: [
            {
                id: MENU_ITEMS_IDS.MY_ACCOUNT,
                title: MENU_ITEMS_TITLE.MY_ACCOUNT,
                isAbsolutePath: true,
                isControllerDependent: false,
                link: 'ezlo/my-account',
                route: /ezlo\/my-account/,
                willChange: [MENU_ITEMS_IDS.ACCOUNT, MENU_ITEMS_IDS.MY_ACCOUNT],
            },
            {
                id: MENU_ITEMS_IDS.PIN_CODES,
                title: MENU_ITEMS_TITLE.PIN_CODES,
                isControllerDependent: false,
                link: 'ezlo/pin-codes',
                willChange: [MENU_ITEMS_IDS.ACCOUNT, MENU_ITEMS_IDS.PIN_CODES],
            },
            {
                id: MENU_ITEMS_IDS.ACCOUNT_USERS,
                title: MENU_ITEMS_TITLE.ACCOUNT_USERS,
                isAbsolutePath: true,
                isControllerDependent: false,
                link: 'ezlo/account-users',
                route: /ezlo\/account-users/,
                willChange: [MENU_ITEMS_IDS.ACCOUNT, MENU_ITEMS_IDS.ACCOUNT_USERS],
            },
            {
                id: MENU_ITEMS_IDS.ACCOUNT_SETTINGS,
                title: MENU_ITEMS_TITLE.ACCOUNT_SETTINGS,
                isAbsolutePath: true,
                isControllerDependent: false,
                link: 'ezlo/account-settings',
                route: /ezlo\/account-settings/,
                willChange: [MENU_ITEMS_IDS.ACCOUNT, MENU_ITEMS_IDS.ACCOUNT_SETTINGS],
            },
        ],
    },
    {
        id: MENU_ITEMS_IDS.OEM_SETTINGS,
        title: MENU_ITEMS_TITLE.OEM_SETTINGS,
        icon: <LeftMenuSettingsIcon />,
        isControllerDependent: false,
        subItems: [
            {
                id: MENU_ITEMS_IDS.NOTIFICATIONS,
                title: MENU_ITEMS_TITLE.NOTIFICATIONS_TEMPLATE,
                isAbsolutePath: true,
                isControllerDependent: false,
                isPK_PermissionRole: true,
                link: 'ezlo/notifications',
                route: /ezlo\/notifications/,
                // TODO: very unsafe, in case if we change any id, we will break the logic
                willChange: [MENU_ITEMS_IDS.OEM_SETTINGS, MENU_ITEMS_IDS.NOTIFICATIONS],
            },
        ],
    },
    {
        id: MENU_ITEMS_IDS.SYSTEM_HISTORY,
        title: MENU_ITEMS_TITLE.ALERT_EVENT_HISTORY,
        icon: <LeftMenuSystemHistoryIcon />,
        isControllerDependent: true,
        link: 'ezlo/system-history',
        willChange: [MENU_ITEMS_IDS.SYSTEM_HISTORY],
    },
    {
        id: MENU_ITEMS_IDS.INSIGHTS,
        title: MENU_ITEMS_TITLE.INSIGHTS,
        isAbsolutePath: true,
        isControllerDependent: true,
        link: 'ezlo/insights',
        route: /ezlo\/insights/,
        willChange: [MENU_ITEMS_IDS.INSIGHTS],
        icon: <IcActionsTarget />,
    },
];

export const supportObj = {
    id: MENU_ITEMS_IDS.HELP,
    title: MENU_ITEMS_TITLE.HELP,
    checked: true,
    isAbsolutePath: true,
    isControllerDependent: false,
    isPK_PermissionRole: true,
    link: 'ezlo/support',
    route: /ezlo\/support/,
    willChange: [MENU_ITEMS_IDS.HELP],
};

export const LIST_OF_MENU_ITEMS = ['Automation', 'Advanced Scripting', 'Integration', 'Plugins', 'Settings', 'Account'];

export const ALLOWED_ITARIAN_SSO_MENU_ITEMS = [
    MENU_ITEMS_IDS.AUTOMATION,
    MENU_ITEMS_IDS.MESHBOTS,
    MENU_ITEMS_IDS.INTEGRATION,
    MENU_ITEMS_IDS.INTEGRATION_NUCAL,
    MENU_ITEMS_IDS.INTEGRATION_VIRTUAL_CONTAINER,
    MENU_ITEMS_IDS.SETTINGS,
    MENU_ITEMS_IDS.CUSTOMIZATION,
    MENU_ITEMS_IDS.ACCOUNT,
    MENU_ITEMS_IDS.MY_ACCOUNT,
    MENU_ITEMS_IDS.ACCOUNT_USERS,
];
/**
 * List of exceptions representing menu items allowed only on internal domains.
 *
 * @constant
 * @type {Array<string>}
 * @description This array contains menu item identifiers that are allowed
 *              exclusively on internal domains. These items may include specific features
 *              or functionalities restricted to internal users, developers, managers, QA.
 * @example
 * const internalDomainMenuItems = ALLOWED_MENU_ITEMS_ONLY_ON_INTERNAL_DOMAIN;
 * // Result: ['geofence']
 */
export const ALLOWED_MENU_ITEMS_ONLY_ON_INTERNAL_DOMAIN = [MENU_ITEMS_IDS.INSIGHTS];

export const UNSUPPORTED_MENU_ITEMS_IDS_FOR_RESIDENT_UNDER_PARTNER = [MENU_ITEMS_IDS.MIGRATION];

import hash from '../../../constants/uniqueHash';
import { getParjectNamesToFilter } from '../EzloRule/EditForm/RuleSettings/components/PAAS/utils';
import { CALLS } from 'constants/api';
import { isArray } from 'lodash';

/**
 * Get list of all available integrations that user can enroll to.
 * Returns body object for 'integrations_list' request.
 * More info {@link https://confluence.mios.com/display/EPD/Integrations+-+List here}
 * @returns {object} body - request body
 * */
export const buildIntegrationsListRequestBody = () => {
    return { call: CALLS.integrations_list };
};

/**
 * Returns 'params' object for integrations setup request's payload.
 * More info {@link https://confluence.mios.com/display/EPD/Integrations+-+Setup here}
 * @param {object} uuid - integration's uuid
 * @returns {object} params - prepared 'params' object.
 * */
export const buildIntegrationSetupParams = (uuid) => {
    return {
        uuid,
        info: {
            action: 'enroll',
            integration: uuid,
            parameters: {},
        },
        version: '1',
    };
};

/**
 */
export const buildIntegrationSetupRequestParams = (params) => {
    return {
        call: 'integrations-setup',
        params,
        id: hash(),
        version: '1',
    };
};

/**
 * Returns 'params' object for integrations setup request's payload.
 * More info {@link https://confluence.mios.com/display/EPD/Integrations+-+Setup here}
 * @param {object} params - integration's uuid
 * @returns {object} params - prepared 'params' object.
 * */
export const buildIntegrationSetupRequestBody = (params) => {
    return {
        call: 'integrations-setup',
        params,
    };
};

/**
 * Returns 'params' object for abstract state get request's payload.
 * More info {@link https://confluence.mios.com/display/EPD/Abstract+-+State+Get here}
 * @param {object} uuids- lists' uuid
 * */
export const buildAbstractStateGetRequestBody = (uuids) => {
    return {
        call: 'abstract_state_get',
        params: {
            list: uuids,
        },
    };
};

/**
 * Returns 'params' object for 'describe' abstract-command request's payload.
 * More info {@link https://confluence.mios.com/display/EPD/PAASI+v.1+-+Paas+API+Commands here}
 * @returns {object} prepared 'params' object.
 * */
export const buildDescribeAbstractCommandParams = (uuid) => {
    return {
        uuid,
        type: 'command',
        capability: 'api',
        command: 'describe',
        parameters: {},
    };
};

/**
 * Returns 'params' object for 'call' abstract-command request's payload.
 * More info {@link https://confluence.mios.com/display/EPD/PAASI+v.1+-+Paas+API+Commands here}
 * @returns {object} prepared 'params' object.
 * */
export const buildCallAbstractCommandParams = (uuid, apiName, params) => {
    return {
        uuid,
        type: 'command',
        capability: 'api',
        command: 'call',
        parameters: {
            name: apiName,
            params,
        },
    };
};

/**
 * Returns body object for abstract-command request.
 * More info {@link https://confluence.mios.com/display/EPD/PAASI+v.1+-+Paas+API+Commands here}
 * @returns {object} prepared 'params' object.
 * */
export const buildAbstractCommandRequestBody = (params) => {
    return {
        call: 'abstract-command',
        params,
    };
};

// /**
//  * Returns body object for 'integrations_config' request.
// eslint-disable-next-line
//  * More info {@link https://confluence.mios.com/pages/viewpage.action?spaceKey=EPD&title=Integrations+-+Get+Config here}
//  * @returns {object} body - request body.
//  * */
// export const buildIntegrationsGetRequestBody = (uuids) => {
//     return {uuids};
// };

/**
 * Returns body object for 'integrations_config' request.
 * More info {@link https://confluence.mios.com/pages/viewpage.action?spaceKey=EPD&title=Integrations+-+Get+Config here}
 * @returns {object} body - request body.
 * */
export const buildIntegrationsGetConfigRequestBody = (params) => {
    return { call: 'integrations_config', id: hash(), params };
};

/**
 */
export const buildGetAuthLinkRequestBody = (params) => {
    return {
        call: 'abstract_command',
        id: hash(),
        version: '1',
        params,
    };
};

/**
 * Returns array of unique "integration_uuid" values from abstracts.
 <pre>
 Input:
 [{
 "uuid": "6d4124bb-f5df-11eb-a379-57cb7037a2c6",
 "name": "Open Weather",
 "capabilities": [
 "name_command",
 "api"
 ],
 "details": {
 "source": "integration",
 "integration_uuid": "d91bc130-eed2-11eb-b933-f7940f20c7d0"
 },
 "return": ""
 },
 {
 "uuid": "330a12e0-f920-11eb-8fe1-8be4b87fd917",
 "name": "Open Weather 2",
 "capabilities": [
 "name_command",
 "api"
 ],
 "details": {
 "source": "integration",
 "integration_uuid": "d91bc130-eed2-11eb-b933-f7940f20c7d0"
 },
 "return": ""
 }]
 </pre>
 <pre>
 Returns:
 ["d91bc130-eed2-11eb-b933-f7940f20c7d0"]
 </pre>
 *
 * @param {Array} abstracts - array of abstract devices
 * @returns {Array} array of unique integrations uuids
 * */
export const extractUserIntegrationsUuidFromAbstracts = (abstracts) => {
    const uuids = (abstracts && Object.values(abstracts).map((abstract) => abstract.details.integration_uuid)) || [];
    const filteredUuids = new Set(uuids);

    return Array.from(filteredUuids);
};

/**
 * Returns 'params' object for EDIT virtual device request's payload.
 * More info {@link https://confluence.mios.com/display/EPD/Virtual+Device+-+Set here}
 * @param {string} uuid - virtual device's abstract UUID
 * @param {string} [name] - new name of virtual device
 * @returns {object} params - prepared 'params' object.
 * */
export const buildEditIntegrationParams = (uuid, name) => {
    return { uuid, name };
};

export const sortUserIntegrationsByAbstractsLength = (userIntegrations) => {
    return Object.keys(userIntegrations)
        .sort((a, b) => {
            return userIntegrations[a].abstracts.length - userIntegrations[b].abstracts.length;
        })
        .reduce((obj, key) => {
            obj[key] = userIntegrations[key];

            return obj;
        }, {});
};

export const colors = [
    '#b71c1c',
    '#f44336',
    '#880e4f',
    '#e91e63',
    '#4a148c',
    '#673ab7',
    '#1a237e',
    '#3f50b5',
    '#28579b',
    '#4ba9f4',
    '#194e33',
    '#4caf50',
    '#827717',
    '#cddc39',
    '#f26f04',
    '#f6c108',
    '#263238',
    '#607d8b',
    '#000000',
    '#969696',
];

export const getColorName = (name) => {
    let colorIndex = 0;
    for (let i = 0; i < name.length; i++) {
        colorIndex += name.codePointAt(i);
    }

    return colors[colorIndex % colors.length];
};
/**
 * Filters the array of integrations by a search term, excluding integrations
 * whose 'name' appears in a list of disallowed project names.
 *
 * @param {Array<Object>} integrations - An array of integration objects.
 * @param {string} search - The search term used for filtering.
 * @returns {Array<Object>|undefined} An array of filtered integrations or undefined if integrations is not provided.
 *
 * @example
 * // If "search" is 'git', it will match integrations whose (title+name).toLowerCase() includes 'git'
 * // It also excludes any integration whose name is in getParjectNamesToFilter().
 */
export const filterIntegrations = (integrations, search) => {
    const parjectNamesToFilter = getParjectNamesToFilter();

    return integrations?.filter(({ name, title }) => {
        const isIntegrationHaveAllowedName = !parjectNamesToFilter.includes(name);
        const isIntegrationShouldBeFiltered = (title + name).toLowerCase().includes(search.toLowerCase());

        return isIntegrationShouldBeFiltered && isIntegrationHaveAllowedName;
    });
};
/**
 * Sorts the given integrations array alphabetically by `integration.name`, and also sorts
 * each integration's `abstracts` array by the `abstract.name`.
 *
 * @param {Array<Object>} integrations - An array of integration objects, each possibly having an `abstracts` property.
 * @returns {Array<Object>} A new sorted array of integrations, each containing sorted `abstracts`.
 *
 * @example
 * // sortIntegrationsByName([
 * //   { name: 'BetaIntegration', abstracts: [{ name: 'abs2' }, { name: 'abs1' }] },
 * //   { name: 'AlphaIntegration', abstracts: [{ name: 'absB' }, { name: 'absA' }] }
 * // ])
 * // => sorted by name: 'AlphaIntegration', 'BetaIntegration', and their `abstracts` also sorted by name
 */
export function sortIntegrationsByName(integrations) {
    return integrations
        .map((integration) => ({
            ...integration,
            abstracts: integration?.abstracts?.sort((a, b) => a.name.localeCompare(b.name)) || [],
        }))
        .sort((a, b) => a.name.localeCompare(b.name));
}
/**
 * Groups an array of `abstracts` by integration UUID, merges them into objects that also
 * contain the integration's name, title, icon, etc., and then sorts them by integration name.
 *
 * @param {Array<Object>} abstracts - An array of abstract objects (each having details.integration_uuid).
 * @param {Object} integrations - A map or object keyed by `integration_uuid`, containing integration info
 *                                such as { name, title, icon }.
 * @returns {Array<Object>} An array of grouped objects with shape:
 * [
 *   {
 *     integration_uuid,
 *     name, // from integration
 *     title, // from integration
 *     icon, // from integration
 *     abstracts: [ ... ]
 *   },
 *   ...
 * ]
 *
 * @example
 * // Suppose `abstracts = [{ name: 'EzloWeather', details: { integration_uuid: 'uuid-1' } }, ...]
 * //        `integrations = { 'uuid-1': { name: 'Ezlo Weather', title: 'Weather', icon: '...' } }
 * // => [ { integration_uuid: 'uuid-1', name: 'Ezlo Weather', title: 'Weather', icon: '...', abstracts: [ ... ] } ]
 */
export function getGroupedAbstractsByIntegrations(abstracts, integrations) {
    if (!isArray(abstracts)) {
        return [];
    }

    const groupedAbstractsByIntegrationsUuid = abstracts.reduce((acc, abstract) => {
        const integration_uuid = abstract?.details?.integration_uuid;

        if (acc[integration_uuid]) {
            acc[integration_uuid].abstracts.push(abstract);
        } else {
            const integration = integrations?.[integration_uuid];
            if (!integration) {
                return acc;
            }

            const { name, title, icon } = integration;
            acc[integration_uuid] = {
                integration_uuid,
                name,
                title,
                icon,
                abstracts: [abstract],
            };
        }

        return acc;
    }, {});

    return sortIntegrationsByName(Object.values(groupedAbstractsByIntegrationsUuid));
}

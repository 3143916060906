import { OBJECT, STRING } from 'constants/MeshbotConstant';
import { filterPdmRequestedCloudCapabilities } from './filterPdmRequestedCloudCapabilities';

/**
 * Filters abstract device capabilities to retain only those available for triggers.
 * @param {string[]} capabilitiesOfAbstract - An array of capability names to filter.
 * @param {Object} listCapabilities - An object containing all available capabilities with their details.
 * @returns {string[]} An array of filtered capability names that match the available trigger capabilities.
 * @example
 * filterCapabilitiesForCloudTriggers(['name', 'name_command'], capabilitiesData);
 */

export const filterCapabilitiesForCloudTriggers = (capabilitiesOfAbstract = [], listCapabilities = {}) => {
    if (!Array.isArray(capabilitiesOfAbstract)) {
        return [];
    }

    const triggerCapabilities = getTriggerCapabilities(listCapabilities);
    const filteredCapabilities = extractCapabilityNames(filterPdmRequestedCloudCapabilities(triggerCapabilities));

    return capabilitiesOfAbstract.filter((capability) => filteredCapabilities.includes(capability));
};

/**
 * Extracts capabilities that have `read_write` set to "0".
 * @param {Object} listCapabilities - An object containing capability details.
 * @returns {string[]} An array of capability names that have `read_write = "0"`. Returns an empty array if input is invalid.
 * @example
 * getTriggerCapabilities({ cap1: { capability_name: 'temp', read_write: '0' } });
 */
export const getTriggerCapabilities = (listCapabilities = {}) => {
    if (!listCapabilities || typeof listCapabilities !== OBJECT) {
        return [];
    }

    return Object.values(listCapabilities)
        .filter((capability) => capability?.read_write === '0' && typeof capability?.capability_name === STRING)
        .map((capability) => capability?.capability_name);
};

/**
 * Extracts capability names from an array of capabilities.
 * If a capability object has a 'capability_name' property, it uses that; otherwise, it returns the capability itself.
 *
 * @param {Array} capabilities - The array of capabilities, which may contain objects or strings.
 * @returns {Array} - An array of extracted capability names.
 */
export const extractCapabilityNames = (capabilities) => {
    return capabilities?.map((capability) => capability?.capability_name || capability);
};

import { log } from '../logging';
import { SEVERITY_TEXT } from '../constants';
import { tracer } from '../tracers';
import { getEnvKey } from 'constants/envs';
import { getIdsFromSpan, replaceIdWithPlaceholder } from '../utils';

export function otNavigation(location) {
    if (!location?.pathname) {
        return;
    }
    const path = `/#${location.pathname}`;
    const environment = getEnvKey();

    const span = tracer.startSpan(`Navigation: ${replaceIdWithPlaceholder(path)}`, {
        attributes: {
            path,
            environment,
        },
    });

    log('Page Navigation', SEVERITY_TEXT.INFO, {
        event: 'navigation',
        path,
        environment,
        ...getIdsFromSpan(span),
    });

    span.end();
}

export function otTrackButtonClicks() {
    document.addEventListener('click', (event) => {
        const target = event?.target;
        const className = target?.className;
        const innerText = target?.innerText;
        const tagName = target?.tagName;
        const environment = getEnvKey();

        const span = tracer.startSpan('Click', {
            attributes: { innerText, tagName, environment },
        });

        log(`Click`, SEVERITY_TEXT.INFO, {
            event: 'click',
            innerText,
            environment,
            className,
            tagName,
            ...getIdsFromSpan(span),
        });

        span.end();
    });
}

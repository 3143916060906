import { withSafelyExecute } from './withSafelyExecute';
import { isString } from 'lodash';
import { getIdsFromSpan } from './getIdsFromSpan';

/**
 * Retrieves OpenTelemetry trace IDs and returns them in an object with custom keys.
 *
 * @param {Object} span - OT span.
 * @param {string} traceIdKey - The key to use for the trace ID in the returned object.
 * @param {string} spanIdKey - The key to use for the span ID in the returned object.
 * @returns {Object} An object containing the trace ID and span ID with the specified keys.
 * @throws {Error} If the active span is missing, otIds are not available, or keys are invalid.
 */
function getOt(span, traceIdKey, spanIdKey) {
    if (!isString(traceIdKey) || !isString(spanIdKey) || !span) {
        throw new Error('Passed incorrect arguments to getOt function');
    }
    const { trace_id, span_id } = getIdsFromSpan(span);
    if (!trace_id || !span_id) {
        throw new Error('Missing trace_id or span_id span');
    }

    return {
        [traceIdKey]: trace_id,
        [spanIdKey]: span_id,
    };
}

/**
 * Retrieves OpenTelemetry trace IDs and returns them in an object with custom keys.
 *
 * @param {Object} span - OT span.
 * @param {string} traceIdKey - The key to use for the trace ID in the returned object.
 * @param {string} spanIdKey - The key to use for the span ID in the returned object.
 * @returns {Object} An object containing the trace ID and span ID with the specified keys or undefined If the span is missing, otIds are not available, or keys are invalid.
 */
export default withSafelyExecute(getOt);

import { retriedFetchIntegrationsPreview } from './retriedFetchIntegrationsPreview';

/**
 * Retrieves the integration preview for a given integration UUID.
 * Attempts to retrieve the preview from cache first; if not available, fetches it.
 *
 * @async
 * @param {string} integrationUuid - The UUID of the integration to retrieve the preview for.
 * @returns {Promise<Object|undefined>} The integration preview object, or undefined if not found.
 */
export const fetchIntegrationPreview = async (integrationUuid) => {
    const response = await retriedFetchIntegrationsPreview([integrationUuid]);
    const integrationPreview = response?.integrations?.[integrationUuid];
    if (!integrationPreview) {
        throw new Error('Missing integrationPreview');
    }

    return integrationPreview;
};

import { useLayoutEffect, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { GenericActions } from '../../actions';
import { useLocation } from 'react-router-dom';
import { otNavigation } from 'lib/opentelemetry';

const LocationContainer = ({ children }) => {
    const dispatch = useDispatch();
    const location = useLocation();

    useLayoutEffect(() => {
        dispatch(GenericActions.locationChange(location));
    }, [location]);

    useEffect(() => {
        otNavigation(location);
    }, [location?.pathname]);

    return children;
};

export default LocationContainer;

import { ERROR_STATUS } from '../../../../constants/api';
import { t } from '../../../../helpers/language';
import { EZLOGIC_TITLE_SOMETHING_WENT_WRONG } from '../../../../constants/language_tokens';
import mainTypes from '../../../../constants/ActionTypes/Main';
import { sleep } from '../../../../helpers/sleep';

/**
 * Handles query response errors
 * @param {Object} response - query response
 * @returns {undefined} In case of an error, it will create new Error and throw it out
 * @example
 * responseErrorHandling({data: { data: { error_text: 'Error'}}})
 * */
export const responseErrorHandling = (response) => {
    if (response.data.status === ERROR_STATUS) {
        const message =
            response.data.data?.error_text ||
            response.data.data?.error_message ||
            t(EZLOGIC_TITLE_SOMETHING_WENT_WRONG);
        throw new Error(message);
    }
};

/**
 * Custom error class representing an Ezlo-specific error.
 *
 * @class EzloError
 * @extends {Error}
 * @param {string} message - The error message.
 * @param {string} type - The type of the Ezlo error.
 */
export class EzloError extends Error {
    constructor(message, type) {
        super(message);
        this.type = type;
    }
}

export const retry = (fn, { limit = mainTypes.DEFAULT_LIMIT_COUNT, delay = mainTypes.DEFAULT_TIMEOUT } = {}) => {
    return fn()
        .then((response) => {
            const { status } = response.data;
            if (status !== mainTypes.SUCCESS_STATUS) {
                throw response;
            }

            return response;
        })
        .catch(async (err) => {
            const shouldRetry = limit > mainTypes.MIN_LIMIT_COUNT;
            if (!shouldRetry) {
                throw err;
            }

            await sleep(delay);

            return retry(fn, { limit: limit - mainTypes.STEP_DECREASING_LIMIT, delay });
        });
};

export function getIdsFromSpan(span) {
    try {
        const spanContext = span.spanContext();

        return {
            trace_id: spanContext.traceId,
            span_id: spanContext.spanId,
        };
    } catch (e) {
        return {};
    }
}

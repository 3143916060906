import { SpanStatusCode } from '@opentelemetry/api';
import { EVENTS, SEVERITY_TEXT, STAGES } from '../../constants';
import { log } from '../../logging';
import { getDynamicHttpLogAttributes } from '../../utils/getDynamicHttpLogAttributes';
import { SEMATTRS_HTTP_STATUS_CODE } from '@opentelemetry/semantic-conventions';
import { HTTP_STATUS_TEXT } from '../../constants/attributeNames';
import { getIdsFromSpan } from '../../utils';

/**
 * Logs and traces an error response for an HTTP request.
 *
 * @param {Object} params - The parameters object.
 * @param {Object} params.span - The span object from OpenTelemetry.
 * @param {string} params.url - The URL of the request that failed.
 * @param {string} params.error - The error message.
 * @param {Object} [params.logData] - Additional log data.
 * @param {string} params.statusText - The HTTP status text.
 * @param {number} params.status - The HTTP status code.
 */
export function logAndTraceErrorResponse({ span, url, error, logData, statusText, status }) {
    span.addEvent(EVENTS.RESPONSE_ERROR);

    log(`Request to ${url} failed`, SEVERITY_TEXT.ERROR, {
        url,
        status,
        error,
        ...getDynamicHttpLogAttributes({ url, stage: STAGES.RESPONSE }),
        ...getIdsFromSpan(span),
        ...logData?.attributes,
    });
    span.setAttributes({
        [SEMATTRS_HTTP_STATUS_CODE]: status,
        [HTTP_STATUS_TEXT]: statusText,
    });
    span.setStatus({ code: SpanStatusCode.ERROR, message: error });
    span.end();
}

export const SUCCESS_STATUS = 1;
export const ERROR_STATUS = 0;
export const QUERY_METHODS = {
    POST: 'POST',
};

/**
 * An enumeration of different API call names.
 *
 * @constant
 * @type {Object<string, string>}
 */
export const CALLS = {
    LEGACY_SEND_NEW_USER_INFO_TO_USER: 'legacy_send_new_user_info_to_user',
    integrations_list: 'integrations_list',
    integrations_preview: 'integrations_preview',
};

export const MMS = 'mms';
export const CLOUD = 'cloud';

export const EVENTS = {
    RESPONSE_RECEIVED: 'response_received',
    RESPONSE_ERROR: 'response_error',
    REQUEST_STARTED: 'request_started',
    WS_SEND_RESPONSE_RECEIVED: 'ws.send.response_received',
    WS_SEND_REQUEST_STARTED: 'ws.send.request_started',
};
export const STAGES = {
    REQUEST: 'request',
    RESPONSE: 'response',
};

export const SEVERITY_TEXT = {
    ERROR: 'ERROR',
    INFO: 'INFO',
    DEBUG: 'DEBUG',
};

export const HUB_COMMAND = 'hub-command';
export const HUB_BROADCAST = 'hub-broadcast';
export const STATUSES = {
    OK: 'OK',
    ERROR: 'ERROR',
};

export const HTTP_CLIENTS = {
    FETCH: 'fetch',
    AXIOS: 'axios',
};

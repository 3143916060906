import axios from 'axios';
import Axios from 'axios';
import { API_CLOUD_EZLO_REQUEST_URL } from '../constants/URLs';
import { axiosCloudConfig } from './config';
import { CALLS } from 'constants/api';

export async function editAbstractNameApi(uuid, newAbstractName) {
    const requestParams = {
        call: 'abstract_command',
        params: {
            capability: 'name_command',
            command: 'set',
            uuid: uuid,
            parameters: {
                target_name: newAbstractName,
            },
        },
        id: uuid,
        version: '1',
    };
    const response = await axios.post(API_CLOUD_EZLO_REQUEST_URL(), requestParams, axiosCloudConfig());

    if (response.data.status === 0) {
        throw new Error(JSON.stringify(response.data.data));
    }

    return response;
}

/**
 * Sends a POST request to retrieve integration previews for a list of UUIDs.
 *
 * @async
 * @param {Array<string>} uuids - An array of integration UUIDs to fetch previews for.
 * @returns {Promise<Object>} A promise that resolves with the Axios response.
 * @throws {Error} If the network request fails or the response is invalid.
 */
export const apiGetIntegrationsPreview = async (uuids) => {
    return Axios.post(
        API_CLOUD_EZLO_REQUEST_URL(),
        {
            call: CALLS.integrations_preview,
            version: '1',
            params: {
                uuids,
            },
        },
        axiosCloudConfig(),
    );
};

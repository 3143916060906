import { createSelector } from 'reselect';
import isObject from 'lodash/isObject';
import { isEmpty, isString } from 'lodash';
import { decompressData } from '../../lib/pako';

/**
 * Retrieves the abstracts array from main.abstractsList.
 *
 * @param {Object} state - The Redux state.
 * @returns {Array|undefined} The list of abstracts, or undefined if not present.
 */
export const abstractsSelector = (state) => state.main?.abstractsList?.abstracts;
/**
 * Retrieves the loading flag for abstracts list from main.abstractsList.
 *
 * @param {Object} state - The Redux state.
 * @returns {boolean|undefined} true if abstracts list is loading, otherwise undefined.
 */
export const isAbstractsLoadingSelector = (state) => state?.main?.abstractsList?.isAbstractsListLoading;
/**
 * Retrieves the integrations object from integrations.integrations.
 *
 * Example shape:
 * {
 *   integrationUuid1: { ... },
 *   integrationUuid2: { ... }
 * }
 *
 * @param {Object} state - The Redux state.
 * @returns {Object|undefined} A map of integrations keyed by their UUID, or undefined if missing.
 */
export const integrationsSelector = (state) => state.integrations?.integrations;
/**
 * Checks if the integrations object from state is not empty.
 *
 * @param {Object} state - The Redux state.
 * @returns {boolean} true if integrations is an object and not empty, otherwise false.
 */
export const isIntegrationsSelector = (state) => !isEmpty(state?.integrations?.integrations);
/**
 * Retrieves the PAASAuth service UUID from main.paas.
 *
 * @param {Object} state - The Redux state.
 * @returns {string|undefined} The PAASAuth service UUID, or undefined if missing.
 */
export const PAASAuthUuidSelector = (state) => state?.main?.paas?.paasAuthServiceUuid;
/**
 * A memoized selector that returns the integrations as an array.
 *
 * If integrations is an object, returns Object.values(integrations).
 * If not an object or undefined, returns an empty array.
 *
 * @function
 * @returns {Function} A reselect selector accepting Redux state, returning an array of integrations.
 */
export const integrationsAsArraySelector = createSelector(integrationsSelector, (integrations) =>
    isObject(integrations) ? Object.values(integrations) : [],
);

/**
 * Determines if the "cloud services" page is in a loading state,
 * by checking multiple flags:
 *  - integrations.isAccountInfoLoading
 *  - integrations.isEnrollmentInProgress
 *  - integrations.isIntegrationsLoading
 *  - main.abstractsList.isAbstractsListLoading
 *  - app.lineLoading
 *
 * @param {Object} state - The Redux state.
 * @returns {boolean} true if any of the above flags is true, false otherwise.
 */
export const isCloudServicesPageLoadingSelector = ({ integrations, app, main }) => {
    const { isAccountInfoLoading, isEnrollmentInProgress, isIntegrationsLoading } = integrations;
    const { lineLoading } = app;
    const isAbstractsListLoading = main?.abstractsList?.isAbstractsListLoading;

    return (
        isAccountInfoLoading || isEnrollmentInProgress || isIntegrationsLoading || isAbstractsListLoading || lineLoading
    );
};
/**
 * Retrieves the `integrations_preview` object from the `integrations` slice of Redux state.
 *
 * Example shape:
 * {
 *   someUuid: {
 *     isLoading: boolean,
 *     data: string,
 *     error: string|undefined
 *   },
 *   anotherUuid: { ... }
 * }
 *
 * @param {Object} state - The Redux state.
 * @returns {Object|undefined} The integrations_preview object, keyed by projectUuid.
 */
export const integrationsPreviewSelector = ({ integrations }) => integrations.integrations_preview;
/**
 * A memoized selector that retrieves a single integration preview entry by its UUID/key.
 *
 * 1. Reads the entire `integrations_preview` object from `integrationsPreviewSelector`.
 * 2. Takes `parjectUuid` as the second argument to the selector.
 * 3. Returns an object with shape: { isLoading, integrationPreview, error }, handling:
 *    - If data is a string, we decompress it via `decompressData`.
 *    - If data is an object, we return it as is.
 *    - If there's an error reading data (exception), we return { integrationPreview: null }.
 *
 * @function
 * @param {Object} state - The Redux state.
 * @param {string} parjectUuid - The UUID/key of the integration preview to retrieve.
 * @returns {Object} An object with:
 *  - `isLoading`: boolean|undefined,
 *  - `integrationPreview`: decompressed data (Object),
 *  - `error`: string|undefined,
 *    or `{ integrationPreview: null }` if an exception occurs.
 */
export const integrationPreviewSelector = createSelector(
    [integrationsPreviewSelector, (_, parjectUuid) => parjectUuid],
    (integrations_preview, parjectUuid) => {
        try {
            const { isLoading, data, error } = integrations_preview?.[parjectUuid] || {};
            if (isString(data)) {
                return { isLoading, integrationPreview: decompressData(data), error };
            }

            return { isLoading, integrationPreview: data, error };
        } catch (e) {
            return { integrationPreview: null };
        }
    },
);

import { SEMATTRS_HTTP_METHOD, SEMATTRS_HTTP_URL } from '@opentelemetry/semantic-conventions';
import { getUrlComponents } from './shared';
import { getEnvKey } from 'constants/envs';
import { HTTP_CLIENT } from '../constants/attributeNames';

export const buildSpanOptionsOfHttpRequest = (url, method, http_client) => ({
    attributes: {
        [SEMATTRS_HTTP_URL]: url,
        [SEMATTRS_HTTP_METHOD]: method.toUpperCase(),
        [HTTP_CLIENT]: http_client,
        ...getUrlComponents(url),
        environment: getEnvKey(),
    },
});

export const HTTP_STATUS_TEXT = 'http.status_text';
export const HTTP_CLIENT = 'http.client';
export const WS_STATUS_TEXT = 'ws.status_text';
export const WS_ERROR_CODE = 'ws.error_code';
export const WS_ERROR_DATA = 'ws.error_data';
export const WS_ERROR_MESSAGE = 'ws.error_message';
export const WS_HUB_SERIAL = 'ws.hub_serial';
export const WS_METHOD = 'ws.method';
export const WS_PARAMS = 'ws.params';
export const WS_BROADCAST_NAME = 'ws.broadcast_name';

import { apiGetIntegrationsPreview } from 'api/ezloProjectsApi';
import { retry } from 'services/utilityService';

/**
 * Fetches the integrations preview for a given integrations UUIDs.
 *
 * @async
 * @param {string[]} uuids - The UUIDs of the integration to fetch the preview for.
 * @returns {Promise<Object|undefined>} The integration preview object, or undefined if response.data.data is missing.
 */
export const retriedFetchIntegrationsPreview = async (uuids) => {
    const response = await retry(() => apiGetIntegrationsPreview(uuids));

    return response?.data?.data;
};

import isFunction from 'lodash/isFunction';
import defer from 'lodash/defer';

/***
 * @namespace
 * @class
 *
 * @property {number} id - id message
 * @property {object} data - request data
 * @property {object|string} error - server error
 * @property {function} reject - reject promise
 * @propery {function} resolve - resolve promise
 * @propery {object} config - describe body
 * */
export default class WSPacket {
    /**
     * @constructor
     * @param {number} id -  message identifier
     * */
    constructor(id) {
        this.id = id;
        this.message = {
            jsonrpc: '2.0', // by default for all messages
        };
    }

    /**
     * Creates a WebSocket message with the specified method and parameters,
     * and assigns resolve and reject callbacks.
     *
     * Updates the `message` property of the instance by adding `id`, `method`, `params`,
     * and any additional payload `.
     * Also sets the `resolveCb` and `rejectCb` callbacks.
     *
     * @param {string} method - The RPC method to be called.
     * @param {Object} params - The parameters to be sent with the method.
     * @param {Function} resolve - The callback function to be called on successful response.
     * @param {Function} reject - The callback function to be called on error.
     * @param {Object} additionPayload - Addition payload.
     */
    create(method, params, resolve, reject, additionPayload) {
        this.message = {
            ...this.message,
            id: this.id,
            method,
            params,
            ...additionPayload,
        };
        this.rejectCb = reject;
        this.resolveCb = resolve;
    }

    getMessage() {
        return this.message;
    }

    resolve(data) {
        if (isFunction(this.resolveCb)) {
            defer(this.resolveCb, {
                data: data,
            });
        }

        return this;
    }

    reject(error) {
        if (isFunction(this.rejectCb)) {
            defer(this.rejectCb, {
                error: error,
            });
        }

        return this;
    }

    clear() {}
}

import axios from 'axios';
import { axiosCloudConfig } from '../api/config';
import mainTypes from '../constants/ActionTypes/Main';
import { API_CLOUD_EZLO_REQUEST_URL } from '../constants/URLs';
import { bugsnagNotify, bugsnagNotifyWrapper } from '../containers/ErrorBoundary/utils';
import { sleep } from './sleep';
import oemManager from 'services/oem';
import { compressData, decompressData } from 'lib/pako';
import { retriedFetchIntegrationsPreview } from './api/retriedFetchIntegrationsPreview';

function getDataByKeyFromLS(key) {
    return JSON.parse(localStorage.getItem(key));
}
function setDataToLS(key, data) {
    return localStorage.setItem(key, JSON.stringify(data));
}

function splitArrayIntoChunks(array, chunkSize = 25) {
    if (!Array.isArray(array)) {
        throw new Error('Input must be an array');
    }

    const result = [];
    for (let i = 0; i < array.length; i += chunkSize) {
        result.push(array.slice(i, i + chunkSize));
    }

    return result;
}

const extractDesiredData = (allIntegrationsPreview) => {
    return Object.entries(allIntegrationsPreview).reduce((acc, [uuid, integration]) => {
        acc[uuid] = {
            imageSource: integration.nucal_parject_definition.list.imageSource,
            description: integration.nucal_parject_definition.list.description,
            authentication: integration.nucal_parject_definition.list.authentication,
            friendlyName: integration.nucal_parject_definition.list.friendlyName,
            category: integration.nucal_parject_definition.list.category,
            hasCallBack: integration.subscribe_definition.list.length > 0,
        };

        return acc;
    }, {});
};

async function fetchIntegrationsPreview(uuids) {
    const uuidsChunks = splitArrayIntoChunks(uuids);
    const allIntegrationsPreview = {};
    const batchRequests = uuidsChunks.map(async (uuidsChanck) => {
        try {
            const response = await retriedFetchIntegrationsPreview(uuidsChanck);

            if (response?.integrations) {
                Object.assign(allIntegrationsPreview, response.integrations);
            }
        } catch (e) {
            bugsnagNotify(e.data);
        }
    });

    await Promise.all(batchRequests);

    return allIntegrationsPreview;
}
export function clearLSFromRedundantData() {
    const allLocalStorageKeys = Object.keys(localStorage);
    const allEnvs = Object.keys(oemManager.appConfig.getEnvironments());

    allEnvs.forEach((envKey) => {
        if (allLocalStorageKeys.includes(envKey)) {
            localStorage.removeItem(envKey);
        }
    });
}

export const getDesiredDataFromIntegrationsPreview = async (uuids) => {
    const integrationsPreview = await fetchIntegrationsPreview(uuids);

    return extractDesiredData(integrationsPreview);
};
export const setAdditionalDataFromIntegrationsPreview = async (integrations) => {
    const uuids = Object.keys(integrations);
    const extractedDesiredData = await getDesiredDataFromIntegrationsPreview(uuids);

    if (extractedDesiredData) {
        uuids.forEach((uuid) => {
            if (extractedDesiredData[uuid]) {
                Object.assign(integrations[uuid], extractedDesiredData[uuid]);
            }
        });
    }

    return integrations;
};

export async function getIntegrationsData(params) {
    const currentEnv = localStorage.getItem(mainTypes.CLOUD_ENVIRONMENT);
    const storage = getDataByKeyFromLS(mainTypes.INTEGRATIONS_STORAGE);

    if (!storage || currentEnv !== storage.env) {
        clearLSFromRedundantData();
        setDataToLS(mainTypes.INTEGRATIONS_STORAGE, { env: currentEnv });
    }

    return extractCachedDataAndUpdate(params, mainTypes.INTEGRATIONS_STORAGE);
}

async function extractCachedDataAndUpdate(params, key) {
    const storage = getDataByKeyFromLS(key);
    const { call: method } = params;

    if (storage?.[method]) {
        fetchAndSaveDataToCache(params, key);

        return decompressData(storage[method]);
    }

    return fetchAndSaveDataToCache(params, key);
}

export const fetchAndSaveDataToCache = async (params, storageKey) => {
    const { call: method } = params;

    const buildRequestParams = {
        method: 'post',
        data: params,
        ...axiosCloudConfig(),
    };

    try {
        const response = await fetchRetry(buildRequestParams);
        const { status, data } = (await response?.data) || {};

        if (status !== mainTypes.SUCCESS_STATUS) {
            throw data;
        }

        const keys = Object.keys(data);
        const key = keys[mainTypes.KEY_INDEX];

        setDataToCache(data[key], method, storageKey);

        return data[key];
    } catch (error) {
        bugsnagNotifyWrapper(error);
    }
};

export const setDataToCache = (data, method, storageKey) => {
    const storage = getDataByKeyFromLS(storageKey);
    storage[method] = compressData(data);
    setDataToLS(storageKey, storage);
};

const fetchRetry = async (
    fetchParams,
    { limit = mainTypes.DEFAULT_LIMIT_COUNT, delay = mainTypes.DEFAULT_TIMEOUT, ...opts } = {},
) => {
    async function onError(err) {
        const shouldRetry = limit > mainTypes.MIN_LIMIT_COUNT;
        if (!shouldRetry) {
            throw err;
        }

        await sleep(delay);

        return fetchRetry(fetchParams, { limit: limit - mainTypes.STEP_DECREASING_LIMIT, delay, ...opts });
    }

    return axios({
        url: API_CLOUD_EZLO_REQUEST_URL(),
        ...fetchParams,
    })
        .then(async (response) => {
            const status = response?.data?.status;

            if (status !== mainTypes.SUCCESS_STATUS) {
                throw response.data;
            }

            return response;
        })
        .catch(onError);
};

import { TRIGGER_FORM } from 'constants/MeshbotConstant';

/**
 * Extracts a list of method names from the `call_definition.list` in the integration preview result.
 *
 * @param {Object} integrationPreviewResult - The integration preview object.
 * @param {Object} [integrationPreviewResult.call_definition] - Contains the call definitions.
 * @param {Array}  [integrationPreviewResult.call_definition.list] - Array of call definition items, each having a `name`.
 * @returns {Array<string>} A list of method names extracted from `call_definition.list`.
 *
 * @example
 * // Suppose integrationPreviewResult.call_definition.list = [{ name: 'method1' }, { name: 'method2' }]
 * // => extractCallDefinitionListWithCallBack(...) = ['method1', 'method2']
 */
export const extractCallDefinitionListWithCallBack = (integrationPreviewResult) => {
    return (
        integrationPreviewResult?.call_definition?.list.reduce((acc, item) => {
            acc.push(item.name);

            return acc;
        }, []) ?? []
    );
};
/**
 * Extracts a list of method names from the `subscribe_definition.list` in the integration preview result,
 * typically used for "trigger" methods.
 *
 * @param {Object} integrationPreviewResult - The integration preview object.
 * @param {Object} [integrationPreviewResult.subscribe_definition] - Contains the subscribe definitions.
 * @param {Array}  [integrationPreviewResult.subscribe_definition.list] - Array of subscribe items, each with a `name`.
 * @returns {Array<string>|undefined} A list of method names or undefined if `subscribe_definition.list` is missing.
 *
 * @example
 * // Suppose integrationPreviewResult.subscribe_definition.list = [{ name: 'onEvent' }, { name: 'onChange' }]
 * // => getNucalTriggerMethodsName(...) = ['onEvent', 'onChange']
 */
export const getNucalTriggerMethodsName = (integrationPreviewResult) => {
    return integrationPreviewResult?.subscribe_definition?.list.map((item) => {
        return item.name;
    });
};
/**
 * Returns the list of methods that are in `call_definition.list` but *not* in `subscribe_definition.list`.
 * Essentially, these are considered "non-trigger" methods.
 *
 * @param {Object} integrationPreviewResult - The integration preview object.
 * @returns {Array<string>} A filtered list of method names.
 *
 * @example
 * // If call_definition.list = [method1, method2], subscribe_definition.list = [method2],
 * // => getNucalMethods(...) = [method1]
 */
export const getNucalMethods = (integrationPreviewResult) => {
    const callDefinitionListWithCallBack = getNucalTriggerMethodsName(integrationPreviewResult);
    const methods = extractCallDefinitionListWithCallBack(integrationPreviewResult);

    return methods?.filter((method) => !callDefinitionListWithCallBack.includes(method));
};
/**
 * Retrieves the `nucal_parject_definition.list` object from the integration preview result.
 *
 * @param {Object} integrationPreviewResult - The integration preview object.
 * @param {Object} [integrationPreviewResult.nucal_parject_definition] - Contains the nucal project definition info.
 * @param {Object}  [integrationPreviewResult.nucal_parject_definition.list] - Object of project definitions.
 * @returns {Object|undefined} The `nucal_parject_definition.list`, or undefined if missing.
 */
export const getNucalParjectDefinitionList = (integrationPreviewResult) => {
    return integrationPreviewResult?.nucal_parject_definition?.list;
};
/**
 * Returns an array of method definitions from the `nucal_parject_definition.interactions` that match
 * either trigger methods (when `calledFrom` is TRIGGER_FORM) or "non-trigger" methods (otherwise).
 *
 * @param {Object} integrationPreview - The integration preview object.
 * @param {string} calledFrom - A string indicating the context (usually `TRIGGER_FORM`).
 * @returns {Array<Object>} An array of objects with { uuid, name, friendlyName, description } for matching methods.
 *
 * @example
 * // If calledFrom=TRIGGER_FORM => compare to getNucalTriggerMethodsName(...)
 * // Else => compare to getNucalMethods(...)
 */
export const getMethodsForList = (integrationPreview, calledFrom) => {
    const nucalParjectDefinition = getNucalParjectDefinitionList(integrationPreview);
    const methodsNames =
        calledFrom === TRIGGER_FORM
            ? getNucalTriggerMethodsName(integrationPreview)
            : getNucalMethods(integrationPreview);

    return (
        nucalParjectDefinition?.interactions?.reduce((acc, { uuid, name, friendlyName, description, isVisible }) => {
            if (methodsNames.includes(name) && isVisible) {
                acc.push({
                    uuid,
                    name,
                    friendlyName,
                    description,
                });
            }

            return acc;
        }, []) || []
    );
};
/**
 * Builds request params for deleting an "abstract" from a parject (integration) by UUID.
 *
 * @param {string} parjectUuid - The parent parject/integration UUID.
 * @param {string} abstractUuid - The abstract’s UUID to delete.
 * @returns {Object} A payload object for the 'integrations-setup' call.
 *
 * @example
 * // => {
 * //   call: 'integrations-setup',
 * //   params: {
 * //     uuid: parjectUuid,
 * //     info: { action: 'delete', uuid_index: abstractUuid },
 * //   }
 * // }
 */
export const deleteAbstractRequestParams = (parjectUuid, abstractUuid) => {
    return {
        call: 'integrations-setup',
        params: {
            uuid: parjectUuid,
            info: {
                action: 'delete',
                uuid_index: abstractUuid,
            },
        },
    };
};
/**
 * Extracts the integration UUID from an abstract object.
 *
 * @param {Object} abstract - An abstract object, which typically has `details.integration_uuid`.
 * @returns {string|undefined} The extracted `integration_uuid`, or undefined if missing.
 *
 * @example
 * // => details?.integration_uuid
 */
export const extractIntegrationUUID = (abstract) => {
    const details = abstract?.details;

    return details?.integration_uuid;
};

import { EVENTS, SEVERITY_TEXT, STAGES } from '../../constants';
import { log } from '../../logging';
import { getDynamicHttpLogAttributes } from '../../utils/getDynamicHttpLogAttributes';
import { SEMATTRS_HTTP_STATUS_CODE } from '@opentelemetry/semantic-conventions';
import { HTTP_STATUS_TEXT } from '../../constants/attributeNames';
import { SpanStatusCode } from '@opentelemetry/api';
import { withSafelyExecute } from '../../utils/withSafelyExecute';
import { getIdsFromSpan } from '../../utils';

function otlFetchReject(span, url, error) {
    const { status, statusText, responseData } = error;
    span.addEvent(EVENTS.RESPONSE_ERROR);

    log(`Request to ${url} failed`, SEVERITY_TEXT.ERROR, {
        url,
        status,
        error: JSON.stringify(responseData),
        ...getIdsFromSpan(span),
        ...getDynamicHttpLogAttributes({ url, stage: STAGES.RESPONSE }),
    });
    span.setAttributes({ [SEMATTRS_HTTP_STATUS_CODE]: status, [HTTP_STATUS_TEXT]: statusText });
    span.setStatus({ code: SpanStatusCode.ERROR, message: error.message });
}

export default withSafelyExecute(otlFetchReject);

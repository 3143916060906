import { tracer } from '../../tracers';
import { buildSpanOptionsOfHttpRequest, getIdsFromSpan, setOtToHeaders } from '../../utils';
import { withSafelyExecute } from '../../utils/withSafelyExecute';
import { log } from '../../logging';
import { EVENTS, HTTP_CLIENTS, SEVERITY_TEXT, STAGES } from '../../constants';
import { getDynamicHttpLogAttributes } from '../../utils/getDynamicHttpLogAttributes';
// import { incrementMetricsCounter } from '../../metrics';

/**
 * Handles the start of an HTTP request by creating a span, logging the request, and incrementing metrics.
 *
 * @param {Object} config - The configuration object for the HTTP request.
 * @param {string} config.url - The URL of the request.
 * @param {string} config.method - The HTTP method of the request (e.g., 'GET', 'POST').
 * @param {Object} [config.headers] - The headers of the request.
 * @param {*} [config.data] - The data payload of the request.
 * @param {Object} [config.logData] - Additional data to include in the log.
 */
function otlHandleStartRequest(config) {
    const { url, method, headers, data, logData } = config || {};
    config.span = tracer.startSpan(
        `HTTP ${config.method.toUpperCase()} request`,
        buildSpanOptionsOfHttpRequest(url, method, HTTP_CLIENTS.AXIOS),
    );

    setOtToHeaders(config, config.span);
    log(`Starting request to ${url}`, SEVERITY_TEXT.INFO, {
        url,
        method,
        ...getDynamicHttpLogAttributes({ url, stage: STAGES.REQUEST, headers, params: data }),
        ...getIdsFromSpan(config.span),
        ...logData?.attributes,
    });
    config.span.addEvent(EVENTS.REQUEST_STARTED);
    // incrementMetricsCounter(url, method, data);
}

export default withSafelyExecute(otlHandleStartRequest);

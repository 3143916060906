// """Change Password & Forgot Password"""
export const NEW_PASSWORD_TITLE = 'ezlo.change_password.new_password.title';
export const FORGOT_PASSWORD_PAGE_TITLE = 'ezlo.forgot_password_page.title.restore_password';
export const PARTNER_DOMAIN_PAGE_TITLE = 'ezlo.partner_domain.login.heading';
export const FORGOT_PASSWORD_PAGE_DESCRIPTION = 'ezlo.forgot_password_page.description';

export const ENTER_YOUR_PASSWORD_TITLE = 'ezlo.set_password.enter_your_password.title';
export const CONFIRM_PASSWORD_TITLE = 'ezlo.set_password.confirm_password.title';

// """Login & Signup page"""
export const SIGN_UP_BUTTON_TITLE = 'ezlo.button.sign_up.title';
export const REQUEST_RESET_LINK_BUTTON_TITLE = 'ezlo.button.request_reset_link.title';
export const SIGN_IN_ENVIRONMENT_TITLE = 'ezlo.button.sign_in.environment';
export const BACK_TO_LOGIN = 'ezlo.register.back_to_login';
export const LOGIN_BUTTON_TITLE = 'btn_log_in';
export const LOGIN_PAGE_MAIN_HEADING = 'ezlo.login_page.title.login_to_MiOS';
export const LOGIN_PAGE_FORGOT_TITLE = 'ezlo.login.title.forgot_password';
export const LOGIN_PAGE_DONT_HAVE_ACCONT_TITLE = 'ezlo.login.title.dont_have_account';

// """NuCAL Integrations Page"""

// Main Component
export const NUCAL_INTEGRATIONS_MAIN_HEADING = 'ezlogic.nucal_intigration.page.main.heading';
export const NUCAL_INTEGRATIONS_ADD_BTN_TITLE = 'ezlogic.nucal_intigration.add.btn.title';

// IntegrationsSection Component
export const NUCAL_INTEGRATIONS_NO_RESULTS_TITLE = 'ezlogic.nucal_intigration.no.result.title';
export const NUCAL_INTEGRATIONS_ACCOUNTS_TITLE = 'ezlogic.nucal_intigration.accounts.title';
export const NUCAL_INTEGRATIONS_CANCEL_BTN_TITLE = 'ezlogic.nucal_intigration.cancel.btn.title';
export const NUCAL_INTEGRATIONS_NAME_FIELD_TITLE = 'ezlogic.nucal_intigration.name.field.title';
export const NUCAL_INTEGRATIONS_ENROLLMENT_OPERATIONS_TITLE = 'ezlogic.nucal_intigration.enrollment.operations.title';

// NewEnrollmentPage Component
export const NUCAL_INTEGRATIONS_NEW_ENROLLMENT_NOT_SUBCRIBE_TEXT = 'ezlogic.nucal_intigration.not.subcribe.title';
export const NUCAL_INTEGRATIONS_NEW_ENROLLMENT_GUIDE_FOR_SUBCRIBE_TEXT =
    'ezlogic.nucal_intigration.guide.for.subcribe.title';

// UpdateIntegrationDialog Component
export const NUCAL_INTEGRATIONS_UPDATE_INTEGRATION_TITLE = 'ezlogic.nucal_intigration.update.modal.title';

// CreateEnrollmentModal Component
export const NUCAL_INTEGRATIONS_CREATE_ENROLLMENT_SEARCH_TITLE =
    'ezlogic.nucal_intigration.creat.enrollment.search.title';

// EnrollByTokenModal Component
export const NUCAL_INTEGRATIONS_ENROLLMENT_BY_TOKEN_TITLE = 'ezlogic.nucal_intigration.enrollment.by.token.title';
export const NUCAL_INTEGRATIONS_ENROLLMENT_BY_TOKEN_TEXTFIELD_TITLE =
    'ezlogic.nucal_intigration.enrollment.by.token.textfield.title';
export const NUCAL_INTEGRATIONS_ENROLLMENT_BY_TOKEN_SET_BTN_TITLE =
    'ezlogic.nucal_intigration.enrollment.by.token.set.btn.title';

// LoadingBlock Component
export const NUCAL_INTEGRATIONS_ENROLLMENT_LOADING_BLOCK_TITLE =
    'ezlogic.nucal_intigration.enrollment.loading.block.title';

// AddBlock Component
export const NUCAL_INTEGRATIONS_ENROLLMENT_ADD_BLOCK_START_TITLE =
    'ezlogic.nucal_intigration.enrollment.add.block.start.title';
export const NUCAL_INTEGRATIONS_ENROLLMENT_ADD_BLOCK_END_TITLE =
    'ezlogic.nucal_intigration.enrollment.add.block.end.title';
export const NUCAL_INTEGRATIONS_ENROLLMENT_ADD_BLOCK_NO_BTN_TITLE =
    'ezlogic.nucal_intigration.enrollment.add.block.no.btn.title';

// IntegrationModal Component
export const NUCAL_INTEGRATIONS_SUCCESSFULLY_ADD_START_TITLE = 'ezlogic.nucal_intigration.successfully.add.start.title';
export const NUCAL_INTEGRATIONS_SUCCESSFULLY_ADD_END_TITLE = 'ezlogic.nucal_intigration.successfully.end.start.title';

// export const NUCAL_INTEGRATIONS_ENROLLMENT_ITEM_DELETE_TITLE = 'ezlogic.nucal_intigration.enrollment.item.delete.title';
export const NUCAL_INTEGRATIONS_ENROLLMENT_ITEM_DELETE_CONFIRMATION_TITLE =
    'ezlogic.nucal_intigration.enrollment.item.delete.confirmation.title';

// """EzloPlugins"""
//  ""PluginEdge""

// Header
export const EZLO_PLUGIN_EDGE_HEADER_PRIVATE_PAGE_BTN_TITLE = 'ezlogic.plugin.edge.header.private.page.btn.title';
export const EZLO_PLUGIN_EDGE_HEADER_DEFAULT_LIST_DISABLED_BTN_TITLE =
    'ezlogic.plugin.edge.header.default.list.disabled.btn.title';
export const EZLO_PLUGIN_EDGE_HEADER_LIST_PRIVATE_BTN_TITLE = 'ezlogic.plugin.edge.header.list.private.btn.title';
export const EZLO_PLUGIN_EDGE_HEADER_LIST_PUBLISH_BTN_TITLE = 'ezlogic.plugin.edge.header.list.publish.btn.title';
export const EZLO_PLUGIN_EDGE_HEADER_WIFIGENERATOR_PRIVATE_BTN_TITLE =
    'ezlogic.plugin.edge.header.wifi.generator.private.btn.title';
export const EZLO_PLUGIN_EDGE_HEADER_WIFIGENERATOR_PUBLISH_BTN_TITLE =
    'ezlogic.plugin.edge.header.wifi.generator.publish.btn.title';
export const EZLO_PLUGIN_EDGE_HEADER_LIST_AND_WIFIGENERATOR_MARKET_BTN_TITLE =
    'ezlogic.plugin.edge.header.list.and.wifi.generator.market.btn.title';

// PluginsPrivate
export const EZLO_PLUGIN_EDGE_PRIVATE_MAIN_TITLE = 'ezlogic.plugin.edge.private.main.title';
export const EZLO_PLUGIN_EDGE_PRIVATE_CONSTANT_ID_TITLE = 'ezlogic.plugin.edge.private.const.id.title';
export const EZLO_PLUGIN_EDGE_PRIVATE_CONSTANT_IP_DEVICE_TITLE = 'ezlogic.plugin.edge.private.const.ip.device.title';
export const EZLO_PLUGIN_EDGE_PRIVATE_CONSTANT_PUBLISH_TITLE = 'ezlogic.plugin.edge.private.const.publish.title';
export const EZLO_PLUGIN_EDGE_PRIVATE_CONSTANT_DELETE_TITLE = 'ezlogic.plugin.edge.private.const.delete.title';
export const EZLO_PLUGIN_EDGE_PRIVATE_CONSTANT_COPY_OF_TITLE = 'ezlogic.plugin.edge.private.const.copy.of.title';
export const EZLO_PLUGIN_EDGE_PRIVATE_CONSTANT_CERTIFIED_TITLE = 'ezlogic.plugin.edge.private.const.certified.title';
export const EZLO_PLUGIN_EDGE_PRIVATE_CONSTANT_INFO_TITLE = 'ezlogic.plugin.edge.private.const.info.title';
export const EZLO_PLUGIN_EDGE_PRIVATE_CONSTANT_STATUS_TITLE = 'ezlogic.plugin.edge.private.const.status.title';
export const EZLO_PLUGIN_EDGE_PRIVATE_CONSTANT_DOWNLOAD_TITLE = 'ezlogic.plugin.edge.private.const.download.title';
export const EZLO_PLUGIN_EDGE_PRIVATE_CONSTANT_MANAGE_INSTALLATION_TITLE =
    'ezlogic.plugin.edge.private.const.manage.installation.title';

// My Private Plugins, id, IP Device Model, Devices, Publish, Edit, Delete, Create device, Copy to my ezlo, Plugin name, Version, Certified, Info, Status approved, Download, Manage Installations,
// Ezlogic General

// Ezlogic Buttons
export const EZLOGIC_BUTTON_YES_TITLE = 'ezlogic.popup.button.yes.title';
export const EZLOGIC_BTN_DELETE_ENROLLMENT_TITLE = 'ezlogic.btn.delete.enrollment.title';
export const EZLOGIC_BUTTON_UPLOAD_TO_MARKETPLACE_TITLE = 'ezlogic.button.upload.to.marketplace.title';
export const EZLOGIC_BUTTON_SAVE_NEW_CONFIGURE = 'ezlogic.button.save.new.configure';
export const EZLOGIC_BUTTON_CANCEL = 'ezlogic.button.cancel';
export const EZLOGIC_BUTTON_WAITING_TITLE = 'ezlo.button.waiting.title';
export const EZLOGIC_BUTTON_CONFIRM = 'ezlogic.button.confirm';
export const EZLOGIC_BUTTON_UNINSTALL = 'ezlogic.button.uninstall';
export const EZLOGIC_BUTTON_CONFIGURE = 'ezlogic.button.configure';
export const EZLOGIC_BUTTON_INSTALL = 'ezlogic.button.install';
export const EZLOGIC_BUTTON_SELECT_FILE = 'ezlogic.button.select.file';
export const EZLOGIC_BUTTON_UPLOAD_MY_ACCOUNT = 'ezlogic.button.upload.my.account';
export const EZLOGIC_BUTTON_UPLOAD_MARKETPlACE = 'ezlogic.button.upload.marketplace';
export const EZLOGIC_BUTTON_BACK_TO_HOME = 'ezlogic.button.back_to_home';
export const EZLOGIC_BUTTON_CREATE_DEVICE = 'ezlogic.button.create_device';
export const EZLOGIC_BUTTON_ADD_CAPABILITY = 'ezlogic.button.add_capability';
export const EZLOGIC_BUTTON_SAVE_MODEL_TEMPLATE = 'ezlogic.button.save_model_template';
export const EZLOGIC_BUTTON_ADD = 'ezlogic.button.add';
export const EZLOGIC_BUTTON_CREATE_NEW_CONTAINER = 'ezlogic.button.create_new_container';
export const EZLOGIC_BUTTON_ADD_PROPERTY = 'ezlogic.button.add_property';
export const EZLOGIC_BUTTON_CONTINUE = 'ezlo.change_controller.modal.confirm_button.title';
export const EZLOGIC_BUTTON_ADD_MORE = 'ezlogic.button.add_more';
export const EZLOGIC_BUTTON_CREATE = 'ezlogic.button.create';
export const EZLOGIC_BUTTON_SAVE = 'ezlogic.button.save';
export const EZLOGIC_BUTTON_NEXT = 'ezlo.button.next.title';
export const EZLOGIC_BUTTON_RESET_TO_DEFAULT = 'ezlo.button.reset_to_default.title';
export const EZLOGIC_BUTTON_OK = 'ezlogic.button.ok';
export const EZLOGIC_BUTTON_CONFIRM_AND_REMOVE = 'ezlogic.button.confirm_and_remove';
export const EZLOGIC_BUTTON_ALL = 'ezlogic.button.all';
export const EZLOGIC_BUTTON_SETTINGS = 'ezlogic.button.settings';
export const EZLOGIC_BUTTON_ADD_NEW_PARAMETER = 'ezlogic.button_add_new_parameter';
export const EZLOGIC_BUTTON_EDIT = 'ezlogic.button.edit';
export const EZLOGIC_BUTTON_GO_BACK = 'ezlogic.button.go_back';
export const EZLOGIC_BUTTON_USE_INSTALLATION_ADDRESS = 'ezlogic.button.use_installation_address';
export const EZLOGIC_BUTTON_ADD_NEW_USER = 'ezlogic.button.add_new_user';
export const EZLOGIC_BUTTON_RESEND = 'ezlogic.button.resend';
export const EZLOGIC_BUTTON_CREATE_PIN_CODE = 'ezlogic.button.create_pin_code';
export const EZLOGIC_BUTTON_CREAT_NEW_ASSOCIATION = 'ezlogic.button.create_new_association';
export const EZLOGIC_BUTTON_SAVE_ASSOCIATION = 'ezlogic.button.save_association';
export const EZOGIC_TITLE_CURRENT_ASSOCIATION = 'ezlogic.title.current_association';
export const EZOGIC_TITLE_MAXIMUM_ASSOCIATION = 'ezlogic.title.max_association';
export const EZLOGIC_BUTTON_CREATE_BACKUP = 'ezlogic.button.create_backup';
export const EZLOGIC_BUTTON_FINISH = 'ezlogic.button.finish';
export const EZLOGIC_BUTTON_GOT_IT = 'ezlogic.button.got_it';
export const EZLOGIC_BUTTON_RESTORE_THIS_BACKUP = 'ezlogic.button.restore_this_backup';
export const EZLOGIC_BUTTON_START_RESTORING_BACKUP = 'ezlogic.button.start_restoring_backup';
export const EZLOGIC_BUTTON_START_CREATING_BACKUP = 'ezlogic.button.start_creating_backup';
export const EZLOGIC_BUTTON_MY_PRIVATE_SCRIPT = 'ezlogic.button.my_private_script';
export const EZLOGIC_BUTTON_CREATE_SCRIPT = 'ezlogic.button.create_script';
export const EZLOGIC_BUTTON_SAVE_SCRIPT = 'ezlogic.button.save_script';
export const EZLOGIC_BUTTON_EDIT_SCRIPT = 'ezlogic.button.save_script';
export const EZLOGIC_BUTTON_CREATE_NEW_MESHBOT = 'ezlogic.button.create_new_meshbot';
export const EZLOGIC_BUTTON_ADD_ANOTHER_TIME = 'ezlogic.button.add_another_time';
export const EZLOGIC_BUTTON_CONTINUE_TO_LOGIN = 'ezlogic.button.continue_to_login';
export const EZLOGIC_BUTTON_SHOW_MORE = 'ezlogic.button.show_more';
export const EZLOGIC_BUTTON_SHOW_LESS = 'ezlogic.button.show_less';
export const EZLOGIC_BUTTON_REVERT_CHANGES = 'ezlogic.button.revert_changes';
export const EZLOGIC_BUTTON_INTEGRATED_DEVICES = 'ezlogic.button.integrated_devices';
// export const EZLOGIC_BUTTON = 'ezlogic.button';

// Ezlogic Headings
export const EZLOGIC_HEADING_UPLOAD_TO_MARKETPLACE_TITLE = 'ezlogic.heading.upload.to.marketplace.title';
export const EZLOGIC_HEADING_META_DATA_TITLE = 'ezlogic.heading.meta_data';
export const EZLOGIC_HEADING_DEPENDENCIES = 'ezlogic.heading.dependencies';
export const EZLOGIC_HEADING_ADDITIONAL_INFORMATION = 'ezlogic.heading.additional.information';
export const EZLOGIC_HEADING_CONFIGURE_PLUGIN = 'ezlogic.heading.configure.plugin';
export const EZLOGIC_HEADING_UPDATE_PLUGIN = 'ezlogic.heading.udpate.plugin';
export const EZLOGIC_HEADING_MY_PUBLISHED_PLUGIN = 'ezlogic.heading.my.publish.plugin';
export const EZLOGIC_HEADING_PAGE_NOT_FOUND = 'ezlogic.heading.page.not.found';
export const EZLOGIC_HEADING_CREATE_NEW_CONFIGURATION = 'ezlogic.heading.create_new_configuration';
export const EZLOGIC_HEADING_CREATE_YOUR_IP_DEVICE = 'ezlogic.heading.create_your_ip_device';
export const EZLOGIC_HEADING_CREDENTIALS = 'ezlogic.heading.credentials';
export const EZLOGIC_HEADING_CREATE_IP_DEVICE_TEMPLATE = 'ezlogic.heading.create_ip_device_template';
export const EZLOGIC_HEADING_CAPATBILITY_WANT_ADD_DESCRIPTION = 'ezlogic.heading.capability_want_add_description';
export const EZLOGIC_HEADING_EDIT_IP_DEVICE_TEMPLATE = 'ezlogic.heading.edit_ip_device_template';
export const EZLOGIC_HEADING_SELECT_YOUR_PREFIX = 'ezlogic.heading.select_your_prefix';
export const EZLOGIC_HEADING_PREFIX_LIST = 'ezlogic.heading.prefix_list';
export const EZLOGIC_HEADING_DELETE_VIRTUAL_CONTAINER = 'ezlogic.heading.delete_virtual_container';
export const EZLOGIC_HEADING_DELETE_DEVICE_CAPABILITY = 'ezlogic.heading.delete_device_capability';
export const EZLOGIC_HEADING_CREATE_VIRTUAL_DEVICE = 'ezlogic.heading.create_virtual_device';
export const EZLOGIC_HEADING_ACTIVE_GROUP = 'ezlogic.heading.active_group';
export const EZLOGIC_HEADING_UNASSIGNED_CONTROLLERS = 'ezlogic.heading.unassigned_controllers';
export const EZLOGIC_HEADING_GROUP_DELETE = 'ezlogic.heading.group_delete';
export const EZLOGIC_HEADING_DEVICES = 'ezlogic.heading.devices';
export const EZLOGIC_HEADING_CAMERA_STREAM_SETTINGS = 'ezlogic.heading.camera_stream_settings';
export const EZLOGIC_HEADING_GENERAL = 'ezlogic.heading.general';
export const EZLOGIC_HEADING_LIST_OF_PARAMETERS = 'ezlogic.heading.list_of_parameters';
export const EZLOGIC_HEADING_NEW_Z_WAVE_PARAMETER = 'ezlogic.heading.new_z_wave_parameter';
export const EZLOGIC_HEADING_SETTING_NOT_SUPPORT = 'ezlogic.heading.setting_not_support';
export const EZLOGIC_HEADING_INSTALLATION_ADDRESS = 'ezlogic.heading.installation_address';
export const EZLOGIC_HEADING_BILLING_ADDRESS = 'ezlogic.heading.billing_address';
export const EZLOGIC_HEADING_MAILING_ADDRESS = 'ezlogic.heading.mailing_address';
export const EZLOGIC_HEADING_USERS = 'ezlogic.heading.users';
export const EZLOGIC_HEADING_EXISTING_ASSOCIATIONS = 'ezlogic.heading.existing_associations';
export const EZLOGIC_HEADING_CHANGE_THE_CONTROLLER = 'ezlogic.heading.change_the_controller';
export const EZLOGIC_HEADING_DELETE_ASSOCIATION = 'ezlogic.heading.delete_association';
export const EZLOGIC_HEADING_ACCOUNT_SETTING = 'ezlogic.heading.account_setting';

// Ezlogic Title
export const EZLOGIC_TITLE_VERSION = 'ezlogic.title.version';
export const EZLOGIC_TITLE_PLUGIN_NAME = 'ezlogic.title.plugin.name';
export const EZLOGIC_TITLE_AUTHOR = 'ezlogic.title.author';
export const EZLOGIC_TITLE_NAME = 'ezlogic.title.name';
export const EZLOGIC_TITLE_LANGUAGE = 'ezlogic.title.language';
export const EZLOGIC_TITLE_DESCRIPTION = 'ezlogic.title.description';
export const EZLOGIC_TITLE_FIRMWARE = 'ezlogic.title.firmware';
export const EZLOGIC_TITLE_TYPE = 'ezlogic.title.type';
export const EZLOGIC_TITLE_MANAGE_PLUGIN_STARTING = 'ezlogic.title.manage.plugin.starting';
export const EZLOGIC_TITLE_MANAGE_PLUGIN_ENDING = 'ezlogic.title.manage.plugin.ending';
export const EZLOGIC_TITLE_NAME_TOO_LARGE_START = 'ezlogic.title.name.too.large.start';
export const EZLOGIC_TITLE_NAME_TOO_LARGE_END = 'ezlogic.title.name.too.large.end';
export const EZLOGIC_TITLE_FIlE_TOO_LARGE = 'ezlogic.title.file.too.large';
export const EZLOGIC_TITLE_FILE_NAME = 'ezlogic.title.file.name';
export const EZLOGIC_TITLE_DEVICE_NAME = 'ezlogic.title.device_name';
export const EZLOGIC_TITLE_CONTROLLER = 'ezlogic.title.controller';
export const EZLOGIC_TITLE_NO_CREATED_DEVICE_TEXT = 'ezlogic.title.no_created_device_text';
export const EZLOGIC_TITLE_PAGE_NOT_FOUND_DESCRIPTION = 'ezlogic.title.page.not.found.description';
export const EZLOGIC_TITLE_CLOUD = 'ezlogic.title.cloud';
export const EZLOGIC_TITLE_PUBLISH_IP_DEVICE = 'ezlogic.title.publish_ip_device';
export const EZLOGIC_TITLE_DEVICE_MODEL = 'ezlogic.title.device_model';
export const EZLOGIC_TITLE_MAC_ADDRESS = 'ezlogic.title.mac_address';
export const EZLOGIC_TITLE_IP_ADDRESS = 'ezlogic.title.ip_address';
export const EZLOGIC_TITLE_PORT = 'ezlogic.title.port';
export const EZLOGIC_TITLE_USER_NAME = 'ezlogic.title.name_here';
export const EZLOGIC_TITLE_PASSWORD = 'ezlogic.title.password';
export const EZLOGIC_TITLE_PUT_DEVICE_NAME = 'ezlogic.title.put_device_name';
export const EZLOGIC_TITLE_SUPPORTED_MODELS = 'ezlogic.title.supported_models';
export const EZLOGIC_TITLE_PUT_AUTHOR_NAME = 'ezlogic.title.put_author_name';
export const EZLOGIC_TITLE_PUT_DESCRIPTION = 'ezlogic.title.put_description';
export const EZLOGIC_TITLE_PUT_SUPPORTED_MODELS = 'ezlogic.title.put_supported_models';
export const EZLOGIC_TITLE_PUT_USER_NAME = 'ezlogic.title.put_user_name';
export const EZLOGIC_TITLE_MAC = 'ezlogic.title.mac';
export const EZLOGIC_TITLE_IP = 'ezlogic.title.ip';
export const EZLOGIC_TITLE_USER = 'ezlogic.title.user';
export const EZLOGIC_TITLE_GIVE_A_NAME = 'ezlogic.title.give_a_name';
export const EZLOGIC_TITLE_GIVE_A_NAME_TO_TEMPLATE = 'ezlogic.title.give_a_name_to_template';
export const EZLOGIC_TITLE_DEVICE_TYPE = 'ezlogic.title.device_type';
export const EZLOGIC_TITLE_ADD_TYPE = 'ezlogic.title.add_type';
export const EZLOGIC_TITLE_CATEGORY = 'ezlogic.title.category';
export const EZLOGIC_TITLE_ADD_CATEGORY = 'ezlogic.title_add_category';
export const EZLOGIC_TITLE_ADD_SUB_CATEGORY = 'ezlogic.title.add_sub_category';
export const EZLOGIC_TITLE_SUB_CATEGORY = 'ezlogic.title_sub_category';
export const EZLOGIC_TITLE_LOGO_IMAGE_ICON_URL = 'ezlogic.title.logo_image_icon_url';
export const EZLOGIC_TITLE_ADD_LOGO_IMAGE_ICON_URL = 'ezlogic.title.add_logo_image_icon_url';
export const EZLOGIC_TITLE_UPLOAD_LOGO_FIELD_ERROR_TEXT = 'ezlogic.title.upload_logo_field_error_text';
export const EZLOGIC_TITLE_UPLOAD_LOGO_FIELD_INFO = 'ezlogic.title.upload_logo_field_info';
export const EZLOGIC_TITLE_UPLOAD = 'ezlogic.title.upload';
export const EZLOGIC_TITLE_OR = 'ezlo.color_picker.or';
export const EZLOGIC_TITLE_PREFIX = 'ezlogic.title.prefix';
export const EZLOGIC_TITLE_PREFIX_NAME = 'ezlogic.title.prefix_name';
export const EZLOGIC_TITLE_VALID = 'ezlogic.title.valid';
export const EZLOGIC_TITLE_INVALID = 'ezlogic.title.invalid';
export const EZLOGIC_TITLE_PLUGIN_SETTING_PARA_1 = 'ezlogic.title.plugin_setting_para_1';
export const EZLOGIC_TITLE_PLUGIN_SETTING_PARA_2 = 'ezlogic.title.plugin_setting_para_2';
export const EZLOGIC_TITLE_PLUGIN_SETTING_PARA_3 = 'ezlogic.title.plugin_setting_para_3';
export const EZLOGIC_TITLE_PLUGIN_SETTING_PARA_4 = 'ezlogic.title.plugin_setting_para_4';
export const EZLOGIC_TITLE_PLUGIN_SETTING_PARA_5 = 'ezlogic.title.plugin_setting_para_5';
export const EZLOGIC_TITLE_EXAMPLE_CODE_IMAGE = 'ezlogic.title.exaple_code_img';
export const EZLOGIC_TITLE_SOME_THING_WENT_WRONG = 'ezlogic.title.some_thing_went_wrong';
export const EZLOGIC_TITLE_TRY_RELOAD = 'ezlogic.title.try_reload';
export const EZLOGIC_TITLE_DELETE_VIRTUAL_CONTAINER_DESCRIPTION = 'ezlogic.title.delete_virtual_container_description';
export const EZLOGIC_TITLE_DELETE = 'ezlogic.title.delete';
export const EZLOGIC_TITLE_EDIT_VIRTUAL_CONTAINER = 'ezlogic.title.edit_virtual_container';
export const EZLOGIC_TITLE_NEW_VIRTUAL_CONTAINER = 'ezlogic.title.new_virtual_container';
export const EZLOGIC_TITLE_TRIGGER_ITEMS = 'ezlogic.title.trigger_items';
export const EZLOGIC_TITLE_ACTION_ITEMS = 'ezlogic.title.action_items';
export const EZLOGIC_TITLE_SELECT_PROPERTY = 'ezlogic.title.select_property';
export const EZLOGIC_TITLE_SELECT_CAPABILITY_TYPE = 'ezlogic.title.select_capability_type';
export const EZLOGIC_TITLE_ADVANCED = 'ezlogic.title.advanced';
export const EZLOGIC_TITLE_STANDARD = 'ezlogic.title.standard';
export const EZLOGIC_TITLE_MAPTO = 'ezlogic.title.mapto';
export const EZLOGIC_TITLE_DATA_VARIABLES = 'ezlogic.title.data_variables';
export const EZLOGIC_TITLE_DATA_STRUCTURE = 'ezlogic.title.data_structure';
export const EZLOGIC_TITLE_SELECT_DEVICE_SERVICE = 'ezlogic.title.select_device_service_WS';
export const EZLOGIC_TITLE_SELECT_DEVICE_SERVICE_CAPABILITY = 'ezlogic.title.select_device_service_capability_WS';
export const EZLOGIC_TITLE_VARIABLE = 'ezlo.variables.add_variable_button.title';
export const EZLOGIC_LABEL_COMPARATOR = 'ezlogic.title.comparator';
export const EZLOGIC_HINTS_VARIABLE = 'ezlogic.hint.value';
export const EZLOGIC_HINTS_VALUE_TYPE = 'ezlogic.title.value_type';
export const EZLOGIC_HINTS_COMPARATOR = 'ezlogic.hint.comparator';
export const EZLOGIC_PLACEHOLDER_ENTER_VALUE = 'ezlogic.title.enter_value';
export const EZLOGIC_PLACEHOLDER_ENTER_NAME = 'ezlogic.title.enter_name';
export const EZLOGIC_PLACEHOLDER_ENTER_NUMBER = 'ezlogic.title.enter_number';
export const EZLOGIC_TITLE_COMMAND = 'ezlogic.title.command';
export const EZLOGIC_TITLE_COMMAND_VARIABLE = 'ezlogic.title.command_variable';
export const EZLOGIC_TITLE_METHOD = 'ezlogic.title.method';
export const EZLOGIC_TITLE_ENTER_JS_CODE = 'ezlogic.title.enter_js_code';
export const EZLOGIC_TITLE_CREATE_CUSTOM_VARIABLE = 'ezlogic.title.create_custom_variable';
export const EZLOGIC_TITLE_CREATE_EDIT_VARIABLE = 'ezlogic.title.edit_variable';
export const EZLOGIC_TITLE_CREATE_YOUR_VARIABLE = 'ezlogic.title.your_variable';
export const EZLOGIC_TITLE_DELETE_DEVICE_CAPABILITY_DESCRIPTION = 'ezlogic.title.delete_device_capability_description';
export const EZLOGIC_TITLE_EXIT_THE_EDIT_CONTAINER = 'ezlogic.title.exit_the_edit_container';
export const EZLOGIC_TITLE_EXIT_EDIT_CONTAINER_DESCRIPTION = 'ezlogic.title.exit_edit_container_description_WC';
export const EZLOGIC_TITLE_EXIT_THE_CREATE_CONTAINER = 'ezlogic.title.exit_the_create_container';
export const EZLOGIC_TITLE_EXIT_CREATE_CONTAINER_DESCRIPTION = 'ezlogic.title.exit_create_container_description_WC';
export const EZLOGIC_TITLE_EXIT_CREATE_NEW_ASSOCIATION_FORM = 'ezlogic.title.exit_create_new_association_form';
export const EZLOGIC_TITLE_EXIT_CREATE_NEW_ASSOCIATION_FORM_DESCRIPTION =
    'ezlogic.title.exit_create_new_association_form_description';
export const EZLOGIC_TITLE_EXIT_CREATE_IP_DEVICE_TEMPLATE_FORM = 'ezlogic.title.exit_create_ip_device_template_form';
export const EZLOGIC_TITLE_EXIT_CREATE_IP_DEVICE_TEMPLATE_FORM_DESCRIPTION =
    'ezlogic.title.exit_create_ip_device_template_form_description';
export const EZLOGIC_TITLE_EXIT_EDIT_IP_DEVICE_TEMPLATE_FORM = 'ezlogic.title.exit_edit_ip_device_template_form';
export const EZLOGIC_TITLE_EXIT_EDIT_IP_DEVICE_TEMPLATE_FORM_DESCRIPTION =
    'ezlogic.title.exit_edit_ip_device_template_form_description';
export const EZLOGIC_TITLE_EXIT_CREATE_IP_DEVICE_FORM = 'ezlogic.title.exit_create_ip_device_form';
export const EZLOGIC_TITLE_EXIT_CREATE_IP_DEVICE_FORM_DESCRIPTION =
    'ezlogic.title.exit_create_ip_device_form_description';
export const EZLOGIC_TITLE_INSTALL_PLUGIN_ON_VIRTUAL_DEVICE = 'ezlogic.title.install_plugin_on_virtual_device';
export const EZLOGIC_TITLE_VIRTUAL_DEVICE_TYPE = 'ezlogic.title.virtual_device_type';
export const EZLOGIC_TITLE_QUATITY = 'ezlogic.title.quatity';
export const EZLOGIC_TITLE_CREATE_VIRTUAL_DEVICE_DESCRIPTION = 'ezlogic.title.create_virtual_device_description';
export const EZLOGIC_TITLE_CONTROLLER_DELETE_MESSAGE = 'ezlogic.title.controller_delete_message';
export const EZLOGIC_TITLE_CONTROLLERS = 'ezlogic.title.controllers';
export const EZLOGIC_TITLE_CREATE_NEW_GROUP = 'ezlogic.title.create_new_group';
export const EZLOGIC_TITLE_CREATE_NEW = 'ezlogic.title.create_new';
export const EZLOGIC_TITLE_REQUEST_PARAMS = 'ezlogic.title.request_params';
export const EZLOGIC_TITLE_YOU_ALREADY_HAVE = 'ezlogic.title.you_already_have';
export const EZLOGIC_TITLE_ENROLLOMENT = 'ezlogic.title.enrolloment';
export const EZLOGIC_TITLE_SEARCH_DEVICE = 'ezlogic.title.search_device';
export const EZLOGIC_TITLE_PARSER_SOURCE_CODE = 'ezlogic.title.parser_source_code';
export const EZLOGIC_TITLE_MESSAGE_OF_DELETING_GROUP = 'ezlogic.title.message_of_deleting_group';
export const EZLOGIC_TITLE_EDIT_GROUP = 'ezlogic.title.edit_group';
export const EZLOGIC_TITLE_MESSAGE_OF_PROVIDING_NAME_REQUIRED = 'ezlogic.title.message_of_providing_name_required';
export const EZLOGIC_TITLE_MESSAGE_OF_CONTROLLERS_ARE_REQUIRED = 'ezlogic.title.message_of_controllers_required';
export const EZLOGIC_TITLE_MODEL = 'ezlogic.title.model';
export const EZLOGIC_TITLE_CONTROLLER_ID = 'ezlogic.title.controller_id';
export const EZLOGIC_TITLE_UPTIME_INFO = 'ezlogic.title.uptime_info';
export const EZLOGIC_TITLE_ADVANCED_SCENES = 'ezlogic.title.advanced_scenes';
export const EZLOGIC_TITLE_ONLINE = 'ezlogic.title.online';
export const EZLOGIC_TITLE_OFFLINE = 'ezlogic.title.offline';
export const EZLOGIC_TITLE_REMOVE_YOUR_HUB = 'ezlogic.title.remove_your_hub';
export const EZLOGIC_TITLE_FUNCTION = 'ezlogic.title.function';
export const EZLOGIC_TITLE_NO_CONTROLLER_MESSAGE = 'ezlogic.title.no_controller_message';
export const EZLOGIC_TITLE_SEARCH_DEVICES = 'ezlogic.title.search_devices';
export const EZLOGIC_TITLE_NO_DEVICES = 'ezlogic.title.no_devices';
export const EZLOGIC_TITLE_NO_INFORMATION = 'ezlogic.title.no_information';
export const EZLOGIC_TITLE_UNASSIGNED = 'ezlogic.title.unassigned';
export const EZLOGIC_TITLE_NO_ROOMS = 'ezlogic.title.no_rooms';
export const EZLOGIC_TITLE_REFRESH_INFO = 'ezlogic.title.refresh_info';
export const EZLOGIC_TITLE_REMOVE_DEVICE = 'ezlogic.title.remove_device';
export const EZLOGIC_TITLE_CONFIRM_MODAL_MESSAGE = 'ezlogic.title.confirm_modal_message';
export const EZLOGIC_TITLE_STATUS = 'ezlogic.title.status';
export const EZLOGIC_TITLE_TEXT_INPUT = 'ezlogic.title.text_input';
export const EZLOGIC_TITLE_PUT_YOUR_TEXT_HERE = 'ezlogic.title.put_your_text_here';
export const EZLOGIC_TITLE_SYNCHRONIZED_INFO_TOOLTIP = 'ezlogic.title.synchronized_info_tooltip';
export const EZLOGIC_TITLE_PENDING_DEVICE_INFO_TOOLTIP = 'ezlogic.title.pending_device_info_tooltip';
export const EZLOGIC_TITLE_SYNC_FAILED_INFO_TOOLTIP = 'ezlogic.title.sync_failed_info_tooltip';
export const EZLOGIC_TITLE_PARAMETER_ID = 'ezlogic.title.parameter_id';
export const EZLOGIC_TITLE_VALUE = 'ezlogic.title.value';
export const EZLOGIC_TITLE_RESTORE = 'ezlogic.title.restore';
export const EZLOGIC_TITLE_RED = 'ezlogic.title.red';
export const EZLOGIC_TITLE_GREEN = 'ezlogic.title.green';
export const EZLOGIC_TITLE_BLUE = 'ezlogic.title.blue';
export const EZLOGIC_TITLE_RESET_YOUR_DEVICE_CONFIRM_MESSAGE = 'ezlogic.title.reset_your_device_confirm_message';
export const EZLOGIC_TITLE_Z_WAVE_ALLOW_MESSAGE = 'ezlogic.title.z_wave_allow_message';
export const EZLOGIC_TITLE_CHECK_DEVICE_MANUAL = 'ezlogic.title.check_device_manual';
export const EZLOGIC_TITLE_DISPLAY_FORMAT = 'ezlogic.title.display_format';
export const EZLOGIC_TITLE_DATA_SIZE = 'ezlogic.title.data_size';
export const EZLOGIC_TITLE_CHANGE_WILL_BE_APPLIED_MESSAGE = 'ezlogic.title.change_will_be_applied_message';
export const EZLOGIC_TITLE_BACK = 'ezlogic.title.back';
export const EZLOGIC_TITLE_PARAMETER_NAME = 'ezlogic.title.parameter_name';
export const EZLOGIC_TITLE_DESIRED_VALUE = 'ezlogic.title.desired_value';
export const EZLOGIC_TITLE_ROOM_MANAGEMENT_CONTENT = 'ezlogic.title.room_managment_content';
export const EZLOGIC_TITLE_CHILD_DEVICES = 'ezlogic.title.child_devices';
export const EZLOGIC_TITLE_MOVE_CHILD_DEVICES = 'ezlogic.title.move_child_devices';
export const EZLOGIC_TITLE_TERMS_AND_CONDITIONS = 'ezlogic.title.terms_and_conditions';
export const EZLOGIC_TITLE_PRIVACY_POLICY = 'ezlogic.title.privacy_policy';
export const EZLOGIC_TITLE_ACCOUNT_INFO = 'ezlogic.title.account_info';
export const EZLOGIC_TITLE_FIRST_NAME = 'ezlogic.title.first_name';
export const EZLOGIC_TITLE_LAST_NAME = 'ezlogic.title.last_name';
export const EZLOGIC_TITLE_STREET_ADDRESS_1 = 'ezlogic.title.street_address_1';
export const EZLOGIC_TITLE_STREET_ADDRESS_2 = 'ezlogic.title.street_address_2';
export const EZLOGIC_TITLE_ZIP = 'ezlogic.title.zip';
export const EZLOGIC_TITLE_CITY = 'ezlogic.title.city';
export const EZLOGIC_TITLE_COUNTRY = 'ezlogic.title.country';
export const EZLOGIC_TITLE_STATE = 'ezlogic.title.state';
export const EZLOGIC_TITLE_ENTER_YOUR = 'ezlogic.title.enter_your';
export const EZLOGIC_TITLE_EMAIL = 'ezlogic.title.email';
export const EZLOGIC_TITLE_WEBSITE = 'ezlogic.title.website';
export const EZLOGIC_TITLE_USER_DETAILS = 'ezlogic.title.user_details';
export const EZLOGIC_TITLE_ACCOUNT_TYPE = 'ezlogic.title.account_type';
export const EZLOGIC_TITLE_CONFIRM_PASSWORD = 'ezlogic.title.confirm_password';
export const EZLOGIC_TITLE_CHANGE_PASSWORD = 'ezlogic.title.change_password';
export const EZLOGIC_TITLE_CURRENT_PASSWORD = 'ezlogic.title.current_password';
export const EZLOGIC_TITLE_CONFIRM_YOUR_NEW_PASSWORD = 'ezlogic.title.confirm_your_new_password';
export const EZLOGIC_TITLE_PASSWORD_REQUIREMENTS = 'ezlogic.title.password_requirements';
export const EZLOGIC_TITLE_UPPERCASE_LETTER = 'ezlogic.title.uppercase_letter';
export const EZLOGIC_TITLE_LOWERCASE_LETTER = 'ezlogic.title.lowercase_letter';
export const EZLOGIC_TITLE_ONE_NUMBER_AND_NON_ALPHABETIC = 'ezlogic.title.one_number_and_non_alphabetic';
export const EZLOGIC_TITLE_MINIMUM_PASSWORD_LENGTH = 'ezlogic.title.miniumum_password_length';
export const EZLOGIC_TITLE_PASSWORDS_MATCH = 'ezlogic.title.passwords_match';
export const EZLOGIC_TITLE_EMAIL_FIELD_DESCRIPTION = 'ezlogic.title.email_field_description';
export const EZLOGIC_TITLE_PASSWORD_CONFIRM_WARNING_TEXT = 'ezlogic.title.change_password_confirm_text';
export const EZLOGIC_TITLE_SELECT_CONTROLLER_ACCESS = 'ezlogic.title.select_controller_access';
export const EZLOGIC_TITLE_EZLO = 'ezlogic.title.ezlo';
export const EZLOGIC_TITLE_PIN_NAME = 'ezlogic.title.pin_name';
export const EZLOGIC_TITLE_DELETE_WARNING = 'ezlogic.title.delete_warning';
export const EZLOGIC_TITLE_SECURITY_PANEL = 'ezlogic.title.security_panel';
export const EZLOGIC_TITLE_PIN_NAME_IS_REQUIRED = 'ezlogic.title.pin_name_is_required';
export const EZLOGIC_TITLE_ACCESS_CODE_MUST_BE_POSITIVE_INTEGER = 'ezlogic.title.access_code_must_be_positive_integer';
export const EZLOGIC_TITLE_ONLY_NUMBERS_MIN_4_SYMBOLS = 'ezlogic.title.only_numbers_min_4_symbols';
export const EZLOGIC_TITLE_ONLY_NUMBERS_MAX_8_SYMBOLS = 'ezlogic.title.only_numbers_max_8_symbols';
export const EZLOGIC_TITLE_ACCESS_CODE_IS_REQUIRED = 'ezlogic.title.access_code_is_required';
export const EZLOGIC_TITLE_ALL_PIN_CODES = 'ezlogic.title.all_pin_codes';
export const EZLOGIC_TITLE_MY_PIN_CODES = 'ezlogic.title.my_pin_codes';
export const EZLOGIC_TITLE_ACCESS = 'ezlogic.title.access';
export const EZLOGIC_TITLE_SELECT_THE_DEVICES_YOU_WANT_THIS_PIN_TO_WORK_ON =
    'ezlogic.title.select_the_devices_you_want_this_PIN_to_work_on';
export const EZLOGIC_TITLE_THERE_ARE_NO_PAIRED_COMPATIBLE_DEVICES_AVAILABLE_FOR_PIN_CODE_USAGE =
    'ezlogic.title.there_are_no_paired_compatible_devices_available_for_pin_code_usage';
export const EZLOGIC_TITLE_LOCKS = 'ezlogic.title.locks';
export const EZLOGIC_TITLE_SELECT_ALL = 'ezlogic.title.select_all';
export const EZLOGIC_TITLE_NEW_PIN_CODE = 'ezlogic.title.new_pin_code';
export const EZLOGIC_TITLE_PIN_CODE = 'ezlogic.title.pin_code';
export const EZLOGIC_TITLE_ENTER_YOUR_PIN_CODE = 'ezlogic.title.enter_your_pin_code';
export const EZLOGIC_TITLE_ENTER_YOUR_NEW_PIN_CODE = 'ezlogic.title.enter_your_new_pin_code';
export const EZLOGIC_TITLE_ACCESS_CODE = 'ezlogic.title.access_code';
export const EZLOGIC_TITLE_OFF = 'ezlogic.title.off';
export const EZLOGIC_TITLE_LOSE_YOUR_CURRENT_PROGRESS = 'ezlogic.title.lose_your_current_progress';
export const EZLOGIC_TITLE_Z_WAVE_NODE_ID = 'ezlogic.title.z_wave_node_id';
export const EZLOGIC_TITLE_CHANNEL = 'ezlogic.title.channel';
export const EZLOGIC_TITLE_GROUP = 'ezlogic.title.group';
export const EZLOGIC_TITLE_UNASSIGNED_DEVICES = 'ezlogic.title.unassigned_devices';
export const EZLOGIC_TITLE_ASSOCIATED_WITH = 'ezlogic.title.associated_with';
export const EZLOGIC_TITLE_DELETE_ASSOCIATION_MESSAGE = 'ezlogic.title.delete_association_message';
export const EZLOGIC_TITLE_DEVICE_ASSOCIATION_NOT_SUPPORT = 'ezlogic.title.device_association_not_support';
export const EZLOGIC_TITLE_MINIMUM_ALLOW_VERSION = 'ezlogic.title.minimum_allow_version';
export const EZLOGIC_TITLE_YOUR_CONTROLLER_IS_OFFLINE = 'ezlogic.title.your_controller_is_offline';
export const EZLOGIC_TITLE_TO_CREATE_ASSOCIATION = 'ezlogic.title.to_create_association';
export const EZLOGIC_TITLE_SOURCE_CHANNEL = 'ezlogic.title.source_channel';
export const EZLOGIC_TITLE_SOURCE_DEVICE = 'ezlogic.title.source_device';
export const EZLOGIC_TITLE_SOURCE_GROUP = 'ezlogic.title.source_group';
export const EZLOGIC_TITLE_TARGET_CHANNEL = 'ezlogic.title.targer_channel';
export const EZLOGIC_TITLE_TARGET_DEVICE = 'ezlogic.title.targer_device';
export const EZLOGIC_TITLE_NON_SPECIFIED = 'ezlogic.title.non_specified';
export const EZLOGIC_TITLE_THIS_DEVICE_NOT_SUPPORT = 'ezlogic.title.this_device_not_support';
export const EZLOGIC_TITLE_CONTROLLABLE = 'ezlogic.title.controllable';
export const EZLOGIC_TITLE_NODE = 'ezlogic.title.node';
export const EZLOGIC_TITLE_DEVICE_NOT_FOUND = 'ezlogic.title.device_not_found';
export const EZLOGIC_TITLE_MAXIMUM_NUMBER_OF_ASSOCIATIONS = 'ezlogic.title.maximum_number_of_association';
export const EZLOGIC_TITLE_MAXIMUM_ASSOCIATIONS_FOR_DEVICE = 'ezlogic.title.maximum_association_for_device';
export const EZLOGIC_TITLE_LIST_OF_COMMANDS = 'ezlogic.title.list_of_commands';
export const EZLOGIC_TITLE_DEVICE = 'ezlogic.title.device';
export const EZLOGIC_TITLE_DATE_AND_TIME = 'ezlogic.title.date_and_time';
export const EZLOGIC_TITLE_NUCAL = 'ezlogic.title.nucal';
export const EZLOGIC_TITLE_CLOUD_VARIABLES = 'ezlogic.title.cloud_variables';
export const EZLOGIC_TITLE_CLOUD_VARIABLE = 'ezlogic.title.cloud_variable';
export const EZLOGIC_TITLE_GEOFENCE = 'ezlogic.title.geofence';
export const EZLOGIC_TITLE_NOTIFICATION = 'ezlogic.title.notification';
export const EZLOGIC_TITLE_MESHBOT = 'ezlogic.title.meshbot';
export const EZLOGIC_TITLE_DAY = 'ezlogic.title.day';
export const EZLOGIC_TITLE_MONTH = 'ezlogic.title.month';
export const EZLOGIC_TITLE_YEAR = 'ezlogic.title.year';
export const EZLOGIC_TITLE_DASHBOARD = 'ezlogic.title.dashboard';
export const EZLOGIC_TITLE_LOCAL_VARIABLE = 'ezlogic.title.local_variable';
export const EZLOGIC_TITLE_HOUSE_MODE = 'ezlogic.title.house_mode';
export const EZLOGIC_TITLE_EXPRESSION = 'ezlogic.title.expression';
export const EZLOGIC_TITLE_HTTP_REQUEST = 'ezlogic.title.http_request';
export const EZLOGIC_TITLE_LUA_SCRIPT = 'ezlogic.title.lua_script';
export const EZLOGIC_TITLE_SECURITY_MODE = 'ezlogic.title.security_mode';
export const EZLOGIC_TITLE_EXIT_UNSAVED_CUSTOMIZATION_CHANGES = 'ezlogic.title.exit_unsaved_customization_changes';
export const EZLOGIC_TITLE_DISCARD_UNSAVED_CUSTOMIZATION_CHANGES =
    'ezlogic.title.discard_unsaved_customization_changes';
export const EZLOGIC_TITLE_BACKUPS = 'ezlogic.title.backups';
export const EZLOGIC_TITLE_MIGRATE_TO_EZLO = 'ezlogic.title.migrate_to_ezlo';
export const EZLOGIC_TITLE_BACKUP_TIME_MESSAGE = 'ezlogic.title.backup_time_message';
export const EZLOGIC_TITLE_DO_NOT_USE_DEVICES = 'ezlogic.title.do_not_use_devices';
export const EZLOGIC_TITLE_TO_AVOID_ERROR_MESSAGE = 'ezlogic.title.to_avoid_error_message';
export const EZLOGIC_TITLE_CREAING_BACKUP = 'ezlogic.title.create_backup';
export const EZLOGIC_TITLE_PROCESS_WILL_TAKE_3_5_MINUTES = 'ezlogic.title.process_will_take_3_5_minutes';
export const EZLOGIC_TITLE_BACKUP_SUCCESSFULLY_CREATED = 'ezlogic.title.back_successfully_created';
export const EZLOGIC_TITLE_YOU_MAY_NOW_CONTINUE_USING = 'ezlogic.title.you_may_now_continue_using';
export const EZLOGIC_TITLE_KEEP_THIS_BACKUP = 'ezlogic.title.keep_this_backup';
export const EZLOGIC_TITLE_IT_WONT_BE_AUTOMATICALLY = 'ezlogic.title.it_wont_be_automatically';
export const EZLOGIC_TITLE_BACKUP_PROCESS_WAS_INTERRUPTED = 'ezlogic.title.backup_process_was_interrupted';
export const EZLOGIC_TITLE_CONNECTION_ISSUE = 'ezlogic.title.connection_issue';
export const EZLOGIC_TITLE_BACKUP_INCLUDE_SCENES = 'ezlogic.title.backup_include_scenes';
export const EZLOGIC_TITLE_RESTORING_BACKUP = 'ezlogic.title.restoring_backup';
export const EZLOGIC_TITLE_PLEASE_DO_NOT_DISCONNECT = 'ezlogic.title.please_do_not_disconnect';
export const EZLOGIC_TITLE_RESTORE_PROCESS_WAS_INTERRUPTED = 'ezlogic.title.restore_process_was_interrupted';
export const EZLOGIC_TITLE_BACKUP_SUCCESSFULLY_RESTORED = 'ezlogic.title.back_successfully_restored';
export const EZLOGIC_TITLE_YOU_HAVE_NO_BACKUP = 'ezlogic.title.you_have_no_backup';
export const EZLOGIC_TITLE_YOUR_BACKUP_WILL_APPEAR = 'ezlogic.title.your_backup_will_appear';
export const EZLOGIC_TITLE_NOT_SUPPORTED = 'ezlogic.title.not_supported';
export const EZLOGIC_TITLE_THE_BACKUP_IS_NOT_SUPPORTED = 'ezlogic.title.the_backup_is_not_supported';
export const EZLOGIC_TITLE_CONTROLLER_WILL_NOT_RESPOND = 'ezlogic.title.controller_will_not_respond';
export const EZLOGIC_TITLE_PROCESS_MAKE_A_FEW_MINUTES = 'ezlogic.title.process_make_a_few_minutes';
export const EZLOGIC_TITLE_DATA_TYPE_INTEGER = 'ezlogic.title.data_type_integer';
export const EZLOGIC_TITLE_DATA_TYPE_FLOAT = 'ezlogic.title.data_type_float';
export const EZLOGIC_TITLE_DATA_TYPE_STRING = 'ezlogic.title.data_type_string';
export const EZLOGIC_TITLE_DATA_TYPE_BOOLEAN = 'ezlogic.title.data_type_boolean';
export const EZLOGIC_TITLE_DATA_TYPE_ACTION_BUTTON = 'ezlogic.title.data_type_action_button';
export const EZLOGIC_TITLE_DATA_TYPE_COLOR = 'ezlogic.title.data_type_color';
export const EZLOGIC_TITLE_DATA_TYPE_TOKEN = 'ezlogic.title.data_type_token';
export const EZLOGIC_TITLE_DATA_TYPE_SCALABLE = 'ezlogic.title.data_type_scalable';
export const EZLOGIC_TITLE_PLEASE_ENTER_A_VALUE = 'ezlogic.title.please_enter_a_value';
export const EZLOGIC_TITLE_VARIABLE_SCALE = 'ezlogic.title.variable_scale';
export const EZLOGIC_TITLE_VARIABLE_SCALE_TYPE = 'ezlogic.title.variable_scalable_type';
export const EZLOGIC_TITLE_VARIABLE_TEXT = 'ezlogic.title.variable_text';
export const EZLOGIC_TITLE_VARIABLE_LANGUAGE_TAG = 'ezlogic.title.variable_language_tag';
export const EZLOGIC_TITLE_VARIABLE_TYPE = 'ezlogic.title.variable_type';
export const EZLOGIC_TITLE_VARIABLE_NAME = 'ezlogic.title.variable_name';
export const EZLOGIC_TITLE_VARIABLE_VALUE = 'ezlogic.title.variable_value';
export const EZLOGIC_TITLE_SELECT_A_VALUE_LABEL = 'ezlo.select_a_value.label';
export const EZLOGIC_TITLE_SCALABLE_TYPE_TEMPERATURE = 'ezlogic.title.scalable_type_temperature';
export const EZLOGIC_TITLE_SCALABLE_TYPE_ILLUMINANCE = 'ezlogic.title.scalable_type_illuminance';
export const EZLOGIC_TITLE_SCALABLE_TYPE_PRESSURE = 'ezlogic.title.scalable_type_pressure';
export const EZLOGIC_TITLE_SCALABLE_TYPE_MOISTURE = 'ezlogic.title.scalable_type_moisture';
export const EZLOGIC_TITLE_SCALABLE_TYPE_SUBSTANCE_AMOUNT = 'ezlogic.title.scalable_type_substance_amount';
export const EZLOGIC_TITLE_SCALABLE_TYPE_POWER = 'ezlogic.title.scalable_type_power';
export const EZLOGIC_TITLE_SCALABLE_TYPE_VELOCITY = 'ezlogic.title.scalable_type_velocity';
export const EZLOGIC_TITLE_SCALABLE_TYPE_ACCELERATION = 'ezlogic.title.scalable_type_acceleration';
export const EZLOGIC_TITLE_SCALABLE_TYPE_DIRECTION = 'ezlogic.title.scalable_type_direction';
export const EZLOGIC_TITLE_SCALABLE_TYPE_GENERAL_PURPOSE = 'ezlogic.title.scalable_type_general_purpose';
export const EZLOGIC_TITLE_SCALABLE_TYPE_ACIDITY = 'ezlogic.title.scalable_type_acidity';
export const EZLOGIC_TITLE_SCALABLE_TYPE_ELECTRIC_POTENTIAL = 'ezlogic.title.scalable_type_electric_potential';
export const EZLOGIC_TITLE_SCALABLE_TYPE_ELECTRIC_CURRENT = 'ezlogic.title.scalable_type_electric_current';
export const EZLOGIC_TITLE_SCALABLE_TYPE_FORCE = 'ezlogic.title.scalable_type_force';
export const EZLOGIC_TITLE_SCALABLE_TYPE_IRRADIANCE = 'ezlogic.title.scalable_type_irradiance';
export const EZLOGIC_TITLE_SCALABLE_TYPE_PRECIPITATION = 'ezlogic.title.scalable_type_precipitation';
export const EZLOGIC_TITLE_SCALABLE_TYPE_LENGTH = 'ezlogic.title.scalable_type_length';
export const EZLOGIC_TITLE_SCALABLE_TYPE_MASS = 'ezlogic.title.scalable_type_mass';
export const EZLOGIC_TITLE_SCALABLE_TYPE_VOLUME_FLOW = 'ezlogic.title.scalable_type_volume_flow';
export const EZLOGIC_TITLE_SCALABLE_TYPE_VOLUME = 'ezlogic.title.scalable_type_volume';
export const EZLOGIC_TITLE_SCALABLE_TYPE_ANGLE = 'ezlogic.title.scalable_type_angle';
export const EZLOGIC_TITLE_SCALABLE_TYPE_FREQUENCY = 'ezlogic.title.scalable_type_frequency';
export const EZLOGIC_TITLE_SCALABLE_TYPE_SEISMIC_INTENSITY = 'ezlogic.title.scalable_type_seismic_intensity';
export const EZLOGIC_TITLE_SCALABLE_TYPE_SEISMIC_MAGNITUDE = 'ezlogic.title.scalable_type_seismic_magnitude';
export const EZLOGIC_TITLE_SCALABLE_TYPE_ULTRAVIOLET = 'ezlogic.title.scalable_type_ultraviolet';
export const EZLOGIC_TITLE_SCALABLE_TYPE_ELECTRICAL_RESISTANCE = 'ezlogic.title.scalable_type_electrical_resistance';
export const EZLOGIC_TITLE_SCALABLE_TYPE_ELECTRICAL_CONDUCTIVITY =
    'ezlogic.title.scalable_type_electrical_conductivity';
export const EZLOGIC_TITLE_SCALABLE_TYPE_LOUDNESS = 'ezlogic.title.scalable_type_loudness';
export const EZLOGIC_TITLE_SCALABLE_TYPE_TIME = 'ezlogic.title.scalable_type_time';
export const EZLOGIC_TITLE_SCALABLE_TYPE_RADON_CONCENTRATION = 'ezlogic.title.scalable_type_radon_concentration';
export const EZLOGIC_TITLE_SCALABLE_TYPE_BLOOD_PRESSURE = 'ezlogic.title.scalable_type_blood_pressure';
export const EZLOGIC_TITLE_SCALABLE_TYPE_ENERGY = 'ezlogic.title.scalable_type_energy';
export const EZLOGIC_TITLE_SCALABLE_TYPE_RF_SIGNAL_STRENGTH = 'ezlogic.title.scalable_type_rf_signal_strength';
export const EZLOGIC_TITLE_SCALABLE_TYPE_HUMIDITY = 'ezlogic.title.scalable_type_humidity';
export const EZLOGIC_TITLE_SCALABLE_TYPE_KILO_VOLT_AMPERE_HOUR = 'ezlogic.title.scalable_type_kilo_volt_ampere_hour';
export const EZLOGIC_TITLE_SCALABLE_TYPE_REACTIVE_POWER_INSTANT = 'ezlogic.title.scalable_type_reactive_power_instant';
export const EZLOGIC_TITLE_SCALABLE_TYPE_AMOUNT_OF_USEFUL_ENERGY =
    'ezlogic.title.scalable_type_amount_of_useful_energy';
export const EZLOGIC_TITLE_SCALABLE_TYPE_REACTIVE_POWER_CONSUMPTION =
    'ezlogic.title.scalable_type_reactive_power_consumption';
export const EZLOGIC_TITLE_SCALE_TYPE_CELSIUS = 'ezlogic.title.scale_type_celsius';
export const EZLOGIC_TITLE_SCALE_TYPE_FAHRENHEIT = 'ezlogic.title.scale_type_fahrenheit';
export const EZLOGIC_TITLE_SCALE_TYPE_PERCENT = 'ezlogic.title.scale_type_percent';
export const EZLOGIC_TITLE_SCALE_TYPE_LUX = 'ezlogic.title.scale_type_lux';
export const EZLOGIC_TITLE_SCALE_TYPE_KILO_PASCAL = 'ezlogic.title.scale_type_kilo_pascal';
export const EZLOGIC_TITLE_SCALE_TYPE_POUND_PER_SQUARE_INCH = 'ezlogic.title.scale_type_pound_per_square_inch';
export const EZLOGIC_TITLE_SCALE_TYPE_INCHES_OF_MERCURY = 'ezlogic.title.scale_type_inches_of_mercury';
export const EZLOGIC_TITLE_SCALE_TYPE_VOLUME_WATER_CONTENT = 'ezlogic.title.scale_type_volume_water_content';
export const EZLOGIC_TITLE_SCALE_TYPE_IMPEDANCE = 'ezlogic.title.scale_type_impedance';
export const EZLOGIC_TITLE_SCALE_TYPE_WATER_ACTIVITY = 'ezlogic.title.scale_type_water_activity';
export const EZLOGIC_TITLE_SCALE_TYPE_GRAM_PER_CUBIC_METER = 'ezlogic.title.scale_type_gram_per_cubic_meter';
export const EZLOGIC_TITLE_SCALE_TYPE_MICRO_GRAM_PER_CUBIC_METER =
    'ezlogic.title.scale_type_micro_gram_per_cubic_meter';
export const EZLOGIC_TITLE_SCALE_TYPE_MOLE_PER_CUBIC_METER = 'ezlogic.title.scale_type_mole_per_cubic_meter';
export const EZLOGIC_TITLE_SCALE_TYPE_PARTS_PER_MILLION = 'ezlogic.title.scale_type_parts_per_million';
export const EZLOGIC_TITLE_SCALE_TYPE_MILLI_GRAM_PER_LITER = 'ezlogic.title.scale_type_milli_gram_per_liter';
export const EZLOGIC_TITLE_SCALE_TYPE_WATT = 'ezlogic.title.scale_type_watt';
export const EZLOGIC_TITLE_SCALE_TYPE_BTU_PER_HOUR = 'ezlogic.title.scale_type_btu_per_hour';
export const EZLOGIC_TITLE_SCALE_TYPE_METER_PER_SECOND = 'ezlogic.title.scale_type_meter_per_second';
export const EZLOGIC_TITLE_SCALE_TYPE_MILE_PER_HOUR = 'ezlogic.title.scale_type_mile_per_hour';
export const EZLOGIC_TITLE_SCALE_TYPE_METER_PER_SQUARE_SECOND = 'ezlogic.title.scale_type_meter_per_square_second';
export const EZLOGIC_TITLE_SCALE_TYPE_NO_DIRECTION = 'ezlogic.title.scale_type_no_direction';
export const EZLOGIC_TITLE_SCALE_TYPE_EAST = 'ezlogic.title.scale_type_east';
export const EZLOGIC_TITLE_SCALE_TYPE_SOUTH = 'ezlogic.title.scale_type_south';
export const EZLOGIC_TITLE_SCALE_TYPE_WEST = 'ezlogic.title.scale_type_west';
export const EZLOGIC_TITLE_SCALE_TYPE_NORTH = 'ezlogic.title.scale_type_north';
export const EZLOGIC_TITLE_SCALE_TYPE_FLOAT = 'ezlogic.title.scale_type_float';
export const EZLOGIC_TITLE_SCALE_TYPE_POTENTIAL_OF_HYDROGEN = 'ezlogic.title.scale_type_potential_of_hydrogen';
export const EZLOGIC_TITLE_SCALE_TYPE_MILLI_VOLT = 'ezlogic.title.scale_type_milli_volt';
export const EZLOGIC_TITLE_SCALE_TYPE_VOLT = 'ezlogic.title.scale_type_volt';
export const EZLOGIC_TITLE_SCALE_TYPE_AMPERE = 'ezlogic.title.scale_type_ampere';
export const EZLOGIC_TITLE_SCALE_TYPE_MILLI_AMPERE = 'ezlogic.title.scale_type_milli_ampere';
export const EZLOGIC_TITLE_SCALE_TYPE_NEWTON = 'ezlogic.title.scale_type_newton';
export const EZLOGIC_TITLE_SCALE_TYPE_WATT_PER_SQUARE_METER = 'ezlogic.title.scale_type_watt_per_square_meter';
export const EZLOGIC_TITLE_SCALE_TYPE_MILLI_METER_PER_HOUR = 'ezlogic.title.scale_type_milli_meter_per_hour';
export const EZLOGIC_TITLE_SCALE_TYPE_INCHES_PER_HOUR = 'ezlogic.title.scale_type_inches_per_hour';
export const EZLOGIC_TITLE_SCALE_TYPE_METER = 'ezlogic.title.scale_type_meter';
export const EZLOGIC_TITLE_SCALE_TYPE_FEET = 'ezlogic.title.scale_type_feet';
export const EZLOGIC_TITLE_SCALE_TYPE_CENTI_METER = 'ezlogic.title.scale_type_centi_meter';
export const EZLOGIC_TITLE_SCALE_TYPE_KILO_GRAM = 'ezlogic.title.scale_type_kilo_gram';
export const EZLOGIC_TITLE_SCALE_TYPE_POUNDS = 'ezlogic.title.scale_type_pounds';
export const EZLOGIC_TITLE_SCALE_TYPE_CUBIC_METER_PER_HOUR = 'ezlogic.title.scale_type_cubic_meter_per_hour';
export const EZLOGIC_TITLE_SCALE_TYPE_CUBIC_FEET_PER_MINUTE = 'ezlogic.title.scale_type_cubic_feet_per_minute';
export const EZLOGIC_TITLE_SCALE_TYPE_LITER_PER_HOUR = 'ezlogic.title.scale_type_liter_per_hour';
export const EZLOGIC_TITLE_SCALE_TYPE_LITER = 'ezlogic.title.scale_type_liter';
export const EZLOGIC_TITLE_SCALE_TYPE_CUBIC_METER = 'ezlogic.title.scale_type_cubic_meter';
export const EZLOGIC_TITLE_SCALE_TYPE_CUBIC_FEET = 'ezlogic.title.scale_type_cubic_feet';
export const EZLOGIC_TITLE_SCALE_TYPE_GALLONS = 'ezlogic.title.scale_type_gallons';
export const EZLOGIC_TITLE_SCALE_TYPE_NORTH_POLE_DEGREES = 'ezlogic.title.scale_type_north_pole_degrees';
export const EZLOGIC_TITLE_SCALE_TYPE_REVOLUTIONS_PER_MINUTE = 'ezlogic.title.scale_type_revolutions_per_minute';
export const EZLOGIC_TITLE_SCALE_TYPE_HERTZ = 'ezlogic.title.scale_type_hertz';
export const EZLOGIC_TITLE_SCALE_TYPE_KILO_HERTZ = 'ezlogic.title.scale_type_kilo_hertz';
export const EZLOGIC_TITLE_SCALE_TYPE_BREATHS_PER_MINUTE = 'ezlogic.title.scale_type_breaths_per_minute';
export const EZLOGIC_TITLE_SCALE_TYPE_BEATS_PER_MINUTE = 'ezlogic.title.scale_type_beats_per_minute';
export const EZLOGIC_TITLE_SCALE_TYPE_MERCALLI = 'ezlogic.title.scale_type_mercalli';
export const EZLOGIC_TITLE_SCALE_TYPE_EUROPEAN_MACROSEISMIC = 'ezlogic.title.scale_type_european_macroseismic';
export const EZLOGIC_TITLE_SCALE_TYPE_LIEDU = 'ezlogic.title.scale_type_liedu';
export const EZLOGIC_TITLE_SCALE_TYPE_SHINDO = 'ezlogic.title.scale_type_shindo';
export const EZLOGIC_TITLE_LOCAL = 'ezlogic.title.local';
export const EZLOGIC_TITLE_SCALE_TYPE_SURFACE_WAVE = 'ezlogic.title.scale_type_surface_wave';
export const EZLOGIC_TITLE_SCALE_TYPE_BODY_WAVE = 'ezlogic.title.scale_type_body_wave';
export const EZLOGIC_TITLE_SCALE_TYPE_UV_INDEX = 'ezlogic.title.scale_type_uv_index';
export const EZLOGIC_TITLE_SCALE_TYPE_OHM_METER = 'ezlogic.title.scale_type_ohm_meter';
export const EZLOGIC_TITLE_SCALE_TYPE_SIEMENS_PER_METER = 'ezlogic.title.scale_type_siemens_per_meter';
export const EZLOGIC_TITLE_SCALE_TYPE_DECIBEL = 'ezlogic.title.scale_type_decibel';
export const EZLOGIC_TITLE_SCALE_TYPE_A_WEIGHTED_DECIBEL = 'ezlogic.title.scale_type_a_weighted_decibels';
export const EZLOGIC_TITLE_SCALE_TYPE_BECQUEREL_PER_CUBIC_METER = 'ezlogic.title.scale_type_becquerel_per_cubic_meter';
export const EZLOGIC_TITLE_SCALE_TYPE_PICOCURIES_PER_LITER = 'ezlogic.title.scale_type_picocuries_per_liter';
export const EZLOGIC_TITLE_SCALE_TYPE_SYSTOLIC = 'ezlogic.title.scale_type_systolic';
export const EZLOGIC_TITLE_SCALE_TYPE_DIASTOLIC = 'ezlogic.title.scale_type_diastolic';
export const EZLOGIC_TITLE_SCALE_TYPE_JOULE = 'ezlogic.title.scale_type_joule';
export const EZLOGIC_TITLE_SCALE_TYPE_KILO_WATT_HOUR = 'ezlogic.title.scale_type_kilo_watt_hour';
export const EZLOGIC_TITLE_SCALE_TYPE_DECIBEL_MILLI_WATT = 'ezlogic.title.scale_type_decibel_milli_watt';
export const EZLOGIC_TITLE_SCALE_TYPE_KILO_VOLT_AMPERE_HOUR = 'ezlogic.title.scale_type_kilo_volt_ampere_hour';
export const EZLOGIC_TITLE_SCALE_TYPE_KILO_VOLT_AMPERE_REACTIVE = 'ezlogic.title.scale_type_kilo_volt_ampere_reactive';
export const EZLOGIC_TITLE_SCALE_TYPE_KILO_VOLT_AMPERE_REACTIVE_HOUR =
    'ezlogic.title.scale_type_kilo_volt_ampere_reactive_hour';
export const EZLOGIC_TITLE_SCALE_TYPE_MOMENT = 'ezlogic.title.scale_type_moment';
export const EZLOGIC_TITLE_EXPRESSION_DELETED_ERROR_HELPER_TEXT = 'ezlogic.title.expression_deleted_error_helper_text';
export const EZLOGIC_TITLE_VARIABLE_DELETED_ERROR_HELPER_TEXT = 'ezlogic.title.variable_deleted_error_helper_text';
export const EZLOGIC_TITLE_SET_VARIABLE_CAPABILITY = 'ezlogic.title.set_variable_capability';
export const EZLOGIC_TITLE_TRUE = 'ezlogic.title.true';
export const EZLOGIC_TITLE_FALSE = 'ezlogic.title.false';
export const EZLOGIC_TITLE_ERROR = 'ezlogic.title.error';
export const EZLOGIC_TITLE_PLEASE_TRY_AGAIN_LATER = 'ezlogic.title.please_try_again_later';
export const EZLOGIC_TITLE_ADD_NEW_VARIABLE_FORM_EXIT_MESSAGE = 'ezlogic.title.add_new_variable_form_exit_message';
export const EZLOGIC_TITLE_EDIT_EXPRESSION_FORM = 'ezlogic.title.edit_expression_form';
export const EZLOGIC_TITLE_ADD_EXPRESSION_FORM = 'ezlogic.title.add_expression_form';
export const EZLOGIC_DESCRIPTION_EDIT_EXPRESSION_FORM = 'ezlogic.description.edit_expression_form_WC';
export const EZLOGIC_DESCRIPTION_ADD_EXPRESSION_FORM = 'ezlogic.description.add_expression_form_WC';
export const EZLOGIC_DESCRIPTION_ADD_SCRIPT = 'ezlogic.description.exit_add_script_WC';
export const EZLOGIC_TITLE_ADD_SCRIPT = 'ezlogic.title.exit_add_script';
export const EZLOGIC_TITLE_EDIT_SCRIPT = 'ezlogic.title.exit_edit_script';
export const EZLOGIC_DESCRIPTION_EDIT_SCRIPT = 'ezlogic.description.exit_edit_script_WC';
export const EZLOGIC_TITLE_ADD_NEW_VARIABLE_FORM_DISCARD_EDITING_MESSAGE =
    'ezlogic.title.add_new_variable_form_discard_editing_message';
export const EZLOGIC_TITLE_EDIT_FORM_EXIT_MESSAGE = 'ezlogic.title.edit_variable_form_exit_message';
export const EZLOGIC_TITLE_SCRIPTS_RESULT = 'ezlogic.title.result_scripts';
export const EZLOGIC_TITLE_SCRIPTS_NAME = 'ezlogic.title.script_name';
export const EZLOGIC_TITLE_RUN = 'ezlogic.title.run';
export const EZLOGIC_TITLE_LATCH_NAME = 'ezlogic.title.latch_name';
export const EZLOGIC_TITLE_NAME_SCRIPT = 'ezlogic.title.name_script';
export const EZLOGIC_TITLE_PUT_SCRIPT_NAME = 'ezlogic.title.put_script_name';
export const EZLOGIC_TITLE_PUT_SCRIPT_BODY = 'ezlogic.title.put_script_body';
export const EZLOGIC_TITLE_TURN_OFF_ALL_DEVICES = 'ezlogic.title.turn_off_all_devices';
export const EZLOGIC_TITLE_ID_MESHBOT = 'ezlogic.title.id_meshbot';
export const EZLOGIC_TITLE_CHECKBOX_SELECTION = 'ezlogic.title.checkbox_selection';
export const EZLOGIC_TITLE_MESHBOT_NAME = 'ezlogic.title.meshbot_name';
export const EZLOGIC_TITLE_GROUP_NAME = 'ezlogic.title.group_name';
export const EZLOGIC_TITLE_MESHBOT_TYPE = 'ezlogic.title.meshbot_type';
export const EZLOGIC_TITLE_EDGE_COMPUTER = 'ezlogic.title.edge_computer';
export const EZLOGIC_TITLE_RUN_ONCE = 'ezlogic.title.run_once';
// export const EZLOGIC_TITLE_MESHBOTS = 'ezlogic.title.meshbots';
export const EZLOGIC_TITLE_CREATE_MESHBOT = 'ezlogic.title.create_meshbot';
export const EZLOGIC_TITLE_AUTOMATION_MESHBOT = 'ezlogic.title.automation_meshbot';
export const EZLOGIC_TITLE_NOTIFICATION_MESHBOT = 'ezlogic.title.notification_meshbot';
export const EZLOGIC_TITLE_INTERACTION_FLOW_MESHBOT = 'ezlogic.title.interaction_flow_meshBot';
export const EZLOGIC_TITLE_YOU_HAVE_NO_MESHBOT_YET = 'ezlogic.title.you_have_no_meshbot_yet';
export const EZLOGIC_TITLE_YOU_HAVE_NO_INTEGRATIONS_YET = 'ezlogic.title.you_have_no_integrations_yet';
export const EZLOGIC_TITLE_YOUR_MESHBOT_WILL_APEAR = 'ezlogic.title.your_meshbot_will_apear';
export const EZLOGIC_TITLE_YOUR_INTEGRATIONS_WILL_APEAR = 'ezlogic.title.your_integrations_will_apear';
export const EZLOGIC_TITLE_YOU_HAVE_NO_ASSOCIATIONS_YET = 'ezlogic.title.you_have_no_association_yet';
export const EZLOGIC_TITLE_YOUR_ASSOCIATION_WILL_APPEAR = 'ezlogic.title.your_association_will_appear';
// export const EZLOGIC_TITLE_DELETE_MESHBOT = 'ezlogic.title.delete_meshbot';
// export const EZLOGIC_TITLE_DELETE_MESHBOT_MESSAGE = 'ezlogic.title.delete_meshbot_message';
export const EZLOGIC_TITLE_NO_ROWS = 'ezlogic.title.no_rows';
export const EZLOGIC_TITLE_NO_RESULT_FOUND = 'ezlogic.title.no_result_found';
export const EZLOGIC_TITLE_DENSITY = 'ezlogic.title.density';
export const EZLOGIC_TITLE_MORE = 'ezlogic.title.more';
export const EZLOGIC_TITLE_COMPACT = 'ezlogic.title.compact';
export const EZLOGIC_TITLE_COMFORTABLE = 'ezlogic.title.comfortable';
export const EZLOGIC_TITLE_COLUMNS = 'ezlogic.title.columns';
export const EZLOGIC_TITLE_COLUMN = 'ezlogic.title.column';
export const EZLOGIC_TITLE_FILTERS = 'ezlogic.title.filters';
export const EZLOGIC_TITLE_OPERATORS = 'ezlogic.title.operators';
export const EZLOGIC_TITLE_FILTER_VALUE = 'ezlogic.title.filter_value';
export const EZLOGIC_TITLE_CONTAINS = 'ezlogic.title.contains';
export const EZLOGIC_TITLE_EQUALS = 'ezlogic.title.equals';
export const EZLOGIC_TITLE_STARTS_WITH = 'ezlogic.title.starts_with';
export const EZLOGIC_TITLE_END_WITH = 'ezlogic.title.ends_with';
export const EZLOGIC_TITLE_IS_EMPTY = 'ezlogic.title.is_empty';
export const EZLOGIC_TITLE_IS_NOT_EMPTY = 'ezlogic.title.is_not_empty';
export const EZLOGIC_TITLE_FILTER = 'ezlogic.title.filter';
export const EZLOGIC_TITLE_EXPORT = 'ezlogic.title.export';
export const EZLOGIC_TITLE_DOWNLOAD_AS_CSV = 'ezlogic.title.download_as_csv';
export const EZLOGIC_TITLE_ROWS_PER_PAGE = 'ezlogic.title.rows_per_page';
export const EZLOGIC_TITLE_SHOW_COLUMNS = 'ezlogic.title.show_columns';
export const EZLOGIC_TITLE_HIDE = 'ezlogic.title.hide';
export const EZLOGIC_TITLE_UNSORT = 'ezlogic.title.unsort';
export const EZLOGIC_TITLE_SORT_BY_ASC = 'ezlogic.title.sort_by_asc';
export const EZLOGIC_TITLE_SORT_BY_DESC = 'ezlogic.title.sort_by_desc';
// export const EZLOGIC_TITLE_AUTOMATION = 'ezlogic.title.automation';
export const EZLOGIC_TITLE_DELETE_MESHBOT = 'ezlogic.title.delete_meshbot';
export const EZLOGIC_TITLE_DELETE_MESHBOT_MESSAGE = 'ezlogic.title.delete_meshbot_message';
export const EZLOGIC_TITLE_NEED_TO_ADD_A_GROUP_NAME = 'ezlogic.title.need_to_add_a_group_name';
export const EZLOGIC_TITLE_DO_YOU_WANT_TO_EXIT_CREATE_MESHBOT = 'ezlogic.title.do_you_want_to_exit_create_meshBot';
export const EZLOGIC_TITLE_DISCARD_CREATING_MESHBOT = 'ezlogic.title.discard_creating_meshbot_WC';
export const EZLOGIC_TITLE_DO_YOU_WANT_TO_EXIT_EDIT_MESHBOT = 'ezlogic.title.do_you_want_to_exit_edit_meshBot';
export const EZLOGIC_TITLE_DISCARD_EDIT_MESHBOT = 'ezlogic.title.discard_edit_meshbot_WC';
export const EZLOGIC_TITLE_SCRIPT_SUCCESSFULLY = 'ezlogic.title.script_has_been_successfully';
export const EZLOGIC_TITLE_ADDED = 'ezlogic.title.added';
export const EZLOGIC_TITLE_UPDATED = 'ezlogic.title.updated';
export const EZLOGIC_TITLE_UPDATE = 'ezlogic.title.update';
export const EZLOGIC_TITLE_CANNOT_BE_USED_WITH_OTHER_DATE_AND_TIME =
    'ezlogic.title.cannot_be_used_with_other_date_and_time';
export const EZLOGIC_TITLE_FIRMWARE_SHOULD_INCLUDE_ADVANCED_SCENES =
    'ezlogic.title.firmware_should_include_advanced_scenes';
export const EZLOGIC_TITLE_SELECT_FUNCTION = 'ezlogic.title.select_function';
export const EZLOGIC_TITLE_FOR = 'ezlogic.title.for';
export const EZLOGIC_TITLE_REPEAT = 'ezlogic.title.repeat';
export const EZLOGIC_TITLE_FOLLOW = 'ezlogic.title.follow';
export const EZLOGIC_TITLE_PULSE = 'ezlogic.title.pulse';
export const EZLOGIC_TITLE_LATCH = 'ezlogic.title.latch';
export const EZLOGIC_TITLE_TRIGGER_FUNCTIONS_INFO = 'ezlogic.title.trigger_functions_info';
export const EZLOGIC_TITLE_ACTION = 'ezlogic.title.action';
export const EZLOGIC_TITLE_AT_LEAST = 'ezlogic.title.at_least';
export const EZLOGIC_TITLE_LESS_THAN = 'ezlogic.title.less_then';
export const EZLOGIC_TITLE_WITHIN = 'ezlogic.title.within';
export const EZLOGIC_TITLE_DELAY_RESET = 'ezlogic.title.delay_reset';
export const EZLOGIC_TITLE_MINUTES = 'ezlogic.title.minutes';
export const EZLOGIC_TITLE_HOURS = 'ezlogic.title.hours';
export const EZLOGIC_TITLE_TRUE_FOR = 'ezlogic.title.true_for';
export const EZLOGIC_TITLE_ONCE = 'ezlogic.title.once';
export const EZLOGIC_TITLE_INFINITE = 'ezlogic.title.infinite';
export const EZLOGIC_TITLE_SECS = 'ezlogic.title.secs';
export const EZLOGIC_TITLE_MINS = 'ezlogic.title.mins';
export const EZLOGIC_TITLE_TIMES = 'ezlogic.title.times';
export const EZLOGIC_TITLE_FAILED = 'ezlogic.title.failed';
export const EZLOGIC_TITLE_FINISHED = 'ezlogic.title.finished';
export const EZLOGIC_TITLE_PARTIALLY_FINISHED = 'ezlogic.title.partially_finished';
export const EZLOGIC_TITLE_ANY_RESULT = 'ezlogic.title.any_result';
export const EZLOGIC_TITLE_MESHBOT_ENABLED = 'ezlogic.title.meshbot_enabled';
export const EZLOGIC_TITLE_MESHBOT_DISABLED = 'ezlogic.title.meshbot_disabled';
export const EZLOGIC_TITLE_DAYS_WEEK = 'ezlogic.title.days_week';
export const EZLOGIC_TITLE_DAYS_MONTH = 'ezlogic.title.days_month';
export const EZLOGIC_TITLE_WEEKS_MONTH = 'ezlogic.title.weeks_month';
export const EZLOGIC_TITLE_WEEKS_YEAR = 'ezlogic.title.weeks_year';
export const EZLOGIC_TITLE_CUSTOM_TIME = 'ezlogic.title.custom_time';
export const EZLOGIC_TITLE_CLOUD_CONNECTION = 'ezlogic.title.cloud_connection';
export const EZLOGIC_TITLE_BATTERY_STATE = 'ezlogic.title.battery_state';
export const EZLOGIC_TITLE_BATTERY_LEVEL = 'ezlogic.title.bettery_level';
export const EZLOGIC_TITLE_GREATER = 'ezlogic.title.greater';
export const EZLOGIC_TITLE_LESS = 'ezlogic.title.less';
export const EZLOGIC_TITLE_EQUAL = 'ezlogic.title.equal';
export const EZLOGIC_TITLE_NOT_EQUAL = 'ezlogic.title.not_equal';
export const EZLOGIC_TITLE_LESS_EQUAL = 'ezlogic.title.less_equal';
export const EZLOGIC_TITLE_GREATER_EQUAL = 'ezlogic.title.greater_equal';
export const EZLOGIC_HINT_HOUSE_MODES = 'ezlogic.hint.house_modes';
export const EZLOGIC_TITLE_SECURITY_MODE_CHANGE_TO = 'ezlogic.title.security_mode_change_to';
export const EZLOGIC_TITLE_SECURITY_MODE_CHANGE_FROM = 'ezlogic.title.security_mode_change_from';
export const EZLOGIC_TITLE_SECURITY_MODES = 'ezlogic.title.security_modes';
export const EZLOGIC_TITLE_HOUSE_MODES = 'ezlogic.title.house_modes';
export const EZLOGIC_TITLE_DISARMED = 'ezlogic.title.disarmed';
export const EZLOGIC_TITLE_ARMED = 'ezlogic.title.armed';
export const EZLOGIC_TITLE_ARM_STAY = 'ezlogic.title.arm_stay';
export const EZLOGIC_TITLE_HOME = 'ezlogic.title.home';
export const EZLOGIC_TITLE_AWAY = 'ezlogic.title.away';
export const EZLOGIC_TITLE_NIGHT = 'ezlogic.title.night';
export const EZLOGIC_TITLE_VACATION = 'ezlogic.title.vacation';
export const EZLOGIC_TITLE_CHARGING = 'ezlogic.title.charging';
export const EZLOGIC_TITLE_FULL = 'ezlogic.title.full';
export const EZLOGIC_TITLE_DISCHARGING = 'ezlogic.title.discharging';
export const EZLOGIC_TITLE_MISSING = 'ezlogic.title.missing';
export const EZLOGIC_TITLE_CONNECTED = 'ezlogic.title.connected';
export const EZLOGIC_TITLE_DISCONNECTED = 'ezlogic.title.disconnected';
export const EZLOGIC_TITLE_HOUSE_MODE_CHANGED_TO = 'ezlogic.title.house_mode_changed_to';
export const EZLOGIC_TITLE_HOUSE_MODE_CHANGED_FROM = 'ezlogic.title.house_mode_changed_from';
export const EZLOGIC_TITLE_MODE = 'ezlogic.title.mode';
export const EZLOGIC_INFO_TEXT_MODE = 'ezlogic.info.text.mode';
export const EZLOGIC_TITLE_NO_OPTIONS = 'ezlogic.title.no_options';
export const EZLOGIC_TITLE_ARMED_STATE = 'ezlogic.title.armed_state';
export const EZLOGIC_HINT_ARMED_STATE = 'ezlogic.hint.armed_state';
export const EZLOGIC_TITLE_ANY = 'ezlogic.title.any';
export const EZLOGIC_TITLE_ERROR_COMMUNICATING_WITH_HUB = 'ezlogic.title.error_communicating_with_hub';
export const EZLOGIC_TITLE_VALUE_START = 'ezlogic.title.value_start';
export const EZLOGIC_TITLE_VALUE_END = 'ezlogic.title.value_end';
export const EZLOGIC_HINT_VALUE_START = 'ezlogic.hint.value_start';
export const EZLOGIC_HINT_VALUE_END = 'ezlogic.hint.value_end';
export const EZLOGIC_TITLE_TYPE_A_VALUE = 'ezlogic.title.type_a_value';
export const EZLOGIC_TITLE_ANY_MOTION = 'ezlogic.title.any_motion';
export const EZLOGIC_TITLE_FACE_DETECTION = 'ezlogic.title.face_detection';
export const EZLOGIC_TITLE_OBJECT_DETECTION = 'ezlogic.title.object_detection';
export const EZLOGIC_TITLE_QR_CODE_DETECTION = 'ezlogic.title.QR_code_detection';
export const EZLOGIC_TITLE_ANY_PERSON = 'ezlogic.title.any_person';
export const EZLOGIC_TITLE_ANY_OBJECT = 'ezlogic.title.any_object';
export const EZLOGIC_TITLE_ANY_QR_CODE = 'ezlogic.title.any_QR_code';
export const EZLOGIC_TITLE_IS_DETECTED_IN = 'ezlogic.title.is_detected_in';
export const EZLOGIC_TITLE_FULL_SCREEN = 'ezlogic.title.full_screen';
export const EZLOGIC_TITLE_ANY_ZONE = 'ezlogic.title.any_zone';
export const EZLOGIC_TITLE_VALUE_NAME_EXPRESSION = 'ezlogic.title.value_name_expression';
export const EZLOGIC_TITLE_NAME_VARIABLE = 'ezlogic.title.name_variable';
export const EZLOGIC_TITLE_CODE_FOR_EXPRESSION = 'ezlogic.title.code_for_expression';
export const EZLOGIC_TITLE_SELECT_ITEM = 'ezlogic.title.select_item';
export const EZLOGIC_TITLE_TOGGLE = 'ezlogic.title.toggle';
export const EZLOGIC_TITLE_ENTER_TEXT = 'ezlogic.title.enter_text';
export const EZLOGIC_TITLE_EVERY = 'ezlogic.title.every';
export const EZLOGIC_TITLE_SECONDS = 'ezlogic.title.seconds';
export const EZLOGIC_TITLE_DAYS = 'ezlogic.title.days';
export const EZLOGIC_TITLE_INCLUDE_ADVANCED_SCENES = 'ezlogic.title.include_advanced_scenes';
export const EZLOGIC_TITLE_CHOOSE_THE_MOMENT_OF_DAY = 'ezlogic.title.choose_the_moment_of_day';
export const EZLOGIC_TITLE_EVENT = 'ezlogic.title.event';
export const EZLOGIC_HINT_EVENT = 'ezlogic.hint.event';
export const EZLOGIC_TITLE_ADD_OFFSET = 'ezlogic.title.add_offset';
export const EZLOGIC_HINT_ADD_OFFSET = 'ezlogic.hint.add_offset';
export const EZLOGIC_HINT_OFFSET = 'ezlogic.hint.offset';
export const EZLOGIC_HINT_TRUE_ACTION = 'ezlogic.hint.true_action';
export const EZLOGIC_HINT_FALSE_ACTION = 'ezlogic.hint.false_action';
export const EZLOGIC_TITLE_VALUE_SPECIAL_TIME_OF_DAY = 'ezlogic.title.value_special_time_of_day';
export const EZLOGIC_TITLE_AT_ONE_TIME_ONLY = 'ezlogic.title.at_one_time_only';
export const EZLOGIC_TITLE_DAY_UNTIL_SUNRISE = 'ezlogic.title.day_until_sunrise';
export const EZLOGIC_TITLE_DAY_UNTIL_SUNSET = 'ezlogic.title.day_until_sunset';
export const EZLOGIC_TITLE_SUNRISE_UNTIL_MIDNIGHT = 'ezlogic.title.sunrise_until_midnight';
export const EZLOGIC_TITLE_SUNSET_UNTIL_MIDNIGHT = 'ezlogic.title.sunset_until_midnight';
export const EZLOGIC_TITLE_AT_A_TIME_OF_DAY = 'ezlogic.title.at_a_time_of_day';
export const EZLOGIC_TITLE_BETWEEN = 'ezlogic.title.between';
export const EZLOGIC_TITLE_START_TIME = 'ezlogic.title.start_time';
export const EZLOGIC_TITLE_END_TIME = 'ezlogic.title.end_time';
export const EZLOGIC_TITLE_LAST = 'ezlogic.title.last';
export const EZLOGIC_TITLE_NO_VALID_DATE_RANGE = 'ezlogic.title.no_valid_date_range';
export const EZLOGIC_TITLE_WARNING = 'ezlogic.title.warning';
export const EZLOGIC_TITLE_SERVICE_ICON = 'ezlogic.title.service_icon';
export const EZLOGIC_TITLE_NOT_BETWEEN = 'ezlogic.title.not_between';
export const EZLOGIC_TITLE_GREAER_THAN = 'ezlogic.title.greater_than';
export const EZLOGIC_TITLE_LESS_THEN = 'ezlogic.title.less_than';
export const EZLOGIC_TITLE_ENABLE = 'ezlogic.title.enable';
export const EZLOGIC_TITLE_DISABLE = 'ezlogic.title.disable';
export const EZLOGIC_TITLE_UNLATCH = 'ezlogic.title.unlatch';
export const EZLOGIC_TITLE_IMMEDIATELY = 'ezlogic.title.immediately';
export const EZLOGIC_TITLE_DEALY = 'ezlogic.title.delay';
export const EZLOGIC_TITLE_SELECT_EXISTING_SCRIPT = 'ezlogic.title.select_existing_script';
export const EZLOGIC_TITLE_CREATE_NEW_SCRIPT = 'ezlogic.title.create_new_script';
export const EZLOGIC_HINT_LUA_SCRIPT_NODE = 'ezlogic.hint.lua_script_node';
export const EZLOGIC_TITLE_GET = 'ezlogic.title.get';
export const EZLOGIC_TITLE_POST = 'ezlogic.title.post';
export const EZLOGIC_TITLE_PUT = 'ezlogic.title.put';
export const EZLOGIC_TITLE_TARGET_URL = 'ezlogic.title.target_url';
export const EZLOGIC_TITLE_USER_ID = 'ezlogic.title.user_id';
export const EZLOGIC_TITLE_URL = 'ezlogic.title.url';
export const EZLOGIC_TITLE_URL_PLACEHOLDER = 'ezlogic.title.url_placeholder';
export const EZLOGIC_TITLE_TYPE_OF_SENDING_CONTENT = 'ezlogic.title.type_of_sending_content';
export const EZLOGIC_TITLE_SENDING_CONTENT = 'ezlogic.title.sending_content';
export const EZLOGIC_TITLE_CONTENT_TYPE = 'ezlogic.title.content_type';
export const EZLOGIC_TITLE_CONTENT = 'ezlogic.title.content';
export const EZLOGIC_TITLE_PUT_REQUEST_BODY_HERE = 'ezlogic.title.put_request_body_here';
export const EZLOGIC_HINT_SAVE_RESULT_NUCAL = 'ezlogic.title.save_result_nucal_invariable_text';
export const EZLOGIC_TITLE_SAVE_TO_LOCAL_VARIABLE = 'ezlogic.title.save_to_local_variable';
export const EZLOGIC_TITLE_THE = 'ezlogic.title.the';
export const EZLOGIC_TITLE_VARIABLE_NO_LONGER_EXISTS = 'ezlogic.title.variable_no_longer_exists';
export const EZLOGIC_TITLE_CHOOSE_A_VARIABLE = 'ezlogic.title.choose_a_variable';
export const EZLOGIC_TITLE_ADD_MANUALLY = 'ezlogic.title.add_manually';
export const EZLOGIC_TITLE_DRAG_YOUR_ITEMS_HERE = 'ezlogic.title.drag_your_items_here';
export const EZLOGIC_TITLE_TEST_YOUR_VARIABLE = 'ezlogic.title.test_your_variable';
export const EZLOGIC_TITLE_TEST_YOUR_VARIABLES = 'ezlogic.title.test_your_variables';
export const EZLOGIC_TITLE_CREATE_NEW_VARIABLE = 'ezlogic.title.create_new_variable';
export const EZLOGIC_TITLE_IGNORE_SELF_SIGNED_INVALID_CERTIFICATES =
    'ezlogic.title.ignore_self_signed_invalid_certificates';
export const EZLOGIC_TITLE_ADD_HEADER = 'ezlogic.title.add_header';
export const EZLOGIC_TITLE_VALUE_HEADER = 'ezlogic.title.value_header';
export const EZLOGIC_TITLE_KEY_HEADER = 'ezlogic.title.key_header';
export const EZLOGIC_TITLE_KEY = 'ezlogic.title.key';
export const EZLOGIC_TITLE_SET_OF_CUSTOM_HTTP = 'ezlogic.title.set_of_custom_http';
export const EZLOGIC_TITLE_POS_START = 'ezlogic.title.pos_start';
export const EZLOGIC_TITLE_POS_END = 'ezlogic.title.pos_end';
export const EZLOGIC_TITLE_WORD_TO_BE_SEARCHED_FOR = 'ezlogic.title.word_to_be_searched_for';
export const EZLOGIC_TITLE_CURRENT_WEATHER_TEMPERATURE = 'ezlogic.title.current_weather_temperature';
export const EZLOGIC_TITLE_USE_VARIABLE = 'ezlogic.title.use_variable';
export const EZLOGIC_HINT_SELECT_USER = 'ezlogic.hint.select_user';
export const EZLOGIC_TITLE_BODY = 'ezlogic.label.body';
export const EZLOGIC_TITLE_THE_ENTERED_JSON_NOT_VALID = 'ezlogic.title.the_entered_json_not_valid';
export const EZLOGIC_TITLE_RECORDING_LENGTH = 'ezlogic.title.recording_length';
export const EZLOGIC_TITLE_CAMERAS = 'ezlogic.title.cameras';
export const EZLOGIC_TITLE_REBOOT_CONTROLLER = 'ezlogic.title.reboot_controller';
export const EZLOGIC_TITLE_RESET_CONTROLLER = 'ezlogic.title.reset_controller';
export const EZLOGIC_TITLE_SOFT_RESET = 'ezlogic.title.soft_reset';
export const EZLOGIC_TITLE_FACTORY_RESET = 'ezlogic.title.factory_reset';
export const EZLOGIC_TITLE_SAVE_MESHBOT_CONFIRMATION_MSG = 'ezlogic.title.save_meshBot_confirmation_msg';
export const EZLOGIC_TITLE_SAVE_MESHBOT = 'ezlogic.title.save_meshBot';
export const EZLOGIC_TITLE_EXECUTE_LUA_SCRIPTS = 'ezlogic.title.execute_lua_scripts';
export const EZLOGIC_TITLE_SCRIPT_SUCCESSFULLY_REMOVE = 'ezlogic.title.script_successfully_remove';
export const EZLOGIC_TITLE_SCRIPT_STARTED = 'ezlogic.title.script_started';
export const EZLOGIC_TITLE_ERROR_OCCURRED_RUN_SCRIPT = 'ezlogic.title.error_occurred_run_script';
export const EZLOGIC_TITLE_SCRIPT_EXECUTED_SUCCESSFULLY = 'ezlogic.title.script_executed_successfully';
export const EZLOGIC_TITLE_ACCOUNT_ADMINISTRATOR = 'ezlogic.title.account_administrator';
export const EZLOGIC_TITLE_ADVANECED_USER = 'ezlogic.title.advanced_user';
export const EZLOGIC_TITLE_NOTIFICATION_ONLY_RECIPIENT = 'ezlogic.title.notification_only_recipient';
export const EZLOGIC_TITLE_BASIC_USER = 'ezlogic.title.basic_user';
export const EZLOGIC_TITLE_ACCOUNT_ADMINISTRATOR_DESC = 'ezlogic.title.account_administrator_desc';
export const EZLOGIC_TITLE_ADVANECED_USER_DESC = 'ezlogic.title.advanced_user_desc';
export const EZLOGIC_TITLE_NOTIFICATION_ONLY_RECIPIENT_DESC = 'ezlogic.title.notification_only_recipient_desc';
export const EZLOGIC_TITLE_BASIC_USER_DESC = 'ezlogic.title.basic_user_desc';
export const EZLOGIC_TITLE_ACCOUNT_ADMINISTRATOR_PERMISSION = 'ezlogic.title.account_administrator_permission';
export const EZLOGIC_TITLE_ADVANECED_USER_PERMISSION = 'ezlogic.title.advanced_user_permission';
export const EZLOGIC_TITLE_NOTIFICATION_ONLY_RECIPIENT_PERMISSION =
    'ezlogic.title.notification_only_recipient_permission';
export const EZLOGIC_TITLE_BASIC_USER_PERMISSION = 'ezlogic.title.basic_user_permission';
export const EZLOGIC_TITLE_ENTER_YOUR_PHONE_NUMBER = 'ezlogic.title.enter_your_phone_number';
export const EZLOGIC_TITLE_PERMISSION = 'ezlogic.title.permission';
export const EZLOGIC_TITLE_NOT_SUCCESSFULLY_CREATED = 'ezlogic.title.not_successfully_created';
export const EZLOGIC_TITLE_SUCCESSFULLY_CREATED = 'ezlogic.title.successfully_created';
export const EZLOGIC_TITLE_VIRTUAL_DEVICES_WITH = 'ezlogic.title.virtual_devices_with';
export const EZLOGIC_TITLE_PRIVATE_IP_DEVICE = 'ezlogic.title.private_ip_device';
export const EZLOGIC_TITLE_MARKET_PLACE_DEVICE = 'ezlogic.title.market_place_device';
export const EZLOGIC_TITLE_DEVICE_NAME_SMALL = 'ezlogic.title.device_name_small';
export const EZLOGIC_TITLE_ROOM_NAME = 'ezlogic.title.room_name';
export const EZLOGIC_TITLE_CONTROLLER_NAME = 'ezlogic.title.controller_name';
export const EZLOGIC_TITLE_ON_CHANGE_LABEL = 'ezlogic.title.on_change_option_label';
export const EZLOGIC_TITLE_EQUAL_LABEL = 'ezlogic.title.equal_label';
export const EZLOGIC_TITLE_NOT_EQUAL_LABEL = 'ezlogic.title.not_equal_label';
export const EZLOGIC_TITLE_LESSER_LABEL = 'ezlogic.title.lesser_label';
export const EZLOGIC_TITLE_GREATER_LABEL = 'ezlogic.title.greater_label';
export const EZLOGIC_TITLE_ERROR_WHILE_UPDATE_PLUGIN = 'ezlogic.title.error_while_update_plugin';
export const EZLOGIC_TITLE_ERROR_WHILE_CREATE_DASHBOARD = 'ezlogic.title.error_while_create_dashboard';
export const EZLOGIC_TITLE_ERROR_WHILE_CREATE_TEMPLATE = 'ezlogic.title.error_while_create_template';
export const EZLOGIC_TITLE_TEMPLATE_SUCCESSFULLY_CREATED = 'ezlogic.title.template_successfully_created';
export const EZLOGIC_TITLE_TEMPLATE_SUCCESSFULLY_UPDATED = 'ezlogic.title.template_successfully_updated';
export const EZLOGIC_TITLE_ERROR_WHILE_UPDATE_TEMPLATE = 'ezlogic.title.error_while_update_template';
export const EZLOGIC_TITLE_ERROR_WHILE_UPDATE_DASHBOARD = 'ezlogic.title.error_while_update_dashboard';
export const EZLOGIC_TITLE_ERROR_OCCURED_WHILE_REMOVING = 'ezlogic.title.an_error_occured_while_removing';
export const EZLOGIC_TITLE_ERROR_OCCURED_ADD_PLUGIN = 'ezlogic.title.an_error_occured_add_plugin';
export const EZLOGIC_TITLE_PLUGIN_SUCCESSFULLY_ADDED = 'ezlogic.title.plugin_successfully_added';
export const EZLOGIC_TITLE_HAS_BEEN_SUCCESSFULLY_REMOVED = 'ezlogic.title.has_been_successfully_removed';
export const EZLOGIC_TITLE_SUCCESSFULLY_PUBLISHED = 'ezlogic.title.successfully_published';
export const EZLOGIC_TITLE_SOMETHING_WENT_WRONG = 'ezlogic.title.something_went_wrong';
export const EZLOGIC_TITLE_SUCCESSFULLY_PUBLISHED_TO_PRIVATE = 'ezlogic.title.successfully_published_to_private';
export const EZLOGIC_TITLE_ERROR_OCCURED_PUBLISHING_TO_PRIVATE =
    'ezlogic.title.error_while_create_publishing_to_private';
export const EZLOGIC_TITLE_ERROR_OCCURED_SETTING_THE_PREFIX = 'ezlogic.title.error_while_setting_the_prefix';
export const EZLOGIC_TITLE_ERROR_OCCURED_GETTING_THE_PREFIX = 'ezlogic.title.error_while_getting_the_prefix';
export const EZLOGIC_TITLE_PREFIX_WAS_SUCCESSFULLY = 'ezlogic.title.prefix_was_successfully';
export const EZLOGIC_TITLE_UNEXPECTED_ERROR = 'ezlogic.title.unexpected_error';
export const EZLOGIC_TITLE_GOT_IT = 'ezlogic.title.got_it';
export const EZLOGIC_TITLE_DEVICE_GROUP = 'ezlogic.title.device_group';
export const EZLOGIC_TITLE_WOULD_YOU_LIKE_TO = 'ezlogic.title.would_you_like_to';
export const EZLOGIC_TITLE_PRIVATE_TEMPLATE_TO_MARKET = 'ezlogic.title.private_template_to_market';
export const EZLOGIC_TITLE_ON = 'ezlogic.title.on';
export const EZLOGIC_TITLE_TO = 'ezlogic.title.to';
export const EZLOGIC_TITLE_MESHBOT_SUCCESSFULLY_SAVED = 'ezlogic.title.meshbot_successfully_saved';
export const EZLOGIC_TOAST_MESHBOT_SUCCESSFULLY_DELETED = 'ezlogic.toast.meshbot_successfully_deleted';
export const EZLOGIC_TITLE_WAITING_FOR_UPDATE = 'ezlogic.title.waiting_for_update';
export const EZLOGIC_TITLE_CONTROLLER_SUCCESSFULLY_UPDATED = 'ezlogic.title.controller_successfully_updated';
export const EZLOGIC_TITLE_PLUGIN_SUCCESSFULLY_UPDATED = 'ezlogic.title.plugin_successfully_updated';
export const EZLOGIC_TITLE_CATEGORY_NOT_CREATED = 'ezlogic.title.category_not_created';
export const EZLOGIC_TITLE_SUCCESSFULLY_CREATE = 'ezlogic.title.successfully_create';
export const EZLOGIC_TITLE_SUCCESSFULLY_EDITED = 'ezlogic.title.successfully_edited';
export const EZLOGIC_TITLE_SUCCESSFULLY_DELETED = 'ezlogic.title.successfully_deleted';
export const EZLOGIC_TITLE_ALREADY_HAVE_CATEGORY = 'ezlogic.title.already_have_category';
export const EZLOGIC_TITLE_INSTALLATION_ADDRESS_UPDATED = 'ezlogic.title.installation_address_updated';
export const EZLOGIC_TITLE_ACCOUNT_UPDATED = 'ezlogic.title.account_updated';
export const EZLOGIC_TITLE_ARE_YOU_SURE_DELETE = 'ezlogic.title.are_you_sure_delete';
export const EZLOGIC_TITLE_CREATED_SUCCESSFULLY = 'ezlogic.title.created_successfully';
export const EZLOGIC_TITLE_UPDATED_SUCCESSFULLY = 'ezlogic.title.updated_successfully';
export const EZLOGIC_TITLE_DELETED_SUCCESSFULLY = 'ezlogic.title.deleted_successfully';
export const EZLOGIC_TITLE_PASSWORD_CHANGED_SUCCESSFULLY = 'ezlogic.title.password_changed_successfully';
export const EZLOGIC_TITLE_PLEASE_VERIFY_YOUR_EMAIL = 'ezlogic.title.please_verify_your_email';
export const EZLOGIC_TITLE_ACCESS_CODE_HASNT_REMOVED = 'ezlogic.title.access_code_hasnt_removed';
export const EZLOGIC_TITLE_ACCESS_CODE_HASNT_UPDATED = 'ezlogic.title.access_code_hasnt_updated';
export const EZLOGIC_TITLE_ACCESS_CODE_WASNT_ADDED = 'ezlogic.title.access_code_wasnt_added';
export const EZLOGIC_TITLE_MON = 'ezlogic.title.mon';
export const EZLOGIC_TITLE_TUE = 'ezlogic.title.tue';
export const EZLOGIC_TITLE_WED = 'ezlogic.title.wed';
export const EZLOGIC_TITLE_THUR = 'ezlogic.title.thur';
export const EZLOGIC_TITLE_FRI = 'ezlogic.title.fri';
export const EZLOGIC_TITLE_SAT = 'ezlogic.title.sat';
export const EZLOGIC_TITLE_SUN = 'ezlogic.title.sun';
export const EZLOGIC_TITLE_PLEASE_ENTER_NEW_PASSWORD = 'ezlogic.title.please_enter_new_password';
export const EZLOGIC_TITLE_RESET_YOUR_PASSWORD = 'ezlogic.title.reset_your_password';
export const EZLOGIC_TITLE_RE_ENTER_NEW_PASSWORD = 'ezlogic.title.re_enter_new_password';
export const EZLOGIC_TITLE_PASSWORD_RESET_SUCCESSFUL_ICON = 'ezlogic.title.password_reset_successful_icon';
export const EZLOGIC_TITLE_PASSWORD_RESET_SUCCESSFUL = 'ezlogic.title.password_reset_successful';
export const EZLOGIC_TITLE_USE_YOUR_NEW_PASSWORD = 'ezlogic.title.use_your_new_password';
export const EZLOGIC_TITLE_EMAIL_VALIDATED = 'ezlogic.title.email_validated';
export const EZLOGIC_SUBTITLE_EMAIL_VALIDATED = 'ezlogic.subtitle.email_validated';

export const EZLOGIC_TITLE_SET_YOUR_PASSWORD = 'ezlogic.title.set_your_password';
export const EZLOGIC_TITLE_PASSWORD_SET_SUCCESSFUL = 'ezlogic.title.password_set_successful';
export const EZLOGIC_TITLE_PLEASE_ENTER_PASSWORD = 'ezlogic.title.please_enter_password';
export const EZLOGIC_TITLE_NO_LOCATION_TEXT = 'ezlogic.title.no_location_text';
export const EZLOGIC_TITLE_NO_LOCATION_HEADING = 'ezlogic.title.no_location_heading';
export const EZLOGIC_TITLE_NO_DEVICE_TEXT = 'ezlogic.title.no_device_text';
export const EZLOGIC_TITLE_NO_DEVICE_HEADING = 'ezlogic.title.no_device_heading';
export const EZLOGIC_TITLE_ADD_NEW_LOCATION_BTN_TEXT = 'ezlogic.title.add_new_location_btn_text';
export const EZLOGIC_TITLE_GEOFENCING = 'ezlogic.title.geofencing';
export const EZLOGIC_TITLE_MY_LOCATIONS = 'ezlogic.title.my_locations';
export const EZLOGIC_TITLE_OPTIONS = 'ezlogic.title.options';
export const EZLOGIC_TITLE_CONNECTED_DEVICE = 'ezlogic.title.connected_device';
export const EZLOGIC_TITLE_ENABLE_GEOFENCE = 'ezlogic.title.enable_geofence';
export const EZLOGIC_TITLE_ENABLE_GEOFENCE_TEXT = 'ezlogic.title.enable_geofence_text';
// Ezlogic PlaceHolder
export const EZLOGIC_SEARCH_TITLE = 'ezlogic.search.title';

export const EZLOGIC_START_TYPING = 'ezlogic.integrations.search';
export const EZLOGIC_PlACEHOLDER_ADD_NAME = 'ezlogic.placeholder.add_name';
export const EZLOGIC_PlACEHOLDER_ADD_VERSION = 'ezlogic.placeholder.add_version';
export const EZLOGIC_PLACEHOLDER_FILE_NAME = 'ezlogic.placeholder.file_name';

// Ezlogic Loading
export const EZLOGIC_LOADING_PLESE_WAIT = 'ezlogic.loading.please_wait';

// Ezlogic Toast
export const EZLOGIC_TOAST_DEVICE_ADDED = 'ezlogic.toast.device.added';
export const EZLOGIC_TOAST_UPDATE_CONFIGURE_ERROR = 'ezlogic.toast.update.configure.error';
export const EZLOGIC_TOAST_UPDATE_CONFIGURE_SUCCESSFULLY = 'ezlogic.toast.update.configure.successfully';
export const EZLOGIC_TOAST_CORRECT_DATA_TO_DELETE = 'ezlogic.toast.correct.data.to.delete';
export const EZLOGIC_TOAST_PLUGIN_HAS_BEEN_SUCCESSFULLY = 'ezlogic.toast.plugin.has.been.successfully';
export const EZLOGIC_TOAST_VIRTUAL_CONTAINER_CONFIGURATION = 'ezlogic.toast.virtual_container_configuration';
export const EZLOGIC_TOAST_DEVICE_ERROR_WHILE_SAVING = 'ezlogic.toast.device_error_while_saving';
export const EZLOGIC_TOAST_DEVICE_DATA_UPDATED = 'ezlogic.toast.device_data_updated';
export const EZLOGIC_TOAST_REQUEST_PROCEED = 'ezlogic.toast.request_proceed';
export const EZLOGIC_TOAST_ALL_DEVICES_REMOVE = 'ezlogic.toast.all_devices_remove';
export const EZLOGIC_TOAST_SUCCESSFULLY_SAVED_MESSAGE = 'ezlogic.toast.successfully_saved_message';
export const EZLOGIC_TOAST_SUCCESSFULLY_DELETED_MESSAGE = 'ezlogic.toast.successfully_deleted_message';
export const EZLOGIC_TOAST_EXPRESSION_WAS_NOT_DELETED_MESSAGE = 'ezlogic.toast.expression_was_not_deleted_message';
export const EZLOGIC_TOAST_SCRIPT_SUCCESSFULLY_ADDED = 'ezlogic.toast.script_successfully_added';
// export const EZLOGIC_TOAST = 'ezlogic.toast';

// LUA SCRIPTS
export const EZLOGIC_LABEL_LUA_CODE = 'ezlogic.label.lua_code';

//EXPRESSIONS
export const EZLOGIC_VARIABLES_ADD_EXPRESSION_BUTTON = 'ezlo.variables.add_expression_button.title';
export const EZLOGIC_VARIABLES_ADD_VARIABLE_BUTTON = 'ezlo.variables.add_variable_button.title';
export const EZLOGIC_VARIABLES_ADD_BUTTON = 'ezlo.variables.add_button.title';
export const EZLOGIC_VARIABLES_INPUT_UNIQUE_NAME_PLACEHOLDER = 'ezlo.variables.input_unique_name.placeholder';
export const EZLOGIC_EXPRESSIONS_LUA_CODE_PLACEHOLDER = 'ezlo.expressions.lua_code.placeholder';
export const EZLOGIC_DEVICE_ADVANCED_SECTION_ASSOCIATION_SELECT_DEVICE =
    'ezlo.device_advanced.section.association.select_device';
export const EZLOGIC_SELECT_CAPABILITY_LABEL = 'ezlo.select_capability.label';
export const EZLOGIC_ADD_REFERENCE_LABEL = 'ezlo.add_reference.label';
export const EZLOGIC_VARIABLES_EXPRESSIONS_TITLE = 'ezlogic.title.expressions';
export const EZLOGIC_VARIABLES_VARIABLES_TITLE = 'ezlogic.title.variables';
export const EZLOGIC_TITLE_VARIABLES_VARIABLE_SCALE_LABEL = 'ezlo.variables.variable_scale.label';
export const EZLOGIC_VARIABLES_EXPRESSIONS_HELPER_TEXT = 'ezlogic.variables.expressions_helper_text';

// SYSTEM HISTORY
export const EZLOGIC_TITLE_ALERT_HISTORY = 'ezlogic.title.alert_history';
export const EZLOGIC_TITLE_START_TYPING = 'ezlogic.title.start_typing';
export const EZLOGIC_TITLE_ALL_EVENTS = 'ezlogic.title.all_events';
export const EZLOGIC_TITLE_IMAGE = 'ezlogic.title.image';
export const EZLOGIC_TITLE_VIDEO = 'ezlogic.title.video';
export const EZLOGIC_TITLE_TRIGGER = 'ezlogic.title.trigger';
export const EZLOGIC_TITLE_LOGIN = 'ezlogic.title.login';
export const EZLOGIC_TITLE_GATEWAY_CONNECTED = 'ezlogic.title.gateway_connected';
export const EZLOGIC_TITLE_EVENT_TYPE = 'ezlogic.title.event_type';
export const EZLOGIC_TITLE_START_DATE = 'ezlogic.title.start_date';
export const EZLOGIC_TITLE_END_DATE = 'ezlogic.title.end';
export const EZLOGIC_TITLE_INCORRECT_DATE = 'ezlogic.title.incorrect_date';

// NAVIGATION
export const EZLOGIC_TITLE_EXPRESSIONS = 'ezlogic.title.expressions';
export const EZLOGIC_TITLE_VARIABLES = 'ezlogic.title.variables';
export const EZLOGIC_TITLE_LUA_SCRIPTS = 'ezlogic.title.lua_scripts';
export const EZLOGIC_TITLE_INTEGRATION = 'ezlogic.title.integration';
export const EZLOGIC_TITLE_INTEGRATIONS = 'ezlogic.title.integrations';
export const EZLOGIC_TITLE_IP_DEVICES = 'ezlogic.title.ip_devices';
export const EZLOGIC_TITLE_VIRTUAL_CONTAINER = 'ezlogic.title.virtual_container';
export const EZLOGIC_TITLE_VIRTUAL_DEVICES = 'ezlogic.title.virtual_devices';
export const EZLOGIC_TITLE_PLUGINS = 'ezlogic.title.plugins';
export const EZLOGIC_TITLE_EDGE_PLUGINS = 'ezlogic.title.edge_plugins';
export const EZLOGIC_TITLE_PLUGIN_SETTINGS = 'ezlogic.title.plugin_settings';
export const EZLOGIC_TITLE_CUSTOMIZATION = 'ezlogic.title.customization';
export const EZLOGIC_TITLE_Z_WAVE_ASSOCIATIONS = 'ezlogic.title.z_wave_associations';
export const EZLOGIC_TITLE_NOTIFICATIONS_SETTINGS = 'ezlogic.title.notifications';
export const EZLOGIC_TITLE_MY_ACCOUNT = 'ezlogic.title.my_account';
export const EZLOGIC_TITLE_PIN_CODES = 'ezlogic.title.pin_codes';
export const EZLOGIC_TITLE_NOTIFICATION_TEMPLATES = 'ezlogic.title.notification_templates';
export const EZLOGIC_TITLE_OEM_SETTINGS = 'ezlogic.title.oem_settings';
export const EZLOGIC_TITLE_USER_MANAGEMENT = 'ezlogic.title.user_management';
export const EZLOGIC_TITLE_ALERT_EVENT_HISTORY = 'ezlogic.title.alert_event_history';
export const EZLOGIC_TITLE_SUPPORT = 'ezlogic.title.support';
export const EZLOGIC_TITLE_ACCOUNT = 'ezlogic.title.account';
export const EZLOGIC_TITLE_DEVICE_GROUPS = 'ezlogic.title.device_groups';
export const EZLOGIC_TITLE_DEVICES = 'ezlogic.title.devices';
export const EZLOGIC_TITLE_SETTINGS = 'ezlogic.title.settings';
export const EZLOGIC_TITLE_ADVANCED_SCRIPTING = 'ezlogic.title.advanced_scripting';
export const EZLOGIC_TITLE_PLUGIN_DEVELOPMENT = 'ezlogic.title.plugin_development';
export const EZLOGIC_TITLE_AUTOMATION = 'ezlogic.title.automation';
export const EZLOGIC_TITLE_MESHBOTS = 'ezlogic.title.meshbots';
export const EZLOGIC_TITLE_LOGOUT = 'ezlogic.title.logout';
export const EZLOGIC_TITLE_PREVIEW = 'ezlogic.title.preview';
export const EZLOGIC_TITLE_SHOW = 'ezlogic.title.show';
// NOTIFICATION TEMPLATES
export const EZLOGIC_TITLE_SYSTEM_NOTIFICATION_TEMPLATES = 'ezlogic.title.system_notification_templates';
export const EZLOGIC_TITLE_CREATE_NEW_TEMPLATE = 'ezlogic.title.create_new_template';
export const EZLOGIC_TITLE_TEMPLATE_NAME = 'ezlogic.title.template_name';
export const EZLOGIC_TITLE_GIVE_TEMPLATE_NAME = 'ezlogic.title.give_template_name';
export const EZLOGIC_TITLE_PUT_TEMPLATE_NAME = 'ezlogic.title.put_template_name';
export const EZLOGIC_TITLE_ACTIVE = 'ezlogic.title.active';
export const EZLOGIC_TITLE_COLUMN_TYPE = 'ezlogic.title.column_type';
export const EZLOGIC_TITLE_EDIT = 'ezlogic.title.edit';
export const EZLOGIC_TITLE_DUPLICATE = 'ezlogic.title.duplicate';
export const EZLOGIC_TITLE_TEMPLATE_INFORMATION_ABOUT_TEMPLATES = 'ezlogic.title.template_information_about_templates';
export const EZLOGIC_TITLE_TEMPLATE_INFORMATION_ABOUT_ENABLED_AND_DISABLED =
    'ezlogic.title.template_information_about_enabled_and_disabled';
export const EZLOGIC_TITLE_TEMPLATE_CREATE_NOTIFICATION_TEMPLATE = 'ezlogic.title.create_notification_template';
export const EZLOGIC_TITLE_MESHBOT_TEMPLATE = 'ezlogic.title.meshbot_template';
export const EZLOGIC_TITLE_SYSTEM_TEMPLATE = 'ezlogic.title.system_template';
export const EZLOGIC_TITLE_DUPLICATE_TEMPLATE = 'ezlogic.title.duplicate_template';
export const EZLOGIC_TITLE_COPY_OF = 'ezlogic.title.copy_of';
export const EZLOGIC_TITLE_SUPPORT_TICKET = 'ezlogic.title.support_ticket';
export const EZLOGIC_TITLE_OPEN_A_TICKET = 'ezlogic.title.open_a_ticket';
export const EZLOGIC_TITLE_HERE = 'ezlogic.title.here';
export const EZLOGIC_TITLE_DEALER_SUPPORT_CENTER = 'ezlogic.title.dealer_support_center';
export const EZLOGIC_TITLE_CONTACT_AS_FOR_HELP = 'ezlogic.title.contact_as_for_help';
export const EZLOGIC_TITLE_PRIORITY_LEVEL = 'ezlogic.title.priority_level';
export const EZLOGIC_TITLE_ISSUE_DESCRIPTION = 'ezlogic.title.issue_description';
export const EZLOGIC_TITLE_ADD_TEXT = 'ezlogic.title.add_text';
export const EZLOGIC_TITLE_CREATE_A_NEW_TICKET = 'ezlogic.title.create_a_new_ticket';
export const EZLOGIC_TITLE_CONTROLLER_SUPPORT_FOR_GROUP_OPERATIONS =
    'ezlogic.title.controller_support_for_group_operations';
//Device Groups page
export const EZLOGIC_LABEL_DEVICE_LABEL = 'ezlogic.label.device_label';
export const EZLOGIC_INFO_INTEGRATED_DEVICES = 'ezlogic.info.integrated_devices';

// MEDIA STORAGE KEYS
export const EZLOGIC_TITLE_UPLOAD_IMAGES = 'ezlogic.title.upload_images';
export const EZLOGIC_TITLE_UPLOAD_IMAGES_INFO_TEXT = 'ezlogic.title.upload_images_info_text';
export const EZLOGIC_TITLE_STORAGE_LIMIT_INFO_TEXT = 'ezlogic.title.storage_limit_info_text';
export const EZLOGIC_TITLE_CLOUD_SPACE_INFO = 'ezlogic.title.cloud_space_info';
export const EZLOGIC_TITLE_ARE_YOU_SURE_TO_WANT_DELETED_TO_SELECTED_IMAGES = 'ezlogic.title.delete_images_confirm_text';
export const EZLOGIC_TITLE_NEW = 'ezlogic.title.newest_first';
export const EZLOGIC_TITLE_OLD = 'ezlogic.title.oldest_first';
export const EZLOGIC_TITLE_DEFAULT = 'ezlogic.title.default';
export const EZLOGIC_TITLE_TODAY = 'ezlogic.title.today';
export const EZLOGIC_TITLE_WEEK = 'ezlogic.title.week';
export const EZLOGIC_TITLE_ALL_TIME = 'ezlogic.title.all_time';
export const EZLOGIC_TITLE_PREV = 'ezlogic.title.prev';
export const EZLOGIC_TITLE_SEARCH_BY_IMAGE_NAME = 'ezlogic.title.search_by_images_name';
export const EZLOGIC_TITLE_MEDIA_STORAGE = 'ezlogic.title.media_storage';
export const EZLOGIC_TITLE_YOU_HAVE_NO_UPLOADED_IMAGES_YET = 'ezlogic.title.you_have_no_upload_images_yet';
export const EZLOGIC_TITLE_YOU_HAVE_NO_UPLOADED_IMAGES_ON_THIS_PAGE_YET =
    'ezlogic.title.you_have_no_upload_images_on_page';
export const EZLOGIC_TITLE_COPY_LINK = 'ezlogic.title.copy_link';
export const EZLOGIC_TITLE_DRAG_N_DROP_SOME_IMAGES_HERE_OR_CLICK = 'ezlogic.title.drag_n_drop_images_here_or_click';
export const EZLOGIC_TITLE_ONLY_PNG_JPG_SVG_IMAGES_WILL_BE_ACCEPTED = 'ezlogic.title.only_png_jpg_svg_will_be_accepted';
export const EZLOGIC_TITLE_OPEN_FILE_DIALOG = 'ezlogic.title.open_file_dialog';
export const EZLOGIC_TITLE_LIST_OF_NON_RECEIVED_FILES_WARNING = 'ezlogic.title.list_of_non_received_files_warning';
export const EZLOGIC_TITLE_IMAGE_HAS_BEEN_SUCCESSFULLY_UPLOADED = 'ezlogic.title.image_has_been_upload';
export const EZLOGIC_TITLE_IMAGES_HAVE_BEEN_SUCCESSFULLY_DELETED = 'ezlogic.title.image_has_been_deleted';
export const EZLOGIC_TITLE_COPIED = 'ezlogic.title.copied';

//MeshBot labels
export const EZLOGIC_TITLE_HAS_BEEN_CREATED_SUCCESSFULLY = 'ezlogic.title.has_been_created_successfully';
export const EZLOGIC_TITLE_ADD_NEW_LABEL = 'ezlogic.title.add_new_label';
export const EZLOGIC_TITLE_NAME_LABEL = 'ezlogic.title.name_label';
export const EZLOGIC_TITLE_LABELS = 'ezlogic.title.labels';
export const EZLOGIC_TITLE_LABEL_HAS_BEEN_EDITED_SUCCESSFULLY = 'ezlogic.title.label_has_been_edited_successfully';
export const EZLOGIC_TITLE_LABEL_HAS_BEEN_DELETED_SUCCESSFULLY = 'ezlogic.title.label_has_been_deleted_successfully';
export const EZLOGIC_TITLE_EDIT_LABEL = 'ezlogic.title.edit_label';
export const EZLOGIC_TITLE_DELETE_LABEL_DIALOG = 'ezlogic.title.delete_label_dialog';
export const EZLOGIC_SUBTITLE_DELETE_LABEL_DIALOG = 'ezlogic.subtitle.delete_label_dialog';
export const EZLOGIC_TITLE_SHOW_LABELS_PANEL = 'ezlogic.title.show_labels_panel';
export const EZLOGIC_TITLE_HIDE_LABELS_PANEL = 'ezlogic.title.hide_labels_panel';

//CLOUD MESHBOT
export const EZLOGIC_TITLE_ADD_EXCEPTION = 'ezlogic.title.add_exception';
export const EZLOGIC_TITLE_ADD_TRIGGER = 'ezlogic.title.add_trigger';
export const EZLOGIC_TITLE_ADD_GROUP = 'ezlogic.title.add_group';
export const EZLOGIC_TITLE_ADD_ACTION = 'ezlogic.title.add_action';
export const EZLOGIC_TITLE_EXCEPTION = 'ezlogic.title.exception';
export const EZLOGIC_TITLE_CAPABILITY = 'ezlogic.title.capability';
export const EZLOGIC_TITLE_ATTRIBUTE = 'ezlogic.title.attribute';
export const EZLOGIC_TITLE_NOT = 'ezlogic.title.not';
export const EZLOGIC_TITLE_INTERVAL = 'ezlogic.title.interval';
export const EZLOGIC_TITLE_DAILY = 'ezlogic.title.daily';
export const EZLOGIC_TITLE_WEEKLY = 'ezlogic.title.weekly';
export const EZLOGIC_TITLE_MONTHLY = 'ezlogic.title.monthly';
export const EZLOGIC_TITLE_TIMEZONE = 'ezlogic.title.timezone';
export const EZLOGIC_TITLE_NODE_TYPE = 'ezlogic.title.node_type';
export const EZLOGIC_TITLE_SPECIAL_TIME_OF_A_DAY = 'ezlogic.title.special_time_of_a_day';
export const EZLOGIC_TITLE_ADD_ACCOUNT = 'ezlogic.title.add_account';
export const EZLOGIC_TITLE_SELECT_METHOD = 'ezlogic.title.select_method';
export const EZLOGIC_TITLE_SELECT_SERVICE = 'ezlogic.title.select_service';
export const EZLOGIC_TITLE_SELECT_ACCOUNT = 'ezlogic.title.select_account';
export const EZLOGIC_PLACEHOLDER_SELECT_NAME = 'ezlogic.title.select_name';
export const EZLOGIC_PLACEHOLDER_ACCOUNTS = 'ezlogic.placeholder.accounts';
export const EZLOGIC_PLACEHOLDER_METHODS = 'ezlogic.placeholder.methods';
export const EZLOGIC_HINT_NODE_TYPE = 'ezlogic.hint.node_type';
export const EZLOGIC_LABEL_EXPAND = 'ezlogic.label.expande';
export const EZLOGIC_HINT_NODE = 'ezlogic.hint.node';
export const EZLOGIC_HINT_CAPABILITY = 'ezlogic.hint.capability';
export const EZLOGIC_HINT_VALUE_TYPE = 'ezlogic.hint.value_type';
export const EZLOGIC_HINT_CONTROLLABLE_TYPE = 'ezlogic.hint.controllable_type';
export const EZLOGIC_HINT_CONTROLLABLE = 'ezlogic.hint.controllable_WC';
export const EZLOGIC_HINT_ERROR_MESHBOT_DOESNT_EXIST = 'ezlogic.hint.error_meshbot_doesnt_exist';
export const EZLOGIC_HINT_SELECT_USERS = 'ezlogic.hint.select_users';
export const EZLOGIC_HINT_SELECT_CHANNELS = 'ezlogic.hint.select_channels';
export const EZLOGIC_HINT_SELECT_CATEGORY = 'ezlogic.hint.select_category';
export const EZLOGIC_TITLE_CURRENT_VALUE = 'ezlogic.title.current_value';
export const EZLOGIC_TITLE_REQUEST_TO_GET_CURRENT_VALUE_FAILED = 'ezlogic.title.request_to_get_current_value_failed';
export const EZLOGIC_TITLE_CURRENT_VALUE_BOOLEAN = 'ezlogic.title.current_value_boolean';
export const EZLOGIC_TITLE_CHANNELS = 'ezlogic.title.channels';
export const EZLOGIC_TITLE_CATEGORIES = 'ezlogic.title.categories';
export const EZLOGIC_TITLE_SUBJECT = 'ezlogic.title.subject';
export const EZLOGIC_TITLE_MESSAGE_BODY = 'ezlogic.title.message_body';
export const EZLOGIC_TITLE_HTML_MESSAGE_BODY = 'ezlogic.title.html_message_body';
export const EZLOGIC_TITLE_MESSAGE_BODY_HTML = 'ezlogic.title.message_body_html';
export const EZLOGIC_LABEL_VALUE_TYPE = 'ezlogic.label.valueType';
export const EZLOGIC_TITLE_OFFSET = 'ezlogic.title.offset';
export const EZLOGIC_TITLE_SUNRISE = 'ezlogic.title.sunrise';
export const EZLOGIC_TITLE_SUNSET = 'ezlogic.title.sunset';
export const EZLOGIC_TITLE_BEFORE = 'ezlogic.title.before';
export const EZLOGIC_TITLE_UP_TO = 'ezlogic.title.up_to';
export const EZLOGIC_TITLE_EXACTLY_AT = 'ezlogic.title.exactly_at';
export const EZLOGIC_TITLE_MANAGE_CATEGORIES = 'ezlogic.title.manage_categories';
export const EZLOGIC_TITLE_CHARACTERS = 'ezlogic.title.characters';
export const EZLOGIC_TITLE_TITLES_LIMITS = 'ezlogic.title.titles_limits';
export const EZLOGIC_TITLE_LIMIT_FOR_IOS = 'ezlogic.title.limit_for_ios';
export const EZLOGIC_TITLE_LIMIT_FOR_ANDROID = 'ezlogic.title.limit_for_android';
export const EZLOGIC_TITLE_BE_AWARE = 'ezlogic.title.limit_be_aware';
export const EZLOGIC_TITLE_CATEGORY_NAME = 'ezlogic.title.category_name';
export const EZLOGIC_TITLE_CREATE_CATEGORY = 'ezlogic.title.create_category';
export const EZLOGIC_TITLE_AVAILABLE_KEYWORDS = 'ezlogic.title.available_keywords';
export const EZLOGIC_TITLE_KEYWORDS_USAGE = 'ezlogic.title.keywords_usage';
export const EZLOGIC_TITLE_ENTER_FRIENDLY_NAME = 'ezlogic.title.enter_friendly_name';
export const EZLOGIC_TITLE_AFTER = 'ezlogic.title.after';
export const EZLOGIC_TITLE_TRIGGER_FUNCTION = 'ezlogic.title.trigger_function';
export const EZLOGIC_TITLE_SAVE_OUTPUT_TO_CLOUD_VARIABLE = 'ezlogic.title.save_output_to_cloud_variable';
export const EZLOGIC_HINT_PLEASE_BE_AWARE = 'ezlogic.hint.please_be_aware';
export const EZLOGIC_TITLE_TEST = 'ezlogic.title.test';
export const EZLOGIC_TITLE_RAW = 'ezlogic.title.raw';
export const EZLOGIC_TITLE_SELECT_DEVICE = 'ezlogic.title.select_device';
export const EZLOGIC_TITLE_OBJECT = 'ezlogic.title.object';
export const EZLOGIC_TITLE_SIMPLE = 'ezlogic.title.simple';
export const EZLOGIC_TITLE_SELECT_A_DASHBOARD = 'ezlogic.title.select_a_dashboard';
export const EZLOGIC_TITLE_SHOW_ONLY = 'ezlogic.title.show_only';
export const EZLOGIC_TITLE_LOCATION_DELETE_CONFIRMATION_HEADING = 'ezlogic.title.location_delete_confirm_heading';
export const EZLOGIC_TITLE_LOCATION_DELETE_CONFIRMATION_TEXT = 'ezlogic.title.location_delete_confirmation_text';
export const EZLOGIC_TOAST_LOCATION_LIST_ERROR_MESSAGE = 'ezlogic.toast.location_list_error_message';
export const EZLOGIC_TOAST_LOCATION_DELETE_SUCCESS_MESSAGE = 'ezlogic.toast.location_delete_success_message';
export const EZLOGIC_TOAST_LOCATION_DELETE_ERROR_MESSAGE = 'ezlogic.toast.location_delete_error_message';
export const EZLOGIC_TOAST_MULTIPLE_ONCHANGE_WARNING = 'ezlogic.toast.multiple_onchange_warning_message_WC';
export const EZLOGIC_TOAST_MULTIPLE_NUCAL_WARNING = 'ezlogic.toast.multiple_nucal_warning_message';
export const EZLOGIC_TOAST_MULTIPLE_NUCAL_WARNING_LOCAL = 'ezlogic.toast.multiple_nucal_warning_message_local';
export const EZLOGIC_TOAST_NUCAL_COMBINING_ANOTHER_ONCHANGE_TRIGGER_WARNING =
    'ezlogic.toast.nucal_with_another_onchange_trigger_warning_message_WC';
export const EZLOGIC_TITLE_DEVICE_DELETE_CONFIRMATION_HEADING = 'ezlogic.title.device_delete_confirm_heading';
export const EZLOGIC_TITLE_DEVICE_DELETE_CONFIRMATION_TEXT = 'ezlogic.title.device_delete_confirmation_text';
export const EZLOGIC_TITLE_DEVICE_LIST_INFO = 'ezlogic.title.device_list_info';
export const EZLOGIC_TOAST_REGISTERED_DEVICES_LIST_ERROR_MESSAGE =
    'ezlogic.toast.registered_devices_list_error_message';
export const EZLOGIC_TOAST_REGISTERED_DEVICE_DELETE_ERROR_MESSAGE =
    'ezlogic.toast.registered_device_delete_error_message';
export const EZLOGIC_TOAST_REGISTERED_DEVICE_DELETE_SUCCESS_MESSAGE =
    'ezlogic.toast.registered_device_delete_success_message';
export const EZLOGIC_TOAST_ENABLE_GEOFENCE_ERROR_MESSAGE = 'ezlogic.toast.enable_geofence_error_message';
export const EZLOGIC_TOAST_SELECT_DEVICE_ERROR_MESSAGE = 'ezlogic.toast.select_device_error_message';

// The tooltips of the device page
export const EZLOGIC_DEVICES_PAGE_MODEL = 'ezlogic.devices.info.model';
export const EZLOGIC_DEVICES_PAGE_DEVICE_ID = 'ezlogic.devices.info.device_id';
export const EZLOGIC_DEVICES_PAGE_Z_WAVE_NODE_ID = 'ezlogic.devices.info.z_wave_node_id';
export const EZLOGIC_DEVICES_PAGE_MANUFACTURER = 'ezlogic.devices.info.manufacturer';
export const EZLOGIC_DEVICES_PAGE_PROTOCOL = 'ezlogic.devices.info.protocol';
export const EZLOGIC_DEVICES_PAGE_EZLO = 'ezlogic.devices.info.ezlo';
export const EZLOGIC_DEVICES_PAGE_PLUGIN = 'ezlogic.devices.info.plugin';
export const EZLOGIC_DEVICES_PAGE_2GIGTXID = 'ezlogic.devices.info.2gigtxid';

export const EZLOGIC_TITLE_CREATE_A_NEW_SUPPORT_TICKET = 'ezlogic.devices.create_a_new_support_ticket';
export const EZLOGIC_TITLE_ASSOCIATION_SUCCESSFULLY_REMOVED = 'ezlogic.title.association_successfully_removed';
export const EZLOGIC_TITLE_ASSOCIATION_SUCCESSFULLY_ADDED = 'ezlogic.title.association_successfully_added';
export const EZLOGIC_TITLE_ACTIONS_EXECUTION_POLICY_INFO = 'ezlogic.title.actions_execution_policies_info';
export const EZLOGIC_TITLE_ACTION_EXECUTION_POLICY_INFO = 'ezlogic.title.action_execution_policies_info';
export const SCENE_EXECUTION_POLICY_LABEL_KEYS = {
    IGNORE: 'ezlogic.title.execute_actions_in_parallel',
    WAIT: 'ezlogic.title.execute_actions_sequentially',
    CHECK: 'ezlogic.title.execute_actions_sequentially_as_long_as_previous_action_succeeds',
};

export const ACTION_EXECUTION_POLICY_LABEL_KEYS = {
    IGNORE: 'ezlogic.title.execute_next_action_in_parallel',
    WAIT: 'ezlogic.title.execute_next_action_sequentially',
    CHECK: 'ezlogic.title.execute_next_action_sequentially_if_this_action_succeeds',
    INHERIT: 'ezlogic.title.inherit_global_setting',
};

export const EZLOGIC_TITLE_MAXIMUM_NUMBER_OF_ASSOCIATIONS_REACHED =
    'ezlogic.title.maximum_number_of_associations_reached';

export const EZLOGIC_TITLE_DEVICE_SUPPORTS_ONLY_ONE_ASSOCIATION = 'ezlogic.title.device_supports_only_one_association';
export const EZLOGIC_TITLE_NEW_INTEGRATION = 'ezlogic.title.new_integration';
export const EZLOGIC_TITLE_MANAGE = 'ezlogic.title.manage';
export const EZLOGIC_TITLE_UPGRADE = 'ezlogic.title.upgrade';
export const EZLOGIC_TITLE_GETTING_READY = 'ezlogic.title.getting_ready';
export const EZLOGIC_TITLE_PLEASE_WAIT_PREPARING_CONTROLLER = 'ezlogic.title.please_wait_preparing_controller';
export const EZLOGIC_TITLE_DEVICE_ADDED_SUCCESSFULLY = 'ezlogic.title_device_added_successfully';
export const EZLOGIC_TITLE_PLUGIN_INTEGRATION_ADDED = 'ezlogic.title.plugin_integration_added';
export const EZLOGIC_TITLE_DONE = 'ezlogic.button.done';
export const EZLOGIC_TITLE_INSTALLATION = 'ezlogic.title.installation';
export const EZLOGIC_TITLE_PLEASE_TRY_AGAIN = 'ezlogic.title.please_try_again';
export const EZLOGIC_TITLE_INTEGRATION_FAILED = 'ezlogin.title.failed_to_add_integration';
export const EZLOGIC_TITLE_MANUALLY_ADD_DEVICE = 'ezlogic.title.manually_add_device';
export const EZLOGIC_TITLE_SELECT_CONTROLLER_TO_ADD_DEVICE = 'ezlogic.title.select_controller_to_add_device';
export const EZLOGIC_TITLE_CONFIGURE_YOUR_DEVICE = 'ezlogic.title.configure_your_device';
export const EZLOGIC_TITLE_COUNT_INTEGRATIONS_FOUND = 'ezlogic.title.count_integrations_found';
export const EZLOGIC_TITLE_YOU_HAVE = 'ezlogic.title.you_have';
export const EZLOGIC_TITLE_ACTIVE_INSTALLATION_DESCRIPTION = 'ezlogic.title.active_installation_description';
export const EZLOGIC_TITLE_NO_CONTROLLERS = 'ezlogic.title.no_controllers';

// Invalid or expired password reset link page
export const EZLOGIC_TITLE_INVALID_EXPIRED_PASSWORD_RESET_LINK = 'ezlogic.title.invalid_or_expired_password_reset_link';
export const EZLOGIC_TITLE_YOUR_PASSWORD_RESET_LINK_SEEMS_TO_BE_INVALID =
    'ezlogic.title.your_password_reset_link_seems_to_be_invalid';
export const EZLOGIC_TITLE_REQUEST_A_NEW_PASSWORD_RESET_LINK = 'ezlogic.title.request_a_new_password_reset_link';
export const EZLOGIC_TITLE_INFORMATION = 'ezlogic.title.information';
export const EZLOGIC_TITLE_IP_GENERATOR_IS_NOT_INSTALLED = 'ezlogic.title.ip_generator_is_not_installed';
export const EZLOGIC_TITLE_INSTALL_PLUGIN = 'ezlogic.button.install.plugin';
export const EZLOGIC_TITLE_POINT_ALL = 'ezlogic.point.all';
export const EZLOGIC_TITLE_TOGGLE_PASSWORD_VISIBILITY = 'ezlogic.title.toggle_password_visibility';
export const EZLOGIC_TITLE_NO_CONTROLLERS_ONLINE = 'ezlogic.title.no_controllers_online';
export const EZLOGIC_TITLE_LOADING = 'ezlogic.title.loading';
export const EZLOGIC_TITLE_ADD_LABEL = 'ezlogic.title.add_label';
export const EZLOGIC_TITLE_CREATE_NEW_LABEL = 'ezlogic.title.create_new_label';
export const EZLOGIC_TITLE_DELETE_SELECTED = 'ezlogic.title.delete_selected';
export const EZLOGIC_TITLE_LABEL_SELECTED = 'ezlogic.title.label_selected';
export const EZLOGIC_TITLE_LABEL_AS = 'ezlogic.title.label_as';
export const EZLOGIC_TITLE_APPLY = 'ezlogic.title.apply';
export const EZLOGIC_TITLE_DISPLAY_SYSTEM_MESHBOTS = 'ezlogic.title.display_system_meshbots';

export const EZLOGIC_TITLE_NAME_OR_PASSWORD_INCORRECT = 'ezlogic_title_name_or_password_incorrect';
export const EZLOGIC_TITLE_INCORRECT_EMAIL = 'ezlogic_title_incorrect_email';
export const EZLOGIC_TITLE_DO_NOT_GO_THROUGH = 'ezlogic_title_sorry_do_not_go_through';
export const EZLOGIC_TITLE_CONNECTION_ISSUE_TRY_AGAIN = 'ezlogic_title_connection_issue_try_again';
export const EZLOGIC_TITLE_STATE_OR_PROVINCE = 'ezlogic.title.state_or_province';
export const EZLOGIC_TITLE_CITY_OR_TOWN = 'ezlogic.title.city_or_town';
export const EZLOGIC_TITLE_DELETE_SELECTED_MESHBOTS = 'ezlogic.title.delete_selected_meshbots';
export const EZLOGIC_TITLE_DELETE_SELECTED_MESHBOTS_MESSAGE = 'ezlogic.title.delete_selected_meshbots_message';
//isUserLockOperation method
export const EZLOGIC_LABLE_ACTION = 'ezlogic.lable_action';
export const EZLOGIC_LABLE_PIN_CODE = 'ezlogic.lable_pin_code';
export const EZLOGIC_ERROR_TEXT_PIN_CODE = 'ezlogic.error_text_pin_code';
export const EZLOGIC_TITLE_EXAMPLE = 'ezlogic.title.example';

// Installer Access Rights
export const EZLOGIC_TITLE_INSTALLER_DEALER_ACCESS = 'ezlogic.title.installer_dealer_access';
export const EZLOGIC_TITLE_ENABLE_FOR = 'ezlogic.title.enable_for';
export const EZLOGIC_TITLE_ENABLED_UNTIL = 'ezlogic.title.enabled_until';
export const EZLOGIC_TITLE_GO_TO_LOGIN = 'ezlogic.title.go_to_login';
export const EZLOGIC_TITLE_ACCOUNT_ACCESS_REVOKED_MESSAGE = 'ezlogic.title.account_access_revoked_message';
export const EZLOGIC_TITLE_ACCOUNT_ACCESS_REVOKED = 'ezlogic.title.account_access_revoked';
export const EZLOGIC_TITLE_15_MINUTES = 'ezlogic.title.15_minutes';
export const EZLOGIC_TITLE_30_MINUTES = 'ezlogic.title.30_minutes';
export const EZLOGIC_TITLE_45_MINUTES = 'ezlogic.title.45_minutes';
export const EZLOGIC_TITLE_60_MINUTES = 'ezlogic.title.60_minutes';
export const EZLOGIC_TITLE_2_HOURS = 'ezlogic.title.2_hours';
export const EZLOGIC_TITLE_3_HOURS = 'ezlogic.title.3_hours';
export const EZLOGIC_TITLE_4_HOURS = 'ezlogic.title.4_hours';
export const EZLOGIC_TITLE_5_HOURS = 'ezlogic.title.5_hours';
export const EZLOGIC_TITLE_6_HOURS = 'ezlogic.title.6_hours';
export const EZLOGIC_TITLE_INSTALLER_MESSAGE = 'ezlogic.title.installer_disable_message';

export const EZLOGIC_TITLE_CLOUD_MESHBOT = 'ezlogic.title.cloud_meshbot';
export const EZLOGIC_TITLE_LOCAL_MESHBOT = 'ezlogic.title.local_meshbot';
export const EZLOGIC_TITLE_SYSTEM = 'ezlogic.title.system';
export const EZLOGIC_TITLE_MY_INTEGRATIONS = 'ezlogic.title_my_integrations';
export const EZLOGIC_TITLE_YOU_DONT_HAVE_ANY_INTEGRATIONS_YET = 'ezlogic.title_you_dont_have_any_integrations_yet';
export const EZLOGIC_TITLE_LETS_FIND_SOMETHING_FOR_YOU = 'ezlogic.title_lets_find_something_for_you';
export const EZLOGIC_TITLE_EXPLORE = 'ezlogic.title_explore';
export const EZLOGIC_TITLE_BACK_TO_LIST = 'ezlogit.title_back_to_list';
export const EZLOGIC_TITLE_ABOUT = 'ezlogic.title_about';
export const EZLOGIC_TITLE_ADD_INTEGRATION = 'ezlogic.title_add_new_integration';
export const EZLOGIC_TITLE_SUPPORTED_DEVICES = 'ezlogic.title_supported_devices';
export const EZLOGIC_TITLE_TRIGGERS = 'ezlogic.title.triggers';
export const EZLOGIC_TITLE_ACTIONS = 'ezlogic.title.actions';
export const EZLOGIC_TITLE_ADD_NEW_DEVICE = 'ezlogic.title.add_new_device';
export const EZLOGIC_TITLE_INSTALLED = 'ezlogic.title.installed';
export const EZLOGIC_TITLE_SUPPORTED_TRIGGERS_AND_ACTIONS = 'ezlogic.title.supported_triggers_and_actions';
export const EZLOGIC_TITLE_YOU_CAN_USE_THIS_SERVICE_IN_SCENES = 'ezlogic.title.you_can_use_this_service_in_scenes';
export const EZLOGIC_TITLE_COMMUNITY = 'ezlogic.title_community';
export const EZLOGIC_TITLE_CONFIGURE_YOUR_NEW_DEVICE = 'ezlogic.title.configure_your_new_device';
export const EZLOGIC_TITLE_WOULD_YOU_LIKE_TO_UPDATE_INTEGRATION = 'ezlogic.title_would_you_like_to_update_integration';
export const EZLOGIC_TITLE_UPDATING = 'ezlogic.title_updating';
export const EZLOGIC_TITLE_INTEGRATION_UPDATED = 'ezlogic.title_integration_updated';
export const EZLOGIC_TITLE_INTEGRATION_UPDATED_YOU_MAY_CONTINUE_USING_DEVICES =
    'ezlogic.title_integration_updated_you_may_continue_using_deices';
export const EZLOGIC_TITLE_ARE_YOU_SURE_ANT_UNINSTALL_INTEGRATION =
    'ezlogic.title.are_you_sure_want_uninstall_integration';
export const EZLOGIC_TITLE_YOUR_DEVICES_CREATED_BY_INTEGRATION_WILL_BE_REMOVED =
    'ezlogic.title.your_devices_created_by_integration_will_be_removed';
export const EZLOGIC_TITLE_SYSTEM_MESHBOT = 'ezlogic.title.system_meshbot';
export const EZLOGIC_TITLE_CHANGELOG = 'ezlogic.title.changelog';
export const EZLOGIC_TITLE_INTEGRATIONS_NO_RESULT_FOUND = 'ezlogic.title.no_results_found';
export const EZLOGIC_TITLE_WE_CANNOT_FOUNT_WHAT_YOU_SEARCHED = 'ezlogic.title.we_couldnt_find_what_you_searched';
export const EZLOGIC_TITLE_CONTROLLER_SETTINGS_RANDOMIZE_VALUES = 'ezlogic.title.controller_settings.randomize_values';
export const EZLOGIC_BUTTON_START_RANDOMIZE_VALUES = 'ezlogic.button.start_randomize_values';
export const EZLOGIC_BUTTON_STOP_RANDOMIZE_VALUES = 'ezlogic.button.stop_randomize_values';
export const EZLOGIC_INPUT_LABEL_TIMEOUT = 'ezlogic.input.label_timeout';
export const EZLOGIC_TITLE_PLEASE_WAIT_PREPARING_ACCOUNT = 'ezlogic.title.please_wait_preparing_account';
export const EZLOGIC_TITLE_CATEGORY_NOT_FOUND = 'ezlogic.title.category_not_found';
export const EZLOGIC_TEXT_SOMETHING_WENT_WRONG = 'ezlogic.text.something_went_wrong';
export const EZLOGIC_TITLE_EXIT_CREATE_DEVICE_GROUP_FORM = 'ezlogic.title.exit_create_device_group_form';
export const EZLOGIC_TITLE_EXIT_CREATE_DEVICE_GROUP_FORM_DESCRIPTION =
    'ezlogic.title.exit_create_device_group_form_description';
export const EZLOGIC_TITLE_EXIT_EDIT_DEVICE_GROUP_FORM = 'ezlogic.title.exit_edit_device_group_form';
export const EZLOGIC_TITLE_EXIT_EDIT_DEVICE_GROUP_FORM_DESCRIPTION =
    'ezlogic.title.exit_edit_device_group_form_description';
export const EZLOGIC_TABLE_ACTIONS = 'ezlogic.table.actions';
export const EZLOGIC_TABLE_AND = 'ezlogic.table.and';
export const EZLOGIC_TABLE_CANCEL = 'ezlogic.table.cancel';
export const EZLOGIC_TABLE_CHANGE_FILTER_MODE = 'ezlogic.table.change_filter_mode';
export const EZLOGIC_TABLE_CHANGE_SEARCH_MODE = 'ezlogic.table.change_search_mode';
export const EZLOGIC_TABLE_CLEAR_FILTER = 'ezlogic.table.clear_filter';
export const EZLOGIC_TABLE_CLEAR_SEARCH = 'ezlogic.table.clear_search';
export const EZLOGIC_TABLE_CLEAR_SORT = 'ezlogic.table.clear_sort';
export const EZLOGIC_TABLE_CLICK_TO_COPY = 'ezlogic.table.click_to_copy';
export const EZLOGIC_TABLE_COLLAPSE = 'ezlogic.table.collapse';
export const EZLOGIC_TABLE_COLLAPSE_ALL = 'ezlogic.table.collapse_all';
export const EZLOGIC_TABLE_COLUMN_ACTIONS = 'ezlogic.table.column_actions';
export const EZLOGIC_TABLE_COPIED_TO_CLIPBOARD = 'ezlogic.table.copied_to_clipboard';
export const EZLOGIC_TABLE_DROP_TO_GROUP_BY = 'ezlogic.table.drop_to_group_by';
export const EZLOGIC_TABLE_EDIT = 'ezlogic.table.edit';
export const EZLOGIC_TABLE_EXPAND = 'ezlogic.table.expand';
export const EZLOGIC_TABLE_EXPAND_ALL = 'ezlogic.table.expand_all';
export const EZLOGIC_TABLE_INCLUDES = 'ezlogic.table.includes';
export const EZLOGIC_TABLE_INCLUDES_ALL = 'ezlogic.table.includes_all';
export const EZLOGIC_TABLE_BETWEEN = 'ezlogic.table.between';
export const EZLOGIC_TABLE_BETWEEN_INCLUSIVE = 'ezlogic.table.between_inclusive';
export const EZLOGIC_TABLE_FILTER_BY = 'ezlogic.table.filter_by';
export const EZLOGIC_TABLE_CONTAINS = 'ezlogic.table.contains';
export const EZLOGIC_TABLE_EMPTY = 'ezlogic.table.empty';
export const EZLOGIC_TABLE_ENDS_WITH = 'ezlogic.table.ends_with';
export const EZLOGIC_TABLE_EQUALS = 'ezlogic.table.equals';
export const EZLOGIC_TABLE_FUZZY = 'ezlogic.table.fuzzy';
export const EZLOGIC_TABLE_GREATER_THAN = 'ezlogic.table.greater_than';
export const EZLOGIC_TABLE_GREATER_THAN_OR_EQUAL_TO = 'ezlogic.table.greater_than_or_equal_to';
export const EZLOGIC_TABLE_LESS_THAN = 'ezlogic.table.less_than';
export const EZLOGIC_TABLE_LESS_THAN_OR_EQUAL_TO = 'ezlogic.table.less_than_or_equal_to';
export const EZLOGIC_TABLE_FILTER_MODE = 'ezlogic.table.filter_mode';
export const EZLOGIC_TABLE_NOT_EMPTY = 'ezlogic.table.not_empty';
export const EZLOGIC_TABLE_NOT_EQUALS = 'ezlogic.table.not_equals';
export const EZLOGIC_TABLE_STARTS_WITH = 'ezlogic.table.starts_with';
export const EZLOGIC_TABLE_FILTERING_BY = 'ezlogic.table.filtering_by';
export const EZLOGIC_TABLE_GO_TO_FIRST_PAGE = 'ezlogic.table.go_to_first_page';
export const EZLOGIC_TABLE_GO_TO_LAST_PAGE = 'ezlogic.table.go_to_last_page';
export const EZLOGIC_TABLE_GO_TO_NEXT_PAGE = 'ezlogic.table.go_to_next_page';
export const EZLOGIC_TABLE_GO_TO_PREVIOUS_PAGE = 'ezlogic.table.go_to_previous_page';
export const EZLOGIC_TABLE_GRAB = 'ezlogic.table.grab';
export const EZLOGIC_TABLE_GROUP_BY_COLUMN = 'ezlogic.table.group_by_column';
export const EZLOGIC_TABLE_GROUPED_BY = 'ezlogic.table.grouped_by';
export const EZLOGIC_TABLE_HIDE_ALL = 'ezlogic.table.hide_all';
export const EZLOGIC_TABLE_HIDE_COLUMN = 'ezlogic.table.hide_column';
export const EZLOGIC_TABLE_MAX = 'ezlogic.table.max';
export const EZLOGIC_TABLE_MIN = 'ezlogic.table.min';
export const EZLOGIC_TABLE_MOVE = 'ezlogic.table.move';
export const EZLOGIC_TABLE_NO_RECORDS_TO_DISPLAY = 'ezlogic.table.no_records_to_display';
export const EZLOGIC_TABLE_NO_RESULTS_FOUND = 'ezlogic.table.no_results_found';
export const EZLOGIC_TABLE_OF = 'ezlogic.table.of';
export const EZLOGIC_TABLE_OR = 'ezlogic.table.or';
export const EZLOGIC_TABLE_PIN_TO_LEFT = 'ezlogic.table.pin_to_left';
export const EZLOGIC_TABLE_PIN_TO_RIGHT = 'ezlogic.table.pin_to_right';
export const EZLOGIC_TABLE_RESET_COLUMN_SIZE = 'ezlogic.table.reset_column_size';
export const EZLOGIC_TABLE_RESET_ORDER = 'ezlogic.table.reset_order';
export const EZLOGIC_TABLE_ROW_ACTIONS = 'ezlogic.table.row_actions';
export const EZLOGIC_TABLE_ROW_NUMBER = 'ezlogic.table.row_number';
export const EZLOGIC_TABLE_ROW_NUMBERS = 'ezlogic.table.row_numbers';
export const EZLOGIC_TABLE_ROWS_PER_PAGE = 'ezlogic.table.rows_per_page';
export const EZLOGIC_TABLE_SAVE = 'ezlogic.table.save';
export const EZLOGIC_TABLE_SEARCH = 'ezlogic.table.search';
export const EZLOGIC_TABLE_SELECTED_COUNT_OF_ROW_COUNT_ROWS_SELECTED =
    'ezlogic.table.selected_count_of_row_count_rows_selected';
export const EZLOGIC_TABLE_SELECT = 'ezlogic.table.select';
export const EZLOGIC_TABLE_SHOW_ALL = 'ezlogic.table.show_all';
export const EZLOGIC_TABLE_SHOW_ALL_COLUMNS = 'ezlogic.table.show_all_columns';
export const EZLOGIC_TABLE_SHOW_HIDE_COLUMNS = 'ezlogic.table.show_hide_columns';
export const EZLOGIC_TABLE_SHOW_HIDE_FILTERS = 'ezlogic.table.show_hide_filters';
export const EZLOGIC_TABLE_SHOW_HIDE_SEARCH = 'ezlogic.table.show_hide_search';
export const EZLOGIC_TABLE_SORT_BY_COLUMN_ASC = 'ezlogic.table.sort_by_column_asc';
export const EZLOGIC_TABLE_SORT_BY_COLUMN_DESC = 'ezlogic.table.sort_by_column_desc';
export const EZLOGIC_TABLE_SORTED_BY_COLUMN_ASC = 'ezlogic.table.sorted_by_column_asc';
export const EZLOGIC_TABLE_SORTED_BY_COLUMN_DESC = 'ezlogic.table.sorted_by_column_desc';
export const EZLOGIC_TABLE_THEN_BY = 'ezlogic.table.then_by';
export const EZLOGIC_TABLE_TOGGLE_DENSITY = 'ezlogic.table.toggle_density';
export const EZLOGIC_TABLE_TOGGLE_FULL_SCREEN = 'ezlogic.table.toggle_full_screen';
export const EZLOGIC_TABLE_TOGGLE_SELECT_ALL = 'ezlogic.table.toggle_select_all';
export const EZLOGIC_TABLE_TOGGLE_SELECT_ROW = 'ezlogic.table.toggle_select_row';
export const EZLOGIC_TABLE_TOGGLE_VISIBILITY = 'ezlogic.table.toggle_visibility';
export const EZLOGIC_TABLE_UNGROUP_BY_COLUMN = 'ezlogic.table.ungroup_by_column';
export const EZLOGIC_TABLE_UNPIN = 'ezlogic.table.unpin';
export const EZLOGIC_TABLE_UNPIN_ALL = 'ezlogic.table.unpin_all';
export const EZLOGIC_TABLE_UNSORTED = 'ezlogic.table.unsorted';
export const EZLOGIC_TITLE_ARE_YOU_SURE_WANT_TO_DISABLE_PREFIX = 'ezlogic.title.are_you_sure_want_to_disable_prefix';
export const EZLOGIC_TITLE_DISABLE_PREFIX = 'ezlogic.title.disable_prefix';
export const EZLOGIC_TITLE_ENABLE_PREFIX = 'ezlogic.title.enable_prefix';
export const EZLOGIC_TITLE_YOU_HAVE_NO_PLUGINS_YET = 'ezlogic.title.you_have_no_plugins_yet';
export const EZLOGIC_TITLE_YOUR_PLUGINS_WILL_APEAR = 'ezlogic.title.your_plugins_will_apear';
export const EZLOGIC_HEADING_UPLOAD_PLUGIN = 'ezlogic.heading.upload.plugin';
export const EZLOGIC_TITLE_MIN = 'ezlogic.title.min';
export const EZLOGIC_TITLE_MAX = 'ezlogic.title.max';
export const EZLOGIC_TITLE_YOU_HAVE_NO_TEMPLATES_YET = 'ezlogic.title.you_have_no_templates_yet';
export const EZLOGIC_TITLE_YOUR_TEMPLATES_WILL_APEAR = 'ezlogic.title.your_templates_will_apear';
export const EZLOGIC_DEVICE_GROUP_HELPER_TEXT = 'ezlogic.device_group_helper_text';
export const EZLOGIC_TITLE_TO_FIELD_INFO_TEXT = 'ezlogic.title.to_field_info_text';
export const EZLOGIC_TITLE_FROM = 'ezlogic.title.from';
export const EZLOGIC_TITLE_FROM_FIELD_INFO_TEXT = 'ezlogic.title.from_field_info_text';
export const EZLOGIC_TITLE_DISPLAY_TIME_INFO_TEXT = 'ezlogic.title.display_time_info_text';
export const EZLOGIC_TITLE_SERIAL = 'ezlogic.title.serial';
export const EZLOGIC_TITLE_HUB_TYPE = 'ezlogic.title.hub_type';
export const EZLOGIC_TITLE_BACKUP_INFO = 'ezlogic.title.backup_info';
export const EZLOGIC_TITLE_TO_FIELD_ERROR_TEXT = 'ezlogic.title.to_field_error_text';
export const EZLOGIC_TITLE_FROM_FIELD_ERROR_TEXT = 'ezlogic.title.from_field_error_text';
export const EZLOGIC_TITLE_FAILED_TO_GET_BACKUPS = 'ezlogic.title.failed_to_get_backups';
export const EZLOGIC_TITLE_HINT_TRY_TO_USE_SHORTER_DATE_RANGE = 'ezlogic.title.hint_try_to_use_shorter_date_range';
export const EZLOGIC_TITLE_PREVIEW_IMAGE_ICON_URL = 'ezlogic.title.preview_image_icon_url';
export const EZLOGIC_TITLE_COVER_IMAGE_ICON_URL = 'ezlogic.title.cover_image_icon_url';
export const EZLOGIC_TITLE_DEVICE_IMAGE_ICON_URL = 'ezlogic.title.device_image_icon_url';
export const EZLOGIC_TITLE_ADD_AT_LEAST_ONE_CAPABILITY = 'ezlogic.title.add_at_least_one_capability';
export const EZLOGIC_TITLE_UPLOAD_IMAGE_SIZE_ERROR_TEXT = 'ezlogic.title.upload_image_size_error_text';
export const EZLOGIC_TITLE_PROTOCOLS = 'ezlogic.title_protocols';

export const EZLOGIC_TITLE_SCALE_DESCRIPTION = 'ezlogic.title.scale_description';
export const EZLOGIC_TITLE_CHOOSE_SCALE = 'ezlogic.title.choose_scale';
export const EZLOGIC_TITLE_MIN_VALUE_MUST_BE_LESS_THEN_MAX = 'ezlogic.title.min_cannot_be_greater_than_max';
export const EZLOGIC_TITLE_MAX_VALUE_MUST_BE_MORE_THEN_MAX = 'ezlogic.title.max_cannot_be_smaller_than_min';
export const EZLOGIC_TITLE_DUPLICATE_MESHBOT = 'ezlogic.title.duplicate_meshbot';
export const EZLOGIC_TITLE_DUPLICATE_MESHBOT_SUCCESSFULLY_SAVED = 'ezlogic.title.duplicate_meshbot_successfully_saved';
export const EZLOGIC_TITLE_EDIT_WHEN_DONE = 'ezlogic.title.edit_when_done';
export const EZLOGIC_TITLE_CLONE_MESHBOT_FROM_ADVANCED_SCENE_1_70 =
    'ezlogic.title.clone_meshbot_from_advanced_scene_170';
export const EZLOGIC_LABEL_MESHBOT_NAME = 'ezlogic.label.meshBot_name';
export const EZLOGIC_TITLE_NEED_HELP = 'ezlogic.title.need_help';
export const EZLOGIC_TITLE_VERA_CONTROLLER = 'ezlogic.title.vera_controller';
export const EZLOGIC_TITLE_EZLO_CONTROLLER = 'ezlogic.title.ezlo_controller';
export const EZLOGIC_TITLE_OLD_CONTROLLER = 'ezlogic.title.old_controller';
export const EZLOGIC_TITLE_CURRENT_CONTROLLER = 'ezlogic.title.current_controller';
export const EZLOGIC_TITLE_START_MIGRATION = 'ezlogic.title.start_migration';
export const EZLOGIC_TITLE_MIGRATE_TO = 'ezlogic.title.migrate_to';
export const EZLOGIC_TITLE_CONTROLLER_MIGRATION = 'ezlogic.title.controller_migration';
export const EZLOGIC_TITLE_CONTROLLER_MIGRATION_DESCRIPTION = 'ezlogic.title.controller_migration_description';
export const EZLOGIC_TITLE_FILE_INVALID_NAME_ERROR_TEXT = 'ezlogic.title.file_invalid_name_error_text';
export const EZLOGIC_TITLE_FILE_INVALID_TYPE_ERROR_TEXT = 'ezlogic.title.file_invalid_type_error_text';
export const EZLOGIC_TITLE_THE_FIELD_IS_REQUIRED = 'ezlogic.title.the_field_is_required';
export const EZLOGIC_TITLE_NO_CONTROLLER_TO_MIGRATE = 'ezlogic.title.no_controllers_to_migrate';
export const EZLOGIC_TITLE_SELECT_CONTROLLER = 'ezlogic.title.select_controller';
export const EZLOGIC_TITLE_CHOOSE = 'ezlogic.title.choose';
export const EZLOGIC_TITLE_CHECK_BEFORE_MIGRATION = 'ezlogic.title.check_before_migration';
export const EZLOGIC_TITLE_RULES_OF_CHECK_BEFORE_MIGRATION = 'ezlogic.title.rules_of_check_before_migration';
export const EZLOGIC_TITLE_MIGRATION_COULD_NOT_BE_COMPLETED = 'ezlogic.title.migration_could_not_be_completed';
export const EZLOGIC_TITLE_MIGRATION_FROM_VERA_TO_EZLO_SUCCESSFULLY_FINISHED =
    'ezlogic.title.migration_from_vera_to_ezlo_successfully_finished';
export const EZLOGIC_TITLE_EZLOPI = 'ezlogic.title.ezlopi';
export const EZLOGIC_TITLE_EZLOPI_MESHBOT = 'ezlogic.title.ezlopi_meshbot';
export const EZLOGIC_VARIABLES_EXPRESSIONS_EZLOPI_NOT_SUPPORTED = 'ezlogic.notification.ezlopi_not_supported';
export const EZLOGIC_TITLE_TEMPLATE_SUCCESSFULLY_DELETED = 'ezlogic.title.template_successfully_deleted';
export const EZLOGIC_WARNING_MESHBOT_NOT_SAVED = 'ezlogic.warning_meshbot_not_saved';
export const EZLOGIC_VIRTUAL_DEVICES_EZLOPI_NOT_SUPPORTED = 'ezlogic.virtual_devices_ezlopi_not_supported';
export const EZLOGIC_DEVICE_ASSOCIATIONS_EZLOPI_NOT_SUPPORTED = 'ezlogic.device_associations_ezlopi_not_supported';
export const EZLOGIC_ERROR_TOAST_UNABLE_SAVE_MESHBOT = 'ezlogic.error_toast_unable_save_meshbot';
export const EZLOGIC_TITLE_PLUGIN_VERSION = 'ezlogic.title.plugin.version';
export const EZLOGIC_TITLE_TEMPLATE_AND_PLUGIN_VERSION_ARE_INCOMPATIBLE_UPDATE_PLEASE =
    'ezlogic.title.template_and_plugin_version_are_incompatible';
export const EZLOGIC_TITLE_EZLOPI_ADDRESSABLE_LED_CONFIG_PAGE = 'ezlogic.title.ezlopi_addressable_led_config_page';
export const EZLOGIC_TITLE_ADDRESSABLE_LED_DEVICE_NOT_FOUND = 'ezlogic.title.wled_device_not_found';
export const EZLOGIC_TITLE_ADDRESSABLE_LED_CONFIGURATION_NOT_SUPPORTED =
    'ezlogic.title.addressable_led_configuration_not_supported';
export const EZLOGIC_BUTTON_LUA_PATTERN_TESTER = 'ezlogic.button.lua_pattern_tester';
export const EZLOGIC_CHECKBOX_CUSTOM_CONTENT_TYPE = 'ezlogic_checkbox.custom_content_type';
export const EZLOGIC_TITLE_LUA_PATTERNS_TO_MATCH = 'ezlogic.placeholder.lua_patterns_to_match';
export const EZLOGIC_TITLE_MATCHES = 'ezlogic.title.matches';
export const EZLOGIC_TITLE_NO_MATCHES = 'ezlogic.title.no_matches';
export const EZLOGIC_TITLE_ENTER_TEXT_FOR_MATCHES = 'ezlogic.title.enter_text_for_matches';
export const EZLOGIC_TITLE_YOUR_RESULT_WILL_BE_DISPLAYED_HERE = 'ezlogic.title.your_result_will_be_displayed_here';
export const EZLOGIC_TITLE_TEMPLATE_AND_PLUGIN_VERSION_ARE_INCOMPATIBLE_MESSAGE =
    'ezlogic.title.template_and_plugin_version_are_incompatible_message';
export const EZLOGIC_TITLE_TEMPLATE_VERSION = 'ezlogic.title.template_version';
export const EZLOGIC_TITLE_INSTALLED_PLUGIN_VERSION = 'ezlogic.title.installed_plugin_version';
export const EZLOGIC_TITLE_MARKETPLACE_PLUGIN_VERSION = 'ezlogic.title.marketplace_plugin_version';
export const EZLOGIC_TITLE_PLATFORMS = 'ezlogic.title.platforms';
export const EZLOGIC_TITLE_ADDRESSABLE_LEDS = 'ezlogic.title.addressable_leds';
export const EZLOGIC_TITLE_SHOW_ADDRESSABLE_LED_DEVICES = 'ezlogic.title.show_addressable_led_devices_panel';
export const EZLOGIC_TITLE_HIDE_ADDRESSABLE_LED_DEVICES = 'ezlogic.title.hide_addressable_led_devices_panel';
export const EZLOGIC_TITLE_EZLO_PLUS = 'ezlogic.title.ezlo_plus';
export const EZLOGIC_TITLE_EZLO_SECURE = 'ezlogic.title.ezlo_secure';
export const EZLOGIC_TITLE_EZLO_ATOM = 'ezlogic.title.ezlo_atom';
export const EZLOGIC_TITLE_VIRTUAL_HUB = 'ezlogic.title.virtual_hub';
export const EZLOGIC_TITLE_PLUGHUB = 'ezlogic.title.plughub';
export const EZLOGIC_TITLE_LOCAL_FIRMWARE = 'ezlogic.title.local_firmware';
export const EZLOGIC_LUA_SCRIPTS_EZLOPI_NOT_SUPPORTED = 'ezlogic.lua_scripts_ezlopi_not_supported';
export const EZLOGIC_TITLE_TOKENS_FLOW_WARNING_MESSAGE = 'ezlogic_title_tokens_flow_warning_message';
export const EZLOGIC_TITLE_TOKENS_FLOW_SUCCESS_MESSAGE = 'ezlogic_title_tokens_flow_success_message';
export const EZLOGIC_TITLE_INVALID_DATA_TYPE = 'ezlogic_title_invalid_data_type';
export const EZLOGIC_TITLE_USER_LOGGED_OUT = 'ezlogic_title_user_logged_out';
export const EZLOGIC_TITLE_TOO_MANY_LOGINS = 'ezlogic.title.too_many_logins';
export const EZLOGIC_TITLE_INSIGHTS = 'ezlogic.title.too_many_insights';
export const EZLOGIC_TITLE_PUT_CODE_BODY_HERE = 'ezlogic.title.put_code_body_here';
export const EZLOGIC_TITLE_IP_DEVICE_COMMAND = 'ezlogic.title.ip_device_command';
export const EZLOGIC_TITLE_SEND_DATA = 'ezlogic.title.send_data';
export const EZLOGIC_TITLE_STATE_COMPARATORS = 'ezlogic.title.state_comparators';
// Alerts/Histoty Tokens
export const HISTORY_MNT_BTRYOUT = 'history_mnt_btryout';
export const HISTORY_MNT_BTRYLOW = 'history_mnt_btrylow';
export const HISTORY_MNT_REPLACEREQ = 'history_mnt_replacereq';
export const HISTORY_MNT_DEVICEEOL = 'history_mnt_deviceeol';
export const HISTORY_MNT_REPMAINFILT = 'history_mnt_repmainfilt';
export const HISTORY_MNT_HWFAIL = 'history_mnt_hwfail';
export const HISTORY_MNT_HWOK = 'history_mnt_hwok';
export const HISTORY_MNT_SWFAIL = 'history_mnt_swfail';
export const HISTORY_MNT_SWOK = 'history_mnt_swok';
export const HISTORY_SEC_NOSMOKE = 'history_sec_nosmoke';
export const HISTORY_SEC_SMOKE = 'history_sec_smoke';
export const HISTORY_SEC_LEAK = 'history_sec_leak';
export const HISTORY_SEC_NOLEAK = 'history_sec_noleak';
export const HISTORY_SEC_CO = 'history_sec_co';
export const HISTORY_SEC_NOCO = 'history_sec_noco';
export const HISTORY_SEC_HEATOK = 'history_sec_heatok';
export const HISTORY_SEC_HEATOVER = 'history_sec_heatover';
export const HISTORY_SEC_HEATUNDER = 'history_sec_heatunder';
export const HISTORY_SEC_TAMPERCOVER = 'history_sec_tampercover';
export const HISTORY_SEC_NOTAMPERCOVER = 'history_sec_notampercover';
export const HISTORY_SEC_DWOPENED = 'history_sec_dwopened';
export const HISTORY_SEC_DWCLOSED = 'history_sec_dwclosed';
export const HISTORY_SEC_DWHANDLEOPEN = 'history_sec_dwhandleopen';
export const HISTORY_SEC_DWHANDLECLOSED = 'history_sec_dwhandleclosed';
export const HISTORY_SEC_NOTILT = 'history_sec_notilt';
export const HISTORY_SEC_TILT = 'history_sec_tilt';
export const HISTORY_SEC_MOTION = 'history_sec_motion';
export const HISTORY_SEC_NOMOTION = 'history_sec_nomotion';
export const HISTORY_SEC_GLASSBREAK = 'history_sec_glassbreak';
export const HISTORY_SEC_NOGLASSBREAK = 'history_sec_noglassbreak';
export const HISTORY_SEC_CO2 = 'history_sec_co2';
export const HISTORY_SEC_NOCO2 = 'history_sec_noco2';
export const HISTORY_SEC_COMBUSTABLEGAS = 'history_sec_combustablegas';
export const HISTORY_SEC_TOXICGAS = 'history_sec_toxicgas';
export const HISTORY_SEC_NOGAS = 'history_sec_nogas';
export const HISTORY_SEC_SIREN = 'history_sec_siren';
export const HISTORY_SEC_NOSIREN = 'history_sec_nosiren';
export const HISTORY_SEC_RAPIDTEMPRISE = 'history_sec_rapidtemprise';
export const HISTORY_SEC_RAPIDTEMPFAIL = 'history_sec_rapidtempfail';
export const HISTORY_SEC_INTRUSION = 'history_sec_intrusion';
export const HISTORY_SEC_NOINTRUSION = 'history_sec_nointrusion';
export const HISTORY_SEC_TAMPERMOVE = 'history_sec_tampermove';
export const HISTORY_SEC_NOTAMPERMOVE = 'history_sec_notampermove';
export const HISTORY_SEC_NOTAMPERIMPACT = 'history_sec_notamperimpact';
export const HISTORY_SEC_TAMPERIMPACT = 'history_sec_tamperimpact';
export const HISTORY_SEC_TAMPERINVALIDCODE = 'history_sec_tamperinvalidcode';
export const HISTORY_LOCK_MANUAL = 'history_lock_manual';
export const HISTORY_LOCK_RF = 'history_lock_rf';
export const HISTORY_LOCK_KEYPAD = 'history_lock_keypad';
export const HISTORY_LOCK_MANUALUNLOCK = 'history_lock_manualunlock';
export const HISTORY_LOCK_RFUNLOCK = 'history_lock_rfunlock';
export const HISTORY_LOCK_KEYPADUNLOCK = 'history_lock_keypadunlock';
export const HISTORY_LOCK_JAMMED = 'history_lock_jammed';
export const HISTORY_LOCK_NOTFULLMANUAL = 'history_lock_notfullmanual';
export const HISTORY_LOCK_NOTFULLRF = 'history_lock_notfullrf';
export const HISTORY_LOCK_NOTFULLAUTO = 'history_lock_notfullauto';
export const HISTORY_LOCK_UNLOCKRFINVALIDUSER = 'history_lock_unlockrfinvaliduser';
export const HISTORY_LOCK_LOCKRFINVALIDUSER = 'history_lock_lockrfinvaliduser';
export const HISTORY_LOCK_LOCKUNKNOWN = 'history_lock_lockunknown';
export const HISTORY_LOCK_UNSECURED = 'history_lock_unsecured';
export const HISTORY_LOCK_UNSECUREDTIMEOUT = 'history_lock_unsecuredtimeout';
export const HISTORY_LOCK_UNSECUREDINSIDE = 'history_lock_unsecuredinside';
export const HISTORY_LOCK_UNSECUREDINSIDETIMEOUT = 'history_lock_unsecuredinsidetimeout';
export const HISTORY_LOCK_UNSECUREDOUTSIDE = 'history_lock_unsecuredoutside';
export const HISTORY_LOCK_UNSECUREDOUTSIDETIMEOUT = 'history_lock_unsecuredoutsidetimeout';
export const HISTORY_LOCK_SECURED = 'history_lock_secured';
export const HISTORY_LOCK_DOORLOCKUNKNOWN = 'history_lock_doorlockunknown';
export const HISTORY_LOCK_USERNEW = 'history_lock_usernew';
export const HISTORY_LOCK_USESINGLEDELETE = 'history_lock_usesingledelete';
export const HISTORY_LOCK_USERALLDELETE = 'history_lock_useralldelete';
export const HISTORY_LOCK_USERNEWPROGRAM = 'history_lock_usernewprogram';
export const HISTORY_LOCK_LATCHOPEN = 'history_lock_latchopen';
export const HISTORY_LOCK_LATCHCLOSED = 'history_lock_latchclosed';
export const HISTORY_LOCK_KEYPADTEMPDISABLED = 'history_lock_keypadtempdisabled';
export const HISTORY_LOCK_KPMODEVACATION = 'history_lock_kpmodevacation';
export const HISTORY_LOCK_KPMODEPRIVACY = 'history_lock_kpmodeprivacy';
export const HISTORY_LOCK_KPMODELOCKEDOUT = 'history_lock_kpmodelockedout';
export const HISTORY_LOCK_MASTERCODEENABLED = 'history_lock_mastercodeenabled';
export const HISTORY_LOCK_MASTERCODEDISABLED = 'history_lock_mastercodedisabled';
export const HISTORY_LOCK_USERUNLOCKED = 'history_lock_userunlocked';
export const HISTORY_LOCK_USERLOCKED = 'history_lock_userlocked';
export const HST_GRG_BARRIERCLOSED = 'hst_grg_barrierclosed';
export const HST_GRG_BARRIEROPENED = 'hst_grg_barrieropened';
export const HST_GRG_PROCESSCOMPLETED = 'hst_grg_processcompleted';
export const HST_GRG_NOTSTARTED = 'hst_grg_notstarted';
export const HST_GRG_UNATTENDEDOPERATIONENABLED = 'hst_grg_unattendedoperationenabled';
export const HST_GRG_MODEENABLED = 'hst_grg_modeenabled';
export const HST_GRG_MODEDISABLED = 'hst_grg_modedisabled';
export const HST_GRG_OBSTRUCTION = 'hst_grg_obstruction';
export const HST_GRG_NOOBSTRUCTION = 'hst_grg_noobstruction';
export const HST_GRG_LOWBATTERY = 'hst_grg_lowbattery';
export const HST_GRG_SHORTCIRCUITDETECTED = 'hst_grg_shortcircuitdetected';
export const HST_GRG_BARRIEROPFORCEEXCEEDED = 'hst_grg_barrieropforceexceeded';
export const HST_GRG_BARRIERMOTOREXCEEDEDOPTIMELIMIT = 'hst_grg_barriermotorexceededoptimelimit';
export const HST_GRG_BARRIERMOTOREXCEEDEDPHYMECLIMIT = 'hst_grg_barriermotorexceededphymeclimit';
export const HST_GRG_BARRIERDEVICE_MALFUNCTION = 'hst_grg_barrierdevice_malfunction';
export const HST_SCN_PRESS1TIME = 'hst_scn_press1time';
export const HST_SCN_RELEASED = 'hst_scn_released';
export const HST_SCN_HELDDOWN = 'hst_scn_helddown';
export const HST_SCN_PRESS2TIME = 'hst_scn_press2time';
export const HST_SCN_PRESS3TIME = 'hst_scn_press3time';
export const HST_SCN_PRESS4TIME = 'hst_scn_press4time';
export const HST_SCN_PRESS5TIME = 'hst_scn_press5time';
export const HST_SCN_ONTOOFF = 'hst_scn_ontooff';
export const HST_SCN_OFFTOON = 'hst_scn_offtoon';
export const HST_LIGHT_DIMMER = 'hst_light_dimmer';
export const HST_LIGHT_DIMMERDOWN = 'hst_light_dimmerdown';
export const HST_LIGHT_DIMMERSTOP = 'hst_light_dimmerstop';
export const HST_LIGHT_DIMMERUP = 'hst_light_dimmerup';
export const HST_LIGHT_POWER = 'hst_light_power';
export const HST_LIGHT_ON = 'hst_light_on';
export const HST_LIGHT_OFF = 'hst_light_off';
export const HST_HVACFANM_AUTOLOW = 'hst_hvacfanm_autolow';
export const HST_HVACFANM_LOW = 'hst_hvacfanm_low';
export const HST_HVACFANM_AUTOHIGH = 'hst_hvacfanm_autohigh';
export const HST_HVACFANM_HIGH = 'hst_hvacfanm_high';
export const HST_HVACFANM_AUTOMEDIUM = 'hst_hvacfanm_automedium';
export const HST_HVACFANM_MEDIUM = 'hst_hvacfanm_medium';
export const HST_HVACFANM_CIRCULATION = 'hst_hvacfanm_circulation';
export const HST_HVACFANM_HUMIDITYCIRCULATION = 'hst_hvacfanm_humiditycirculation';
export const HST_HVACFANM_LEFTANDRIGHT = 'hst_hvacfanm_leftandright';
export const HST_HVACFANM_UPANDDOWN = 'hst_hvacfanm_upanddown';
export const HST_HVACFANM_QUIET = 'hst_hvacfanm_quiet';
export const HST_HVACFANM_ON = 'hst_hvacfanm_on';
export const HST_HVACFANM_OFF = 'hst_hvacfanm_off';
export const HST_HVACFANM_AUTO = 'hst_hvacfanm_auto';
export const HST_HVACFANS_IDLEOFF = 'hst_hvacfans_idleoff';
export const HST_HVACFANS_RUNLOW = 'hst_hvacfans_runlow';
export const HST_HVACFANS_RUNHIGH = 'hst_hvacfans_runhigh';
export const HST_HVACFANS_RUNMEDIUM = 'hst_hvacfans_runmedium';
export const HST_HVACFANS_CIRCULATION = 'hst_hvacfans_circulation';
export const HST_HVACFANS_HUMCIRCULATION = 'hst_hvacfans_humcirculation';
export const HST_HVACFANS_RIGHTLEFTCIRCULATION = 'hst_hvacfans_rightleftcirculation';
export const HST_HVACFANS_UPDOWNCIRCULATION = 'hst_hvacfans_updowncirculation';
export const HST_HVACFANS_QUIETCIRCULATION = 'hst_hvacfans_quietcirculation';
export const HST_HVACM_OFF = 'hst_hvacm_off';
export const HST_HVACM_HEAT = 'hst_hvacm_heat';
export const HST_HVACM_COOL = 'hst_hvacm_cool';
export const HST_HVACM_AUTO = 'hst_hvacm_auto';
export const HST_HVACM_AUX = 'hst_hvacm_aux';
export const HST_HVACM_FANONLY = 'hst_hvacm_fanonly';
export const HST_HVACM_ECO = 'hst_hvacm_eco';
export const HST_HVAC_SETPOINT = 'hst_hvac_setpoint';
export const HST_HVAC_SETPOINTHEATING = 'hst_hvac_setpointheating';
export const HST_HVAC_SETPOINTCOOLING = 'hst_hvac_setpointcooling';
export const HST_OTHER_AIRQGOOD = 'hst_other_airqgood';
export const HST_OTHER_AIRQEXCELLENT = 'hst_other_airqexcellent';
export const HST_OTHER_AIRQLIGHTLYPOLLUTED = 'hst_other_airqlightlypolluted';
export const HST_OTHER_AIRQMODERATELY_POLLUTED = 'hst_other_airqmoderately_polluted';
export const HST_OTHER_AIRQHEAVILY_POLLUTED = 'hst_other_airqheavily_polluted';
export const HST_OTHER_AIRQSEVERELY_POLLUTED = 'hst_other_airqseverely_polluted';
export const HST_OTHER_AIRQEXTREMELY_POLLUTED = 'hst_other_airqextremely_polluted';
export const HST_SYS_BATTERYINIT = 'hst_sys_batteryinit';
export const HST_SYS_BATTERYMISSING = 'hst_sys_batterymissing';
export const HST_SYS_BATTERYCHARGING = 'hst_sys_battery charging';
export const HST_SYS_BATTERYFULL = 'hst_sys_batteryfull';
export const HST_SYS_BATTERYDISCHARGING = 'hst_sys_batterydischarging';
export const HST_SYS_BATTERYLEVEL = 'hst_sys_batterylevel';
export const HST_SYS_BATTERYREMTIME = 'hst_sys_batteryremtime';
export const HST_SYS_BATTERYHEALTH = 'hst_sys_batteryhealth';
export const HST_SYS_DEVICEADDED = 'hst_sys_deviceadded';
export const HST_SYS_HUBREBOOT = 'hst_sys_hubreboot';
export const HST_SYS_HUBNETWORKDOWN = 'hst_sys_hubnetworkdown';
export const HST_SYS_HUBNETWORKUPDATE = 'hst_sys_hubnetworkupdate';
export const HST_SYS_HUBNETWORKMODEMFAIL = 'hst_sys_hubnetworkmodemfail';
export const HST_SYS_HUBUPDATEPROGRESS = 'hst_sys_hubupdateprogress';
export const HST_SYS_HUBUPDATEFAIL = 'hst_sys_hubupdatefail';
export const HST_SYS_HUBRESTOREPROGRESS = 'hst_sys_hubrestoreprogress';

export const HST_SYS_HUBRESTOREFAIL = 'hst_sys_hubrestorefail';
export const HST_SYS_HUBBACKUPPROGRESS = 'hst_sys_hubbackupprogress';
export const HST_SYS_HUBBACKUPFAIL = 'hst_sys_hubbackupfail';
export const HST_SEC_DISARMED = 'hst_sec_disarmed';
export const HST_SEC_ARMAWAY = 'hst_sec_armaway';
export const HST_SEC_ARMSTAY = 'hst_sec_armstay';
export const HST_SEC_SENSORBYPASS = 'hst_sec_sensorbypass';
export const HST_SEC_ENTRYDELAYFINISH = 'hst_sec_entrydelayfinish';
export const HST_SEC_ALARM = 'hst_sec_alarm';
export const HST_SEC_ENTRYDELAYSTART = 'hst_sec_entrydelaystart';
export const HST_SEC_DEVICEREMOVEFROMBYPASS = 'hst_sec_deviceremovefrombypass';
export const HST_SEC_DEVICEBYPASSED = 'hst_sec_devicebypassed';
export const HST_SEC_ALARMBURGLAR = 'hst_sec_alarmburglar';
export const HST_SEC_ALARMFIRE = 'hst_sec_alarmfire';
export const HST_SEC_ALARMPANICMEDICAL = 'hst_sec_alarmpanicmedical';
export const HST_SEC_ALARMPANICPOLICE = 'hst_sec_alarmpanicpolice';
export const HST_SEC_ALARMPANICCANCEL = 'hst_sec_alarmpaniccancel';
export const HST_SEC_ALARMPANICVERIFY = 'hst_sec_alarmpanicverify';
export const HST_SEC_TESTON = 'hst_sec_teston';
export const HST_SEC_TESTOFF = 'hst_sec_testoff';
export const HISTORY_LOCK_USERPINCODE = 'history_lock_userpincode';
export const HST_DOORBELL_RING = 'hst_doorbell_ring';
export const EZLOGIC_TITLE_ALL_ACTIONS = 'ezlogic.title.all_actions';
export const EZLOGIC_TITLE_MAINTENANCE = 'ezlogic.title.maintenance';
export const EZLOGIC_TITLE_SECURITY_DEVICES = 'ezlogic.title.security_devices';
export const EZLOGIC_TITLE_DOOR_LOCKS = 'ezlogic.title.door_locks';
export const EZLOGIC_TITLE_GARAGE_DOORS = 'ezlogic.title.garage_doors';
export const EZLOGIC_TITLE_SCENE_CONTROLLERS = 'ezlogic.title.scene_controllers';
export const EZLOGIC_TITLE_LIGHTS_AND_SWITCHES = 'ezlogic.title.lights_and_switches';
export const EZLOGIC_TITLE_THERMOSTATS = 'ezlogic.title.thermostats';
export const EZLOGIC_TITLE_DOORBELL = 'ezlogic.title.doorbell';
export const EZLOGIC_TITLE_OTHER_SENSORS = 'ezlogic.title.other_sensors';
export const EZLOGIC_TITLE_ALARM = 'ezlogic.title.alarm';
export const EZLOGIC_TITLE_EMPTY_ALARM_HISTORY_PAGE_MESSAGE = 'ezlogic.title.empty_alarm_history_page_message';
export const EZLOGIC_TITLE_OPTIONAL = 'ezlogic.title.optional';
export const EZLOGIC_TITLE_NO_CONTROLLERS_YET = 'ezlogic.title.no_controllers_yet';
export const EZLOGIC_TITLE_WOULD_YOU_LIKE_TO_UPDATE = 'ezlogic.title.would_you_like_to_update';
export const EZLOGIC_TITLE_DO_YOU_WANT_TO_UPDATE = 'ezlogic.title.do_you_want_to';
export const EZLOGIC_TITLE_DOWNLOAD_PLUGIN = 'ezlogic.title.download_plugin';
export const EZLOGIC_TITLE_DO_YOU_WANT_TO_DOWNLOAD_PLUGIN = 'ezlogic.title.do_you_want_to_download_plugin';
export const EZLOGIC_ERROR_TEXT_DEVICE_GROUP = 'ezlogic.error_text_device_group';
export const EZLOGIC_ERROR_TEXT_DEVICE_CAPABILITY = 'ezlogic.error_text_device_capability';
export const EZLOGIC_TITLE_DO_YOU_WANT_TO_EXIT_CREATE_NOTIFICATION_TEMPLATE_FORM =
    'ezlogic.title.do_you_want_to_exit_create_notification_template_form';
export const EZLOGIC_TITLE_DISCARD_CREATING_NOTIFICATION_TEMPLATE = 'ezlogic.title.exit_creating_notification_template';
export const EZLOGIC_TITLE_DO_YOU_WANT_TO_EXIT_EDIT_NOTIFICATION_TEMPLATE_FORM =
    'ezlogic.title.exit_edit_notification_template_form';
export const EZLOGIC_TITLE_DISCARD_EDITING_NOTIFICATION_TEMPLATE = 'ezlogic.title.discard_edit_notification_template';
export const EZLOGIC_TITLE_FAILED_TO_UPLOAD_PHOTO = 'ezlogic.title.failed_to_upload_photo';
export const EZLOGIC_TITLE_PROFILE_PHOTOS_MUST_BE_JPG_OR_PNG = 'ezlogic.title.profile_photos_must_be_jpg_or_png';
export const EZLOGIC_TITLE_TRY_DIFFERENT_IMAGE = 'ezlogic.title.try_different_image';
export const EZLOGIC_TITLE_EDIT_YOUR_PROFILE = 'ezlogic.title.edit_your_profile';
export const EZLOGIC_TITLE_UPLOAD_PHOTO = 'ezlogic.title.upload_photo';
export const EZLOGIC_TITLE_REMOVE_PHOTO = 'ezlogic.title.remove_photo';
export const EZLOGIC_TITLE_SAVE_CHANGES = 'ezlogic.title.save_changes';
export const EZLOGIC_TITLE_CROP_YOUR_PHOTO = 'ezlogic.title.crop_your_photo';
export const EZLOGIC_TITLE_YOUR_PROFILE_HAS_BEEN_UPLOADED_SUCCESSFULLY =
    'ezlogic.title.your_profile_picture_has_been_uploaded_successfully';
export const EZLOGIC_TITLE_YOUR_PROFILE_HAS_BEEN_DELETED_SUCCESSFULLY =
    'ezlogic.title.your_profile_picture_has_been_deleted_successfully';
export const EZLOGIC_TITLE_SECOND_STREET_ADDRESS_OPTIONAL = 'ezlogic.title_second_street_address_optional';
export const EZLOGIC_TOAST_WARNING_MULTIPLE_DATE_TIME_EZLOPI = 'ezlogic.toast_warning_multiple_date_time_ezlopi';
export const EZLOGIC_TOAST_WARNING_MULTIPLE_DATE_TIME_LOCAL = 'ezlogic.toast_warning_multiple_date_time_local';
export const EZLOGIC_TITLE_HAVE_A_QUESTION_EMAIL_US = 'ezlogic.title.have_a_question_email_us';
export const EZLOGIC_TITLE_CONTACT_MY_DEALER = 'ezlogic.title.contact_my_dealer';
export const EZLOGIC_TITLE_OPERATION = 'ezlogic.title.operation';
export const EZLOGIC_TITLE_SCENE_STOP = 'ezlogic.title.scene_stop';
export const EZLOGIC_TITLE_HAVE_A_QUESTION = 'ezlogic.title.have_a_question';
export const EZLOGIC_TITLE_WE_HAVE_RECEIVED_YOUR_REQUEST = 'ezlogic.title.we_have_received_your_request';
export const EZLOGIC_TITLE_PLEASE_SAFE_TICKET_NUMBER = 'ezlogic.title.please_safe_ticket_number';
export const EZLOGIC_TITLE_THERE_WAS_A_PROBLEM_CREATING_TICKET = 'ezlogic.title.there_was_a_problem_creating_ticket';
export const EZLOGIC_TITLE_TRY_AGAIN = 'ezlogic.title.try_again';
export const EZLOGIC_TITLE_WAIT_RESULT = 'ezlogic.title.wait_result';
export const EZLOGIC_TITLE_CREATING_TICKET = 'ezlogic.title.creating_ticket';
export const EZLOGIC_TITLE_TICKET_IS_SUBMITTED = 'ezlogic.title.ticket_is_submitted';
export const EZLOGIC_TITLE_ARE_YOU_SURE = 'ezlo.generic.are_you_sure';
export const EZLOGIC_TITLE_DO_YOU_WANT_TO_ADD_THIS_PLUGIN_TO_YOUR_FILES =
    'ezlogic.title.do_you_want_to_add_this_plugin_to_your_files';
export const EZLOGIC_TITLE_FIRMWARE_VERSION = 'ezlo.settings.firmware_version.title';
export const EZLOGIC_TITLE_HUB_SERIAL_NUMBER = 'ezlo.about.title.serial';
export const EZLOGIC_TITLE_UPDATE_TIME = 'ezlo.about.title.uptime_info';
export const EZLOGIC_TITLE_REBOOT = 'ezlo.title.reboot';
export const EZLOGIC_TITLE_REMOVE = 'ezlo.title.remove';
export const EZLOGIC_TITLE_SUPPORTED_DEVICE_IMAGES = 'ezlogic.title.supported_device_images';
export const EZLOGIC_TITLE_SELECT_IMAGE = 'ezlogic.title.select_image';
export const EZLOGIC_TITLE_ADD_DEVICE = 'ezlogic.title.add_device';

export const EZLOGIC_TITLE_DEVICE_TITLE = 'ezlogic.title.device_title';
export const EZLOGIC_TITLE_PLEASE_NOTE_IF_YOU_SWITCH_FROM_HTTP = 'ezlogic.title.please_note_if_you_switch_from_http';
export const EZLOGIC_TITLE_DEVICE_LOGO = 'ezlogic.title.device_logo';
export const EZLOGIC_TITLE_ADD_SUPPORTED_DEVICE = 'ezlogic.title.add_supported_device';
export const EZLOGIC_TITLE_YOU_CAN_GET_AN_EXPORT_OF_YOUR_TEMPLATE =
    'ezlogic.title.you_can_get_an_export_of_your_template';
export const EZLOGIC_TITLE_CONDITION = 'ezlogic.title.condition';
export const EZLOGIC_TITLE_ADD_CONDITION = 'ezlogic.title.add_condition';
export const EZLOGIC_TITLE_GLOBAL_RESTRICTION_SECTION_INFO_TEXT = 'ezlogic.title.global_restriction_section_info_text';
export const EZLOGIC_TITLE_PLEASE_ENSURE_THAT_MIN_ANS_MAX_VALUES_NOT_EQUAL =
    'ezlogic.title.please_ensure_that_min_and_max_values_not_equal';
export const EZLOGIC_TITLE_VALUE_IS_REQUIRED = 'ezlogic.title.value_is_required';
export const EZLOGIC_TITLE_PREVENT_PARTNER_AND_INSTALLER_TO_LOGIN_MESSAGE =
    'ezlogic.title.prevent_partner_and_installer_to_login_message';
export const EZLOGIC_TITLE_REACHABLE = 'ezlogic.title.reachable';
export const EZLOGIC_TITLE_UNREACHABLE = 'ezlogic.title.unreachable';
export const EZLOGIC_LABLE_REACHABLE_STATE = 'ezlogic.lable_reachable_state';
('ezlogic.title.prevent_partner_and_installer_to_login_message');
export const EZLOGIC_TITLE_YOU_CANNOT_USE_THE_SAME_METHOD = 'ezlogic.title.you_cannot_use_same_method_more_than_once';
export const EZLOGIC_TOAST_DEVICE__SUCCESSFULLY_DELETED = 'ezlogic.toast.device_successfully_deleted';
export const EZLOGIC_TOAST_VIRTUAL_CONTAINER_SUCCESSFULLY_SAVED = 'ezlogic.toast.virtual_container_successfully_saved';
export const EZLOGIC_TOAST_VIRTUAL_CONTAINER_SUCCESSFULLY_DELETED =
    'ezlogic.toast.virtual_container_successfully_deleted';
export const EZLOGIC_LABEL_CONTROLLABLE_TYPE = 'ezlogic.label.controllable_type';
export const EZLOGIC_TITLE_WE_ARE_SORRY = 'ezlogic.title.we_are_sorry';
export const EZLOGIC_WARNING_FOR_DEVICE_ASSOCIATION = 'ezlogic.warning_for_device_association';
export const EZLOGIC_TITLE_HTML_VALIDATING_ERROR = 'ezlogic.title.html_validating_default_error';
export const EZLOGIC_TITLE_HTML_VALIDATING_ERROR_MSG = 'ezlogic.title.html_validating_error_msg';
export const EZLOGIC_TITLE_MESSAGE_BODY_TEXT_ONLY = 'ezlogic.title.message_body_text_only';
export const EZLOGIC_TITLE_MEESAGE_BODY_HTML_ONLY = 'ezlogic.title.message_body_html_only';
export const EZLOGIC_ERROR_LATCH_NOT_FOUND = 'ezlogic.error.latch_not_found';
export const EZLOGIC_TOAST_VIRTUAL_CONTAINER_SAVE_FAILED = 'ezlogic.toast.virtual_container_save_failed';
